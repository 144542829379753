import {useMutation, useQuery} from '@apollo/client'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import {notify} from 'ui/molecules/CustomNotifications'
import StyledButton from 'ui/atoms/StyledButton'
import StyledInput from 'ui/atoms/StyledInput'
import StyledTable from 'ui/molecules/StyledTable'
import PromotionAssignmentDialog from 'clientDashboard/growthAndSales/PromotionAssignmentDialog'
import SkuListDialog from 'clientDashboard/growthAndSales/PromotionsDialog'
import {ADD_PROMOTION} from 'graphql/mutations/promo-engine.mutations'
import {
  GET_PRODUCT_SKU_LIST,
  GET_PROMOTION_SCHEMAS_TYPE,
  GET_PROMOTION_TYPES,
  GET_PROMOTIONS,
  GET_RULES
} from 'graphql/queries/promo-engine.queries'
import {MRT_ColumnDef} from 'material-react-table'
import {useEffect, useMemo, useState} from 'react'
import {PromoRuleType, PromoType} from 'types/promotion.types'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessId} from 'clientDashboard/employee.selectors'

const Promotions = () => {
  const businessId = useAppSelector(selectBusinessId)

  const [promotionName, setPromotionName] = useState('')
  const [promotionType, setPromotionType] = useState('')
  const [promotionValue, setPromotionValue] = useState(0)
  const [description, setDescription] = useState('')
  const [promotionSchema, setPromotionSchema] = useState('')

  const [active] = useState(true)
  const [canBeCombined] = useState(true)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [priority, setPriority] = useState(1)
  const [selectedRules, setSelectedRules] = useState<string[]>([])
  const {data: productSkuListData} = useQuery(GET_PRODUCT_SKU_LIST)
  const [subscriptionType, setSubscriptionType] = useState(false)

  const [productSkuList, setProductSkuList] = useState<string[]>([])
  useEffect(() => {
    if (productSkuListData && productSkuListData.getProductSkuList) {
      setProductSkuList(productSkuListData.getProductSkuList)
    }
  }, [productSkuListData])

  const {loading, data: allPromotionsData} = useQuery(GET_PROMOTIONS, {
    variables: {businessId: businessId}
  })

  const {data: allRulesData} = useQuery(GET_RULES, {
    variables: {businessId: businessId}
  })
  const [createPromotion] = useMutation(ADD_PROMOTION)

  const [promotionTypes, setPromotionTypes] = useState<string[]>([])
  const {data: promotionTypesData} = useQuery(GET_PROMOTION_TYPES)

  useEffect(() => {
    if (promotionTypesData && promotionTypesData.getPromotionTypes) {
      setPromotionTypes(promotionTypesData.getPromotionTypes)
    }
  }, [promotionTypesData])

  const [promotionSchemas, setPromotionSchemas] = useState<string[]>([])
  const {data: promotionSchemasData} = useQuery(GET_PROMOTION_SCHEMAS_TYPE)

  useEffect(() => {
    if (promotionSchemasData && promotionSchemasData.getPromotionSchemaTypes) {
      setPromotionSchemas(promotionSchemasData.getPromotionSchemaTypes)
    }
  }, [promotionSchemasData])

  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogTwpOpen, setDialogTwoOpen] = useState(false)

  const handleOpenDialog = (promotionName: string) => {
    setDialogOpen(true)
    setPromotionToDisplay(promotionName)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setPromotionToDisplay('')
  }

  const [promotionToDisplay, setPromotionToDisplay] = useState('')

  const handleCreatePromotion = () => {
    createPromotion({
      variables: {
        businessId: businessId,
        active,
        canBeCombined,
        endDate,
        priority,
        promotionName,
        promotionType,
        rules: selectedRules || [],
        startDate,
        promotionValue,
        description,
        promotionSchema,
        subscriptionType
      },
      refetchQueries: [
        {query: GET_PROMOTIONS, variables: {businessId: businessId}}
      ],
      onError: (error) => {
        notify.show(error.message, 'error')
      }
    })
  }

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID'
      },
      {
        accessorKey: 'promotionName',
        header: 'Promotion Name'
      },
      {
        accessorKey: 'promotionType',
        header: 'Promotion Type'
      },
      {
        accessorKey: 'promotionValue',
        header: 'Value'
      },

      {
        accessorKey: 'active',
        header: 'Active',
        Cell: ({cell}) => {
          return cell.getValue() ? 'Yes' : 'No'
        }
      },
      {
        accessorKey: 'priority',
        header: 'Priority',
        Cell: ({cell}) => {
          return `P:${cell.getValue()}`
        }
      },
      {
        accessorKey: 'rules',
        header: 'Rules',
        Cell: ({cell}) => {
          return (
            <ul>
              {((cell?.getValue() as any) || []).map((rule: PromoRuleType) => (
                <li key={rule.ruleName}>{rule.ruleName}</li>
              ))}
            </ul>
          )
        }
      },
      {
        accessorKey: 'promotionSchema',
        header: 'Promotion Schema',
        Cell: ({cell, row}) => {
          return cell.getValue() === 'LineItem' ? (
            <StyledButton
              variant='outlined'
              onClick={() => handleOpenDialog(row.original.promotionName)}
            >
              ADD to SKU
            </StyledButton>
          ) : (
            ''
          )
        }
      }
    ],
    []
  )

  return (
    <Grid>
      <Grid item>
        <Typography
          variant='body2'
          style={{
            fontSize: '24px'
          }}
        >
          Create Promotions
        </Typography>
      </Grid>

      <Grid item container spacing={4} padding={2}>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            fullWidth
            label='Promotion Name'
            value={promotionName}
            onChange={(e) => setPromotionName(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id='rule-type-label'>Promotion Type</InputLabel>
            <Select
              fullWidth
              inputProps={{'aria-label': 'Without label'}}
              size='medium'
              labelId='rule-type-label'
              id='rule-type-select'
              value={promotionType}
              label='Promotion Type'
              onChange={(e) => setPromotionType(e.target.value)}
            >
              {promotionTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            fullWidth
            label='Promotion Value'
            type='number'
            value={promotionValue}
            onChange={(e) => {
              const newValue = parseInt(e.target.value, 10)
              setPromotionValue(!isNaN(newValue) ? Math.max(newValue, 0) : 0)
            }}
            InputProps={{inputProps: {min: 0}}}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            label='Description'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            label='Start Date'
            type='date'
            value={startDate.toISOString().split('T')[0]} // Format date for input type 'date'
            onChange={(e) => setStartDate(new Date(e.target.value))}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            label='End Date'
            type='date'
            value={endDate.toISOString().split('T')[0]} // Format date for input type 'date'
            onChange={(e) => setEndDate(new Date(e.target.value))}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            label='Priority'
            type='number'
            value={priority}
            onChange={(e) => setPriority(parseInt(e.target.value))}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id='rules-label'>Select Rules</InputLabel>
            <Select
              fullWidth
              inputProps={{'aria-label': 'Without label'}}
              size='medium'
              label='Select Rules'
              labelId='rules-label'
              id='rules-select'
              multiple
              value={selectedRules}
              onChange={(e) => setSelectedRules(e.target.value as string[])}
            >
              <MenuItem value='' disabled>
                Select a rule
              </MenuItem>

              {allRulesData?.allRules.map((rule: PromoRuleType) => (
                <MenuItem key={rule.ruleName} value={rule.ruleName}>
                  {rule.ruleName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id='promotion-schema-label'>
              Promotion Schema
            </InputLabel>
            <Select
              fullWidth
              inputProps={{'aria-label': 'Without label'}}
              size='medium'
              label='Promotion Schema'
              labelId='promotion-schema-label'
              id='promotion-schema-select'
              value={promotionSchema}
              onChange={(e) => setPromotionSchema(e.target.value)}
            >
              {promotionSchemas.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={subscriptionType}
                onChange={(e) => {
                  setSubscriptionType(e.target.checked)
                }}
              />
            }
            label='Subscription Type'
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        spacing={4}
        padding={2}
        style={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <Grid item>
          <StyledButton
            variant='contained'
            onClick={handleCreatePromotion}
            disabled={!promotionName || !promotionType || !promotionSchema}
          >
            Create Promotion
          </StyledButton>
        </Grid>

        <Grid item>
          <StyledButton
            variant='contained'
            onClick={() => setDialogTwoOpen(true)}
          >
            Try Promotions
          </StyledButton>
        </Grid>
      </Grid>

      <StyledTable
        columns={columns}
        data={allPromotionsData?.getAllPromotions || []}
        state={{
          showSkeletons: loading,
          showProgressBars: loading
        }}
      />

      <SkuListDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        productSkuList={productSkuList}
        promotionName={promotionToDisplay}
      />
      <PromotionAssignmentDialog
        open={dialogTwpOpen}
        onClose={() => setDialogTwoOpen(false)}
        productSkuList={productSkuList}
        promotionsList={allPromotionsData?.getAllPromotions.map(
          (promotion: PromoType) => promotion.promotionName
        )}
      />
    </Grid>
  )
}

export default Promotions
