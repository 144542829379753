import {RootState} from 'consumerAgent/consumerAgent.store'

const selectPaymentIntentClientSecret = (state: RootState) =>
  state.payment.paymentIntentClientSecret
const selectPaymentGatewayReferenceId = (state: RootState) =>
  state.payment.paymentGatewayReferenceId

const selectIsPaymentSuccessful = (state: RootState) =>
  state.payment.isPaymentSuccessful

const selectPaymentInProgress = (state: RootState) =>
  state.payment.paymentInProgress

const selectPaymentMetadata = (state: RootState) =>
  state.payment.paymentMetadata

const selectSelectedPaymentMethod = (state: RootState) =>
  state.payment.selectedPaymentMethod

const selectIsPollingOrderPaymentStatus = (state: RootState) =>
  state.payment.isPollingOrderPaymentStatus

export {
  selectPaymentIntentClientSecret,
  selectPaymentGatewayReferenceId,
  selectIsPaymentSuccessful,
  selectPaymentInProgress,
  selectPaymentMetadata,
  selectSelectedPaymentMethod,
  selectIsPollingOrderPaymentStatus
}
