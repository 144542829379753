import {useLazyQuery} from '@apollo/client'
import {Avatar, Box, Grid, Typography} from '@mui/material'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {
  selectBusinessId,
  selectLocationId
} from 'clientDashboard/employee.selectors'
import Transactions from 'clientDashboard/growthAndSales/Transactions'
import {CustomerType} from 'graphql/generatedTypes/graphql'
import {FETCH_CUSTOMERS} from 'graphql/queries/customers.queries'
import {useEffect, useState} from 'react'
import Barcode from 'react-barcode'
import {useParams} from 'react-router-dom'
import {stringAvatar} from 'utils/common'

function CustomerProfile() {
  const {id}: any = useParams()
  const [data, setData] = useState<CustomerType[]>([])
  const businessId = useAppSelector(selectBusinessId)
  const locationId = useAppSelector(selectLocationId)
  const [getCustomers, {data: customersData}] = useLazyQuery(FETCH_CUSTOMERS, {
    fetchPolicy: 'no-cache'
  })

  const fetchAllCustomers = () => {
    getCustomers({
      variables: {
        businessId: businessId as string,
        locationId: locationId as string,
        customerId: id as string
      }
    })
  }

  useEffect(() => {
    fetchAllCustomers()
  }, [])

  useEffect(() => {
    if (customersData) {
      setData(customersData.listCustomers as CustomerType[])
    }
  }, [customersData])

  const customerRow = data[0]

  return (
    <Grid item style={{overflowX: 'auto', width: '100%'}}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-around',
          left: '30px',
          maxWidth: '1000px',
          position: 'sticky',
          width: '100%'
        }}
      >
        {customerRow?.avatar ? (
          <Avatar
            alt='avatar'
            src={customerRow?.avatar}
            sx={{width: 150, height: 150}}
          />
        ) : (
          <Avatar
            {...stringAvatar(
              `${customerRow?.firstName} ${customerRow?.lastName}`
            )}
            sx={{width: 150, height: 150}}
          />
        )}
        <Box sx={{textAlign: 'center'}}>
          <Typography variant='h4'>
            Name: {`${customerRow?.firstName} ${customerRow?.lastName}`}
          </Typography>
          <Typography variant='h4'>Email: {customerRow?.email}</Typography>
          <Typography variant='h4'>Phone: {customerRow?.phone}</Typography>
        </Box>
        <Box sx={{textAlign: 'right'}}>
          <Barcode
            value={`cust-${customerRow?.barcode || ''}`}
            width={2}
            height={100}
            textPosition={'right'}
          />
        </Box>
      </Box>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Transactions customerId={id} />
      </LocalizationProvider>
    </Grid>
  )
}

export default CustomerProfile
