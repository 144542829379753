import {useMutation} from '@apollo/client'
import {Grid, MenuItem} from '@mui/material'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import {OMS_CATEGORY, SHOPIFY, SHOPIFY_OMS} from 'constants/constants'
import {
  ServiceConfigProviderMetadataType,
  ServiceConfigType
} from 'graphql/generatedTypes/graphql'
import {ADD_UPDATE_SERVICE_CONFIG} from 'graphql/mutations/service-config.mutations'
import useNotify from 'hooks/useNotify'
import {cloneDeep, set} from 'lodash'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {titleize} from 'utils/common'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessAndLocation} from 'clientDashboard/employee.selectors'

type ShopifyServiceConfigProps = {
  serviceConfig: ServiceConfigType
  toggleServiceConfigInputDialog: (flag: boolean) => void
  onSuccessCallback: () => void
  inputDialogOpen: boolean
  onCloseOrCancelCallback: () => void
  providerMetadata: ServiceConfigProviderMetadataType
}

const defaultFormValues: ServiceConfigType = {
  type: SHOPIFY,
  category: OMS_CATEGORY,
  configs: {
    adminAccessToken: '',
    shopName: '',
    toteShopifyAppSecretKey: '',
    toteShopifyAppApiKey: '',
    apiVersion: '',
    toteOauthClientId: '',
    webhookTopicsToSubscribe: []
  }
}

const ShopifyServiceConfigDialog = ({
  serviceConfig,
  toggleServiceConfigInputDialog,
  onSuccessCallback,
  inputDialogOpen,
  onCloseOrCancelCallback,
  providerMetadata
}: ShopifyServiceConfigProps) => {
  const {t} = useTranslation()
  const notify = useNotify()
  const {businessId} = useAppSelector(selectBusinessAndLocation)
  const [formValues, setFormValues] = useState({...defaultFormValues})
  const handleChange = (path: string, value: string) => {
    setFormValues((prev) => {
      return set({...prev}, path, value)
    })
  }
  const [adminAccessToken, setAdminAccessToken] = useState('')
  const [toteShopifyAppSecretKey, setToteShopifyAppSecretKey] = useState('')
  const [
    isToteShopifyAppSecretKeyEditMode,
    setIsToteShopifyAppSecretKeyEditMode
  ] = useState(false)
  const [isAdminAccessTokenEditMode, setIsAdminAccessTokenEditMode] =
    useState(false)
  const handleToteShopifyAppSecretKeyChange = (path: string, value: any) => {
    setToteShopifyAppSecretKey(value)
    handleChange(path, value)
  }
  const handleAdminAccessTokenChange = (path: string, value: any) => {
    setAdminAccessToken(value)
    handleChange(path, value)
  }
  useEffect(() => {
    setFormValues(cloneDeep(serviceConfig))
  }, [serviceConfig])
  const [_addUpdateServiceConfig, {loading: loadingAddUpdateServiceConfig}] =
    useMutation(ADD_UPDATE_SERVICE_CONFIG, {
      onCompleted: () => {
        onSuccessCallback()
        toggleServiceConfigInputDialog(false)
        setIsToteShopifyAppSecretKeyEditMode(false)
        setIsAdminAccessTokenEditMode(false)
      },
      onError: (error) => {
        toggleServiceConfigInputDialog(false)
        setIsToteShopifyAppSecretKeyEditMode(false)
        setIsAdminAccessTokenEditMode(false)
        console.log('Error adding shopify service configs', error)
        let error_code = error.graphQLErrors[0].extensions.code
        let error_message = error.message
        if (error_code === 'RATE_LIMIT_ERROR') {
          notify.show(
            t('oms-settings.shopify.throttled-try-again-in-sometime'),
            'error'
          )
        } else if (error_message.includes('Incorrect Shopify Credentials')) {
          notify.show(t('oms-settings.shopify.invalid-credentials'), 'error')
        } else if (error_message.includes('Invalid webhook topic')) {
          notify.show(t('oms-settings.shopify.invalid-webhook-topic'), 'error')
        } else if (error_message.includes('Invalid OAuth Client ID')) {
          notify.show(
            t('oms-settings.shopify.invalid-oauth-client-id'),
            'error'
          )
        } else
          notify.show(
            t('oms-settings.shopify.error-adding-shopify-service-configs'),
            'error'
          )
      }
    })

  const addUpdateServiceConfig = () => {
    _addUpdateServiceConfig({
      variables: {
        input: {
          businessId,
          name: SHOPIFY_OMS,
          type: SHOPIFY,
          category: OMS_CATEGORY,
          configs: {
            adminAccessToken: formValues.configs?.adminAccessToken,
            shopName: formValues.configs?.shopName,
            toteShopifyAppSecretKey:
              formValues.configs?.toteShopifyAppSecretKey,
            toteShopifyAppApiKey: formValues.configs?.toteShopifyAppApiKey,
            apiVersion: formValues.configs?.apiVersion,
            toteOauthClientId: formValues.configs?.toteOauthClientId,
            webhookTopicsToSubscribe:
              formValues.configs?.webhookTopicsToSubscribe
          }
        }
      }
    })
  }

  const loadShopifyServiceConfigDialogBody = () => {
    return (
      <Grid container spacing={4} padding={2}>
        <Grid item xs={12}>
          <StyledInput
            label={t('oms-settings.oms')}
            value={titleize(SHOPIFY)}
            variant='outlined'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label={t('oms-settings.shopify.form.shop-name')}
            variant='outlined'
            value={formValues.configs?.shopName}
            onChange={(e: any) =>
              handleChange('configs.shopName', e.target.value)
            }
            helperText={t('oms-settings.shopify.form.shop-name-helper-text')}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label={t('oms-settings.shopify.form.api-version')}
            variant='outlined'
            value={formValues.configs?.apiVersion}
            onChange={(e: any) =>
              handleChange('configs.apiVersion', e.target.value)
            }
            select
            helperText={t('oms-settings.shopify.form.api-version-helper-text')}
          >
            {providerMetadata?.shopifyMetadata?.availableApiVersions?.map(
              (apiVersion: string) => (
                <MenuItem key={apiVersion} value={apiVersion}>
                  {apiVersion}
                </MenuItem>
              )
            )}
          </StyledInput>
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            fullWidth
            label={t('oms-settings.shopify.form.admin-access-token')}
            variant='outlined'
            value={
              !isAdminAccessTokenEditMode
                ? formValues.configs?.adminAccessTokenMasked || ''
                : adminAccessToken || ''
            }
            onChange={(e: any) =>
              handleAdminAccessTokenChange(
                'configs.adminAccessToken',
                e.target.value
              )
            }
            onFocus={() => setIsAdminAccessTokenEditMode(true)}
            autoComplete='off'
            helperText={t(
              'oms-settings.shopify.form.admin-access-token-helper-text'
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label={t('oms-settings.shopify.form.tote-shopify-app-api-key')}
            variant='outlined'
            value={formValues.configs?.toteShopifyAppApiKey}
            onChange={(e: any) =>
              handleChange('configs.toteShopifyAppApiKey', e.target.value)
            }
            autoComplete='off'
            helperText={t(
              'oms-settings.shopify.form.tote-shopify-app-api-key-helper-text'
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInput
            fullWidth
            label={t('oms-settings.shopify.form.tote-shopify-app-secret-key')}
            variant='outlined'
            value={
              !isToteShopifyAppSecretKeyEditMode
                ? formValues.configs?.toteShopifyAppSecretKeyMasked || ''
                : toteShopifyAppSecretKey || ''
            }
            onChange={(e: any) =>
              handleToteShopifyAppSecretKeyChange(
                'configs.toteShopifyAppSecretKey',
                e.target.value
              )
            }
            onFocus={() => setIsToteShopifyAppSecretKeyEditMode(true)}
            autoComplete='off'
            helperText={t(
              'oms-settings.shopify.form.tote-shopify-app-secret-key-helper-text'
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            fullWidth
            label={t('oms-settings.shopify.form.tote-oauth-client-id')}
            variant='outlined'
            value={formValues.configs?.toteOauthClientId}
            onChange={(e: any) =>
              handleChange('configs.toteOauthClientId', e.target.value)
            }
            autoComplete='off'
            helperText={t(
              'oms-settings.shopify.form.tote-oauth-client-id-helper-text'
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            fullWidth
            label={t('oms-settings.shopify.form.webhooks-topics-to-subscribe')}
            variant='outlined'
            value={formValues.configs?.webhookTopicsToSubscribe || []}
            onChange={(e: any) =>
              handleChange('configs.webhookTopicsToSubscribe', e.target.value)
            }
            helperText={t(
              'oms-settings.shopify.form.webhook-topics-subscription-helper-text'
            )}
            select
            SelectProps={{
              multiple: true
            }}
          >
            {providerMetadata?.shopifyMetadata?.webhookTopicsToSubscribe?.map(
              (topic: string) => (
                <MenuItem key={topic} value={topic}>
                  {topic}
                </MenuItem>
              )
            )}
          </StyledInput>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid>
      <StyledDialog
        open={inputDialogOpen}
        title={t('oms-settings.add-oms')}
        onClose={() => {
          toggleServiceConfigInputDialog(false)
          onCloseOrCancelCallback()
        }}
        body={loadShopifyServiceConfigDialogBody()}
        successButtonName={t('save')}
        cancelButtonName={t('cancel')}
        cancelCallback={() => {
          toggleServiceConfigInputDialog(false)
          onCloseOrCancelCallback()
        }}
        successCallback={() => {
          addUpdateServiceConfig()
        }}
        disableSuccessButton={loadingAddUpdateServiceConfig}
      ></StyledDialog>
    </Grid>
  )
}

export default ShopifyServiceConfigDialog
