import {useMutation} from '@apollo/client'
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Typography
} from '@mui/material'
import theme from 'config/theme'
import {
  CustomerType,
  DeliveryTimeSlotType
} from 'graphql/generatedTypes/graphql'
import {
  ADD_CUSTOMER_ADDRESS,
  UPDATE_CART_DELIVERY_INFO,
  VALIDATE_CUSTOMER_SIGNUP_TOKEN
} from 'graphql/mutations/customer.mutation'
import useNotify from 'hooks/useNotify'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import StyledButton from 'ui/atoms/StyledButton'
import Loading from 'ui/molecules/Loading'
import {StyledMenuItem} from 'ui/molecules/StyledSelect'
import AddressFields from 'ui/organisms/AddressFields'

// Define the Address and TimeType types
type Address = {
  street: string
  city: string
  state: string
  zipCode: string
  country: string
}

type TimeType = {
  hours: number
  minutes: number
}

const defaultAddress: Address = {
  street: '',
  city: '',
  state: '',
  zipCode: '',
  country: ''
}

const formattedTimeSlotString = (start: TimeType, end: TimeType): string => {
  const startTime = moment()
    .hour(start.hours)
    .minute(start.minutes)
    .format('hha')
  const endTime = moment().hour(end.hours).minute(end.minutes).format('hha')
  return `${startTime} - ${endTime}`
}

const CustomerDelivery = () => {
  const {t} = useTranslation()
  const notify = useNotify()

  // Extract token from URL params
  const urlParams = new URLSearchParams(window.location.search)
  const tokenParam = urlParams.get('token') || ''

  // State variables
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false)
  const [customer, setCustomer] = useState<CustomerType | null>(null)
  const [cartId, setCartId] = useState<string>('')
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] =
    useState<Address>(defaultAddress)
  const [newAddress, setNewAddress] = useState<Address>(defaultAddress)
  const [showAddressFields, setShowAddressFields] = useState<boolean>(false)
  const [availableTimeSlots, setAvailableTimeSlots] = useState<
    DeliveryTimeSlotType[]
  >([])
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState<
    number | null
  >(null)

  // Function to handle showing or hiding the new address fields
  const handleAddNewAddress = () => {
    setShowAddressFields(!showAddressFields)
  }

  // Set new address from AddressFields component
  const handleSetAddress = (address: Address) => {
    setNewAddress(address)
  }

  // Mutation for updating the cart address
  const [updateCartDeliveryInfo] = useMutation(UPDATE_CART_DELIVERY_INFO, {
    onError: (error) => {
      notify.show(error.message, 'error')
    },
    onCompleted: () => {
      notify.show('Address and Time slot updated successfully', 'success')
      validateTokenMutation({
        variables: {token: tokenParam, checkUsage: false}
      })
    }
  })

  // Fetch and validate token on component mount
  useEffect(() => {
    validateTokenMutation({
      variables: {token: tokenParam, checkUsage: false}
    })
  }, [])

  // Handle token validation errors
  const handleTokenValidationError = (error: Error) => {
    notify.show(error.message, 'error')
    setIsTokenValid(false)
  }

  // Mutation for validating customer signup token
  const [validateTokenMutation, {loading: validatingToken, data}] = useMutation(
    VALIDATE_CUSTOMER_SIGNUP_TOKEN,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setIsTokenValid(true)
        setCustomer(
          data.validateCustomerSignupToken.cart?.customer as CustomerType
        )
        setCartId(data.validateCustomerSignupToken.cart?.id as string)

        // Set available time slots
        setAvailableTimeSlots(
          data.validateCustomerSignupToken?.cart?.location?.business?.configurations?.deliveryTimeSlots?.map(
            (slot: DeliveryTimeSlotType) => ({
              startTime: {
                hours: slot.startTime.hours,
                minutes: slot.startTime.minutes
              },
              endTime: {
                hours: slot.endTime.hours,
                minutes: slot.endTime.minutes
              }
            })
          ) || []
        )

        // Pre-fill selected delivery address if available
        if (data.validateCustomerSignupToken.cart?.deliveryAddress?.street) {
          setSelectedDeliveryAddress({
            street:
              data.validateCustomerSignupToken.cart?.deliveryAddress?.street ||
              '',
            city:
              data.validateCustomerSignupToken.cart?.deliveryAddress?.city ||
              '',
            state:
              data.validateCustomerSignupToken.cart?.deliveryAddress?.state ||
              '',
            zipCode:
              data.validateCustomerSignupToken.cart?.deliveryAddress?.zipCode ||
              '',
            country:
              data.validateCustomerSignupToken.cart?.deliveryAddress?.country ||
              ''
          })
        }
      },
      onError: handleTokenValidationError
    }
  )

  // Mutation for adding a new customer address
  const [addCustomerAddress] = useMutation(ADD_CUSTOMER_ADDRESS, {
    onError: (error) => {
      notify.show(error.message, 'error')
    },
    onCompleted: (data) => {
      notify.show('Address added successfully', 'success')
      setCustomer({
        ...customer,
        addresses: data.addCustomerAddress.addresses
      } as CustomerType)

      setShowAddressFields(false)
      setNewAddress(defaultAddress)
      setSelectedDeliveryAddress({
        street: newAddress.street,
        city: newAddress.city,
        state: newAddress.state,
        zipCode: newAddress.zipCode,
        country: newAddress.country
      })
    }
  })

  // Handle address selection change
  const handleAddressChange = (event: any) => {
    const selectedAddressStr = event.target.value
    const selectedAddressObj = JSON.parse(selectedAddressStr)

    setSelectedDeliveryAddress({
      street: selectedAddressObj.street,
      city: selectedAddressObj.city,
      state: selectedAddressObj.state,
      zipCode: selectedAddressObj.zipCode,
      country: selectedAddressObj.country
    })
  }

  // Render formatted delivery address as a string
  const renderDeliveryAddress = (address: Address) => {
    const addressParts = [
      address.street,
      address.city,
      address.state,
      address.zipCode,
      address.country
    ].filter((part) => part && part.trim() !== '')

    return <>{addressParts.join(', ')}</>
  }

  // Render the header information
  const renderHeaderInformation = () => {
    const deliveryTimeSlot =
      data?.validateCustomerSignupToken?.cart?.deliveryTimeSlot

    return (
      <Grid item xs={12}>
        <Typography variant='h2' align='center' gutterBottom>
          Delivery Address and Slots
        </Typography>

        <Typography variant='subtitle2' align='center' gutterBottom>
          {data?.validateCustomerSignupToken?.cart?.location?.name}
        </Typography>

        <Typography variant='body1' align='center' paragraph>
          Cart ID: {data?.validateCustomerSignupToken?.cart?.id}
        </Typography>

        <Box mb={2} textAlign='center'>
          <Typography variant='body1'>
            Customer: {customer?.firstName || 'Unknown'}{' '}
            {customer?.lastName || ''}
          </Typography>
          <Typography variant='body1'>Email: {customer?.email}</Typography>
        </Box>

        {data?.validateCustomerSignupToken.cart?.deliveryAddress?.street && (
          <Box
            sx={{
              backgroundColor: '#f5f7fa',
              borderRadius: '12px',
              padding: '10px',
              marginBottom: '20px'
            }}
          >
            <Typography variant='h3' align='center' gutterBottom>
              Delivery Address
            </Typography>

            <Typography variant='body1' align='center' paragraph>
              {renderDeliveryAddress(selectedDeliveryAddress)}
            </Typography>
          </Box>
        )}

        {deliveryTimeSlot && (
          <Box
            sx={{
              backgroundColor: '#f5f7fa',
              borderRadius: '12px',
              padding: '10px',
              marginBottom: '20px'
            }}
          >
            <Typography variant='h3' align='center' gutterBottom>
              Selected Delivery Time Slot
            </Typography>

            <Typography variant='body1' align='center' paragraph>
              {formattedTimeSlotString(
                deliveryTimeSlot.startTime,
                deliveryTimeSlot.endTime
              )}
            </Typography>
          </Box>
        )}
      </Grid>
    )
  }

  // Render address fields and time slots
  const renderAddressFields = () => {
    // If delivery address is already set, no need to render address fields
    if (data?.validateCustomerSignupToken?.cart?.deliveryAddress?.street)
      return null

    return (
      <>
        {!showAddressFields && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id='default-location'>
                {t('customer-signup.delivery-flow.select-address-for-delivery')}
              </InputLabel>

              <Select
                size='medium'
                label={t(
                  'customer-signup.delivery-flow.select-address-for-delivery'
                )}
                labelId='default-location'
                value={
                  selectedDeliveryAddress?.street
                    ? JSON.stringify(selectedDeliveryAddress)
                    : ''
                }
                onChange={handleAddressChange}
              >
                {customer?.addresses?.map((address, index) => (
                  <StyledMenuItem
                    key={index}
                    value={JSON.stringify({
                      ...address,
                      __typename: undefined
                    })}
                  >
                    {renderDeliveryAddress(address as Address)}
                  </StyledMenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {!showAddressFields && (
          <Grid item xs={12} textAlign='right'>
            <StyledButton onClick={handleAddNewAddress} variant='text'>
              {t('customer-signup.delivery-flow.add-new-address')}
            </StyledButton>
          </Grid>
        )}

        {showAddressFields && (
          <Grid item xs={12} container spacing={2}>
            <AddressFields
              setAddress={handleSetAddress}
              address={newAddress}
              heading='New Address'
            />

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <StyledButton
                  variant='contained'
                  fullWidth
                  onClick={() => {
                    addCustomerAddress({
                      variables: {
                        customerId: customer?.id as string,
                        token: tokenParam,
                        address: newAddress
                      }
                    })
                  }}
                >
                  {t('customer-signup.delivery-flow.save-address')}
                </StyledButton>
              </Grid>
              <Grid item xs={6}>
                <StyledButton
                  variant='outlined'
                  fullWidth
                  onClick={handleAddNewAddress}
                >
                  {t('cancel')}
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        )}

        {!!availableTimeSlots?.length && (
          <Grid item xs={12}>
            <Typography variant='h4' paragraph>
              Select Delivery Time Slot
            </Typography>
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
              {availableTimeSlots.map((slot, index) => (
                <Chip
                  clickable
                  key={index}
                  classes={{
                    clickableColorPrimary: 'red',
                    clickableColorSecondary: 'blue'
                  }}
                  label={formattedTimeSlotString(slot.startTime, slot.endTime)}
                  color={
                    selectedTimeSlotIndex === index ? 'primary' : 'default'
                  }
                  variant={
                    selectedTimeSlotIndex === index ? 'filled' : 'outlined'
                  }
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.1)'},

                    // change color of selected chip
                    '&.MuiChip-filled': {
                      backgroundColor: theme.palette.primary.main
                    },

                    // it should be white if not selected
                    '&.MuiChip-outlined': {backgroundColor: 'white'}
                  }}
                  onClick={() => {
                    setSelectedTimeSlotIndex(index)
                  }}
                />
              ))}
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <StyledButton
            variant='contained'
            fullWidth
            disabled={
              !selectedDeliveryAddress?.street || selectedTimeSlotIndex === null
            }
            onClick={() => {
              updateCartDeliveryInfo({
                variables: {
                  cartId: cartId,
                  token: tokenParam,
                  address: selectedDeliveryAddress,
                  deliveryTimeSlot:
                    availableTimeSlots[selectedTimeSlotIndex as number]
                }
              })
            }}
          >
            Submit Delivery Information
          </StyledButton>
        </Grid>
      </>
    )
  }

  // Main component rendering
  if (!isTokenValid) {
    if (validatingToken) return <Loading />
    return (
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh'
        }}
      >
        <Typography variant='h2'>
          {t('customer-management.loyalty-signup.invalid-token')}
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '10px',
        background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)'
      }}
    >
      <Box
        sx={{
          padding: {xs: '20px', sm: '40px'},
          backgroundColor: '#fff',
          borderRadius: '12px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          maxWidth: '100%',
          width: {xs: '300px', sm: '500px'}
        }}
      >
        <Grid container spacing={2}>
          {renderHeaderInformation()}
          {renderAddressFields()}
        </Grid>
      </Box>
    </Grid>
  )
}

export default CustomerDelivery
