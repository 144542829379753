import {createSelector} from '@reduxjs/toolkit'
import {RootState} from 'consumerAgent/consumerAgent.store'

export const selectChat = (state: RootState) => state.chat

export const selectChatMessages = createSelector(
  selectChat,
  (chat) => chat.chatMessages
)
export const selectChatSessionId = createSelector(
  selectChat,
  (chat) => chat.chatSessionId
)
export const selectIsPollingGenieResponse = createSelector(
  selectChat,
  (chat) => chat.isPollingGenieResponse
)
export const selectQueryId = createSelector(selectChat, (chat) => chat.queryId)
