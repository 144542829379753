import {graphql} from 'graphql/generatedTypes'

export const LIST_DEVICE_TEMPLATES = graphql(`
  query ListDeviceTemplates($locationId: String) {
    listDeviceTemplates(locationId: $locationId)
  }
`)

export const LIST_DEVICE_BITMAPS = graphql(`
  query ListDeviceBitmaps($locationId: String) {
    listDeviceBitmaps(locationId: $locationId)
  }
`)
