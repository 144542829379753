import {Dialog, Grid, Typography} from '@mui/material'
import {space} from 'config/theme'
import {selectCartId, selectCartItems} from 'consumerAgent/cart.selector'
import {useAppDispatch, useAppSelector} from 'consumerAgent/consumerAgent.store'
import ConsumerAgentCartPriceSummaryCard from 'consumerAgent/consumerAgentCartPriceSummaryCard/ConsumerAgentCartPriceSummaryCard'
import ConsumerAgentItemCard from 'consumerAgent/ConsumerAgentItemCard'
import ConsumerAgentPayments from 'consumerAgent/ConsumerAgentPayments'
import {selectScrollToBottomTrigger} from 'consumerAgent/ui.selector'
import {triggerScrollToBottom} from 'consumerAgent/ui.slice'
import {useEffect, useRef, useState} from 'react'
import QRCode from 'react-qr-code'

const ConsumerAgentCartInterface = () => {
  const dispatch = useAppDispatch()

  const scrollToBottomTrigger = useAppSelector(selectScrollToBottomTrigger)
  const cartId = useAppSelector(selectCartId)
  const cartItems = useAppSelector(selectCartItems)

  const [openQRCode, setOpenQRCode] = useState(false)

  const scrollRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (scrollRef.current) {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({behavior: 'smooth'})
      }, 100)
    }
  }, [scrollToBottomTrigger])

  useEffect(() => {
    dispatch(triggerScrollToBottom())
  }, [])

  return (
    <Grid
      item
      xs
      sx={{
        flexGrow: 1,
        overflowY: 'scroll',
        display: 'flex',
        p: 2
      }}
      padding={space.SMALL}
    >
      <Grid item width={'100%'}>
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexGrow: 1
          }}
        >
          <Grid container item width={'100%'}>
            <Grid item>
              <Typography variant='h2' padding={2}>
                Delivery Order
              </Typography>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{
                display: 'flex',
                flexDirection: 'row',
                overflowX: 'visible',
                padding: space.XS
              }}
            >
              {(cartItems || [])
                .filter((item) => {
                  return item.skuType !== 'subscription'
                })
                .map((item: any, key: any) => (
                  <Grid item key={key} width={'100%'}>
                    <ConsumerAgentItemCard productData={item} />
                  </Grid>
                ))}
            </Grid>
          </Grid>

          <Grid container item width={'100%'}>
            <Grid item>
              <Typography variant='h2' padding={2}>
                Subscription Order
              </Typography>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{
                display: 'flex',
                flexDirection: 'row',
                overflowX: 'visible',
                padding: space.XS
              }}
            >
              {(cartItems || [])
                .filter((item) => {
                  return item.skuType === 'subscription'
                })
                .map((item: any, key: any) => (
                  <Grid item key={key} width={'100%'}>
                    <ConsumerAgentItemCard productData={item} />
                  </Grid>
                ))}
            </Grid>
          </Grid>

          <Grid container item width={'100%'}>
            <Grid item width={'100%'}>
              <ConsumerAgentCartPriceSummaryCard />
            </Grid>
            <Grid
              item
              width={'100%'}
              style={{
                padding: space.SMALL
              }}
            >
              {!!cartItems.length && <ConsumerAgentPayments />}
            </Grid>

            <Dialog
              open={openQRCode}
              onClose={() => {
                setOpenQRCode(false)
              }}
            >
              <QRCode value={`${cartId}`} />
            </Dialog>
          </Grid>
        </Grid>

        <div ref={scrollRef} />
      </Grid>
    </Grid>
  )
}

export default ConsumerAgentCartInterface
