import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Grid, IconButton, Tooltip} from '@mui/material'
import {getSaveCustomerModalFields} from 'clientDashboard/customers/customer.helpers'
import _cloneDeep from 'lodash/cloneDeep'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import StyledButton from 'ui/atoms/StyledButton'
import StyledInput from 'ui/atoms/StyledInput'
import StyledDialog from 'ui/molecules/StyledDialog'
import FormBuilder from 'ui/organisms/FormBuilder/FormBuilder'
import {useTranslation} from 'react-i18next'

const preference = {
  title: '',
  value: [''],
  key: ''
}

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  gender: '',
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
  preferences: [preference]
}

type Props = {
  saveCustomerModalData: any
  onSubmit: (data: any) => void
  onClose: () => void
}

const SaveCustomerProfileModal = (props: Props) => {
  const {saveCustomerModalData, onSubmit, onClose} = props
  const [customerPreferences, setCustomerPreferences] = useState<any>(
    saveCustomerModalData?.preferences || []
  )
  const {t} = useTranslation()
  const formContext = useForm<any>({
    defaultValues,
    values: saveCustomerModalData,
    resetOptions: {
      keepDefaultValues: true
    }
  })

  const {isValid} = formContext.formState
  const isEditMode = !!saveCustomerModalData?.id
  const customerConfigurationDialog = () => {
    return (
      <Grid container spacing={2} padding={2}>
        <FormBuilder
          formContext={formContext}
          fields={getSaveCustomerModalFields(formContext)}
        />

        {/* Preferences */}
        <Grid
          container
          item
          gap={4}
          style={{display: 'flex', flexDirection: 'column'}}
        >
          {customerPreferences?.map((preference: any, index: any) => (
            <Grid
              container
              item
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
              spacing={2}
              key={index}
            >
              <Grid item xs={5} sm={5}>
                <StyledInput
                  fullWidth
                  label='Title'
                  variant='outlined'
                  value={preference?.title}
                  helperText={t('customers.preference.hint.title')}
                  onChange={(e: any) =>
                    handlePChange(index, 'title', e.target.value)
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={5} sm={5}>
                <StyledInput
                  fullWidth
                  label='Value'
                  variant='outlined'
                  value={preference?.value.join(',')}
                  helperText={t('customers.preference.hint.value')}
                  onChange={(e: any) =>
                    handlePChange(
                      index,
                      'value',
                      e.target.value.split(',').map((v: string) => v.trim())
                    )
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={2}>
                <IconButton
                  onClick={(e: any) => {
                    handleRemoveField(index)
                  }}
                >
                  <Tooltip title='Delete'>
                    <FontAwesomeIcon
                      icon={icon({
                        name: 'trash-alt',
                        family: 'classic',
                        style: 'solid'
                      })}
                      fontSize={20}
                      style={{
                        borderWidth: '1px'
                      }}
                    />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={6}>
          <StyledButton
            variant='contained'
            color='primary'
            onClick={addPreference}
          >
            Add Preference
          </StyledButton>
        </Grid>
      </Grid>
    )
  }

  const addPreference = () => {
    setCustomerPreferences([...customerPreferences, preference])
  }

  const handlePChange = (index: any, path: string, value: any) => {
    let _customerPreferences = _cloneDeep(customerPreferences)

    let customerPreference = _customerPreferences[index]
    customerPreference = {
      ...customerPreference,
      [path]: value
    } as typeof preference
    _customerPreferences[index] = customerPreference
    setCustomerPreferences(_customerPreferences)
  }

  const handleRemoveField = (index: any) => {
    let _customerPreferences = _cloneDeep(customerPreferences)
    _customerPreferences.splice(index, 1)
    setCustomerPreferences(_customerPreferences)
  }

  return (
    <StyledDialog
      open
      onClose={() => {
        onClose()
      }}
      title={
        isEditMode ? t('customers.edit-customer') : t('customers.add-customer')
      }
      successButtonName={
        isEditMode ? t('customers.update') : t('customers.add')
      }
      cancelButtonName={t('customers.cancel')}
      cancelCallback={() => {
        onClose()
      }}
      disableSuccessButton={!isValid}
      successCallback={() => {
        formContext.handleSubmit(
          (data) => {
            onSubmit({
              ...data,
              preferences: customerPreferences
            })
          },
          (error) => {
            console.log(error)
          }
        )()
      }}
      body={customerConfigurationDialog()}
    />
  )
}

export default SaveCustomerProfileModal
