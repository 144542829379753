import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import client from 'config/apollo.config'
import {RootState} from 'consumerAgent/consumerAgent.store'
import {ProductDataType} from 'consumerAgent/ConsumerAgentItemCard'
import {
  CREATE_CART_FOR_CONSUMER_AGENT,
  UPDATE_CART_GUEST,
  VERIFY_CART_FOR_CONSUMER_AGENT
} from 'graphql/mutations/cart.mutations'
import {omitTypenameDeep} from 'utils/common.utils'

type CartData = {
  subTotalAmount: number
  totalAmount: number
  totalDiscount: number
  totalTax: number
  deliveryFee: number
}

export enum ConsumerAgentCartTransferMethods {
  QR_CODE = 'QR_CODE',
  NFC_TAP = 'NFC_TAP'
}

type CartState = {
  cartItems: ProductDataType[]
  cartId: string
  cartData: CartData | null
  cartTransferMethod: ConsumerAgentCartTransferMethods
  addressFields: Address
  address: string
}

type Address = {
  street: string
  city: string
  state: string
  zipCode: string
  country: string
}

const initialState: CartState = {
  cartItems: [],
  cartId: '',
  cartData: null,
  cartTransferMethod: ConsumerAgentCartTransferMethods.QR_CODE,
  address: '',
  addressFields: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  }
}

// Create a new cart for the consumer agent
export const createCartForConsumerAgent = createAsyncThunk(
  'cart/createCartForConsumerAgent',
  async (_, {getState}) => {
    const locationId = (getState() as RootState).consumer.locationId
    const response = await client.mutate({
      mutation: CREATE_CART_FOR_CONSUMER_AGENT,
      variables: {locationId}
    })
    return response.data.createCartGuest.id
  }
)

// Verify the cart for the consumer agent
export const verifyCartForConsumerAgent = createAsyncThunk(
  'cart/verifyCartForConsumerAgent',
  async (_, {getState, dispatch}) => {
    const locationId = (getState() as RootState).consumer.locationId
    const cartId = (getState() as RootState).cart.cartId
    if (!cartId) {
      dispatch(createCartForConsumerAgent())
      return
    }
    try {
      await client.mutate({
        mutation: VERIFY_CART_FOR_CONSUMER_AGENT,
        variables: {cartId, locationId}
      })
    } catch (_) {
      dispatch(createCartForConsumerAgent())
    }
  }
)

// Update the cart with items, address, and customer info
export const updateCart = createAsyncThunk(
  'cart/updateCart',
  async (
    {
      cartId,
      items,
      subscriptionItems = [],
      returnedItems = [],
      deliveryAddress,
      customerId = null
    }: {
      cartId: string
      items: ProductDataType[]
      subscriptionItems?: ProductDataType[]
      returnedItems?: ProductDataType[]
      deliveryAddress?: Address
      customerId: string | null
    },
    {dispatch, rejectWithValue}
  ) => {
    try {
      const response = await client.mutate({
        mutation: UPDATE_CART_GUEST,
        variables: {
          cartId,
          items,
          subscriptionItems,
          returnedItems,
          deliveryAddress,
          customerId
        }
      })
      return response.data.updateCartItems
    } catch (error) {
      return rejectWithValue('Failed to update cart')
    }
  }
)

export const updateCartItems = createAsyncThunk(
  'cart/updateCartItems',
  async (items: ProductDataType[], {dispatch, getState}) => {
    dispatch(setCartItems(items))
    const state = getState() as RootState

    const cartId = state.cart.cartId
    const customerId = state.consumer.customer?.id || null

    const _items = items
      .filter((item) => {
        return item.skuType !== 'subscription'
      })
      .map((item) => {
        const _item = omitTypenameDeep(item)
        delete _item.frequency
        return _item as ProductDataType
      })

    const _subscriptionItems = items
      .filter((item) => {
        return item.skuType === 'subscription'
      })
      .map((item) => {
        return omitTypenameDeep(item) as ProductDataType
      })

    dispatch(
      updateCart({
        cartId,
        items: _items,
        subscriptionItems: _subscriptionItems,
        deliveryAddress: (getState() as RootState).cart.addressFields,
        customerId: customerId
      })
    )
  }
)

export const updateCartAddress = createAsyncThunk(
  'cart/updateCartAddress',
  async (address: Address, {dispatch, getState}) => {
    const state = getState() as RootState
    const items = state.cart.cartItems
    dispatch(setAddressFields(address))
    dispatch(updateCartItems(items))
  }
)

export const resetCartId = createAsyncThunk(
  'cart/resetCartId',
  async (_, {dispatch}) => {
    dispatch(setCartId(''))
    await dispatch(createCartForConsumerAgent())
  }
)

// Cart Slice
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartItems(state, action: PayloadAction<ProductDataType[]>) {
      state.cartItems = action.payload
    },

    setCartId(state, action: PayloadAction<string>) {
      state.cartId = action.payload
    },
    setCartData(state, action: PayloadAction<CartData | null>) {
      state.cartData = action.payload
    },
    setCartTransferMethod(
      state,
      action: PayloadAction<ConsumerAgentCartTransferMethods>
    ) {
      state.cartTransferMethod = action.payload
    },
    setAddressFields(state, action: PayloadAction<Address>) {
      state.addressFields = action.payload
    },
    setAddress(state, action: PayloadAction<string>) {
      state.address = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCartForConsumerAgent.fulfilled, (state, action) => {
        state.cartId = action.payload
      })

      .addCase(updateCart.fulfilled, (state, action) => {
        // Update the cart data after successful updateCart mutation
        state.cartData = {
          subTotalAmount: action.payload.subTotal,
          totalDiscount: action.payload.totalDiscount,
          totalTax: action.payload.totalTax,
          totalAmount: action.payload.totalAmount,
          deliveryFee: 0
        }
      })
      .addCase(updateCart.rejected, (state, action) => {
        // Handle update cart errors
        console.error(action.payload)
      })
  }
})

export const {
  setCartItems,
  setCartId,
  setCartData,
  setCartTransferMethod,
  setAddressFields,
  setAddress
} = cartSlice.actions

export default cartSlice.reducer
