import {useMutation} from '@apollo/client'
import {Grid, Typography} from '@mui/material'
import {DELETE_INVENTORY} from 'graphql/mutations/inventory-management.mutations'
import {useTranslation} from 'react-i18next'
import {notify} from 'ui/molecules/CustomNotifications'
import StyledDialog from 'ui/molecules/StyledDialog'

interface DeleteInventoryModalProps {
  onClose: () => void
  inventoryData: any
  onSubmit: () => void
}

export default function DeleteInventoryModal({
  onClose,
  inventoryData,
  onSubmit
}: DeleteInventoryModalProps) {
  const {t} = useTranslation('commons')
  const [_deleteInventory, {loading: loadingDeleteInventory}] = useMutation(
    DELETE_INVENTORY,
    {
      onCompleted: () => {
        onSubmit()
        notify.show(t('inventory.delete-inventory-success'), 'success')
      },
      onError: (error) => {
        console.log('error', error)
        notify.show(t('inventory.delete-inventory-failure'), 'error')
        onSubmit()
      }
    }
  )

  const deleteInventory = (_inventoryId: string) => {
    _deleteInventory({
      variables: {
        id: _inventoryId
      }
    })
  }

  const deleteInventoryDialogBody = () => {
    return (
      <Grid container>
        <Grid item>
          <Typography variant='subtitle2'>
            {t('inventory.delete-confirmation')}
            <strong>
              {inventoryData.sku}
              {'('}
              {inventoryData.description}
              {') ?'}
            </strong>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <StyledDialog
      open
      onClose={() => {
        onClose()
      }}
      title={t('inventory.delete-inventory-title')}
      successButtonName={t('delete')}
      cancelButtonName={t('cancel')}
      cancelCallback={() => {
        onClose()
      }}
      disableSuccessButton={loadingDeleteInventory}
      successCallback={() => {
        deleteInventory(inventoryData.id)
      }}
      body={deleteInventoryDialogBody()}
    />
  )
}
