import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  SwipeableDrawer
} from '@mui/material'
import nfcTapGif from 'assets/nfc-tap.gif'
import scanQRGif from 'assets/scan-qr.gif'
import theme, {space} from 'config/theme'
import {selectCartTransferMethod} from 'consumerAgent/cart.selector'
import {
  ConsumerAgentCartTransferMethods,
  setCartTransferMethod
} from 'consumerAgent/cart.slice'
import {useAppDispatch, useAppSelector} from 'consumerAgent/consumerAgent.store'
import {ConsumerAgentPaymentMethods} from 'consumerAgent/ConsumerAgentPayments'
import {
  selectPaymentMetadata,
  selectSelectedPaymentMethod
} from 'consumerAgent/payment.selector'
import {
  defaultPaymentMetadata,
  setPaymentMetadata
} from 'consumerAgent/payment.slice'
import StripeCheckout from 'consumerAgent/StripeCheckout'
import {selectIsBottomDrawerOpen} from 'consumerAgent/ui.selector'
import {
  ConsumerAgentCheckoutSteps,
  setIsBottomDrawerOpen,
  setSelectedCheckoutStep
} from 'consumerAgent/ui.slice'
import {t} from 'i18next'
import React from 'react'
import StyledButton from 'ui/atoms/StyledButton'

const OrderedList = ({items}: any) => {
  return (
    <List>
      {items.map((item: any, index: any) => (
        <ListItem key={index}>
          <ListItemIcon
            sx={{
              minWidth: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid black',
              borderRadius: '50%',
              backgroundColor: theme.palette.primary.dark,
              color: 'white',
              marginRight: space.MEDIUM
            }}
          >
            {index + 1}
          </ListItemIcon>

          {item}
        </ListItem>
      ))}
    </List>
  )
}

const nfcTapInstructions = [
  t('consumer-agent.cart-transfer.instructions.let-associate-know'),
  t('consumer-agent.cart-transfer.instructions.tap-nfc'),
  t('consumer-agent.cart-transfer.instructions.complete-purchase')
]

const qrCodeInstructions = [
  t('consumer-agent.cart-transfer.instructions.let-associate-know'),
  t('consumer-agent.cart-transfer.instructions.scan-qr-code'),
  t('consumer-agent.cart-transfer.instructions.complete-purchase')
]

const ConsumerAgentBottomDrawer = () => {
  const dispatch = useAppDispatch()

  const isBottomDrawerOpen = useAppSelector(selectIsBottomDrawerOpen)

  const paymentMetadata = useAppSelector(selectPaymentMetadata)
  const selectedPaymentMethod = useAppSelector(selectSelectedPaymentMethod)

  const cartTransferMethod = useAppSelector(selectCartTransferMethod)

  const stripeRef = React.useRef(null)

  const submitPayment = () => {
    if (selectedPaymentMethod === ConsumerAgentPaymentMethods.CARD) {
      if (stripeRef.current) {
        return (stripeRef.current as any)?.submitPaymentForm?.()
      }
    }

    setIsBottomDrawerOpen(false)
  }

  const renderPaymentOptions = () => {
    if (selectedPaymentMethod === ConsumerAgentPaymentMethods.CASH) {
      return (
        <Grid
          item
          style={{
            backgroundColor: theme.palette.background.paper,
            padding: space.SMALL,
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'center',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
            borderRadius: '12px',
            border: '1px solid black',
            width: '100%'
          }}
        >
          <Grid
            xs={5}
            item
            gap={1}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {cartTransferMethod === ConsumerAgentCartTransferMethods.NFC_TAP ? (
              <img
                src={nfcTapGif}
                alt='cash-payment'
                style={{
                  maxHeight: '130px',
                  height: 'auto',
                  maxWidth: '130px'
                }}
              />
            ) : (
              <img
                src={scanQRGif}
                alt='scan-qr'
                style={{
                  maxHeight: '130px',
                  height: 'auto',
                  maxWidth: '130px'
                }}
              />
            )}

            <StyledButton
              onClick={() => {
                dispatch(
                  setCartTransferMethod(
                    cartTransferMethod ===
                      ConsumerAgentCartTransferMethods.QR_CODE
                      ? ConsumerAgentCartTransferMethods.NFC_TAP
                      : ConsumerAgentCartTransferMethods.QR_CODE
                  )
                )
              }}
              size='small'
              style={{
                backgroundColor: theme.palette.primary.dark
              }}
            >
              {cartTransferMethod === ConsumerAgentCartTransferMethods.QR_CODE
                ? t('consumer-agent.cart-transfer.switch-to-nfc-tap')
                : t('consumer-agent.cart-transfer.switch-to-qr-code')}
            </StyledButton>
          </Grid>

          <Grid item xs={7}>
            <OrderedList
              items={
                cartTransferMethod === ConsumerAgentCartTransferMethods.NFC_TAP
                  ? nfcTapInstructions
                  : qrCodeInstructions
              }
            />
          </Grid>
        </Grid>
      )
    }

    if (selectedPaymentMethod === ConsumerAgentPaymentMethods.CARD) {
      return <StripeCheckout ref={stripeRef} />
    }
  }

  return (
    <SwipeableDrawer
      anchor='bottom'
      open={isBottomDrawerOpen}
      onClose={() => {
        dispatch(setIsBottomDrawerOpen(false))
        dispatch(setSelectedCheckoutStep(ConsumerAgentCheckoutSteps.DELIVERY))
        dispatch(setPaymentMetadata(defaultPaymentMetadata))
      }}
      onOpen={() => {
        dispatch(setIsBottomDrawerOpen(true))
        dispatch(setSelectedCheckoutStep(ConsumerAgentCheckoutSteps.DELIVERY))
      }}
      ModalProps={{
        keepMounted: true
      }}
      PaperProps={{
        sx: {
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          width: '100%'
        }
      }}
    >
      <Grid
        style={{
          padding: space.MEDIUM,

          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
        width={'100%'}
        gap={2}
      >
        <Grid
          container
          spacing={2}
          width={'100%'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Grid item xs={12}>
            {renderPaymentOptions()}
          </Grid>

          <Grid
            item
            container
            spacing={2}
            width={'100%'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            {!paymentMetadata.isPaymentSuccessful ? (
              <Grid item xs={6}>
                <StyledButton
                  fullWidth
                  onClick={() => {
                    submitPayment()
                  }}
                  style={{
                    marginTop: space.MEDIUM
                  }}
                >
                  Confirm
                </StyledButton>
              </Grid>
            ) : (
              <Grid item xs={6}>
                <StyledButton
                  fullWidth
                  onClick={() => {
                    dispatch(setIsBottomDrawerOpen(false))
                    dispatch(
                      setSelectedCheckoutStep(
                        ConsumerAgentCheckoutSteps.DELIVERY
                      )
                    )
                    dispatch(setPaymentMetadata(defaultPaymentMetadata))
                  }}
                  style={{
                    marginTop: space.MEDIUM
                  }}
                >
                  Close
                </StyledButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  )
}

export default ConsumerAgentBottomDrawer
