import {Typography} from '@mui/material'
import {LocationType} from 'graphql/generatedTypes/graphql'
import {MRT_ColumnDef} from 'material-react-table'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useAppSelector} from 'adminPanel/adminPanel.store'
import {selectStoreGroupClusters} from 'clientDashboard/business.selectors'

function useLocationColumns() {
  const storeGroupClusters = useAppSelector(selectStoreGroupClusters)
  const {t} = useTranslation()

  const dynamicStoreGroupColumns = useMemo<
    MRT_ColumnDef<LocationType>[]
  >(() => {
    if (!storeGroupClusters || storeGroupClusters.length === 0) return []
    return storeGroupClusters.map((storeGroupCluster) => {
      // Define your dynamic columns based on storeGroupCluster
      return {
        accessorFn: (row) => {
          const storeGroup = row.storeGroups?.find(
            (storeGroup) =>
              storeGroup.storeGroupCluster.name === storeGroupCluster.name
          )
          return storeGroup ? storeGroup.name : null
        },
        header: storeGroupCluster.name, // Adjust based on your actual data structure
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>{cell.getValue() || ''}</Typography>
          )
        }
      }
    })
  }, [storeGroupClusters])

  const columns = useMemo<MRT_ColumnDef<LocationType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('location-management.name'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'brand',
        header: t('location-management.brand'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'storeNumber',
        header: t('location-management.store-number'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'status',
        header: t('location-management.status'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      ...dynamicStoreGroupColumns
    ],

    [dynamicStoreGroupColumns]
  )
  return columns
}

export default useLocationColumns
