import {
  AutocompleteElement,
  AutocompleteElementProps
} from 'react-hook-form-mui'

const AutocompleteField = (
  props: AutocompleteElementProps & {className?: string}
) => {
  return (
    <AutocompleteElement
      autocompleteProps={{
        className: props.className
      }}
      {...props}
    />
  )
}

export default AutocompleteField
