import {useAppDispatch} from 'consumerAgent/consumerAgent.store'
import {useEffect} from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'

import {reAuthenticate, setLocationId} from './consumer.slice'
import {setQueryId} from 'consumerAgent/chat.slice'

const useConsumerAgent = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)

  const {locationId} = useParams<{
    locationId: string
  }>()

  useEffect(() => {
    dispatch(setLocationId(locationId))
    const queryId = queryParams.get('query-id')
    queryId && dispatch(setQueryId(queryId))
    locationId && dispatch(reAuthenticate())

    queryParams.delete('query-id')
    history.replace({
      search: queryParams.toString()
    })
  }, [locationId, dispatch])
}

export default useConsumerAgent
