import {FormControlLabel, Grid, Switch, Typography} from '@mui/material'
import StyledInput from 'ui/atoms/StyledInput'
import {space} from 'config/theme'
import {useEffect, useState} from 'react'
import Mustache from 'mustache'
import StyledButton from 'ui/atoms/StyledButton'
import {useLazyQuery, useMutation} from '@apollo/client'
import {GET_ORDER_RECEIPT_TEMPLATE_CONFIG} from 'graphql/queries/business.queries'
import {
  RESET_ORDER_EMAIL_RECEIPT_TEMPLATE,
  TOGGLE_EMAIL_RECEIPT_ENABLED,
  UPDATE_ORDER_EMAIL_RECEIPT_TEMPLATE
} from 'graphql/mutations/business.mutation'
import useNotify from 'hooks/useNotify'
import {useTranslation} from 'react-i18next'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusiness, selectBusinessAndLocation} from 'clientDashboard/employee.selectors'

const sampleData = {
  orderNumber: 'ORD123456',
  date: '2024-11-23',
  items: [
    {
      image:
        'https://t3.ftcdn.net/jpg/03/56/39/80/360_F_356398061_sD6T5f5HWbmRBYNmrYQTEPoCKjlICdBv.jpg',
      title: 'Sample Item 1',
      sku: 'SKU123456',
      quantity: 1,
      price: '100.00'
    },
    {
      image:
        'https://t3.ftcdn.net/jpg/03/56/39/80/360_F_356398061_sD6T5f5HWbmRBYNmrYQTEPoCKjlICdBv.jpg',
      title: 'Sample Item 2',
      sku: 'SKU123457',
      quantity: 2,
      price: '50.00'
    },
    {
      image:
        'https://t3.ftcdn.net/jpg/03/56/39/80/360_F_356398061_sD6T5f5HWbmRBYNmrYQTEPoCKjlICdBv.jpg',
      title: 'Sample Item 3',
      sku: 'SKU123458',
      quantity: 1,
      price: '75.00'
    },
    {
      image:
        'https://t3.ftcdn.net/jpg/03/56/39/80/360_F_356398061_sD6T5f5HWbmRBYNmrYQTEPoCKjlICdBv.jpg',
      title: 'Sample Item 4',
      sku: 'SKU123459',
      quantity: 1,
      price: '150.00'
    }
  ],
  payments: [
    {
      paymentMethod: 'Visa ending in 1234',
      amount: '100.00',
      id: 'PAY123456'
    },
    {
      paymentMethod: 'Cash Payment',
      amount: '350.00',
      id: 'PAY123457'
    }
  ],
  subtotal: '425.00',
  tax: '25.00',
  discount: '0.00',
  total: '450.00',
  businessName: 'Sample Business'
}

const TemplateEditor = () => {
  const {t} = useTranslation()
  const {businessId} = useAppSelector(selectBusinessAndLocation)
  const business = useAppSelector(selectBusiness)

  const notify = useNotify()
  const [receiptTemplate, setReceiptTemplate] = useState<unknown>('')
  const [lastSavedTemplate, setLastSavedTemplate] = useState<unknown>('')
  const [isEmailEnabled, setIsEmailEnabled] = useState(false)

  const [getOrderReceiptTemplateConfig] = useLazyQuery(
    GET_ORDER_RECEIPT_TEMPLATE_CONFIG,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setIsEmailEnabled(
          data.getBusiness.configurations?.emailReceiptEnabled || false
        )
        setReceiptTemplate(
          data.getBusiness.configurations?.templates?.orderReceipt
        )
        setLastSavedTemplate(
          data.getBusiness.configurations?.templates?.orderReceipt
        )
      },
      onError: (error) => {
        console.error(error)
      }
    }
  )

  const [updateOrderEmailReceiptTemplate] = useMutation(
    UPDATE_ORDER_EMAIL_RECEIPT_TEMPLATE,
    {
      onCompleted: (data) => {
        setReceiptTemplate(data.updateOrderEmailReceiptTemplate)
        setLastSavedTemplate(data.updateOrderEmailReceiptTemplate)
        notify.show(
          t('email-templates.template-updated-successfully'),
          'success'
        )
      },
      onError: (error) => {
        console.error(error)
        notify.show(t('email-templates.failed-to-update-template'), 'error')
      }
    }
  )

  const [toggleEmailReceiptEnabled] = useMutation(
    TOGGLE_EMAIL_RECEIPT_ENABLED,
    {
      onCompleted: (data) => {
        setIsEmailEnabled(data.toggleEmailReceiptEnabled)
        getOrderReceiptTemplateConfig({
          variables: {
            businessId
          }
        })
      },
      onError: (error) => {
        console.error(error)
      }
    }
  )

  const [resetOrderEmailReceiptTemplate] = useMutation(
    RESET_ORDER_EMAIL_RECEIPT_TEMPLATE,
    {
      onCompleted: (data) => {
        setReceiptTemplate(data.resetOrderEmailReceiptTemplate)
        setLastSavedTemplate(data.resetOrderEmailReceiptTemplate)
        notify.show(t('email-templates.template-reset-successfully'), 'success')
      },
      onError: (error) => {
        console.error(error)
        notify.show(t('email-templates.failed-to-reset-template'), 'error')
      }
    }
  )

  useEffect(() => {
    getOrderReceiptTemplateConfig({
      variables: {
        businessId
      }
    })
  }, [])

  return (
    <Grid
      spacing={4}
      container
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Grid item>
        <Typography variant='h1'>
          {t('email-templates.receipt-template-editor-heading')}
        </Typography>
      </Grid>

      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={isEmailEnabled}
              onChange={(e) => {
                toggleEmailReceiptEnabled({
                  variables: {
                    emailReceiptEnabled: e.target.checked
                  }
                })
              }}
            />
          }
          label={t('email-templates.toggle.enable-emails-for-order-receipts')}
        />
      </Grid>

      {receiptTemplate !== null && (
        <Grid
          container
          item
          gap={4}
          style={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Grid item xs={12} sm={5} padding={space.SMALL}>
            <StyledInput
              InputProps={{
                style: {fontSize: '16px'}
              }}
              label={t('email-templates.template-name-label')}
              placeholder={t('email-templates.enter-template-name-placeholder')}
              fullWidth
              minRows={25}
              maxRows={25}
              multiline
              value={receiptTemplate}
              onChange={(e) => setReceiptTemplate(e.target.value)}
            />

            <Grid
              spacing={4}
              container
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Grid item>
                <StyledButton
                  variant='contained'
                  color='primary'
                  style={{
                    marginTop: space.MEDIUM
                  }}
                  disabled={
                    !receiptTemplate || receiptTemplate === lastSavedTemplate
                  }
                  onClick={() => {
                    updateOrderEmailReceiptTemplate({
                      variables: {
                        orderReceipt: receiptTemplate as string
                      }
                    })
                  }}
                >
                  {t('email-templates.buttons.update')}
                </StyledButton>
              </Grid>

              <Grid item>
                <StyledButton
                  variant='outlined'
                  color='primary'
                  style={{
                    marginTop: space.MEDIUM
                  }}
                  disabled={!receiptTemplate}
                  onClick={() => {
                    resetOrderEmailReceiptTemplate()
                  }}
                >
                  {t('email-templates.buttons.reset')}
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: Mustache.render(receiptTemplate as string, {
                  ...sampleData,
                  businessName: business?.name || ''
                })
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default TemplateEditor
