import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import StyledTable from 'ui/molecules/StyledTable'
import { Box, Grid, IconButton, Tab, Tabs, Tooltip } from '@mui/material'
import { GET_REGISTERS } from 'graphql/queries/cash-register-till.queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import RegisterForm from 'clientDashboard/settings/cashManagementSettings/RegisterForm'
import { CREATE_REGISTER, UPDATE_REGISTER } from 'graphql/mutations/register.mutations'
import { useTranslation } from 'react-i18next'
import useNotify from 'hooks/useNotify'
import { useAppSelector } from 'clientDashboard/clientDashboard.store'
import { selectLocationId} from 'clientDashboard/employee.selectors'
import StyledButton from 'ui/atoms/StyledButton'
import usePermission from 'hooks/usePermission'
import { Permission } from 'constants/permissions'
import { columns } from 'clientDashboard/settings/cashManagementSettings/registerForm.helpers'

type Props = {
  setSelectedTab: any
}

function RegisterTab(props: Props) {
  const {setSelectedTab} = props

    const hasAccess = usePermission(Permission.MANAGE_CASH_REGISTERS_AND_TILLS)
    
    const {t} = useTranslation()
    const notify = useNotify()

    const locationId = useAppSelector(selectLocationId)
    const [modalData, setModalData] = React.useState<any>(null)

    const [pagination, setPagination] = React.useState({
      pageIndex: 0,
      pageSize: 10
    })
  
    const [columnFilters, setColumnFilters] = React.useState([])

    const [_addRegister] = useMutation(CREATE_REGISTER, {
        onCompleted: () => {
            notify.show(
                t('cash-management.register.success'),
                'success'
            )
            refetchRegister()
            setTimeout(() => {
                setModalData(null)
            })
        },
        onError: (error) => {
          notify.show(error.message, 'error')
        }
    })
    const [_updateRegister] = useMutation(UPDATE_REGISTER, {
        onCompleted: () => {
            notify.show(
                t('cash-management.register.update-success'),
                'success'
            )
            refetchRegister()
            setTimeout(() => {
                setModalData(null)
            })
        },
        onError: (error) => {
          notify.show(error.message, 'error')
        }
      })

    const {
        loading: loadingRegister,
        error: registerError,
        data: registerData,
        refetch: refetchRegister
        } = useQuery(GET_REGISTERS, {
        variables: {
            locationId: locationId,
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize
        }
    })

    
    const addOrUpdateRegister = (data: any) => {
        const {id, location, ...rest} = data || {}
        if (id) {
          _updateRegister({
            variables: {
              registerId: id,
              register: {
                name: data.name,
                isActive: data.isActive,
                location: location.id ? location.id : data.location,
                externalRegisterId: data.externalRegisterId,
                source: data.source
              }
            }
          })
        } else {
          _addRegister({
            variables: {
              register: {
                location: location.id ? location.id : data.location,
                ...rest
              }
            }
          })
        }
      }

    if (registerError) {
        return <p>Error fetching data</p>
    }
  
    return (
      <>
        <StyledTable
          columns={columns}
          data={registerData?.getCashRegisters.nodes || []}
          state={{
            showSkeletons: loadingRegister,
            showProgressBars: loadingRegister,
            columnFilters: columnFilters,
            pagination: pagination
          }}
          enableTableFooter={true}
          enableSorting={false}
          enableStickyHeader={true}
          manualFiltering={true}
          manualPagination={true}
          enableRowActions={true}
          onColumnFiltersChange={setColumnFilters as any}
          onPaginationChange={setPagination}
          rowCount={registerData?.getCashRegisters?.totalPageCount || 0}
          initialState={{
            columnPinning: {
                right: ['mrt-row-actions']
            }
          }}

          muiTopToolbarProps ={ {
          }}

          renderTopToolbarCustomActions={() => {
            return (
              <>
                <Box className={'mr-auto'}>
                  <Tabs
                    value={'register'} 
                    onChange={(event: React.ChangeEvent<{}>, value: string) =>
                      setSelectedTab(value)
                    }
                  >
                    <Tab value="register" label="Register" />
                    <Tab value="till" label="Till" />
                  </Tabs>
                </Box>
                <Grid style={{order: 1}}>
                  <StyledButton 
                    disabled={!hasAccess}
                    onClick={() => { setModalData({})}}>
                      {t('cash-management.action.add-register')}
                  </StyledButton>
                </Grid>
              </>
            )
          }}
          renderRowActions={({row}) => (
            <Box>
                <IconButton
                    disabled={!hasAccess}
                    onClick={() => {
                        setModalData(row.original)
                    }}
                >
                    <Tooltip title={t('cash-management.action.edit')}>
                        <FontAwesomeIcon
                            icon={icon({
                                name: 'edit',
                                family: 'classic',
                                style: 'light'
                            })}
                            fontSize={20}
                            color='black'
                            style={{
                                borderWidth: '1px'
                            }}
                        />
                    </Tooltip>
                </IconButton>
            </Box>
          )}
        />

        {modalData && (
        <RegisterForm
          modalData={modalData}
          onSubmit={(data: any) => {
            addOrUpdateRegister(data)
          }}
          onClose={() => {
            setModalData(null)
          }}
        />
      )}
      
      </>
    )
  }
  
export default RegisterTab
