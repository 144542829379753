import {gql, useMutation, useQuery} from '@apollo/client'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessId} from 'clientDashboard/employee.selectors'
import {ADD_PROMOTION_TO_SKU} from 'graphql/mutations/promo-engine.mutations'
import React, {useEffect, useState} from 'react'

interface SkuListDialogProps {
  open: boolean
  onClose: () => void
  productSkuList: string[]
  promotionName: string
}

export const APPLY_PROMOTION = gql`
  query applyPromotion(
    $sku: String!
    $quantity: Int!
    $promotionName: String!
    $businessId: String!
  ) {
    applyPromotion(
      sku: $sku
      quantity: $quantity
      promotionName: $promotionName
      businessId: $businessId
    ) {
      promotionAmount
      totalAmount
    }
  }
`

const SkuListDialog: React.FC<SkuListDialogProps> = ({
  open,
  onClose,
  productSkuList,
  promotionName
}) => {
  const businessId = useAppSelector(selectBusinessId)
  const [addPromotionToSku, {loading: mutationLoading}] =
    useMutation(ADD_PROMOTION_TO_SKU)

  const [selectedSku, setSelectedSku] = useState<string>('')
  const [quantity, setQuantity] = useState<number>(0)

  const {refetch} = useQuery(APPLY_PROMOTION, {
    skip: !selectedSku || quantity === 0,
    variables: {
      sku: selectedSku,
      quantity: quantity || 0,
      promotionName,
      businessId: businessId
    }
  })

  useEffect(() => {
    if (selectedSku && quantity > 0) {
      refetch({
        sku: selectedSku,
        quantity: quantity || 0,
        promotionName,
        businessId: businessId
      })
    }
  }, [selectedSku, quantity, promotionName, businessId, refetch])
  const handleAttachPromotion = () => {
    addPromotionToSku({
      variables: {
        promotionName,
        sku: selectedSku,
        businessId: businessId
      }
    })
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>Select SKUs to Apply Promotion</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems='flex-end'>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id='sku-select-label'>SKU</InputLabel>
              <Select
                labelId='sku-select-label'
                id='sku-select'
                value={selectedSku}
                onChange={(e) => setSelectedSku(e.target.value as string)}
                fullWidth
              >
                {productSkuList.map((sku) => (
                  <MenuItem key={sku} value={sku}>
                    {sku}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type='number'
              label='Quantity'
              value={quantity || ''}
              onChange={(e) =>
                setQuantity(Math.max(0, parseInt(e.target.value)))
              }
              inputProps={{min: 0}}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleAttachPromotion}
            disabled={mutationLoading || !selectedSku}
          >
            Attach Promotion
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default SkuListDialog
