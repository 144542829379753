import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import client from 'config/apollo.config'
import {
  createCartForConsumerAgent,
  setCartData,
  setCartItems
} from 'consumerAgent/cart.slice'
import {addChatMessage} from 'consumerAgent/chat.slice'
import {RootState} from 'consumerAgent/consumerAgent.store'
import {
  ConsumerAgentCheckoutSteps,
  setSelectedCheckoutStep,
  triggerScrollToBottom
} from 'consumerAgent/ui.slice'
import {OrderType} from 'graphql/generatedTypes/graphql'
import {
  CREATE_PAYMENT_INTENT,
  ORDER_PAYMENT_STATUS,
  PLACE_ORDER
} from 'graphql/mutations/payments.mutation'
import {get} from 'lodash'
import {v4 as randomUUID} from 'uuid'

type PaymentMetadata = {
  paymentGatewayReferenceId: string
  paymentId: string
  orderId: string
  isPaymentSuccessful: boolean
}

export const defaultPaymentMetadata = {
  paymentGatewayReferenceId: '',
  paymentId: '',
  orderId: '',
  isPaymentSuccessful: false
}

// Define the initial state for the payment slice
interface PaymentState {
  paymentIntentClientSecret: string
  paymentGatewayReferenceId: string
  isPaymentSuccessful: boolean
  paymentInProgress: boolean
  paymentMetadata: PaymentMetadata
  selectedPaymentMethod?: string
  isPollingOrderPaymentStatus: boolean
}

const initialState: PaymentState = {
  paymentIntentClientSecret: '',
  paymentGatewayReferenceId: '',
  isPaymentSuccessful: false,
  paymentInProgress: false,
  paymentMetadata: defaultPaymentMetadata,
  selectedPaymentMethod: '',
  isPollingOrderPaymentStatus: false
}

// Async Thunk to create payment intent
export const createPaymentIntent = createAsyncThunk(
  'payment/createPaymentIntent',
  async (amount: number, {getState, dispatch}) => {
    if (!amount) return
    const state = getState() as RootState
    const businessId = state.consumer.businessId
    const paymentIntentClientSecret = state.payment.paymentIntentClientSecret
    if (paymentIntentClientSecret) {
      dispatch(setPaymentIntentClientSecret(''))
    }

    const response = await client.mutate({
      mutation: CREATE_PAYMENT_INTENT,
      variables: {amount, businessId}
    })
    return response.data?.createPaymentIntent.client_secret
  }
)

// Async Thunk to place an order
export const placeOrder = createAsyncThunk(
  'payment/placeOrder',
  async (_, {getState, dispatch}) => {
    try {
      const state = getState() as RootState
      const {cartId, cartData} = state.cart
      const {locationId, businessId} = state.consumer
      const {paymentGatewayReferenceId, paymentMetadata} = state.payment

      const response = await client.mutate({
        mutation: PLACE_ORDER,
        variables: {
          amount: cartData?.totalAmount || 0,
          paymentMethod: 'card',
          paymentGateway: 'stripe',
          currency: 'USD',
          nonce: randomUUID(),
          paymentGatewayReferenceId,
          businessId,
          locationId,
          cartId
        }
      })

      dispatch(
        setPaymentMetadata({
          ...paymentMetadata,
          paymentId:
            response.data?.placeOrderConsumerAgent?.payments?.[0]?.id || '',
          orderId: response.data?.placeOrderConsumerAgent?.id || ''
        })
      )
      dispatch(pollOrderPaymentStatus())
    } catch (error) {
      console.error('Error in placing order:', error)
      dispatch(setPaymentInProgress(false))
      dispatch(setPaymentSuccessful(false))
      dispatch(setPaymentIntentClientSecret(''))
      dispatch(setSelectedCheckoutStep(ConsumerAgentCheckoutSteps.DELIVERY))
    }
  }
)

// Async Thunk to fetch payment status (used for polling)
export const fetchOrderPaymentStatus = createAsyncThunk(
  'payment/fetchOrderPaymentStatus',
  async (_, {getState, dispatch}) => {
    const state = getState() as RootState
    const {orderId, paymentId} = state.payment.paymentMetadata
    const {locationId, businessId} = state.consumer
    const response = await client.mutate({
      mutation: ORDER_PAYMENT_STATUS,
      variables: {orderId, paymentId, businessId, locationId}
    })
    return response.data?.orderPaymentStatusConsumerAgent
  }
)

// Async Thunk to repeatedly fetch payment status
export const pollOrderPaymentStatus = createAsyncThunk(
  'payment/pollOrderPaymentStatus',
  async (_, {dispatch, getState}) => {
    dispatch(startPollingOrderPaymentStatus())
    let counter = 0
    const maxAttempts = 12
    let timeoutRef: any = null

    const poll = async () => {
      // if the polling is stopped, clear the timeout and return
      const isPollingOrderPaymentStatus = (getState() as RootState).payment
        .isPollingOrderPaymentStatus

      if (!isPollingOrderPaymentStatus) {
        clearTimeout(timeoutRef)
        return
      }

      // if the counter exceeds the max attempts, stop polling and update the message as failed
      if (counter >= maxAttempts) {
        dispatch(stopPollingOrderPaymentStatus())
        dispatch(setPaymentInProgress(false))
        dispatch(setPaymentSuccessful(false))
        return
      }

      // fetch the order response and process it if it is processed or failed
      const response = await dispatch(fetchOrderPaymentStatus())
      dispatch(processOrderPaymentStatus(get(response, 'payload') as OrderType))

      // increment the counter and set the timeout for the next poll
      counter++
      timeoutRef = setTimeout(poll, 5000)
    }
    poll()
  }
)

export const processOrderPaymentStatus = createAsyncThunk(
  'payment/processOrderPaymentStatus',
  async (response: OrderType, {dispatch, getState}) => {
    const state = getState() as RootState

    const {paymentMetadata} = state.payment
    const {orderId, paymentId} = paymentMetadata

    if (response.paymentStatus !== 'paid') return
    dispatch(stopPollingOrderPaymentStatus())
    dispatch(setPaymentInProgress(false))
    dispatch(setPaymentSuccessful(true))
    dispatch(
      setPaymentMetadata({
        ...paymentMetadata,
        isPaymentSuccessful: true,
        paymentId,
        orderId
      })
    )

    dispatch(
      addChatMessage({
        query: '',
        hasFetched: true,
        messageId: '',
        response: {
          answer: `Your order has been placed successfully. Your order id is ${orderId}, payment id is ${paymentId}, payment gateway reference id is ${paymentMetadata.paymentGatewayReferenceId}`
        }
      })
    )

    dispatch(cleanupPayment())
  }
)

export const cleanupPayment = createAsyncThunk(
  'payment/cleanupPayment',
  async (_, {dispatch}) => {
    dispatch(setCartData(null))
    dispatch(setCartItems([]))
    dispatch(createCartForConsumerAgent())
    dispatch(setPaymentGatewayReferenceId(''))
    dispatch(setPaymentIntentClientSecret(''))
    dispatch(setPaymentSuccessful(false))
    dispatch(triggerScrollToBottom())
  }
)

// Payment slice definition
const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentGatewayReferenceId(state, action: PayloadAction<string>) {
      state.paymentGatewayReferenceId = action.payload
    },
    setPaymentMetadata(state, action: PayloadAction<PaymentMetadata>) {
      state.paymentMetadata = action.payload
    },
    setPaymentInProgress(state, action: PayloadAction<boolean>) {
      state.paymentInProgress = action.payload
    },
    setPaymentSuccessful(state, action: PayloadAction<boolean>) {
      state.isPaymentSuccessful = action.payload
    },
    setSelectedPaymentMethod(state, action: PayloadAction<string>) {
      state.selectedPaymentMethod = action.payload
    },
    setPaymentIntentClientSecret(state, action: PayloadAction<string>) {
      state.paymentIntentClientSecret = action.payload
    },
    startPollingOrderPaymentStatus(state) {
      state.isPollingOrderPaymentStatus = true
    },
    stopPollingOrderPaymentStatus(state) {
      state.isPollingOrderPaymentStatus = false
    },
    resetPaymentState(state) {
      state.paymentIntentClientSecret = ''
      state.paymentGatewayReferenceId = ''
      state.isPaymentSuccessful = false
      state.paymentInProgress = false
      state.paymentMetadata = {
        paymentGatewayReferenceId: '',
        paymentId: '',
        orderId: '',
        isPaymentSuccessful: false
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle the result of createPaymentIntent
      .addCase(createPaymentIntent.fulfilled, (state, action) => {
        state.paymentIntentClientSecret = action.payload
      })
      .addCase(createPaymentIntent.rejected, (state, action) => {
        console.error('Error in creating payment intent:', action.payload)
      })
  }
})

export const {
  setPaymentGatewayReferenceId,
  setPaymentMetadata,
  setPaymentInProgress,
  resetPaymentState,
  setSelectedPaymentMethod,
  startPollingOrderPaymentStatus,
  stopPollingOrderPaymentStatus,
  setPaymentSuccessful,
  setPaymentIntentClientSecret
} = paymentSlice.actions

export default paymentSlice.reducer
