import {forwardRef} from 'react'
import {MultiSelectElement, MultiSelectElementProps} from 'react-hook-form-mui'

const MultiSelectField = forwardRef(
  (props: MultiSelectElementProps & {className?: string}, ref: any) => {
    const {className, ...rest} = props
    return (
      <MultiSelectElement
        formControlProps={{
          className: props.className
        }}
        ref={ref}
        showCheckbox
        {...rest}
      />
    )
  }
)

export default MultiSelectField
