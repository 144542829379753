import {Field, FieldType} from 'ui/organisms/FormBuilder/FormBuilder'
import {t} from 'app/i18n'
import _keyBy from 'lodash/keyBy'
import {UseFormReturn} from 'react-hook-form'
import StreetAddressField, {
  Address
} from 'ui/organisms/FormBuilder/customFields/StreetAddressField'
import {usaTimeZones} from 'utils/date.utils'
import {getSelectOptions} from 'utils/array.utils'
import OpeningHoursField from 'ui/organisms/FormBuilder/customFields/OpeningHoursField'
import {daysOfWeek} from 'constants/dateTime.constants'
import _lowerCase from 'lodash/lowerCase'

const timingFields = daysOfWeek.map((day, index) => ({
  id: `operatingHours.${index}`,
  label: day,
  fieldComponent: OpeningHoursField,
  span: 12
}))

const fields: Field[] = [
  {
    id: 'details',
    label: t('location-management.details'),
    type: FieldType.SectionHeader
  },
  {
    id: 'name',
    label: t('location-management.name'),
    required: true
  },
  {
    id: 'brand',
    label: t('location-management.brand'),
    required: true
  },
  {
    id: 'storeNumber',
    label: t('location-management.store-number'),
    required: true
  },
  {
    id: 'timeZone',
    label: t('location-management.time-zone'),
    type: FieldType.Select,
    options: getSelectOptions(usaTimeZones),
    required: true
  },
  {
    id: 'description',
    label: t('location-management.description'),
    multiline: true,
    rows: 4,
    span: 12
  },
  {
    id: 'notificationEmails',
    label: t('location-management.notification-emails'),
    span: 12
  },
  {
    id: 'Address',
    label: t('Address'),
    type: FieldType.SectionHeader
  },
  {
    id: 'address.street',
    label: t('Street'),
    fieldComponent: StreetAddressField,
    span: 12
  },
  {
    id: 'address.city',
    label: t('City')
  },
  {
    id: 'address.state',
    label: t('State')
  },
  {
    id: 'address.zipCode',
    label: t('Zip Code')
  },
  {
    id: 'address.country',
    label: t('Country')
  },
  {
    id: 'phoneNumbers',
    label: t('Phone Numbers'),
    type: FieldType.SectionHeader
  },
  {
    id: 'phoneNumbers.contactPhone',
    label: t('Contact Phone')
  },
  {
    id: 'phoneNumbers.supportPhone',
    label: t('Support Phone')
  },
  {
    id: 'operatingHours',
    label: t('Operating hours'),
    type: FieldType.SectionHeader
  },
  ...timingFields
]

const fieldsById = _keyBy(fields, 'id')

type EditLocationFields = {
  formContext: UseFormReturn<any>
  storeNumbers: string[]
  storeNames: string[]
  editLocationModalData: any
}
export const getEditLocationFields = ({
  formContext,
  storeNumbers,
  storeNames,
  editLocationModalData
}: EditLocationFields) => {
  fieldsById['address.street'].setFullAddress = (address: Address) => {
    formContext.setValue('address.street', address.street)
    formContext.setValue('address.city', address.city)
    formContext.setValue('address.state', address.state)
    formContext.setValue('address.zipCode', address.zipcode)
    formContext.setValue('address.country', address.country)
  }

  fieldsById['storeNumber'].rules = {
    validate: (value: string) => {
      if (
        value !== editLocationModalData.storeNumber &&
        storeNumbers.includes(_lowerCase(value))
      ) {
        return t('location-management.store-number-already-exists')
      }
      return true
    }
  }

  fieldsById['storeNumber'].onChange = () => {
    formContext.trigger('storeNumber')
  }

  fieldsById['name'].rules = {
    validate: (value: string) => {
      if (
        value !== editLocationModalData.name &&
        storeNames.includes(_lowerCase(value))
      ) {
        return t('location-management.name-already-exists')
      }
      return true
    }
  }

  fieldsById['name'].onChange = () => {
    formContext.trigger('name')
  }

  return fields
}
