import {useLazyQuery, useMutation} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessAndLocation} from 'clientDashboard/employee.selectors'
import DeviceBitmapConfig from 'clientDashboard/settings/templates/DeviceBitmapConfig'
import {
  DELETE_DEVICE_TEMPLATE,
  SAVE_DEVICE_TEMPLATE
} from 'graphql/mutations/templates.mutations'
import {LIST_DEVICE_TEMPLATES} from 'graphql/queries/templates.queries'
import useNotify from 'hooks/useNotify'
import _isEmpty from 'lodash/isEmpty'
import _includes from 'lodash/includes'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {formatFileName} from 'utils/common'

const MAX_FILE_SIZE_IN_BYTES = 1024 * 100 // 100 KB

const DeviceTemplateConfig = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const notify = useNotify()

  const {businessId} = useAppSelector(selectBusinessAndLocation)
  const {templateName: templateNameParam} = useParams<{templateName: string}>()
  const [templateName, setTemplateName] = useState(
    (templateNameParam ? templateNameParam.split('.')[0] : '') || ''
  )
  const isNewTemplate = !templateNameParam

  const [uploadedFile, setUploadedFile] = useState<{
    name: string
    extension: string
    content: string
  } | null>(null)

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (file.size > MAX_FILE_SIZE_IN_BYTES) {
        notify.show('File size should be less than 100 KB', 'error')
        return
      }

      const [name, extension] = file.name.split('.')
      !templateName && setTemplateName(name)

      const reader = new FileReader()
      reader.onload = (e) => {
        const content = e.target?.result as string
        setUploadedFile({
          name: file.name,
          extension: extension,
          content: content
        })
      }
      reader.readAsText(file)
    }
  }

  const handleCancelUpload = () => {
    setUploadedFile(null)
  }

  const formattedFileName = formatFileName(
    templateName,
    uploadedFile?.extension
  )

  const isSaveDisabled = !templateName || !uploadedFile?.content

  const [listDeviceTemplates] = useLazyQuery(LIST_DEVICE_TEMPLATES, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const {listDeviceTemplates} = data || {}

      /**
       * Redirect to templates page
       * 1. If the template name does not exist
       * 2. If adding another template
       *
       */
      let shouldRedirect = !!templateNameParam
      if (!_isEmpty(listDeviceTemplates)) {
        shouldRedirect = !_includes(listDeviceTemplates, templateNameParam)
      }
      shouldRedirect && history.push(`/${businessId}/templates`)
    }
  })

  useEffect(() => {
    listDeviceTemplates()
  }, [])

  const [saveDeviceTemplate] = useMutation(SAVE_DEVICE_TEMPLATE, {
    onCompleted: () => {
      history.push(`/${businessId}/templates`)
    }
  })

  const [deleteDeviceTemplate] = useMutation(DELETE_DEVICE_TEMPLATE, {
    onCompleted: () => {
      history.push(`/${businessId}/templates`)
    }
  })

  return (
    <Grid container spacing={8} direction='column'>
      <Grid item container spacing={2} direction='column'>
        <Grid
          item
          gap={4}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <TextField
            label={t('templates.template-name')}
            value={templateName || ''}
            disabled={!isNewTemplate}
            inputProps={{maxLength: 30}}
            onChange={(e) => {
              setTemplateName(e.target.value)
            }}
          />

          {!isNewTemplate && (
            <Tooltip title={t('templates.delete-template')}>
              <IconButton
                onClick={() => {
                  deleteDeviceTemplate({
                    variables: {
                      templateName: templateNameParam
                    }
                  })
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'circle-xmark',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  style={{color: 'red'}}
                />
              </IconButton>
            </Tooltip>
          )}
        </Grid>

        <Grid item xs={12}>
          {!uploadedFile?.name ? (
            <>
              <Button variant='text' component='label' color='primary'>
                {t('templates.button.upload-file')}
                <input
                  type='file'
                  hidden
                  onChange={handleFileUpload}
                  accept='.txt .html'
                />
              </Button>
            </>
          ) : (
            <Grid container alignItems='center' spacing={2}>
              <Grid item>
                <Typography variant='body2' color='textSecondary'>
                  {uploadedFile?.name} (It will be stored as {formattedFileName}
                  )
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleCancelUpload}>
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'circle-xmark',
                      family: 'classic',
                      style: 'light'
                    })}
                    fontSize={20}
                    style={{color: 'red'}}
                  />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            variant='contained'
            color='primary'
            disabled={isSaveDisabled}
            onClick={() => {
              saveDeviceTemplate({
                variables: {
                  fileName: formattedFileName,
                  fileContent: uploadedFile?.content || ''
                }
              })
            }}
          >
            {t('save')}
          </Button>
        </Grid>
      </Grid>

      <Grid item container direction='column'>
        <Grid item>
          <Typography variant='h6'>{t('templates.device-bitmaps')}</Typography>
        </Grid>

        <Grid item>
          <DeviceBitmapConfig />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DeviceTemplateConfig
