import {useLazyQuery, useMutation} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import {
  DELETE_DEVICE_BITMAP,
  SAVE_DEVICE_BITMAP
} from 'graphql/mutations/templates.mutations'
import {LIST_DEVICE_BITMAPS} from 'graphql/queries/templates.queries'
import useNotify from 'hooks/useNotify'
import {t} from 'i18next'
import {MRT_ColumnDef} from 'material-react-table'
import React from 'react'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledTable from 'ui/molecules/StyledTable'
import {formatFileName} from 'utils/common'

const MAX_FILE_SIZE_IN_BYTES = 1024 * 100 // 100 KB

const columns = [
  {
    accessorKey: 'name',
    header: t('templates.header.name'),
    Cell: ({cell}: any) => {
      return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
    }
  }
] as MRT_ColumnDef<any>[]

const DeviceBitmapConfig = () => {
  const {t} = useTranslation()
  const notify = useNotify()

  const [openCreateBitmapDialog, setOpenCreateBitmapDialog] = useState(false)
  const [uploadedBitmapFile, setUploadedBitmapFile] = useState<{
    name: string
    extension: string
    content: string
  } | null>(null)

  const [bitmapName, setBitmapName] = useState('')

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (file.size > MAX_FILE_SIZE_IN_BYTES) {
        notify.show('File size should be less than 100 KB', 'error')
        return
      }

      const [name, extension] = file.name.split('.')
      !bitmapName && setBitmapName(name)
      const reader = new FileReader()
      reader.onload = (e) => {
        const binaryString = btoa(e.target?.result as any)
        setUploadedBitmapFile({
          name: file.name,
          extension: extension,
          content: binaryString
        })
      }
      reader.readAsBinaryString(file)
    }
  }

  const handleCancelUpload = () => {
    setOpenCreateBitmapDialog(false)
    setBitmapName('')
    setUploadedBitmapFile(null)
  }

  const formattedFileName = formatFileName(
    bitmapName,
    uploadedBitmapFile?.extension
  )

  const [saveDeviceBitmap] = useMutation(SAVE_DEVICE_BITMAP, {})

  const [deleteDeviceBitmap] = useMutation(DELETE_DEVICE_BITMAP, {
    fetchPolicy: 'no-cache'
  })

  const [listDeviceBitmaps, {data: deviceBitmaps}] = useLazyQuery(
    LIST_DEVICE_BITMAPS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  useEffect(() => {
    listDeviceBitmaps()
  }, [])

  return (
    <div>
      <StyledTable
        columns={columns}
        data={
          deviceBitmaps?.listDeviceBitmaps.map((bitmap: any) => ({
            name: bitmap
          })) || []
        }
        enableRowActions={true}
        enableToolbarInternalActions={false}
        initialState={{
          columnPinning: {
            right: ['mrt-row-actions']
          }
        }}
        renderTopToolbarCustomActions={({table}) => {
          return (
            <Grid
              style={{
                order: 1
              }}
            >
              <Button
                onClick={() => {
                  setOpenCreateBitmapDialog(true)
                }}
              >
                {t('templates.button.add-bitmap')}
              </Button>
            </Grid>
          )
        }}
        renderRowActions={({row}) => (
          <Box>
            <IconButton
              onClick={async () => {
                await deleteDeviceBitmap({
                  variables: {
                    bitmapName: row.original.name
                  }
                })

                listDeviceBitmaps()
              }}
            >
              <Tooltip title={t('templates.delete-bitmap')}>
                <FontAwesomeIcon
                  icon={icon({
                    name: 'circle-xmark',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  color='black'
                  style={{
                    borderWidth: '1px',
                    color: 'red'
                  }}
                />
              </Tooltip>
            </IconButton>
          </Box>
        )}
      />

      <StyledDialog
        open={openCreateBitmapDialog}
        onClose={handleCancelUpload}
        title={t('templates.create-bitmap')}
        successButtonName={t('templates.button.create')}
        cancelButtonName={t('templates.button.cancel')}
        cancelCallback={handleCancelUpload}
        successCallback={async () => {
          await saveDeviceBitmap({
            variables: {
              fileName: formattedFileName,
              fileContent: uploadedBitmapFile?.content || ''
            }
          })

          handleCancelUpload()
          listDeviceBitmaps({
            variables: {}
          })
        }}
        body={
          <>
            <Grid
              item
              gap={4}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <TextField
                label={t('templates.bitmap-name')}
                value={bitmapName || ''}
                inputProps={{maxLength: 30}}
                onChange={(e) => {
                  setBitmapName(e.target.value)
                }}
              />
            </Grid>

            <Grid item xs={12}>
              {!uploadedBitmapFile?.name ? (
                <>
                  <Button variant='text' component='label' color='primary'>
                    {t('templates.button.upload-file')}
                    <input
                      type='file'
                      hidden
                      onChange={handleFileUpload}
                      accept='.bmp'
                    />
                  </Button>
                </>
              ) : (
                <Grid container alignItems='center' spacing={2}>
                  <Grid item>
                    <Typography variant='body2' color='textSecondary'>
                      {uploadedBitmapFile?.name} (It will be stored as{' '}
                      {formattedFileName})
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setUploadedBitmapFile(null)
                      }}
                    >
                      <FontAwesomeIcon
                        icon={icon({
                          name: 'circle-xmark',
                          family: 'classic',
                          style: 'light'
                        })}
                        fontSize={20}
                        style={{color: 'red'}}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        }
        disableSuccessButton={!bitmapName || !uploadedBitmapFile?.name}
      />
    </div>
  )
}

export default DeviceBitmapConfig
