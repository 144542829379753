/* eslint-disable */
import * as types from './graphql'
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  mutation createBusiness($business: BusinessDetailsInput!) {\n    createBusiness(business: $business) {\n      brand\n      id\n      logo\n      name\n      okta {\n        oktaClientId\n        oktaIssuer\n      }\n      contactDetails {\n        contactPhone\n        contactEmail\n        supportPhone\n        supportEmail\n      }\n      featureFlags\n      address {\n        city\n        lat\n        long\n        state\n        street\n        zipCode\n        country\n      }\n      authMethod\n    }\n  }\n':
    types.CreateBusinessDocument,
  '\n  mutation updateBusiness(\n    $businessId: String!\n    $business: BusinessDetailsUpdateInput!\n  ) {\n    updateBusiness(business: $business, businessId: $businessId) {\n      brand\n      id\n      logo\n      name\n      okta {\n        oktaClientId\n        oktaIssuer\n      }\n      contactDetails {\n        contactPhone\n        contactEmail\n        supportPhone\n        supportEmail\n      }\n      featureFlags\n      address {\n        city\n        lat\n        long\n        state\n        street\n        zipCode\n        country\n      }\n      authMethod\n    }\n  }\n':
    types.UpdateBusinessDocument,
  '\n  mutation updateBusinessColors(\n    $businessId: String!\n    $primary: String!\n    $secondary: String!\n  ) {\n    updateBusinessColors(\n      businessId: $businessId\n      primary: $primary\n      secondary: $secondary\n    ) {\n      primary\n      secondary\n    }\n  }\n':
    types.UpdateBusinessColorsDocument,
  '\n  mutation updateOrderEmailReceiptTemplate($orderReceipt: String!) {\n    updateOrderEmailReceiptTemplate(orderReceipt: $orderReceipt)\n  }\n':
    types.UpdateOrderEmailReceiptTemplateDocument,
  '\n  mutation toggleEmailReceiptEnabled($emailReceiptEnabled: Boolean!) {\n    toggleEmailReceiptEnabled(emailReceiptEnabled: $emailReceiptEnabled)\n  }\n':
    types.ToggleEmailReceiptEnabledDocument,
  '\n  mutation resetOrderEmailReceiptTemplate {\n    resetOrderEmailReceiptTemplate\n  }\n':
    types.ResetOrderEmailReceiptTemplateDocument,
  '\n  mutation createCart($locationId: String!, $employeeId: String!, $customerId: String!) {\n    createCart(locationId: $locationId, employeeId: $employeeId, customerId: $customerId) {\n      id\n    }\n  }\n':
    types.CreateCartDocument,
  '\n  mutation createCartGuest($locationId: String!) {\n    createCartGuest(locationId: $locationId) {\n      id\n    }\n  }\n':
    types.CreateCartGuestDocument,
  '\n  mutation updateCartItems(\n    $cartId: String!, \n    $items: [ItemInput!]!, \n    $subscriptionItems: [SubscriptionItemInput!]!,\n    $returnedItems: [ReturnItemInput!]!,\n    $customerId: String,\n    $promotionId: String,\n    $deliveryAddress: AddressInput\n  ) {\n      updateCartItems(\n        cartId: $cartId, \n        items: $items, \n        subscriptionItems: $subscriptionItems, \n        returnedItems: $returnedItems, \n        promotionId: $promotionId, \n        deliveryAddress: $deliveryAddress, \n        customerId: $customerId\n      ) {\n        id\n        items {\n          sku\n          quantity\n          unitPrice\n          unitDiscount\n          description\n          logoUrl\n          unitSubTotal\n          unitDiscount\n          unitTaxAmount\n          totalTaxAmount\n          totalDiscount\n          total\n        }\n        totalDiscount\n        subTotal\n        totalAmount\n        totalTax\n      }\n  }\n':
    types.UpdateCartItemsDocument,
  '\n  mutation copyNfcCartToMpos(\n    $mposCartId: String!\n    $nfcCartId: String!\n    $locationId: String!\n  ) {\n    copyNfcCartToMpos(\n      mposCartId: $mposCartId\n      nfcCartId: $nfcCartId\n      locationId: $locationId\n    )\n  }\n':
    types.CopyNfcCartToMposDocument,
  '\n  mutation verifyCart($cartId: ID!, $locationId: ID!) {\n    verifyCart(cartId: $cartId, locationId: $locationId) \n  }\n':
    types.VerifyCartDocument,
  '\n  mutation cashPickup(\n    $location: String!\n    $amount: Float!\n    $till: String!\n    $register: String!\n    $eventId: String!\n  ) {\n    addTransactionEvent(\n      locationId: $location\n      register: $register\n      till: $till\n      amount: $amount\n      transactionType: "debit"\n      eventType: "cash_pickup"\n      description: "Cash Pickup"\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n':
    types.CashPickupDocument,
  '\n  mutation cashDrop(\n    $location: String!\n    $amount: Float!\n    $till: String!\n    $register: String!\n    $eventId: String!\n  ) {\n    addTransactionEvent(\n      locationId: $location\n      register: $register\n      till: $till\n      amount: $amount\n      transactionType: "credit"\n      eventType: "cash_drop"\n      description: "Cash Drop"\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n':
    types.CashDropDocument,
  '\n  mutation createTransaction(\n    $location: String!\n    $amount: Float!\n    $employeeCode: String\n    $till: String!\n    $register: String!\n    $businessDate: String\n    $eventType: String!\n    $description: String!\n    $transactionType: String\n    $transactionId: String\n    $eventId: String!\n  ) {\n    addTransactionEvent(\n      locationId: $location\n      register: $register\n      till: $till\n      employeeCode: $employeeCode\n      amount: $amount\n      businessDate: $businessDate\n      transactionType: $transactionType\n      eventType: $eventType\n      description: $description\n      transactionId: $transactionId\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n':
    types.CreateTransactionDocument,
  '\n  mutation createSafeTransaction(\n    $locationId: String!\n    $fromSafe: String!\n    $toSafe: String!\n    $amount: Float!\n    $eventType: String!\n    $description: String!\n    $eventId: String!\n  ) {\n    addSafeCashTransactionEvent(\n      locationId: $locationId\n      fromSafe: $fromSafe\n      toSafe: $toSafe\n      amount: $amount\n      eventType: $eventType\n      description: $description\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n':
    types.CreateSafeTransactionDocument,
  '\n  mutation cashTillClose(\n    $location: String!\n    $amount: Float!\n    $cashVariance: Float!\n    $till: String!\n    $register: String!\n    $businessDate: String\n    $reasonCode: String\n    $denominationBreakdown: [DenominationInput!]\n    $reasonDescription: String\n    $otherDenominationCurrency: Int\n    $eventId: String!\n  ) {\n    addTransactionEvent(\n      locationId: $location\n      register: $register\n      till: $till\n      amount: $amount\n      businessDate: $businessDate\n      cashVariance: $cashVariance\n      reasonCode: $reasonCode\n      transactionType: "debit"\n      eventType: "reconcile"\n      description: "Reconcile Till"\n      denominationBreakdown: $denominationBreakdown\n      otherDenominationCurrency: $otherDenominationCurrency\n      reasonDescription: $reasonDescription\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n':
    types.CashTillCloseDocument,
  '\n  mutation cashDeposit($location: String!, $amount: Int!) {\n    createBankDeposit(locationId: $location, amount: $amount) {\n      amount\n      createdAt\n      updatedAt\n      status\n      id\n    }\n  }\n':
    types.CashDepositDocument,
  '\n  mutation safeReconcile(\n    $location: String!\n    $amount: Int!\n    $safeType: String!\n    $reasonCode: String!\n    $denominationBreakdown: [DenominationInput!]\n    $reasonDescription: String\n    $otherDenominationCurrency: Int\n  ) {\n    createStoreTransaction(\n      locationId: $location\n      amount: $amount\n      safeType: $safeType\n      reasonCode: $reasonCode\n      denominationBreakdown: $denominationBreakdown\n      reasonDescription: $reasonDescription\n      otherDenominationCurrency: $otherDenominationCurrency\n    ) {\n      amount\n      dateCreated\n      dateModified\n      id\n    }\n  }\n':
    types.SafeReconcileDocument,
  '\n  mutation cashDepositUpdate(\n    $id: String!\n    $location: String!\n    $depositNumber: String!\n    $depositReceipt: Upload\n  ) {\n    updateBankDeposit(\n      id: $id\n      locationId: $location\n      depositNumber: $depositNumber\n      depositReceipt: $depositReceipt\n    ) {\n      amount\n      createdAt\n      updatedAt\n      status\n      depositReceipt\n      id\n    }\n  }\n':
    types.CashDepositUpdateDocument,
  '\n  mutation DownloadFile($locationId: String!, $id: String!) {\n    downloadDepositReceipt(locationId: $locationId, id: $id) {\n      fileContent\n      fileName\n    }\n  }\n':
    types.DownloadFileDocument,
  '\n  mutation generateAtlasAuthToken {\n    generateAtlasAuthToken {\n      success\n      token\n    }\n  }\n':
    types.GenerateAtlasAuthTokenDocument,
  '\n  mutation bulkCashTransaction($transactions: [CashTransactionInputType!]!) {\n    bulkTransactionEvent(transactions: $transactions) {\n      cashEvent\n    }\n  }\n':
    types.BulkCashTransactionDocument,
  '\n  mutation addCustomerForConsumerAgent($customer: CustomerInput!) {\n    addCustomerForConsumerAgent(customer: $customer)\n  }\n':
    types.AddCustomerForConsumerAgentDocument,
  '\n  mutation loginCustomer(\n    $email: String!\n    $password: String!\n    $businessId: String!\n    $locationId: String!\n  ) {\n    loginCustomer(\n      email: $email\n      password: $password\n      businessId: $businessId\n      locationId: $locationId\n    ) {\n      customer {\n        id\n        email\n        firstName\n        lastName\n      }\n      token\n    }\n  }\n':
    types.LoginCustomerDocument,
  '\n  mutation addCustomer($customer: CustomerInput!) {\n    addCustomer(customer: $customer)\n  }\n':
    types.AddCustomerDocument,
  '\n  mutation updateCustomer($customerId: String!, $customer: CustomerInput!) {\n    updateCustomer(customer: $customer, customerId: $customerId)\n  }\n':
    types.UpdateCustomerDocument,
  '\n  mutation validateCustomerSignupToken($token: String!, $checkUsage: Boolean) {\n    validateCustomerSignupToken(token: $token, checkUsage: $checkUsage) {\n      flow\n\n      cart {\n        customer {\n          id\n          firstName\n          lastName\n          email\n\n          addresses {\n            street\n            city\n            state\n            zipCode\n            country\n          }\n        }\n        deliveryAddress {\n          street\n          city\n          state\n          zipCode\n          country\n        }\n        deliveryTimeSlot {\n          startTime {\n            hours\n            minutes\n          }\n          endTime {\n            hours\n            minutes\n          }\n        }\n        subscriptionItems {\n          frequency\n          status\n          description\n          sku\n          total\n          quantity\n        }\n        id\n        location {\n          id\n          name\n          business {\n            id\n            name\n            configurations {\n              deliveryTimeSlots {\n                startTime {\n                  hours\n                  minutes\n                }\n                endTime {\n                  hours\n                  minutes\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.ValidateCustomerSignupTokenDocument,
  '\n  mutation signupCustomer($customer: CustomerInput!, $token: String!) {\n    signupCustomer(customer: $customer, token: $token) {\n      customer {\n        id\n        email\n        firstName\n        lastName\n      }\n      token\n    }\n  }\n':
    types.SignupCustomerDocument,
  '\n  mutation customerSignin(\n    $email: String!\n    $password: String!\n    $token: String!\n  ) {\n    customerSignin(email: $email, password: $password, token: $token) {\n      customer {\n        id\n        email\n        firstName\n        lastName\n      }\n      token\n    }\n  }\n':
    types.CustomerSigninDocument,
  '\n  mutation approveItemSubscription(\n    $cartId: String!\n    $sku: String!\n    $token: String!\n  ) {\n    approveItemSubscription(cartId: $cartId, sku: $sku, token: $token)\n  }\n':
    types.ApproveItemSubscriptionDocument,
  '\n  mutation generateGenieChatSessionIdConsumerAgent {\n    generateGenieChatSessionIdConsumerAgent\n  }\n':
    types.GenerateGenieChatSessionIdConsumerAgentDocument,
  '\n  mutation deleteChatHistoryForConsumerAgent($chatSessionId: String!) {\n    deleteChatHistoryForConsumerAgent(chatSessionId: $chatSessionId)\n  }\n':
    types.DeleteChatHistoryForConsumerAgentDocument,
  '\n  mutation addCustomerAddress(\n    $customerId: String!\n    $token: String!\n    $address: CustomerAddressInput!\n  ) {\n    addCustomerAddress(\n      customerId: $customerId\n      token: $token\n      address: $address\n    ) {\n      addresses {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      id\n    }\n  }\n':
    types.AddCustomerAddressDocument,
  '\n  mutation updateCartDeliveryInfo(\n    $cartId: String!\n    $token: String!\n    $address: AddressInput!\n    $deliveryTimeSlot: DeliveryTimeSlotInput\n  ) {\n    updateCartDeliveryInfo(\n      cartId: $cartId\n      token: $token\n      address: $address\n      deliveryTimeSlot: $deliveryTimeSlot\n    ) {\n      id\n    }\n  }\n':
    types.UpdateCartDeliveryInfoDocument,
  '\n  mutation addCustomerToCart($token: String!, $cartId: String!) {\n    addCustomerToCart(token: $token, cartId: $cartId)\n  }\n':
    types.AddCustomerToCartDocument,
  '\n  mutation createEmployee($businessId: String!, $employee: EmployeeInput!) {\n    createEmployee(businessId: $businessId, employee: $employee) {\n      id\n      name\n      email\n      phoneNumber\n      employeeCode\n      fullAddress {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      role {\n        id\n        name\n      }\n      locations {\n        id\n        name\n      }\n      defaultLocation {\n        id\n        name\n      }\n      status\n    }\n  }\n':
    types.CreateEmployeeDocument,
  '\n  mutation updateEmployee(\n    $employeeId: String!\n    $businessId: String!\n    $employee: EmployeeInput!\n  ) {\n    updateEmployee(\n      employee: $employee\n      businessId: $businessId\n      employeeId: $employeeId\n    ) {\n      id\n      name\n      email\n      phoneNumber\n      employeeCode\n      fullAddress {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      role {\n        id\n        name\n      }\n      locations {\n        id\n        name\n      }\n      defaultLocation {\n        id\n        name\n      }\n      status\n    }\n  }\n':
    types.UpdateEmployeeDocument,
  '\n  mutation sendOtpForPasswordReset($token: String!) {\n    sendOtpForPasswordReset(token: $token)\n  }\n':
    types.SendOtpForPasswordResetDocument,
  '\n  mutation resetPasswordWithOtp(\n    $token: String!\n    $otp: String!\n    $password: String!\n    $pin: String!\n  ) {\n    resetPasswordWithOtp(\n      token: $token\n      otp: $otp\n      password: $password\n      pin: $pin\n    )\n  }\n':
    types.ResetPasswordWithOtpDocument,
  '\n  mutation resendOtpForPasswordReset($token: String!) {\n    resendOtpForPasswordReset(token: $token)\n  }\n':
    types.ResendOtpForPasswordResetDocument,
  '\n  mutation GenerateGenieChatSessionId {\n    generateGenieChatSessionId\n  }\n':
    types.GenerateGenieChatSessionIdDocument,
  '\n  mutation createInventory($inventory: InventoryInput!, $locationId: String!) {\n    createInventory(inventory: $inventory, locationId: $locationId) {\n      id\n      logoUrl\n      description\n      pricingDetails {\n        currency\n        currencyMultiplier\n        discount\n        total\n        unitPrice\n      }\n      sku\n      taxDetails {\n        taxAmount\n        taxGroup\n        taxRate\n      }\n      unit\n      unitCount\n      upc\n      businessId\n      locationId\n      taxable\n      skuType\n      className\n      subclassName\n      categoryName\n    }\n  }\n':
    types.CreateInventoryDocument,
  '\n  mutation updateInventory($inventory: InventoryInput!, $locationId: String!) {\n    updateInventory(inventory: $inventory, locationId: $locationId) {\n      id\n      logoUrl\n      description\n      pricingDetails {\n        currency\n        currencyMultiplier\n        discount\n        total\n        unitPrice\n      }\n      sku\n      taxDetails {\n        taxAmount\n        taxGroup\n        taxRate\n      }\n      unit\n      unitCount\n      upc\n      businessId\n      locationId\n      taxable\n      skuType\n      className\n      subclassName\n      categoryName\n    }\n  }\n':
    types.UpdateInventoryDocument,
  '\n  mutation deleteInventory($id: String!) {\n    deleteInventory(id: $id)\n  }\n':
    types.DeleteInventoryDocument,
  '\n  mutation addLocation($location: LocationInput!) {\n    addLocation(location: $location) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      status\n      notificationEmails\n    }\n  }\n':
    types.AddLocationDocument,
  '\n  mutation updateLocation($locationId: String!, $location: LocationInput!) {\n    updateLocation(location: $location, locationId: $locationId) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      status\n      notificationEmails\n    }\n  }\n':
    types.UpdateLocationDocument,
  '\n  mutation locationConfig($input: LocationConfigInput!) {\n    locationConfig(input: $input)\n  }\n':
    types.LocationConfigDocument,
  '\n  mutation deactivateLocation($locationId: String!) {\n    deactivateLocation(locationId: $locationId) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      status\n    }\n  }\n':
    types.DeactivateLocationDocument,
  '\n  mutation activateLocation($locationId: String!) {\n    activateLocation(locationId: $locationId) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      status\n    }\n  }\n':
    types.ActivateLocationDocument,
  '\n  mutation VerifyOktaTokenAndGenerateSessionToken(\n    $businessId: String = null\n    $accessToken: String!\n    $idToken: String!\n    $oktaClientId: String!\n    $oktaIssuer: String!\n  ) {\n    verifyOktaTokenAndGenerateSessionToken(\n      businessId: $businessId\n      accessToken: $accessToken\n      idToken: $idToken\n      oktaClientId: $oktaClientId\n      oktaIssuer: $oktaIssuer\n    )\n  }\n':
    types.VerifyOktaTokenAndGenerateSessionTokenDocument,
  '\n  mutation loginUserForDashboard(\n    $email: String!\n    $password: String!\n    $businessId: String!\n  ) {\n    loginUserForDashboard(\n      email: $email\n      password: $password\n      businessId: $businessId\n    ) {\n      toteAccessToken\n      businessId\n      mfaEnabled\n    }\n  }\n':
    types.LoginUserForDashboardDocument,
  '\n  mutation loginGuest($locationId: ID!) {\n    loginGuest(locationId: $locationId) {\n      success\n      token\n      businessConfigs {\n        stripePublishableKey\n      }\n      location {\n        id\n        name\n        business {\n          id\n          name\n          configurations {\n            colors {\n              primary\n              secondary\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.LoginGuestDocument,
  '\n  mutation verifyOtpAndGenerateSessionToken(\n    $email: String!\n    $otp: String!\n    $businessId: String!\n  ) {\n    verifyOtpAndGenerateSessionToken(\n      email: $email\n      otp: $otp\n      businessId: $businessId\n    ) {\n      toteAccessToken\n      businessId\n    }\n  }\n':
    types.VerifyOtpAndGenerateSessionTokenDocument,
  '\n  mutation resendOtp($email: String!, $businessId: String!) {\n    resendOtp(email: $email, businessId: $businessId)\n  }\n':
    types.ResendOtpDocument,
  '\n  mutation VerifyAdminOktaTokenAndGenerateSessionToken(\n    $accessToken: String!\n    $idToken: String!\n    $oktaClientId: String!\n    $oktaIssuer: String!\n  ) {\n    verifyAdminOktaTokenAndGenerateSessionToken(\n      accessToken: $accessToken\n      idToken: $idToken\n      oktaClientId: $oktaClientId\n      oktaIssuer: $oktaIssuer\n    )\n  }\n':
    types.VerifyAdminOktaTokenAndGenerateSessionTokenDocument,
  '\n  mutation generateOauthApplications($locationId: String!, $appName: String!) {\n    generateOauthApplications(locationId: $locationId, appName: $appName) {\n      appName\n      clientId\n      clientSecret\n      status\n    }\n  }\n':
    types.GenerateOauthApplicationsDocument,
  '\n  mutation createPaymentIntent($amount: Int!, $businessId: String!) {\n    createPaymentIntent(amount: $amount, businessId: $businessId)\n  }\n':
    types.CreatePaymentIntentDocument,
  '\n  mutation placeOrderConsumerAgent(\n    $cartId: String!\n    $currency: String!\n    $amount: Int!\n    $paymentMethod: String!\n    $paymentGateway: String\n    $paymentGatewayReferenceId: String\n    $nonce: String!\n    $businessId: String!\n    $locationId: String!\n  ) {\n    placeOrderConsumerAgent(\n      input: {\n        cartId: $cartId\n        currency: $currency\n        amount: $amount\n        paymentMethod: $paymentMethod\n        paymentGateway: $paymentGateway\n        paymentGatewayReferenceId: $paymentGatewayReferenceId\n        nonce: $nonce\n      }\n      requestContext: {businessId: $businessId, locationId: $locationId}\n    ) {\n      amountPaid\n      amountRefunded\n      currency\n      id\n      paymentStatus\n      refundStatus\n      totalAmount\n      payments {\n        id\n        orderId\n        status\n        amount\n        paymentMethod\n        paymentGatewayReferenceId\n        currency\n        paymentMethodDetails {\n          type\n          card {\n            last4\n            brand\n            fingerprint\n          }\n        }\n      }\n    }\n  }\n':
    types.PlaceOrderConsumerAgentDocument,
  '\n  mutation orderPaymentStatusConsumerAgent(\n    $orderId: String!\n    $paymentId: String!\n    $businessId: String!\n    $locationId: String!\n  ) {\n    orderPaymentStatusConsumerAgent(\n      orderId: $orderId\n      paymentId: $paymentId\n      requestContext: {businessId: $businessId, locationId: $locationId}\n    ) {\n      id\n      paymentStatus\n      refundStatus\n      totalAmount\n      currency\n      amountPaid\n      amountRefunded\n    }\n  }\n':
    types.OrderPaymentStatusConsumerAgentDocument,
  '\n  mutation AddZoneData(\n    $businessId: String!\n    $locationId: String!\n    $name: String!\n    $description: String!\n    $devices: [String!]!\n    $registers: [String!]!\n  ) {\n    addZoneData(\n      businessId: $businessId\n      locationId: $locationId\n      name: $name\n      description: $description\n      devices: $devices\n      registers: $registers\n    )\n  }\n':
    types.AddZoneDataDocument,
  '\n  mutation UpdateZone(\n    $businessId: String!\n    $locationId: String!\n    $zoneId: String!\n    $name: String!\n    $description: String!\n    $devices: [String!]!\n    $registers: [String!]!\n  ) {\n    updateZone(\n      businessId: $businessId\n      locationId: $locationId\n      zoneId: $zoneId\n      name: $name\n      description: $description\n      devices: $devices\n      registers: $registers\n    )\n  }\n':
    types.UpdateZoneDocument,
  '\n  mutation ResolveErrorNotification($activityId: String!) {\n    resolveErrorNotification(activityId: $activityId)\n  }\n':
    types.ResolveErrorNotificationDocument,
  '\n  mutation DeleteZone($zoneId: String!) {\n    deleteZone(zoneId: $zoneId)\n  }\n':
    types.DeleteZoneDocument,
  '\n  mutation updatePeripheralHubNotificationConfig(\n    $notificationConfiguration: PeripheralHubNotificationsInput!\n  ) {\n    updatePeripheralHubNotificationConfig(\n      notificationConfiguration: $notificationConfiguration\n    )\n  }\n':
    types.UpdatePeripheralHubNotificationConfigDocument,
  '\n  mutation CREATE_RULE(\n    $businessId: String!\n    $ruleName: String!\n    $ruleType: String!\n    $ruleValue: String!\n    $applyTo: [String!]\n    $excludeFrom: [String!]\n    $applyToProductGroup: [String!]\n    $excludeFromProductGroup: [String!]\n  ) {\n    createRule(\n      businessId: $businessId\n      ruleName: $ruleName\n      ruleType: $ruleType\n      ruleValue: $ruleValue\n      applyTo: $applyTo\n      excludeFrom: $excludeFrom\n      applyToProductGroup: $applyToProductGroup\n      excludeFromProductGroup: $excludeFromProductGroup\n    ) {\n      businessId\n      ruleName\n      ruleType\n      ruleValue\n      applyTo\n      excludeFrom\n      applyToProductGroup\n      excludeFromProductGroup\n    }\n  }\n':
    types.Create_RuleDocument,
  '\n  mutation createPromotion(\n    $businessId: String!\n    $active: Boolean!\n    $canBeCombined: Boolean!\n    $endDate: DateTime!\n    $priority: Int!\n    $promotionName: String!\n    $promotionType: String!\n    $rules: [String!]!\n    $startDate: DateTime!\n    $promotionValue: Int!\n    $description: String!\n    $promotionSchema: String!\n    $subscriptionType: Boolean!\n  ) {\n    createPromotion(\n      businessId: $businessId\n      active: $active\n      canBeCombined: $canBeCombined\n      endDate: $endDate\n      priority: $priority\n      promotionName: $promotionName\n      promotionType: $promotionType\n      rules: $rules\n      startDate: $startDate\n      promotionValue: $promotionValue\n      description: $description\n      promotionSchema: $promotionSchema\n      subscriptionType: $subscriptionType\n    ) {\n      businessId\n      active\n      canBeCombined\n      endDate\n      priority\n      promotionName\n      promotionType\n      startDate\n      promotionValue\n      description\n      promotionSchema\n      subscriptionType\n      rules {\n        ruleName\n        ruleType\n        ruleValue\n      }\n    }\n  }\n':
    types.CreatePromotionDocument,
  '\n  mutation addPromotionToSku(\n    $promotionName: String!\n    $sku: String!\n    $businessId: String!\n  ) {\n    addPromotionToSku(\n      promotionName: $promotionName\n      sku: $sku\n      businessId: $businessId\n    ) {\n      success\n      message\n    }\n  }\n':
    types.AddPromotionToSkuDocument,
  '\n    mutation AddRegister($register: RegisterInput!) {\n        addRegister(register: $register) {\n            id \n            name\n            isActive\n        }\n    }\n':
    types.AddRegisterDocument,
  '\n    mutation UpdateRegister($register: RegisterInput!, $registerId: String!) {\n        updateRegister(register: $register, registerId: $registerId) {\n            id\n            name\n            isActive\n        }\n    }\n':
    types.UpdateRegisterDocument,
  '\n  mutation createRole(\n    $businessId: String!\n    $name: String!\n    $description: String!\n    $permissions: [String!]!\n  ) {\n    createRole(\n      businessId: $businessId\n      name: $name\n      description: $description\n      permissions: $permissions\n    )\n  }\n':
    types.CreateRoleDocument,
  '\n  mutation updateRole(\n    $businessId: String!\n    $id: String!\n    $name: String!\n    $description: String!\n    $isActive: Boolean!\n    $permissions: [String!]!\n  ) {\n    updateRole(\n      businessId: $businessId\n      id: $id\n      name: $name\n      description: $description\n      isActive: $isActive\n      permissions: $permissions\n    )\n  }\n':
    types.UpdateRoleDocument,
  '\n  mutation deleteRole($businessId: String!, $id: String!) {\n    deleteRole(businessId: $businessId, id: $id)\n  }\n':
    types.DeleteRoleDocument,
  '\n  mutation assignRole($employeeId: String!, $roleId: String) {\n    assignRole(employeeId: $employeeId, roleId: $roleId)\n  }\n':
    types.AssignRoleDocument,
  '\n  mutation AddSecret($secretName: String!, $secretValue: String!) {\n    addSecret(secretName: $secretName, secretValue: $secretValue)\n  }\n':
    types.AddSecretDocument,
  '\n  mutation DeleteSecret($secretName: String!) {\n    deleteSecret(secretName: $secretName)\n  }\n':
    types.DeleteSecretDocument,
  '\n  mutation AddUpdateServiceConfig($input: ServiceConfigInput!) {\n    serviceConfig(input: $input)\n  }\n':
    types.AddUpdateServiceConfigDocument,
  '\n  mutation VerifyServiceConfig($input: ServiceConfigInput!) {\n    verifyServiceConfig(input: $input)\n  }\n':
    types.VerifyServiceConfigDocument,
  '\n  mutation RenameStoreGroupCluster(\n    $storeGroupClusterId: String!\n    $newClusterName: String!\n  ) {\n    renameStoreGroupCluster(\n      storeGroupClusterId: $storeGroupClusterId\n      newClusterName: $newClusterName\n    ) {\n      name\n      hierarchyLevel\n    }\n  }\n':
    types.RenameStoreGroupClusterDocument,
  '\n  mutation CreateStoreGroup(\n    $name: String!\n    $status: String\n    $storeGroupClusterId: String!\n    $childStoreGroupIds: [String!]!\n    $childLocationIds: [String!]!\n  ) {\n    createStoreGroup(\n      input: {\n        name: $name\n        status: $status\n        storeGroupClusterId: $storeGroupClusterId\n        childStoreGroupIds: $childStoreGroupIds\n        childLocationIds: $childLocationIds\n      }\n    ) {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n':
    types.CreateStoreGroupDocument,
  '\n  mutation UpdateStoreGroup(\n    $id: String!\n    $name: String!\n    $status: String\n    $childStoreGroupIds: [String!]!\n    $childLocationIds: [String!]!\n  ) {\n    updateStoreGroup(\n      input: {\n        id: $id\n        name: $name\n        status: $status\n        childStoreGroupIds: $childStoreGroupIds\n        childLocationIds: $childLocationIds\n      }\n    ) {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n':
    types.UpdateStoreGroupDocument,
  '\n  mutation SaveDeviceTemplate(\n    $fileContent: String!\n    $fileName: String!\n    $locationId: String\n  ) {\n    saveDeviceTemplate(\n      fileContent: $fileContent\n      fileName: $fileName\n      locationId: $locationId\n    )\n  }\n':
    types.SaveDeviceTemplateDocument,
  '\n  mutation DeleteDeviceTemplate($templateName: String!, $locationId: String) {\n    deleteDeviceTemplate(locationId: $locationId, templateName: $templateName)\n  }\n':
    types.DeleteDeviceTemplateDocument,
  '\n  mutation SaveDeviceBitmap(\n    $fileContent: String!\n    $fileName: String!\n    $locationId: String\n  ) {\n    saveDeviceBitmap(\n      fileContent: $fileContent\n      fileName: $fileName\n      locationId: $locationId\n    )\n  }\n':
    types.SaveDeviceBitmapDocument,
  '\n  mutation DeleteDeviceBitmap($bitmapName: String!, $locationId: String) {\n    deleteDeviceBitmap(locationId: $locationId, bitmapName: $bitmapName)\n  }\n':
    types.DeleteDeviceBitmapDocument,
  '\n    mutation AddTill($till: TillInput!) {\n        addTill(till: $till) {\n            id \n            name\n            isActive\n        }\n    }\n':
    types.AddTillDocument,
  '\n    mutation UpdateTill($till: TillInput!, $tillId: String!) {\n        updateTill(till: $till, tillId: $tillId) {\n            id\n            name\n            isActive\n        }\n    }\n':
    types.UpdateTillDocument,
  '\n  query getBusinesses {\n    getBusinesses {\n      brand\n      id\n      logo\n      name\n      okta {\n        oktaClientId\n        oktaIssuer\n      }\n      contactDetails {\n        contactPhone\n        contactEmail\n        supportPhone\n        supportEmail\n      }\n      featureFlags\n      address {\n        city\n        lat\n        long\n        state\n        street\n        zipCode\n        country\n      }\n      authMethod\n      storeGroupClusters {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n':
    types.GetBusinessesDocument,
  '\n  query getAllRolesEmployeesLocations(\n    $businessId: String!\n    $status: String\n    $fetchAllEmployees: Boolean\n  ) {\n    getAllRoles(businessId: $businessId) {\n      id\n      name\n      description\n      isActive\n      permissions\n      createdAt\n      updatedAt\n    }\n\n    getAllEmployees(businessId: $businessId, fetchAll: $fetchAllEmployees) {\n      employees {\n        id\n        name\n        email\n        phoneNumber\n        employeeCode\n        fullAddress {\n          street\n          city\n          state\n          zipCode\n          country\n        }\n        role {\n          id\n          name\n          permissions\n          description\n        }\n        toteSuperAdmin\n      }\n      totalCount\n    }\n\n    getAllLocations(businessId: $businessId, status: $status) {\n      id\n      name\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      timeZone\n      status\n      storeNumber\n    }\n  }\n':
    types.GetAllRolesEmployeesLocationsDocument,
  '\n  query getOrderReceiptTemplateConfig($businessId: String!) {\n    getBusiness(businessId: $businessId) {\n      id\n      configurations {\n        emailReceiptEnabled\n        templates {\n          orderReceipt\n        }\n      }\n    }\n  }\n':
    types.GetOrderReceiptTemplateConfigDocument,
  '\n  query TILL_TRANSACTIONS(\n    $register: String\n    $till: String\n    $date: String!\n    $locationId: String!\n  ) {\n    tillTransactions(\n      till: $till\n      register: $register\n      date: $date\n      locationId: $locationId\n    ) {\n      nodes {\n        id\n        date\n        openTime\n        closeTime\n        netCash\n        status\n        till\n        totalCash\n        register\n        employeeCode\n        employee {\n          name\n        }\n        defaultStartingFloat\n        tillAmountUpperLimit\n      }\n      registerTotalCash\n      registerNetCash\n      activeTill\n    }\n  }\n':
    types.Till_TransactionsDocument,
  '\n  query TRANSACTION_SUMMARY(\n    $register: String\n    $till: String\n    $date: String!\n    $locationId: String!\n  ) {\n    tillTransactionsSummary(\n      till: $till\n      register: $register\n      date: $date\n      locationId: $locationId\n    ) {\n      cash\n      sale\n      cashAdjustment\n      cashPickup\n      paidIn\n      paidOut\n      tillFloat\n      open\n      tillClose\n      reconcile\n      transfer\n      refund\n      cashDrop\n      startingDate\n      endingDate\n      registerTotalCash\n      safeTotalCash\n      storeTotalCash\n      reserveTotalCash\n    }\n  }\n':
    types.Transaction_SummaryDocument,
  '\n  query TRANSACTIONS(\n    $register: String\n    $till: String\n    $dateRange: [String!]!\n    $locationId: String!\n    $employeeCode: String\n    $transactionType: String\n    $pageIndex: Int\n    $pageSize: Int\n  ) {\n    cashTransactions(\n      till: $till\n      register: $register\n      dateRange: $dateRange\n      locationId: $locationId\n      employeeCode: $employeeCode\n      transactionType: $transactionType\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n    ) {\n      totalPageCount\n      nodes {\n        amount\n        closeTime\n        eventTime\n        dateCreated\n        description\n        employeeCode\n        eventType\n        id\n        openTime\n        register\n        storeNumber\n        till\n        transactionType\n        transactionId\n        transferTo\n        transferToTill\n        transactionTime\n        eventId\n        safeType\n        isSafeTransaction\n        source\n        employee {\n          name\n        }\n      }\n    }\n  }\n':
    types.TransactionsDocument,
  '\n  query GET_STATUS($locationId: String!, $businessId: String!) {\n    getTillStatus(locationId: $locationId, businessId: $businessId)\n  }\n':
    types.Get_StatusDocument,
  '\n  query BANK_DEPOSITS(\n    $locationId: String!\n    $dateRange: [String!]!\n    $pageIndex: Int\n    $pageSize: Int\n  ) {\n    getBankDeposits(\n      locationId: $locationId\n      dateRange: $dateRange\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n    ) {\n      totalPageCount\n      nodes {\n        id\n        amount\n        createdAt\n        updatedAt\n        depositNumber\n        depositReceipt\n        employee {\n          name\n        }\n        status\n      }\n    }\n  }\n':
    types.Bank_DepositsDocument,
  '\n  query AGGREGRATE_CASH_TRANSACTIONS(\n    $storeNumbers: [String!]\n    $locationIds: [String!]\n    $transactionTimeRange: [String!]\n    $eventType: [String!]\n  ) {\n    aggregrateCashTransactions(\n      storeNumbers: $storeNumbers\n      locationIds: $locationIds\n      transactionTimeRange: $transactionTimeRange\n      eventType: $eventType\n    ) {\n      cashSalesStats {\n        cashSales\n        percentageChange\n      }\n      averageTransactionValueStats {\n        averageTransactionValue\n        percentageChange\n      }\n      cashVarianceStats {\n        cashVariance\n        percentageChange\n      }\n    }\n  }\n':
    types.Aggregrate_Cash_TransactionsDocument,
  '\n  query VARIANCE_REPORT(\n    $storeNumbers: [String!]\n    $locationIds: [String!]\n    $transactionTimeRange: [String!]\n    $employeeCodes: [String!]\n    $eventType: [String!]\n  ) {\n    aggregrateCashTransactions(\n      storeNumbers: $storeNumbers\n      locationIds: $locationIds\n      transactionTimeRange: $transactionTimeRange\n      employeeCodes: $employeeCodes\n      eventType: $eventType\n    ) {\n      totalExpectedCashStats {\n        totalExpectedCash\n        percentageChange\n      }\n      totalActualCashStats {\n        totalActualCash\n        percentageChange\n      }\n      totalVarianceStats {\n        totalVariance\n        percentageChange\n      }\n      averageVariancePercentageStats {\n        averageVariancePercentage\n        percentageChange\n      }\n    }\n  }\n':
    types.Variance_ReportDocument,
  '\n  query getRegisterTills($businessId: String!, $locationId: String!) {\n    fetchRegisterList(businessId: $businessId, locationId: $locationId) {\n      id\n      name\n    }\n\n    fetchTills(businessId: $businessId, locationId: $locationId) {\n      id\n      name\n    }\n  }\n':
    types.GetRegisterTillsDocument,
  '\n  query getCashRegisters($locationId: String!, $pageIndex: Int, $pageSize: Int) {\n    getCashRegisters(locationId: $locationId, pageIndex: $pageIndex, pageSize: $pageSize) {\n      nodes {\n        id\n        name\n        isActive\n        externalRegisterId\n        source\n        createdAt\n        updatedAt\n      }\n      totalPageCount  \n    }\n  }\n':
    types.GetCashRegistersDocument,
  '\n  query getTills($locationId: String!, $pageIndex: Int, $pageSize: Int) {\n    getTills(locationId: $locationId, pageIndex: $pageIndex, pageSize: $pageSize) {\n      nodes {\n        id\n        name\n        isActive\n        externalTillId\n        source\n        createdAt\n        updatedAt\n      }\n      totalPageCount  \n    }\n  }\n':
    types.GetTillsDocument,
  '\n  query listCustomers(\n    $businessId: String!\n    $locationId: String!\n    $customerId: String\n  ) {\n    listCustomers(\n      businessId: $businessId\n      locationId: $locationId\n      customerId: $customerId\n    ) {\n      title\n      id\n      firstName\n      lastName\n      avatar\n      barcode\n      email\n      phone\n      updatedAt\n      preferences {\n        key\n        title\n        value\n      }\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n    }\n  }\n':
    types.ListCustomersDocument,
  '\n  query GenieQueryConsumerAgent(\n    $query: String!\n    $chatSessionId: String!\n    $queryType: String\n    $locationId: String\n  ) {\n    genieQueryConsumerAgent(\n      query: $query\n      chatSessionId: $chatSessionId\n      queryType: $queryType\n      locationId: $locationId\n    ) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieChatQuery {\n        products {\n          skuType\n          sku\n          unitPrice\n          unitDiscount\n          unitTaxAmount\n          description\n          unitSubTotal\n          aisleLocation\n          logoUrl\n          promotions {\n            description\n            promotionType\n            promotionValue\n            promotionName\n            active\n            businessId\n            canBeCombined\n            startDate\n            endDate\n            priority\n            subscriptionType\n          }\n        }\n      }\n    }\n  }\n':
    types.GenieQueryConsumerAgentDocument,
  '\n  query FetchGenieMessageConsumerAgent($messageId: String!) {\n    fetchGenieMessageConsumerAgent(messageId: $messageId) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieChatQuery {\n        products {\n          skuType\n          sku\n          unitPrice\n          unitDiscount\n          unitTaxAmount\n          description\n          unitSubTotal\n          aisleLocation\n          logoUrl\n          promotions {\n            description\n            promotionType\n            promotionValue\n            promotionName\n            active\n            businessId\n            canBeCombined\n            startDate\n            endDate\n            priority\n            subscriptionType\n          }\n        }\n      }\n    }\n  }\n':
    types.FetchGenieMessageConsumerAgentDocument,
  '\n  query fetchCustomer {\n    fetchCustomer {\n      id\n      firstName\n      lastName\n      avatar\n      barcode\n      email\n      phone\n      updatedAt\n      preferences {\n        key\n        title\n        value\n      }\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n    }\n  }\n':
    types.FetchCustomerDocument,
  '\n  query PredefinedChatQueryConsumerAgent(\n    $queryId: String!\n    $locationId: String!\n    $businessId: String\n  ) {\n    predefinedChatQueryConsumerAgent(\n      queryId: $queryId\n      locationId: $locationId\n      businessId: $businessId\n    ) {\n      products {\n        skuType\n        sku\n        unitPrice\n        unitDiscount\n        unitTaxAmount\n        description\n        unitSubTotal\n        aisleLocation\n        logoUrl\n        promotions {\n          description\n          promotionType\n          promotionValue\n          promotionName\n          active\n          businessId\n          canBeCombined\n          startDate\n          endDate\n          priority\n          subscriptionType\n        }\n      }\n      answer\n      query\n      queryId\n    }\n  }\n':
    types.PredefinedChatQueryConsumerAgentDocument,
  '\n  query getEmployee {\n    getEmployee {\n      id\n      name\n      email\n      locations {\n        id\n        address {\n          street\n          city\n          state\n          zipCode\n          country\n        }\n        name\n        timeZone\n        status\n        storeNumber\n        locationConfig {\n          cashManagement {\n            tillAmountUpperLimit\n            storeFloatAmount\n          }\n        }\n        storeGroups {\n          id\n          name\n          status\n          storeGroupCluster {\n            id\n            name\n            hierarchyLevel\n          }\n        }\n      }\n      business {\n        id\n        name\n        logo\n        brand\n        featureFlags\n        configurations {\n          colors {\n            primary\n            secondary\n          }\n        }\n        storeGroupClusters {\n          id\n          name\n          hierarchyLevel\n        }\n      }\n      role {\n        id\n        name\n        permissions\n        description\n      }\n      toteSuperAdmin\n      assignedStoreGroups {\n        id\n        name\n        status\n        storeGroupCluster {\n          id\n          name\n          hierarchyLevel\n        }\n        parentStoreGroup {\n          id\n          name\n          status\n          storeGroupCluster {\n            id\n            name\n            hierarchyLevel\n          }\n        }\n      }\n    }\n  }\n':
    types.GetEmployeeDocument,
  '\n  query getAllEmployees(\n    $businessId: String!\n    $locationId: String\n    $name: String\n    $email: String\n    $role: String\n    $employeeCode: String\n    $pageIndex: Int\n    $pageSize: Int\n    $fetchAll: Boolean\n    $storeGroupIds: [String!]\n    $locationIds: [String!]\n  ) {\n    getAllEmployees(\n      businessId: $businessId\n      locationId: $locationId\n      name: $name\n      email: $email\n      role: $role\n      employeeCode: $employeeCode\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n      fetchAll: $fetchAll\n      storeGroupIds: $storeGroupIds\n      locationIds: $locationIds\n    ) {\n      employees {\n        id\n        name\n        email\n        phoneNumber\n        employeeCode\n        fullAddress {\n          street\n          city\n          state\n          zipCode\n          country\n        }\n        role {\n          id\n          name\n        }\n        locations {\n          id\n          name\n        }\n        defaultLocation {\n          id\n          name\n        }\n        status\n        assignedStoreGroups {\n          id\n          name\n          status\n          storeGroupCluster {\n            id\n            name\n            hierarchyLevel\n          }\n          parentStoreGroup {\n            id\n            name\n            status\n            storeGroupCluster {\n              id\n              name\n              hierarchyLevel\n            }\n          }\n        }\n      }\n      totalCount\n    }\n  }\n':
    types.GetAllEmployeesDocument,
  '\n  query getAuthMethod($emailId: String!, $businessId: String, $token: String) {\n    getAuthMethod(emailId: $emailId, businessId: $businessId, token: $token) {\n      businessId\n      businessName\n      authMethod\n      okta {\n        clientId\n        issuer\n      }\n    }\n  }\n':
    types.GetAuthMethodDocument,
  '\n  query getOktaConfigFromIssuer($issuer: String!, $businessId: String) {\n    getOktaConfigFromIssuer(issuer: $issuer, businessId: $businessId) {\n      businessId\n      businessName\n      authMethod\n      okta {\n        clientId\n        issuer\n      }\n    }\n  }\n':
    types.GetOktaConfigFromIssuerDocument,
  '\n  mutation sendPasswordSetLink($email: String!, $businessId: String!) {\n    sendPasswordSetLink(email: $email, businessId: $businessId)\n  }\n':
    types.SendPasswordSetLinkDocument,
  '\n  query validatePasswordSetToken($token: String!) {\n    validatePasswordSetToken(token: $token)\n  }\n':
    types.ValidatePasswordSetTokenDocument,
  '\n  query getAdminUser {\n    getAdminUser {\n      id\n      name\n      email\n      phoneNumber\n    }\n  }\n':
    types.GetAdminUserDocument,
  '\n  query GenieQueryDashboard(\n    $query: String!\n    $chatSessionId: String!\n    $queryType: String\n  ) {\n    genieQueryDashboard(\n      query: $query\n      chatSessionId: $chatSessionId\n      queryType: $queryType\n    ) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieAnalyticsQuery {\n        data\n        html\n      }\n    }\n  }\n':
    types.GenieQueryDashboardDocument,
  '\n  query FetchGenieMessageDashboard($messageId: String!) {\n    fetchGenieMessageDashboard(messageId: $messageId) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieAnalyticsQuery {\n        data\n        html\n      }\n    }\n  }\n':
    types.FetchGenieMessageDashboardDocument,
  '\n  query getInsight(\n    $insightTypes: String!\n    $durationType: String!\n    $storeNumbers: String\n    $locationIds: String\n    $employeeCodes: String\n    $eventTypes: String\n    $duration: String\n  ) {\n    getInsights(\n      insightTypes: $insightTypes\n      durationType: $durationType\n      storeNumbers: $storeNumbers\n      locationIds: $locationIds\n      employeeCodes: $employeeCodes\n      eventTypes: $eventTypes\n      duration: $duration\n    ) {\n      type\n      dashboardId\n      dashboardTitle\n      dashboardSubtitle\n      dashboardBaseUrl\n      dashboardDurationType\n      dashboardHeight\n      dashboardWidth\n      enableDashboard\n      charts {\n        chartId\n        chartBaseUrl\n        chartName\n        category\n        xAxis {\n          name\n          type\n          timeType\n          label\n        }\n        yAxis {\n          name\n          type\n          timeType\n          label\n        }\n        filter\n        enableChart\n      }\n    }\n  }\n':
    types.GetInsightDocument,
  '\n  query getInsightCharts(\n    $chartCategory: String!\n    $insightType: String!\n    $durationType: String!\n    $storeNumbers: String\n    $locationIds: String\n    $employeeCodes: String\n    $eventTypes: String\n    $duration: String\n  ) {\n    getInsightCharts(\n      chartCategory: $chartCategory\n      insightType: $insightType\n      durationType: $durationType\n      storeNumbers: $storeNumbers\n      locationIds: $locationIds\n      employeeCodes: $employeeCodes\n      eventTypes: $eventTypes\n      duration: $duration\n    ) {\n      charts {\n        category\n        chartId\n        chartBaseUrl\n        chartName\n        enableChart\n        xAxis {\n          label\n          name\n          timeType\n          type\n        }\n        yAxis {\n          label\n          name\n          timeType\n          type\n        }\n        filter\n      }\n      type\n      category\n      durationType\n    }\n  }\n':
    types.GetInsightChartsDocument,
  '\n  query fetchLocationInventory(\n    $locationId: String!\n    $description: String!\n    $sku: String!\n    $unitPrice: String!\n    $unitCount: String!\n    $pageIndex: Int\n    $pageSize: Int\n  ) {\n    fetchLocationInventory(\n      locationId: $locationId\n      description: $description\n      sku: $sku\n      unitPrice: $unitPrice\n      unitCount: $unitCount\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n    ) {\n      inventories {\n        id\n        sku\n        description\n        unit\n        unitCount\n        taxable\n        sendEmail\n        taxDetails {\n          taxAmount\n          taxGroup\n          taxRate\n        }\n        pricingDetails {\n          currency\n          currencyMultiplier\n          discount\n          total\n          unitPrice\n        }\n        logoUrl\n        upc\n        categoryName\n        className\n        subclassName\n        skuType\n        ageVerificationRequired\n        requiredMinimumAge\n        recommendedProducts {\n          sku\n          description\n        }\n      }\n      totalPageCount\n      pageIndex\n      pageSize\n    }\n  }\n':
    types.FetchLocationInventoryDocument,
  '\n  query getInventoryByCommand(\n    $command: String!\n    $locationId: String!\n    $genieVersion: String!\n    $chatSessionId: String\n  ) {\n    getInventoryByCommand(\n      command: $command\n      locationId: $locationId\n      genieVersion: $genieVersion\n      chatSessionId: $chatSessionId\n    ) {\n      products {\n        skuType\n        sku\n        unitPrice\n        unitDiscount\n        unitTaxAmount\n        description\n        unitSubTotal\n        aisleLocation\n        logoUrl\n        promotions {\n          promotionType\n          promotionValue\n          promotionName\n        }\n      }\n      answer\n      resultType\n      query\n      chatSessionId\n      followups\n    }\n  }\n':
    types.GetInventoryByCommandDocument,
  '\n  query getLocations(\n    $businessId: String!\n    $storeGroupIds: [String!]\n    $locationIds: [String!]\n  ) {\n    getAllLocations(\n      businessId: $businessId\n      storeGroupIds: $storeGroupIds\n      locationIds: $locationIds\n    ) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      notificationEmails\n      status\n      storeGroups {\n        id\n        name\n        status\n        storeGroupCluster {\n          id\n          name\n          hierarchyLevel\n        }\n      }\n    }\n  }\n':
    types.GetLocationsDocument,
  '\nquery getLocationsWithGroups($businessId: String!, $status: String) {\n  getAllLocations(businessId: $businessId, status: $status) {\n    id\n    name\n    storeGroups {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n}\n':
    types.GetLocationsWithGroupsDocument,
  '\n  query getOauthApplications($locationId: String) {\n    getOauthApplications(locationId: $locationId) {\n      applications {\n        appName\n        clientId\n        clientSecret\n        status\n      }\n      locationId\n      message\n      success\n    }\n  }\n':
    types.GetOauthApplicationsDocument,
  '\n  query listOrders(\n    $businessId: String!\n    $locationId: String!\n    $customerId: String\n    $pageIndex: Int\n    $pageSize: Int\n    $storeGroups: [String!]\n    $locationIds: [String!]\n  ) {\n    listOrders(\n      businessId: $businessId\n      locationId: $locationId\n      customerId: $customerId\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n      storeGroups: $storeGroups\n      locationIds: $locationIds\n    ) {\n      totalPageCount\n      orders {\n        id\n        orderNumber\n        amountPaid\n        createdAt\n        paymentStatus\n        totalAmount\n        currency\n        items {\n          sku\n          description\n          quantity\n          taxAmount\n          totalDiscount\n          unitPrice\n          unit\n          total\n        }\n        subscriptionItems {\n          sku\n          description\n          quantity\n          taxAmount\n          totalDiscount\n          unitPrice\n          unit\n          total\n          frequency\n          status\n        }\n        location {\n          id\n          name\n          address {\n            street\n            city\n            state\n          }\n          storeGroups {\n            id\n            name\n            storeGroupCluster {\n              id\n              name\n              hierarchyLevel\n            }\n          }\n        }\n        customer {\n          firstName\n          lastName\n        }\n        employee {\n          id\n          name\n          employeeCode\n        }\n        register\n        orderType\n        fulfillmentStatus\n      }\n    }\n  }\n':
    types.ListOrdersDocument,
  '\n  query getDeviceInfo($deviceId: String!) {\n    getDeviceInfo(deviceId: $deviceId) {\n      name\n      id\n      status\n      deviceType\n      numberOfErrors\n      timeUsed\n      metadata\n    }\n  }\n':
    types.GetDeviceInfoDocument,
  '\n  query getDeviceActivities($deviceId: String!) {\n    getDeviceActivities(deviceId: $deviceId) {\n      id\n      time\n      user\n      action\n      description\n    }\n  }\n':
    types.GetDeviceActivitiesDocument,
  '\n  query getErrorNotifications(\n    $businessId: String!\n    $locationId: String!\n    $live: Boolean\n  ) {\n    getErrorNotifications(\n      businessId: $businessId\n      locationId: $locationId\n      live: $live\n    ) {\n      id\n      activityId\n      lastActive\n      severity\n      deviceName\n      registerName\n      zoneName\n      serialNumber\n      issueDescription\n      action\n      timeNotified\n      timeResolved\n    }\n  }\n':
    types.GetErrorNotificationsDocument,
  '\n  query getDeviceData($businessId: String!, $locationId: String!) {\n    getDeviceData(businessId: $businessId, locationId: $locationId) {\n      id\n      lastActive\n      deviceName\n      severity\n      registerName\n      zoneName\n      serialNumber\n      issueDescription\n      action\n      deviceType\n    }\n  }\n':
    types.GetDeviceDataDocument,
  '\n  query getBusiness($businessId: String!) {\n    getBusiness(businessId: $businessId) {\n      id\n      name\n      brand\n      logo\n      authMethod\n      featureFlags\n    }\n  }\n':
    types.GetBusinessDocument,
  '\n  query getPeripheralHubNotificationConfig($employeeId: String!) {\n    getPeripheralHubNotificationConfig(employeeId: $employeeId) {\n      enabled\n      severity\n      issue\n      device\n      alert\n      methods {\n        email\n        text\n      }\n    }\n  }\n':
    types.GetPeripheralHubNotificationConfigDocument,
  '\n  query getZoneData1($businessId: String!, $locationId: String!) {\n    fetchZones(businessId: $businessId, locationId: $locationId) {\n      id\n      name\n      description\n      registers {\n        id\n      }\n    }\n\n    fetchDevices(businessId: $businessId, locationId: $locationId) {\n      id\n      status\n      deviceName\n      metadata\n    }\n\n    fetchRegisters(businessId: $businessId, locationId: $locationId) {\n      id\n      name\n      devices {\n        id\n      }\n    }\n  }\n':
    types.GetZoneData1Document,
  '\n  query deviceHubVersionMetadata(\n    $businessId: String!\n    $locationId: String!\n    $generateUrl: Boolean!\n  ) {\n    deviceHubVersionMetadata(\n      businessId: $businessId\n      locationId: $locationId\n      generateUrl: $generateUrl\n    ) {\n      currentVersion\n      url\n      hash\n      versions\n      readme {\n        version\n        text\n      }\n    }\n  }\n':
    types.DeviceHubVersionMetadataDocument,
  '\n  mutation assignCurrentVersion(\n    $businessId: String!\n    $locationId: String!\n    $version: String!\n    $current: Boolean!\n  ) {\n    assignCurrentVersion(\n      businessId: $businessId\n      locationId: $locationId\n      version: $version\n      current: $current\n    )\n  }\n':
    types.AssignCurrentVersionDocument,
  '\n  query allRules($businessId: String!) {\n    allRules(businessId: $businessId) {\n      businessId\n      ruleName\n      ruleType\n      ruleValue\n      applyTo\n      excludeFrom\n      applyToProductGroup\n      excludeFromProductGroup\n    }\n  }\n':
    types.AllRulesDocument,
  '\n  query getRuleTypes {\n    getRuleTypes\n  }\n':
    types.GetRuleTypesDocument,
  '\n  query getPromotionTypes {\n    getPromotionTypes\n  }\n':
    types.GetPromotionTypesDocument,
  '\n  query getProductSkuList {\n    getProductSkuList\n  }\n':
    types.GetProductSkuListDocument,
  '\n  query getPromotionSchemaTypes {\n    getPromotionSchemaTypes\n  }\n':
    types.GetPromotionSchemaTypesDocument,
  '\n  query getAllPromotions($businessId: String!) {\n    getAllPromotions(businessId: $businessId) {\n      id\n      businessId\n      active\n      canBeCombined\n      description\n      endDate\n      promotionName\n      promotionType\n      startDate\n      promotionValue\n      priority\n      promotionSchema\n      subscriptionType\n      rules {\n        ruleName\n        ruleType\n        ruleValue\n      }\n    }\n  }\n':
    types.GetAllPromotionsDocument,
  '\n  query GetTotalsForPromotions(\n    $sku: String!\n    $quantity: Int!\n    $promotionNames: [String!]!\n    $businessId: String!\n  ) {\n    getTotalsForPromotions(\n      sku: $sku\n      quantity: $quantity\n      promotionNames: $promotionNames\n      businessId: $businessId\n    ) {\n      totalAmount\n      promotionAmount\n    }\n  }\n':
    types.GetTotalsForPromotionsDocument,
  '\n  query getInventoryBySkuAndDiscount($sku: String!) {\n    getInventoryBySkuAndDiscount(sku: $sku) {\n      sku\n      unitPrice\n      unitDiscount\n      unitTaxAmount\n      description\n      unitSubTotal\n      aisleLocation\n      logoUrl\n    }\n  }\n':
    types.GetInventoryBySkuAndDiscountDocument,
  '\n  query cartGuest($cartId: String!) {\n    cartGuest(cartId: $cartId) {\n      id\n      items {\n        sku\n        quantity\n        unitPrice\n        unitDiscount\n        description\n        logoUrl\n        unitSubTotal\n        unitDiscount\n        unitTaxAmount\n        totalTaxAmount\n        totalDiscount\n        total\n      }\n      totalDiscount\n      subTotal\n      totalAmount\n      totalTax\n    }\n  }\n':
    types.CartGuestDocument,
  '\n  query getAllRoles($businessId: String!) {\n    getAllRoles(businessId: $businessId) {\n      id\n      name\n      description\n      isActive\n      permissions\n      createdAt\n      updatedAt\n    }\n\n    getAllPermissions {\n      displayName\n      name\n      description\n      category\n    }\n  }\n':
    types.GetAllRolesDocument,
  '\n  query listSecrets {\n    listSecrets {\n      secretName\n      secretValue\n    }\n  }\n':
    types.ListSecretsDocument,
  '\n  query FetchServiceConfigByType(\n    $serviceConfigType: String!\n    $businessId: String\n    $locationId: String\n  ) {\n    serviceConfig(\n      serviceConfigType: $serviceConfigType\n      businessId: $businessId\n      locationId: $locationId\n    ) {\n      type\n      name\n      configs\n      updatedAt\n    }\n  }\n':
    types.FetchServiceConfigByTypeDocument,
  '\n  query FetchServiceConfigProviders($category: String!) {\n    serviceConfigProviders(category: $category) {\n      provider\n      metadata {\n        shopifyMetadata {\n          webhookTopicsToSubscribe\n          availableApiVersions\n        }\n      }\n    }\n  }\n':
    types.FetchServiceConfigProvidersDocument,
  '\n  query FetchServiceConfigsByCategory(\n    $category: String!\n    $businessId: String\n    $locationId: String\n  ) {\n    serviceConfigsByCategory(\n      category: $category\n      businessId: $businessId\n      locationId: $locationId\n    ) {\n      type\n      name\n      configs\n      updatedAt\n    }\n  }\n':
    types.FetchServiceConfigsByCategoryDocument,
  '\n  query StoreGroupClusterStats {\n    storeGroupClusterStats {\n      id\n      storeGroupClusterName\n      activeCount\n      draftCount\n    }\n  }\n':
    types.StoreGroupClusterStatsDocument,
  '\n  query StoreGroupsByClusterId(\n    $storeGroupClusterId: String!\n    $status: String\n    $fetchOrphanedStoreGroups: Boolean\n  ) {\n    storeGroupsByClusterId(\n      storeGroupClusterId: $storeGroupClusterId\n      status: $status\n      fetchOrphanedStoreGroups: $fetchOrphanedStoreGroups\n    ) {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n':
    types.StoreGroupsByClusterIdDocument,
  '\n  query ChildStoreGroups($storeGroupId: String!) {\n    childStoreGroups(storeGroupId: $storeGroupId) {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n':
    types.ChildStoreGroupsDocument,
  '\n  query StoreGroups {\n    storeGroups {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n      parentStoreGroup {\n        id\n        name\n        status\n        storeGroupCluster {\n          id\n          name\n          hierarchyLevel\n        }\n      }\n    }\n  }\n':
    types.StoreGroupsDocument,
  '\n  query ListDeviceTemplates($locationId: String) {\n    listDeviceTemplates(locationId: $locationId)\n  }\n':
    types.ListDeviceTemplatesDocument,
  '\n  query ListDeviceBitmaps($locationId: String) {\n    listDeviceBitmaps(locationId: $locationId)\n  }\n':
    types.ListDeviceBitmapsDocument,
  '\n  query cashTransactionFailures(\n    $locationId: String!\n    $dateRange: [String!]!\n    $pageIndex: Int\n    $pageSize: Int\n  ) {\n    cashTransactionFailures(\n      locationId: $locationId\n      dateRange: $dateRange\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n    ) {\n      nodes {\n        createdAt\n        error\n        eventId\n        eventType\n        id\n        payload\n      }\n      totalPageCount\n    }\n  }\n':
    types.CashTransactionFailuresDocument,
  '\n  subscription cashEventSubscription($locationId: String!) {\n    webCashEvent(locationId: $locationId) {\n      cashEvent\n    }\n  }\n':
    types.CashEventSubscriptionDocument,
  '\n  subscription transactionEventSubscription(\n    $locationId: String!\n    $businessId: String!\n  ) {\n    webCashEvent(locationId: $locationId, businessId: $businessId) {\n      cashEvent\n    }\n  }\n':
    types.TransactionEventSubscriptionDocument,
  '\n  subscription customerCartSubscriptionEvents($token: String!) {\n    customerCartSubscriptionEvents(token: $token) {\n      flow\n      cart {\n        subscriptionItems {\n          frequency\n          status\n          description\n          sku\n          total\n          quantity\n        }\n        id\n        location {\n          id\n          name\n          business {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n':
    types.CustomerCartSubscriptionEventsDocument,
  '\n  subscription genieQueryEventConsumerAgent(\n    $locationId: String\n    $chatSessionId: String\n    $lastReadTimestamp: DateTime\n  ) {\n    genieQueryEventConsumerAgent(\n      locationId: $locationId\n      chatSessionId: $chatSessionId\n      lastReadTimestamp: $lastReadTimestamp\n    ) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieChatQuery {\n        products {\n          skuType\n          sku\n          unitPrice\n          unitDiscount\n          unitTaxAmount\n          description\n          unitSubTotal\n          aisleLocation\n          logoUrl\n          promotions {\n            description\n            promotionType\n            promotionValue\n            promotionName\n            active\n            businessId\n            canBeCombined\n            startDate\n            endDate\n            priority\n            subscriptionType\n          }\n        }\n      }\n    }\n  }\n':
    types.GenieQueryEventConsumerAgentDocument,
  '\n  subscription GenieQueryEventDashboard($chatSessionId: String!) {\n    genieQueryEventDashboard(chatSessionId: $chatSessionId) {\n      answer\n      chatSessionId\n      messageId\n      query\n      status\n      queryType\n      genieAnalyticsQuery {\n        data\n        html\n      }\n    }\n  }\n':
    types.GenieQueryEventDashboardDocument,
  '\n  subscription orderUpdateEventsConsumerAgent(\n    $cartId: String!\n    $businessId: String!\n    $locationId: String!\n  ) {\n    orderUpdateEventsConsumerAgent(\n      cartId: $cartId\n      requestContext: {businessId: $businessId, locationId: $locationId}\n    ) {\n      order {\n        id\n        paymentStatus\n        refundStatus\n        totalAmount\n        currency\n        amountPaid\n        amountRefunded\n      }\n    }\n  }\n':
    types.OrderUpdateEventsConsumerAgentDocument,
  '\n  subscription orderUpdateEventsDashboard {\n    orderUpdateEventsDashboard {\n      order {\n        id\n        orderNumber\n        amountPaid\n        createdAt\n        paymentStatus\n        totalAmount\n        currency\n        items {\n          sku\n          description\n          quantity\n          taxAmount\n          totalDiscount\n          unitPrice\n          unit\n          total\n        }\n        subscriptionItems {\n          sku\n          description\n          quantity\n          taxAmount\n          totalDiscount\n          unitPrice\n          unit\n          total\n          frequency\n          status\n        }\n        location {\n          id\n          name\n          address {\n            street\n            city\n            state\n          }\n          storeGroups {\n            id\n            name\n            storeGroupCluster {\n              id\n              name\n              hierarchyLevel\n            }\n          }\n        }\n        customer {\n          firstName\n          lastName\n        }\n        employee {\n          id\n          name\n          employeeCode\n        }\n        register\n        orderType\n        fulfillmentStatus\n      }\n    }\n  }\n':
    types.OrderUpdateEventsDashboardDocument
}

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createBusiness($business: BusinessDetailsInput!) {\n    createBusiness(business: $business) {\n      brand\n      id\n      logo\n      name\n      okta {\n        oktaClientId\n        oktaIssuer\n      }\n      contactDetails {\n        contactPhone\n        contactEmail\n        supportPhone\n        supportEmail\n      }\n      featureFlags\n      address {\n        city\n        lat\n        long\n        state\n        street\n        zipCode\n        country\n      }\n      authMethod\n    }\n  }\n'
): (typeof documents)['\n  mutation createBusiness($business: BusinessDetailsInput!) {\n    createBusiness(business: $business) {\n      brand\n      id\n      logo\n      name\n      okta {\n        oktaClientId\n        oktaIssuer\n      }\n      contactDetails {\n        contactPhone\n        contactEmail\n        supportPhone\n        supportEmail\n      }\n      featureFlags\n      address {\n        city\n        lat\n        long\n        state\n        street\n        zipCode\n        country\n      }\n      authMethod\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateBusiness(\n    $businessId: String!\n    $business: BusinessDetailsUpdateInput!\n  ) {\n    updateBusiness(business: $business, businessId: $businessId) {\n      brand\n      id\n      logo\n      name\n      okta {\n        oktaClientId\n        oktaIssuer\n      }\n      contactDetails {\n        contactPhone\n        contactEmail\n        supportPhone\n        supportEmail\n      }\n      featureFlags\n      address {\n        city\n        lat\n        long\n        state\n        street\n        zipCode\n        country\n      }\n      authMethod\n    }\n  }\n'
): (typeof documents)['\n  mutation updateBusiness(\n    $businessId: String!\n    $business: BusinessDetailsUpdateInput!\n  ) {\n    updateBusiness(business: $business, businessId: $businessId) {\n      brand\n      id\n      logo\n      name\n      okta {\n        oktaClientId\n        oktaIssuer\n      }\n      contactDetails {\n        contactPhone\n        contactEmail\n        supportPhone\n        supportEmail\n      }\n      featureFlags\n      address {\n        city\n        lat\n        long\n        state\n        street\n        zipCode\n        country\n      }\n      authMethod\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateBusinessColors(\n    $businessId: String!\n    $primary: String!\n    $secondary: String!\n  ) {\n    updateBusinessColors(\n      businessId: $businessId\n      primary: $primary\n      secondary: $secondary\n    ) {\n      primary\n      secondary\n    }\n  }\n'
): (typeof documents)['\n  mutation updateBusinessColors(\n    $businessId: String!\n    $primary: String!\n    $secondary: String!\n  ) {\n    updateBusinessColors(\n      businessId: $businessId\n      primary: $primary\n      secondary: $secondary\n    ) {\n      primary\n      secondary\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateOrderEmailReceiptTemplate($orderReceipt: String!) {\n    updateOrderEmailReceiptTemplate(orderReceipt: $orderReceipt)\n  }\n'
): (typeof documents)['\n  mutation updateOrderEmailReceiptTemplate($orderReceipt: String!) {\n    updateOrderEmailReceiptTemplate(orderReceipt: $orderReceipt)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation toggleEmailReceiptEnabled($emailReceiptEnabled: Boolean!) {\n    toggleEmailReceiptEnabled(emailReceiptEnabled: $emailReceiptEnabled)\n  }\n'
): (typeof documents)['\n  mutation toggleEmailReceiptEnabled($emailReceiptEnabled: Boolean!) {\n    toggleEmailReceiptEnabled(emailReceiptEnabled: $emailReceiptEnabled)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation resetOrderEmailReceiptTemplate {\n    resetOrderEmailReceiptTemplate\n  }\n'
): (typeof documents)['\n  mutation resetOrderEmailReceiptTemplate {\n    resetOrderEmailReceiptTemplate\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createCart($locationId: String!, $employeeId: String!, $customerId: String!) {\n    createCart(locationId: $locationId, employeeId: $employeeId, customerId: $customerId) {\n      id\n    }\n  }\n'
): (typeof documents)['\n  mutation createCart($locationId: String!, $employeeId: String!, $customerId: String!) {\n    createCart(locationId: $locationId, employeeId: $employeeId, customerId: $customerId) {\n      id\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createCartGuest($locationId: String!) {\n    createCartGuest(locationId: $locationId) {\n      id\n    }\n  }\n'
): (typeof documents)['\n  mutation createCartGuest($locationId: String!) {\n    createCartGuest(locationId: $locationId) {\n      id\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateCartItems(\n    $cartId: String!, \n    $items: [ItemInput!]!, \n    $subscriptionItems: [SubscriptionItemInput!]!,\n    $returnedItems: [ReturnItemInput!]!,\n    $customerId: String,\n    $promotionId: String,\n    $deliveryAddress: AddressInput\n  ) {\n      updateCartItems(\n        cartId: $cartId, \n        items: $items, \n        subscriptionItems: $subscriptionItems, \n        returnedItems: $returnedItems, \n        promotionId: $promotionId, \n        deliveryAddress: $deliveryAddress, \n        customerId: $customerId\n      ) {\n        id\n        items {\n          sku\n          quantity\n          unitPrice\n          unitDiscount\n          description\n          logoUrl\n          unitSubTotal\n          unitDiscount\n          unitTaxAmount\n          totalTaxAmount\n          totalDiscount\n          total\n        }\n        totalDiscount\n        subTotal\n        totalAmount\n        totalTax\n      }\n  }\n'
): (typeof documents)['\n  mutation updateCartItems(\n    $cartId: String!, \n    $items: [ItemInput!]!, \n    $subscriptionItems: [SubscriptionItemInput!]!,\n    $returnedItems: [ReturnItemInput!]!,\n    $customerId: String,\n    $promotionId: String,\n    $deliveryAddress: AddressInput\n  ) {\n      updateCartItems(\n        cartId: $cartId, \n        items: $items, \n        subscriptionItems: $subscriptionItems, \n        returnedItems: $returnedItems, \n        promotionId: $promotionId, \n        deliveryAddress: $deliveryAddress, \n        customerId: $customerId\n      ) {\n        id\n        items {\n          sku\n          quantity\n          unitPrice\n          unitDiscount\n          description\n          logoUrl\n          unitSubTotal\n          unitDiscount\n          unitTaxAmount\n          totalTaxAmount\n          totalDiscount\n          total\n        }\n        totalDiscount\n        subTotal\n        totalAmount\n        totalTax\n      }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation copyNfcCartToMpos(\n    $mposCartId: String!\n    $nfcCartId: String!\n    $locationId: String!\n  ) {\n    copyNfcCartToMpos(\n      mposCartId: $mposCartId\n      nfcCartId: $nfcCartId\n      locationId: $locationId\n    )\n  }\n'
): (typeof documents)['\n  mutation copyNfcCartToMpos(\n    $mposCartId: String!\n    $nfcCartId: String!\n    $locationId: String!\n  ) {\n    copyNfcCartToMpos(\n      mposCartId: $mposCartId\n      nfcCartId: $nfcCartId\n      locationId: $locationId\n    )\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation verifyCart($cartId: ID!, $locationId: ID!) {\n    verifyCart(cartId: $cartId, locationId: $locationId) \n  }\n'
): (typeof documents)['\n  mutation verifyCart($cartId: ID!, $locationId: ID!) {\n    verifyCart(cartId: $cartId, locationId: $locationId) \n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation cashPickup(\n    $location: String!\n    $amount: Float!\n    $till: String!\n    $register: String!\n    $eventId: String!\n  ) {\n    addTransactionEvent(\n      locationId: $location\n      register: $register\n      till: $till\n      amount: $amount\n      transactionType: "debit"\n      eventType: "cash_pickup"\n      description: "Cash Pickup"\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n'
): (typeof documents)['\n  mutation cashPickup(\n    $location: String!\n    $amount: Float!\n    $till: String!\n    $register: String!\n    $eventId: String!\n  ) {\n    addTransactionEvent(\n      locationId: $location\n      register: $register\n      till: $till\n      amount: $amount\n      transactionType: "debit"\n      eventType: "cash_pickup"\n      description: "Cash Pickup"\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation cashDrop(\n    $location: String!\n    $amount: Float!\n    $till: String!\n    $register: String!\n    $eventId: String!\n  ) {\n    addTransactionEvent(\n      locationId: $location\n      register: $register\n      till: $till\n      amount: $amount\n      transactionType: "credit"\n      eventType: "cash_drop"\n      description: "Cash Drop"\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n'
): (typeof documents)['\n  mutation cashDrop(\n    $location: String!\n    $amount: Float!\n    $till: String!\n    $register: String!\n    $eventId: String!\n  ) {\n    addTransactionEvent(\n      locationId: $location\n      register: $register\n      till: $till\n      amount: $amount\n      transactionType: "credit"\n      eventType: "cash_drop"\n      description: "Cash Drop"\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createTransaction(\n    $location: String!\n    $amount: Float!\n    $employeeCode: String\n    $till: String!\n    $register: String!\n    $businessDate: String\n    $eventType: String!\n    $description: String!\n    $transactionType: String\n    $transactionId: String\n    $eventId: String!\n  ) {\n    addTransactionEvent(\n      locationId: $location\n      register: $register\n      till: $till\n      employeeCode: $employeeCode\n      amount: $amount\n      businessDate: $businessDate\n      transactionType: $transactionType\n      eventType: $eventType\n      description: $description\n      transactionId: $transactionId\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n'
): (typeof documents)['\n  mutation createTransaction(\n    $location: String!\n    $amount: Float!\n    $employeeCode: String\n    $till: String!\n    $register: String!\n    $businessDate: String\n    $eventType: String!\n    $description: String!\n    $transactionType: String\n    $transactionId: String\n    $eventId: String!\n  ) {\n    addTransactionEvent(\n      locationId: $location\n      register: $register\n      till: $till\n      employeeCode: $employeeCode\n      amount: $amount\n      businessDate: $businessDate\n      transactionType: $transactionType\n      eventType: $eventType\n      description: $description\n      transactionId: $transactionId\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createSafeTransaction(\n    $locationId: String!\n    $fromSafe: String!\n    $toSafe: String!\n    $amount: Float!\n    $eventType: String!\n    $description: String!\n    $eventId: String!\n  ) {\n    addSafeCashTransactionEvent(\n      locationId: $locationId\n      fromSafe: $fromSafe\n      toSafe: $toSafe\n      amount: $amount\n      eventType: $eventType\n      description: $description\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n'
): (typeof documents)['\n  mutation createSafeTransaction(\n    $locationId: String!\n    $fromSafe: String!\n    $toSafe: String!\n    $amount: Float!\n    $eventType: String!\n    $description: String!\n    $eventId: String!\n  ) {\n    addSafeCashTransactionEvent(\n      locationId: $locationId\n      fromSafe: $fromSafe\n      toSafe: $toSafe\n      amount: $amount\n      eventType: $eventType\n      description: $description\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation cashTillClose(\n    $location: String!\n    $amount: Float!\n    $cashVariance: Float!\n    $till: String!\n    $register: String!\n    $businessDate: String\n    $reasonCode: String\n    $denominationBreakdown: [DenominationInput!]\n    $reasonDescription: String\n    $otherDenominationCurrency: Int\n    $eventId: String!\n  ) {\n    addTransactionEvent(\n      locationId: $location\n      register: $register\n      till: $till\n      amount: $amount\n      businessDate: $businessDate\n      cashVariance: $cashVariance\n      reasonCode: $reasonCode\n      transactionType: "debit"\n      eventType: "reconcile"\n      description: "Reconcile Till"\n      denominationBreakdown: $denominationBreakdown\n      otherDenominationCurrency: $otherDenominationCurrency\n      reasonDescription: $reasonDescription\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n'
): (typeof documents)['\n  mutation cashTillClose(\n    $location: String!\n    $amount: Float!\n    $cashVariance: Float!\n    $till: String!\n    $register: String!\n    $businessDate: String\n    $reasonCode: String\n    $denominationBreakdown: [DenominationInput!]\n    $reasonDescription: String\n    $otherDenominationCurrency: Int\n    $eventId: String!\n  ) {\n    addTransactionEvent(\n      locationId: $location\n      register: $register\n      till: $till\n      amount: $amount\n      businessDate: $businessDate\n      cashVariance: $cashVariance\n      reasonCode: $reasonCode\n      transactionType: "debit"\n      eventType: "reconcile"\n      description: "Reconcile Till"\n      denominationBreakdown: $denominationBreakdown\n      otherDenominationCurrency: $otherDenominationCurrency\n      reasonDescription: $reasonDescription\n      eventId: $eventId\n    ) {\n      cashEvent\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation cashDeposit($location: String!, $amount: Int!) {\n    createBankDeposit(locationId: $location, amount: $amount) {\n      amount\n      createdAt\n      updatedAt\n      status\n      id\n    }\n  }\n'
): (typeof documents)['\n  mutation cashDeposit($location: String!, $amount: Int!) {\n    createBankDeposit(locationId: $location, amount: $amount) {\n      amount\n      createdAt\n      updatedAt\n      status\n      id\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation safeReconcile(\n    $location: String!\n    $amount: Int!\n    $safeType: String!\n    $reasonCode: String!\n    $denominationBreakdown: [DenominationInput!]\n    $reasonDescription: String\n    $otherDenominationCurrency: Int\n  ) {\n    createStoreTransaction(\n      locationId: $location\n      amount: $amount\n      safeType: $safeType\n      reasonCode: $reasonCode\n      denominationBreakdown: $denominationBreakdown\n      reasonDescription: $reasonDescription\n      otherDenominationCurrency: $otherDenominationCurrency\n    ) {\n      amount\n      dateCreated\n      dateModified\n      id\n    }\n  }\n'
): (typeof documents)['\n  mutation safeReconcile(\n    $location: String!\n    $amount: Int!\n    $safeType: String!\n    $reasonCode: String!\n    $denominationBreakdown: [DenominationInput!]\n    $reasonDescription: String\n    $otherDenominationCurrency: Int\n  ) {\n    createStoreTransaction(\n      locationId: $location\n      amount: $amount\n      safeType: $safeType\n      reasonCode: $reasonCode\n      denominationBreakdown: $denominationBreakdown\n      reasonDescription: $reasonDescription\n      otherDenominationCurrency: $otherDenominationCurrency\n    ) {\n      amount\n      dateCreated\n      dateModified\n      id\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation cashDepositUpdate(\n    $id: String!\n    $location: String!\n    $depositNumber: String!\n    $depositReceipt: Upload\n  ) {\n    updateBankDeposit(\n      id: $id\n      locationId: $location\n      depositNumber: $depositNumber\n      depositReceipt: $depositReceipt\n    ) {\n      amount\n      createdAt\n      updatedAt\n      status\n      depositReceipt\n      id\n    }\n  }\n'
): (typeof documents)['\n  mutation cashDepositUpdate(\n    $id: String!\n    $location: String!\n    $depositNumber: String!\n    $depositReceipt: Upload\n  ) {\n    updateBankDeposit(\n      id: $id\n      locationId: $location\n      depositNumber: $depositNumber\n      depositReceipt: $depositReceipt\n    ) {\n      amount\n      createdAt\n      updatedAt\n      status\n      depositReceipt\n      id\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DownloadFile($locationId: String!, $id: String!) {\n    downloadDepositReceipt(locationId: $locationId, id: $id) {\n      fileContent\n      fileName\n    }\n  }\n'
): (typeof documents)['\n  mutation DownloadFile($locationId: String!, $id: String!) {\n    downloadDepositReceipt(locationId: $locationId, id: $id) {\n      fileContent\n      fileName\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation generateAtlasAuthToken {\n    generateAtlasAuthToken {\n      success\n      token\n    }\n  }\n'
): (typeof documents)['\n  mutation generateAtlasAuthToken {\n    generateAtlasAuthToken {\n      success\n      token\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation bulkCashTransaction($transactions: [CashTransactionInputType!]!) {\n    bulkTransactionEvent(transactions: $transactions) {\n      cashEvent\n    }\n  }\n'
): (typeof documents)['\n  mutation bulkCashTransaction($transactions: [CashTransactionInputType!]!) {\n    bulkTransactionEvent(transactions: $transactions) {\n      cashEvent\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addCustomerForConsumerAgent($customer: CustomerInput!) {\n    addCustomerForConsumerAgent(customer: $customer)\n  }\n'
): (typeof documents)['\n  mutation addCustomerForConsumerAgent($customer: CustomerInput!) {\n    addCustomerForConsumerAgent(customer: $customer)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation loginCustomer(\n    $email: String!\n    $password: String!\n    $businessId: String!\n    $locationId: String!\n  ) {\n    loginCustomer(\n      email: $email\n      password: $password\n      businessId: $businessId\n      locationId: $locationId\n    ) {\n      customer {\n        id\n        email\n        firstName\n        lastName\n      }\n      token\n    }\n  }\n'
): (typeof documents)['\n  mutation loginCustomer(\n    $email: String!\n    $password: String!\n    $businessId: String!\n    $locationId: String!\n  ) {\n    loginCustomer(\n      email: $email\n      password: $password\n      businessId: $businessId\n      locationId: $locationId\n    ) {\n      customer {\n        id\n        email\n        firstName\n        lastName\n      }\n      token\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addCustomer($customer: CustomerInput!) {\n    addCustomer(customer: $customer)\n  }\n'
): (typeof documents)['\n  mutation addCustomer($customer: CustomerInput!) {\n    addCustomer(customer: $customer)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateCustomer($customerId: String!, $customer: CustomerInput!) {\n    updateCustomer(customer: $customer, customerId: $customerId)\n  }\n'
): (typeof documents)['\n  mutation updateCustomer($customerId: String!, $customer: CustomerInput!) {\n    updateCustomer(customer: $customer, customerId: $customerId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation validateCustomerSignupToken($token: String!, $checkUsage: Boolean) {\n    validateCustomerSignupToken(token: $token, checkUsage: $checkUsage) {\n      flow\n\n      cart {\n        customer {\n          id\n          firstName\n          lastName\n          email\n\n          addresses {\n            street\n            city\n            state\n            zipCode\n            country\n          }\n        }\n        deliveryAddress {\n          street\n          city\n          state\n          zipCode\n          country\n        }\n        deliveryTimeSlot {\n          startTime {\n            hours\n            minutes\n          }\n          endTime {\n            hours\n            minutes\n          }\n        }\n        subscriptionItems {\n          frequency\n          status\n          description\n          sku\n          total\n          quantity\n        }\n        id\n        location {\n          id\n          name\n          business {\n            id\n            name\n            configurations {\n              deliveryTimeSlots {\n                startTime {\n                  hours\n                  minutes\n                }\n                endTime {\n                  hours\n                  minutes\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation validateCustomerSignupToken($token: String!, $checkUsage: Boolean) {\n    validateCustomerSignupToken(token: $token, checkUsage: $checkUsage) {\n      flow\n\n      cart {\n        customer {\n          id\n          firstName\n          lastName\n          email\n\n          addresses {\n            street\n            city\n            state\n            zipCode\n            country\n          }\n        }\n        deliveryAddress {\n          street\n          city\n          state\n          zipCode\n          country\n        }\n        deliveryTimeSlot {\n          startTime {\n            hours\n            minutes\n          }\n          endTime {\n            hours\n            minutes\n          }\n        }\n        subscriptionItems {\n          frequency\n          status\n          description\n          sku\n          total\n          quantity\n        }\n        id\n        location {\n          id\n          name\n          business {\n            id\n            name\n            configurations {\n              deliveryTimeSlots {\n                startTime {\n                  hours\n                  minutes\n                }\n                endTime {\n                  hours\n                  minutes\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation signupCustomer($customer: CustomerInput!, $token: String!) {\n    signupCustomer(customer: $customer, token: $token) {\n      customer {\n        id\n        email\n        firstName\n        lastName\n      }\n      token\n    }\n  }\n'
): (typeof documents)['\n  mutation signupCustomer($customer: CustomerInput!, $token: String!) {\n    signupCustomer(customer: $customer, token: $token) {\n      customer {\n        id\n        email\n        firstName\n        lastName\n      }\n      token\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation customerSignin(\n    $email: String!\n    $password: String!\n    $token: String!\n  ) {\n    customerSignin(email: $email, password: $password, token: $token) {\n      customer {\n        id\n        email\n        firstName\n        lastName\n      }\n      token\n    }\n  }\n'
): (typeof documents)['\n  mutation customerSignin(\n    $email: String!\n    $password: String!\n    $token: String!\n  ) {\n    customerSignin(email: $email, password: $password, token: $token) {\n      customer {\n        id\n        email\n        firstName\n        lastName\n      }\n      token\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation approveItemSubscription(\n    $cartId: String!\n    $sku: String!\n    $token: String!\n  ) {\n    approveItemSubscription(cartId: $cartId, sku: $sku, token: $token)\n  }\n'
): (typeof documents)['\n  mutation approveItemSubscription(\n    $cartId: String!\n    $sku: String!\n    $token: String!\n  ) {\n    approveItemSubscription(cartId: $cartId, sku: $sku, token: $token)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation generateGenieChatSessionIdConsumerAgent {\n    generateGenieChatSessionIdConsumerAgent\n  }\n'
): (typeof documents)['\n  mutation generateGenieChatSessionIdConsumerAgent {\n    generateGenieChatSessionIdConsumerAgent\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteChatHistoryForConsumerAgent($chatSessionId: String!) {\n    deleteChatHistoryForConsumerAgent(chatSessionId: $chatSessionId)\n  }\n'
): (typeof documents)['\n  mutation deleteChatHistoryForConsumerAgent($chatSessionId: String!) {\n    deleteChatHistoryForConsumerAgent(chatSessionId: $chatSessionId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addCustomerAddress(\n    $customerId: String!\n    $token: String!\n    $address: CustomerAddressInput!\n  ) {\n    addCustomerAddress(\n      customerId: $customerId\n      token: $token\n      address: $address\n    ) {\n      addresses {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      id\n    }\n  }\n'
): (typeof documents)['\n  mutation addCustomerAddress(\n    $customerId: String!\n    $token: String!\n    $address: CustomerAddressInput!\n  ) {\n    addCustomerAddress(\n      customerId: $customerId\n      token: $token\n      address: $address\n    ) {\n      addresses {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      id\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateCartDeliveryInfo(\n    $cartId: String!\n    $token: String!\n    $address: AddressInput!\n    $deliveryTimeSlot: DeliveryTimeSlotInput\n  ) {\n    updateCartDeliveryInfo(\n      cartId: $cartId\n      token: $token\n      address: $address\n      deliveryTimeSlot: $deliveryTimeSlot\n    ) {\n      id\n    }\n  }\n'
): (typeof documents)['\n  mutation updateCartDeliveryInfo(\n    $cartId: String!\n    $token: String!\n    $address: AddressInput!\n    $deliveryTimeSlot: DeliveryTimeSlotInput\n  ) {\n    updateCartDeliveryInfo(\n      cartId: $cartId\n      token: $token\n      address: $address\n      deliveryTimeSlot: $deliveryTimeSlot\n    ) {\n      id\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addCustomerToCart($token: String!, $cartId: String!) {\n    addCustomerToCart(token: $token, cartId: $cartId)\n  }\n'
): (typeof documents)['\n  mutation addCustomerToCart($token: String!, $cartId: String!) {\n    addCustomerToCart(token: $token, cartId: $cartId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createEmployee($businessId: String!, $employee: EmployeeInput!) {\n    createEmployee(businessId: $businessId, employee: $employee) {\n      id\n      name\n      email\n      phoneNumber\n      employeeCode\n      fullAddress {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      role {\n        id\n        name\n      }\n      locations {\n        id\n        name\n      }\n      defaultLocation {\n        id\n        name\n      }\n      status\n    }\n  }\n'
): (typeof documents)['\n  mutation createEmployee($businessId: String!, $employee: EmployeeInput!) {\n    createEmployee(businessId: $businessId, employee: $employee) {\n      id\n      name\n      email\n      phoneNumber\n      employeeCode\n      fullAddress {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      role {\n        id\n        name\n      }\n      locations {\n        id\n        name\n      }\n      defaultLocation {\n        id\n        name\n      }\n      status\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateEmployee(\n    $employeeId: String!\n    $businessId: String!\n    $employee: EmployeeInput!\n  ) {\n    updateEmployee(\n      employee: $employee\n      businessId: $businessId\n      employeeId: $employeeId\n    ) {\n      id\n      name\n      email\n      phoneNumber\n      employeeCode\n      fullAddress {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      role {\n        id\n        name\n      }\n      locations {\n        id\n        name\n      }\n      defaultLocation {\n        id\n        name\n      }\n      status\n    }\n  }\n'
): (typeof documents)['\n  mutation updateEmployee(\n    $employeeId: String!\n    $businessId: String!\n    $employee: EmployeeInput!\n  ) {\n    updateEmployee(\n      employee: $employee\n      businessId: $businessId\n      employeeId: $employeeId\n    ) {\n      id\n      name\n      email\n      phoneNumber\n      employeeCode\n      fullAddress {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      role {\n        id\n        name\n      }\n      locations {\n        id\n        name\n      }\n      defaultLocation {\n        id\n        name\n      }\n      status\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation sendOtpForPasswordReset($token: String!) {\n    sendOtpForPasswordReset(token: $token)\n  }\n'
): (typeof documents)['\n  mutation sendOtpForPasswordReset($token: String!) {\n    sendOtpForPasswordReset(token: $token)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation resetPasswordWithOtp(\n    $token: String!\n    $otp: String!\n    $password: String!\n    $pin: String!\n  ) {\n    resetPasswordWithOtp(\n      token: $token\n      otp: $otp\n      password: $password\n      pin: $pin\n    )\n  }\n'
): (typeof documents)['\n  mutation resetPasswordWithOtp(\n    $token: String!\n    $otp: String!\n    $password: String!\n    $pin: String!\n  ) {\n    resetPasswordWithOtp(\n      token: $token\n      otp: $otp\n      password: $password\n      pin: $pin\n    )\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation resendOtpForPasswordReset($token: String!) {\n    resendOtpForPasswordReset(token: $token)\n  }\n'
): (typeof documents)['\n  mutation resendOtpForPasswordReset($token: String!) {\n    resendOtpForPasswordReset(token: $token)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation GenerateGenieChatSessionId {\n    generateGenieChatSessionId\n  }\n'
): (typeof documents)['\n  mutation GenerateGenieChatSessionId {\n    generateGenieChatSessionId\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createInventory($inventory: InventoryInput!, $locationId: String!) {\n    createInventory(inventory: $inventory, locationId: $locationId) {\n      id\n      logoUrl\n      description\n      pricingDetails {\n        currency\n        currencyMultiplier\n        discount\n        total\n        unitPrice\n      }\n      sku\n      taxDetails {\n        taxAmount\n        taxGroup\n        taxRate\n      }\n      unit\n      unitCount\n      upc\n      businessId\n      locationId\n      taxable\n      skuType\n      className\n      subclassName\n      categoryName\n    }\n  }\n'
): (typeof documents)['\n  mutation createInventory($inventory: InventoryInput!, $locationId: String!) {\n    createInventory(inventory: $inventory, locationId: $locationId) {\n      id\n      logoUrl\n      description\n      pricingDetails {\n        currency\n        currencyMultiplier\n        discount\n        total\n        unitPrice\n      }\n      sku\n      taxDetails {\n        taxAmount\n        taxGroup\n        taxRate\n      }\n      unit\n      unitCount\n      upc\n      businessId\n      locationId\n      taxable\n      skuType\n      className\n      subclassName\n      categoryName\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateInventory($inventory: InventoryInput!, $locationId: String!) {\n    updateInventory(inventory: $inventory, locationId: $locationId) {\n      id\n      logoUrl\n      description\n      pricingDetails {\n        currency\n        currencyMultiplier\n        discount\n        total\n        unitPrice\n      }\n      sku\n      taxDetails {\n        taxAmount\n        taxGroup\n        taxRate\n      }\n      unit\n      unitCount\n      upc\n      businessId\n      locationId\n      taxable\n      skuType\n      className\n      subclassName\n      categoryName\n    }\n  }\n'
): (typeof documents)['\n  mutation updateInventory($inventory: InventoryInput!, $locationId: String!) {\n    updateInventory(inventory: $inventory, locationId: $locationId) {\n      id\n      logoUrl\n      description\n      pricingDetails {\n        currency\n        currencyMultiplier\n        discount\n        total\n        unitPrice\n      }\n      sku\n      taxDetails {\n        taxAmount\n        taxGroup\n        taxRate\n      }\n      unit\n      unitCount\n      upc\n      businessId\n      locationId\n      taxable\n      skuType\n      className\n      subclassName\n      categoryName\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteInventory($id: String!) {\n    deleteInventory(id: $id)\n  }\n'
): (typeof documents)['\n  mutation deleteInventory($id: String!) {\n    deleteInventory(id: $id)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addLocation($location: LocationInput!) {\n    addLocation(location: $location) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      status\n      notificationEmails\n    }\n  }\n'
): (typeof documents)['\n  mutation addLocation($location: LocationInput!) {\n    addLocation(location: $location) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      status\n      notificationEmails\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateLocation($locationId: String!, $location: LocationInput!) {\n    updateLocation(location: $location, locationId: $locationId) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      status\n      notificationEmails\n    }\n  }\n'
): (typeof documents)['\n  mutation updateLocation($locationId: String!, $location: LocationInput!) {\n    updateLocation(location: $location, locationId: $locationId) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      status\n      notificationEmails\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation locationConfig($input: LocationConfigInput!) {\n    locationConfig(input: $input)\n  }\n'
): (typeof documents)['\n  mutation locationConfig($input: LocationConfigInput!) {\n    locationConfig(input: $input)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deactivateLocation($locationId: String!) {\n    deactivateLocation(locationId: $locationId) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      status\n    }\n  }\n'
): (typeof documents)['\n  mutation deactivateLocation($locationId: String!) {\n    deactivateLocation(locationId: $locationId) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      status\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation activateLocation($locationId: String!) {\n    activateLocation(locationId: $locationId) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      status\n    }\n  }\n'
): (typeof documents)['\n  mutation activateLocation($locationId: String!) {\n    activateLocation(locationId: $locationId) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      status\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation VerifyOktaTokenAndGenerateSessionToken(\n    $businessId: String = null\n    $accessToken: String!\n    $idToken: String!\n    $oktaClientId: String!\n    $oktaIssuer: String!\n  ) {\n    verifyOktaTokenAndGenerateSessionToken(\n      businessId: $businessId\n      accessToken: $accessToken\n      idToken: $idToken\n      oktaClientId: $oktaClientId\n      oktaIssuer: $oktaIssuer\n    )\n  }\n'
): (typeof documents)['\n  mutation VerifyOktaTokenAndGenerateSessionToken(\n    $businessId: String = null\n    $accessToken: String!\n    $idToken: String!\n    $oktaClientId: String!\n    $oktaIssuer: String!\n  ) {\n    verifyOktaTokenAndGenerateSessionToken(\n      businessId: $businessId\n      accessToken: $accessToken\n      idToken: $idToken\n      oktaClientId: $oktaClientId\n      oktaIssuer: $oktaIssuer\n    )\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation loginUserForDashboard(\n    $email: String!\n    $password: String!\n    $businessId: String!\n  ) {\n    loginUserForDashboard(\n      email: $email\n      password: $password\n      businessId: $businessId\n    ) {\n      toteAccessToken\n      businessId\n      mfaEnabled\n    }\n  }\n'
): (typeof documents)['\n  mutation loginUserForDashboard(\n    $email: String!\n    $password: String!\n    $businessId: String!\n  ) {\n    loginUserForDashboard(\n      email: $email\n      password: $password\n      businessId: $businessId\n    ) {\n      toteAccessToken\n      businessId\n      mfaEnabled\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation loginGuest($locationId: ID!) {\n    loginGuest(locationId: $locationId) {\n      success\n      token\n      businessConfigs {\n        stripePublishableKey\n      }\n      location {\n        id\n        name\n        business {\n          id\n          name\n          configurations {\n            colors {\n              primary\n              secondary\n            }\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation loginGuest($locationId: ID!) {\n    loginGuest(locationId: $locationId) {\n      success\n      token\n      businessConfigs {\n        stripePublishableKey\n      }\n      location {\n        id\n        name\n        business {\n          id\n          name\n          configurations {\n            colors {\n              primary\n              secondary\n            }\n          }\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation verifyOtpAndGenerateSessionToken(\n    $email: String!\n    $otp: String!\n    $businessId: String!\n  ) {\n    verifyOtpAndGenerateSessionToken(\n      email: $email\n      otp: $otp\n      businessId: $businessId\n    ) {\n      toteAccessToken\n      businessId\n    }\n  }\n'
): (typeof documents)['\n  mutation verifyOtpAndGenerateSessionToken(\n    $email: String!\n    $otp: String!\n    $businessId: String!\n  ) {\n    verifyOtpAndGenerateSessionToken(\n      email: $email\n      otp: $otp\n      businessId: $businessId\n    ) {\n      toteAccessToken\n      businessId\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation resendOtp($email: String!, $businessId: String!) {\n    resendOtp(email: $email, businessId: $businessId)\n  }\n'
): (typeof documents)['\n  mutation resendOtp($email: String!, $businessId: String!) {\n    resendOtp(email: $email, businessId: $businessId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation VerifyAdminOktaTokenAndGenerateSessionToken(\n    $accessToken: String!\n    $idToken: String!\n    $oktaClientId: String!\n    $oktaIssuer: String!\n  ) {\n    verifyAdminOktaTokenAndGenerateSessionToken(\n      accessToken: $accessToken\n      idToken: $idToken\n      oktaClientId: $oktaClientId\n      oktaIssuer: $oktaIssuer\n    )\n  }\n'
): (typeof documents)['\n  mutation VerifyAdminOktaTokenAndGenerateSessionToken(\n    $accessToken: String!\n    $idToken: String!\n    $oktaClientId: String!\n    $oktaIssuer: String!\n  ) {\n    verifyAdminOktaTokenAndGenerateSessionToken(\n      accessToken: $accessToken\n      idToken: $idToken\n      oktaClientId: $oktaClientId\n      oktaIssuer: $oktaIssuer\n    )\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation generateOauthApplications($locationId: String!, $appName: String!) {\n    generateOauthApplications(locationId: $locationId, appName: $appName) {\n      appName\n      clientId\n      clientSecret\n      status\n    }\n  }\n'
): (typeof documents)['\n  mutation generateOauthApplications($locationId: String!, $appName: String!) {\n    generateOauthApplications(locationId: $locationId, appName: $appName) {\n      appName\n      clientId\n      clientSecret\n      status\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPaymentIntent($amount: Int!, $businessId: String!) {\n    createPaymentIntent(amount: $amount, businessId: $businessId)\n  }\n'
): (typeof documents)['\n  mutation createPaymentIntent($amount: Int!, $businessId: String!) {\n    createPaymentIntent(amount: $amount, businessId: $businessId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation placeOrderConsumerAgent(\n    $cartId: String!\n    $currency: String!\n    $amount: Int!\n    $paymentMethod: String!\n    $paymentGateway: String\n    $paymentGatewayReferenceId: String\n    $nonce: String!\n    $businessId: String!\n    $locationId: String!\n  ) {\n    placeOrderConsumerAgent(\n      input: {\n        cartId: $cartId\n        currency: $currency\n        amount: $amount\n        paymentMethod: $paymentMethod\n        paymentGateway: $paymentGateway\n        paymentGatewayReferenceId: $paymentGatewayReferenceId\n        nonce: $nonce\n      }\n      requestContext: {businessId: $businessId, locationId: $locationId}\n    ) {\n      amountPaid\n      amountRefunded\n      currency\n      id\n      paymentStatus\n      refundStatus\n      totalAmount\n      payments {\n        id\n        orderId\n        status\n        amount\n        paymentMethod\n        paymentGatewayReferenceId\n        currency\n        paymentMethodDetails {\n          type\n          card {\n            last4\n            brand\n            fingerprint\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation placeOrderConsumerAgent(\n    $cartId: String!\n    $currency: String!\n    $amount: Int!\n    $paymentMethod: String!\n    $paymentGateway: String\n    $paymentGatewayReferenceId: String\n    $nonce: String!\n    $businessId: String!\n    $locationId: String!\n  ) {\n    placeOrderConsumerAgent(\n      input: {\n        cartId: $cartId\n        currency: $currency\n        amount: $amount\n        paymentMethod: $paymentMethod\n        paymentGateway: $paymentGateway\n        paymentGatewayReferenceId: $paymentGatewayReferenceId\n        nonce: $nonce\n      }\n      requestContext: {businessId: $businessId, locationId: $locationId}\n    ) {\n      amountPaid\n      amountRefunded\n      currency\n      id\n      paymentStatus\n      refundStatus\n      totalAmount\n      payments {\n        id\n        orderId\n        status\n        amount\n        paymentMethod\n        paymentGatewayReferenceId\n        currency\n        paymentMethodDetails {\n          type\n          card {\n            last4\n            brand\n            fingerprint\n          }\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation orderPaymentStatusConsumerAgent(\n    $orderId: String!\n    $paymentId: String!\n    $businessId: String!\n    $locationId: String!\n  ) {\n    orderPaymentStatusConsumerAgent(\n      orderId: $orderId\n      paymentId: $paymentId\n      requestContext: {businessId: $businessId, locationId: $locationId}\n    ) {\n      id\n      paymentStatus\n      refundStatus\n      totalAmount\n      currency\n      amountPaid\n      amountRefunded\n    }\n  }\n'
): (typeof documents)['\n  mutation orderPaymentStatusConsumerAgent(\n    $orderId: String!\n    $paymentId: String!\n    $businessId: String!\n    $locationId: String!\n  ) {\n    orderPaymentStatusConsumerAgent(\n      orderId: $orderId\n      paymentId: $paymentId\n      requestContext: {businessId: $businessId, locationId: $locationId}\n    ) {\n      id\n      paymentStatus\n      refundStatus\n      totalAmount\n      currency\n      amountPaid\n      amountRefunded\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddZoneData(\n    $businessId: String!\n    $locationId: String!\n    $name: String!\n    $description: String!\n    $devices: [String!]!\n    $registers: [String!]!\n  ) {\n    addZoneData(\n      businessId: $businessId\n      locationId: $locationId\n      name: $name\n      description: $description\n      devices: $devices\n      registers: $registers\n    )\n  }\n'
): (typeof documents)['\n  mutation AddZoneData(\n    $businessId: String!\n    $locationId: String!\n    $name: String!\n    $description: String!\n    $devices: [String!]!\n    $registers: [String!]!\n  ) {\n    addZoneData(\n      businessId: $businessId\n      locationId: $locationId\n      name: $name\n      description: $description\n      devices: $devices\n      registers: $registers\n    )\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateZone(\n    $businessId: String!\n    $locationId: String!\n    $zoneId: String!\n    $name: String!\n    $description: String!\n    $devices: [String!]!\n    $registers: [String!]!\n  ) {\n    updateZone(\n      businessId: $businessId\n      locationId: $locationId\n      zoneId: $zoneId\n      name: $name\n      description: $description\n      devices: $devices\n      registers: $registers\n    )\n  }\n'
): (typeof documents)['\n  mutation UpdateZone(\n    $businessId: String!\n    $locationId: String!\n    $zoneId: String!\n    $name: String!\n    $description: String!\n    $devices: [String!]!\n    $registers: [String!]!\n  ) {\n    updateZone(\n      businessId: $businessId\n      locationId: $locationId\n      zoneId: $zoneId\n      name: $name\n      description: $description\n      devices: $devices\n      registers: $registers\n    )\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation ResolveErrorNotification($activityId: String!) {\n    resolveErrorNotification(activityId: $activityId)\n  }\n'
): (typeof documents)['\n  mutation ResolveErrorNotification($activityId: String!) {\n    resolveErrorNotification(activityId: $activityId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteZone($zoneId: String!) {\n    deleteZone(zoneId: $zoneId)\n  }\n'
): (typeof documents)['\n  mutation DeleteZone($zoneId: String!) {\n    deleteZone(zoneId: $zoneId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePeripheralHubNotificationConfig(\n    $notificationConfiguration: PeripheralHubNotificationsInput!\n  ) {\n    updatePeripheralHubNotificationConfig(\n      notificationConfiguration: $notificationConfiguration\n    )\n  }\n'
): (typeof documents)['\n  mutation updatePeripheralHubNotificationConfig(\n    $notificationConfiguration: PeripheralHubNotificationsInput!\n  ) {\n    updatePeripheralHubNotificationConfig(\n      notificationConfiguration: $notificationConfiguration\n    )\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CREATE_RULE(\n    $businessId: String!\n    $ruleName: String!\n    $ruleType: String!\n    $ruleValue: String!\n    $applyTo: [String!]\n    $excludeFrom: [String!]\n    $applyToProductGroup: [String!]\n    $excludeFromProductGroup: [String!]\n  ) {\n    createRule(\n      businessId: $businessId\n      ruleName: $ruleName\n      ruleType: $ruleType\n      ruleValue: $ruleValue\n      applyTo: $applyTo\n      excludeFrom: $excludeFrom\n      applyToProductGroup: $applyToProductGroup\n      excludeFromProductGroup: $excludeFromProductGroup\n    ) {\n      businessId\n      ruleName\n      ruleType\n      ruleValue\n      applyTo\n      excludeFrom\n      applyToProductGroup\n      excludeFromProductGroup\n    }\n  }\n'
): (typeof documents)['\n  mutation CREATE_RULE(\n    $businessId: String!\n    $ruleName: String!\n    $ruleType: String!\n    $ruleValue: String!\n    $applyTo: [String!]\n    $excludeFrom: [String!]\n    $applyToProductGroup: [String!]\n    $excludeFromProductGroup: [String!]\n  ) {\n    createRule(\n      businessId: $businessId\n      ruleName: $ruleName\n      ruleType: $ruleType\n      ruleValue: $ruleValue\n      applyTo: $applyTo\n      excludeFrom: $excludeFrom\n      applyToProductGroup: $applyToProductGroup\n      excludeFromProductGroup: $excludeFromProductGroup\n    ) {\n      businessId\n      ruleName\n      ruleType\n      ruleValue\n      applyTo\n      excludeFrom\n      applyToProductGroup\n      excludeFromProductGroup\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPromotion(\n    $businessId: String!\n    $active: Boolean!\n    $canBeCombined: Boolean!\n    $endDate: DateTime!\n    $priority: Int!\n    $promotionName: String!\n    $promotionType: String!\n    $rules: [String!]!\n    $startDate: DateTime!\n    $promotionValue: Int!\n    $description: String!\n    $promotionSchema: String!\n    $subscriptionType: Boolean!\n  ) {\n    createPromotion(\n      businessId: $businessId\n      active: $active\n      canBeCombined: $canBeCombined\n      endDate: $endDate\n      priority: $priority\n      promotionName: $promotionName\n      promotionType: $promotionType\n      rules: $rules\n      startDate: $startDate\n      promotionValue: $promotionValue\n      description: $description\n      promotionSchema: $promotionSchema\n      subscriptionType: $subscriptionType\n    ) {\n      businessId\n      active\n      canBeCombined\n      endDate\n      priority\n      promotionName\n      promotionType\n      startDate\n      promotionValue\n      description\n      promotionSchema\n      subscriptionType\n      rules {\n        ruleName\n        ruleType\n        ruleValue\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation createPromotion(\n    $businessId: String!\n    $active: Boolean!\n    $canBeCombined: Boolean!\n    $endDate: DateTime!\n    $priority: Int!\n    $promotionName: String!\n    $promotionType: String!\n    $rules: [String!]!\n    $startDate: DateTime!\n    $promotionValue: Int!\n    $description: String!\n    $promotionSchema: String!\n    $subscriptionType: Boolean!\n  ) {\n    createPromotion(\n      businessId: $businessId\n      active: $active\n      canBeCombined: $canBeCombined\n      endDate: $endDate\n      priority: $priority\n      promotionName: $promotionName\n      promotionType: $promotionType\n      rules: $rules\n      startDate: $startDate\n      promotionValue: $promotionValue\n      description: $description\n      promotionSchema: $promotionSchema\n      subscriptionType: $subscriptionType\n    ) {\n      businessId\n      active\n      canBeCombined\n      endDate\n      priority\n      promotionName\n      promotionType\n      startDate\n      promotionValue\n      description\n      promotionSchema\n      subscriptionType\n      rules {\n        ruleName\n        ruleType\n        ruleValue\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addPromotionToSku(\n    $promotionName: String!\n    $sku: String!\n    $businessId: String!\n  ) {\n    addPromotionToSku(\n      promotionName: $promotionName\n      sku: $sku\n      businessId: $businessId\n    ) {\n      success\n      message\n    }\n  }\n'
): (typeof documents)['\n  mutation addPromotionToSku(\n    $promotionName: String!\n    $sku: String!\n    $businessId: String!\n  ) {\n    addPromotionToSku(\n      promotionName: $promotionName\n      sku: $sku\n      businessId: $businessId\n    ) {\n      success\n      message\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    mutation AddRegister($register: RegisterInput!) {\n        addRegister(register: $register) {\n            id \n            name\n            isActive\n        }\n    }\n'
): (typeof documents)['\n    mutation AddRegister($register: RegisterInput!) {\n        addRegister(register: $register) {\n            id \n            name\n            isActive\n        }\n    }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    mutation UpdateRegister($register: RegisterInput!, $registerId: String!) {\n        updateRegister(register: $register, registerId: $registerId) {\n            id\n            name\n            isActive\n        }\n    }\n'
): (typeof documents)['\n    mutation UpdateRegister($register: RegisterInput!, $registerId: String!) {\n        updateRegister(register: $register, registerId: $registerId) {\n            id\n            name\n            isActive\n        }\n    }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createRole(\n    $businessId: String!\n    $name: String!\n    $description: String!\n    $permissions: [String!]!\n  ) {\n    createRole(\n      businessId: $businessId\n      name: $name\n      description: $description\n      permissions: $permissions\n    )\n  }\n'
): (typeof documents)['\n  mutation createRole(\n    $businessId: String!\n    $name: String!\n    $description: String!\n    $permissions: [String!]!\n  ) {\n    createRole(\n      businessId: $businessId\n      name: $name\n      description: $description\n      permissions: $permissions\n    )\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateRole(\n    $businessId: String!\n    $id: String!\n    $name: String!\n    $description: String!\n    $isActive: Boolean!\n    $permissions: [String!]!\n  ) {\n    updateRole(\n      businessId: $businessId\n      id: $id\n      name: $name\n      description: $description\n      isActive: $isActive\n      permissions: $permissions\n    )\n  }\n'
): (typeof documents)['\n  mutation updateRole(\n    $businessId: String!\n    $id: String!\n    $name: String!\n    $description: String!\n    $isActive: Boolean!\n    $permissions: [String!]!\n  ) {\n    updateRole(\n      businessId: $businessId\n      id: $id\n      name: $name\n      description: $description\n      isActive: $isActive\n      permissions: $permissions\n    )\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteRole($businessId: String!, $id: String!) {\n    deleteRole(businessId: $businessId, id: $id)\n  }\n'
): (typeof documents)['\n  mutation deleteRole($businessId: String!, $id: String!) {\n    deleteRole(businessId: $businessId, id: $id)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation assignRole($employeeId: String!, $roleId: String) {\n    assignRole(employeeId: $employeeId, roleId: $roleId)\n  }\n'
): (typeof documents)['\n  mutation assignRole($employeeId: String!, $roleId: String) {\n    assignRole(employeeId: $employeeId, roleId: $roleId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddSecret($secretName: String!, $secretValue: String!) {\n    addSecret(secretName: $secretName, secretValue: $secretValue)\n  }\n'
): (typeof documents)['\n  mutation AddSecret($secretName: String!, $secretValue: String!) {\n    addSecret(secretName: $secretName, secretValue: $secretValue)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteSecret($secretName: String!) {\n    deleteSecret(secretName: $secretName)\n  }\n'
): (typeof documents)['\n  mutation DeleteSecret($secretName: String!) {\n    deleteSecret(secretName: $secretName)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddUpdateServiceConfig($input: ServiceConfigInput!) {\n    serviceConfig(input: $input)\n  }\n'
): (typeof documents)['\n  mutation AddUpdateServiceConfig($input: ServiceConfigInput!) {\n    serviceConfig(input: $input)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation VerifyServiceConfig($input: ServiceConfigInput!) {\n    verifyServiceConfig(input: $input)\n  }\n'
): (typeof documents)['\n  mutation VerifyServiceConfig($input: ServiceConfigInput!) {\n    verifyServiceConfig(input: $input)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RenameStoreGroupCluster(\n    $storeGroupClusterId: String!\n    $newClusterName: String!\n  ) {\n    renameStoreGroupCluster(\n      storeGroupClusterId: $storeGroupClusterId\n      newClusterName: $newClusterName\n    ) {\n      name\n      hierarchyLevel\n    }\n  }\n'
): (typeof documents)['\n  mutation RenameStoreGroupCluster(\n    $storeGroupClusterId: String!\n    $newClusterName: String!\n  ) {\n    renameStoreGroupCluster(\n      storeGroupClusterId: $storeGroupClusterId\n      newClusterName: $newClusterName\n    ) {\n      name\n      hierarchyLevel\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateStoreGroup(\n    $name: String!\n    $status: String\n    $storeGroupClusterId: String!\n    $childStoreGroupIds: [String!]!\n    $childLocationIds: [String!]!\n  ) {\n    createStoreGroup(\n      input: {\n        name: $name\n        status: $status\n        storeGroupClusterId: $storeGroupClusterId\n        childStoreGroupIds: $childStoreGroupIds\n        childLocationIds: $childLocationIds\n      }\n    ) {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation CreateStoreGroup(\n    $name: String!\n    $status: String\n    $storeGroupClusterId: String!\n    $childStoreGroupIds: [String!]!\n    $childLocationIds: [String!]!\n  ) {\n    createStoreGroup(\n      input: {\n        name: $name\n        status: $status\n        storeGroupClusterId: $storeGroupClusterId\n        childStoreGroupIds: $childStoreGroupIds\n        childLocationIds: $childLocationIds\n      }\n    ) {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateStoreGroup(\n    $id: String!\n    $name: String!\n    $status: String\n    $childStoreGroupIds: [String!]!\n    $childLocationIds: [String!]!\n  ) {\n    updateStoreGroup(\n      input: {\n        id: $id\n        name: $name\n        status: $status\n        childStoreGroupIds: $childStoreGroupIds\n        childLocationIds: $childLocationIds\n      }\n    ) {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation UpdateStoreGroup(\n    $id: String!\n    $name: String!\n    $status: String\n    $childStoreGroupIds: [String!]!\n    $childLocationIds: [String!]!\n  ) {\n    updateStoreGroup(\n      input: {\n        id: $id\n        name: $name\n        status: $status\n        childStoreGroupIds: $childStoreGroupIds\n        childLocationIds: $childLocationIds\n      }\n    ) {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SaveDeviceTemplate(\n    $fileContent: String!\n    $fileName: String!\n    $locationId: String\n  ) {\n    saveDeviceTemplate(\n      fileContent: $fileContent\n      fileName: $fileName\n      locationId: $locationId\n    )\n  }\n'
): (typeof documents)['\n  mutation SaveDeviceTemplate(\n    $fileContent: String!\n    $fileName: String!\n    $locationId: String\n  ) {\n    saveDeviceTemplate(\n      fileContent: $fileContent\n      fileName: $fileName\n      locationId: $locationId\n    )\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteDeviceTemplate($templateName: String!, $locationId: String) {\n    deleteDeviceTemplate(locationId: $locationId, templateName: $templateName)\n  }\n'
): (typeof documents)['\n  mutation DeleteDeviceTemplate($templateName: String!, $locationId: String) {\n    deleteDeviceTemplate(locationId: $locationId, templateName: $templateName)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SaveDeviceBitmap(\n    $fileContent: String!\n    $fileName: String!\n    $locationId: String\n  ) {\n    saveDeviceBitmap(\n      fileContent: $fileContent\n      fileName: $fileName\n      locationId: $locationId\n    )\n  }\n'
): (typeof documents)['\n  mutation SaveDeviceBitmap(\n    $fileContent: String!\n    $fileName: String!\n    $locationId: String\n  ) {\n    saveDeviceBitmap(\n      fileContent: $fileContent\n      fileName: $fileName\n      locationId: $locationId\n    )\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteDeviceBitmap($bitmapName: String!, $locationId: String) {\n    deleteDeviceBitmap(locationId: $locationId, bitmapName: $bitmapName)\n  }\n'
): (typeof documents)['\n  mutation DeleteDeviceBitmap($bitmapName: String!, $locationId: String) {\n    deleteDeviceBitmap(locationId: $locationId, bitmapName: $bitmapName)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    mutation AddTill($till: TillInput!) {\n        addTill(till: $till) {\n            id \n            name\n            isActive\n        }\n    }\n'
): (typeof documents)['\n    mutation AddTill($till: TillInput!) {\n        addTill(till: $till) {\n            id \n            name\n            isActive\n        }\n    }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n    mutation UpdateTill($till: TillInput!, $tillId: String!) {\n        updateTill(till: $till, tillId: $tillId) {\n            id\n            name\n            isActive\n        }\n    }\n'
): (typeof documents)['\n    mutation UpdateTill($till: TillInput!, $tillId: String!) {\n        updateTill(till: $till, tillId: $tillId) {\n            id\n            name\n            isActive\n        }\n    }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getBusinesses {\n    getBusinesses {\n      brand\n      id\n      logo\n      name\n      okta {\n        oktaClientId\n        oktaIssuer\n      }\n      contactDetails {\n        contactPhone\n        contactEmail\n        supportPhone\n        supportEmail\n      }\n      featureFlags\n      address {\n        city\n        lat\n        long\n        state\n        street\n        zipCode\n        country\n      }\n      authMethod\n      storeGroupClusters {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getBusinesses {\n    getBusinesses {\n      brand\n      id\n      logo\n      name\n      okta {\n        oktaClientId\n        oktaIssuer\n      }\n      contactDetails {\n        contactPhone\n        contactEmail\n        supportPhone\n        supportEmail\n      }\n      featureFlags\n      address {\n        city\n        lat\n        long\n        state\n        street\n        zipCode\n        country\n      }\n      authMethod\n      storeGroupClusters {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAllRolesEmployeesLocations(\n    $businessId: String!\n    $status: String\n    $fetchAllEmployees: Boolean\n  ) {\n    getAllRoles(businessId: $businessId) {\n      id\n      name\n      description\n      isActive\n      permissions\n      createdAt\n      updatedAt\n    }\n\n    getAllEmployees(businessId: $businessId, fetchAll: $fetchAllEmployees) {\n      employees {\n        id\n        name\n        email\n        phoneNumber\n        employeeCode\n        fullAddress {\n          street\n          city\n          state\n          zipCode\n          country\n        }\n        role {\n          id\n          name\n          permissions\n          description\n        }\n        toteSuperAdmin\n      }\n      totalCount\n    }\n\n    getAllLocations(businessId: $businessId, status: $status) {\n      id\n      name\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      timeZone\n      status\n      storeNumber\n    }\n  }\n'
): (typeof documents)['\n  query getAllRolesEmployeesLocations(\n    $businessId: String!\n    $status: String\n    $fetchAllEmployees: Boolean\n  ) {\n    getAllRoles(businessId: $businessId) {\n      id\n      name\n      description\n      isActive\n      permissions\n      createdAt\n      updatedAt\n    }\n\n    getAllEmployees(businessId: $businessId, fetchAll: $fetchAllEmployees) {\n      employees {\n        id\n        name\n        email\n        phoneNumber\n        employeeCode\n        fullAddress {\n          street\n          city\n          state\n          zipCode\n          country\n        }\n        role {\n          id\n          name\n          permissions\n          description\n        }\n        toteSuperAdmin\n      }\n      totalCount\n    }\n\n    getAllLocations(businessId: $businessId, status: $status) {\n      id\n      name\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      timeZone\n      status\n      storeNumber\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getOrderReceiptTemplateConfig($businessId: String!) {\n    getBusiness(businessId: $businessId) {\n      id\n      configurations {\n        emailReceiptEnabled\n        templates {\n          orderReceipt\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getOrderReceiptTemplateConfig($businessId: String!) {\n    getBusiness(businessId: $businessId) {\n      id\n      configurations {\n        emailReceiptEnabled\n        templates {\n          orderReceipt\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TILL_TRANSACTIONS(\n    $register: String\n    $till: String\n    $date: String!\n    $locationId: String!\n  ) {\n    tillTransactions(\n      till: $till\n      register: $register\n      date: $date\n      locationId: $locationId\n    ) {\n      nodes {\n        id\n        date\n        openTime\n        closeTime\n        netCash\n        status\n        till\n        totalCash\n        register\n        employeeCode\n        employee {\n          name\n        }\n        defaultStartingFloat\n        tillAmountUpperLimit\n      }\n      registerTotalCash\n      registerNetCash\n      activeTill\n    }\n  }\n'
): (typeof documents)['\n  query TILL_TRANSACTIONS(\n    $register: String\n    $till: String\n    $date: String!\n    $locationId: String!\n  ) {\n    tillTransactions(\n      till: $till\n      register: $register\n      date: $date\n      locationId: $locationId\n    ) {\n      nodes {\n        id\n        date\n        openTime\n        closeTime\n        netCash\n        status\n        till\n        totalCash\n        register\n        employeeCode\n        employee {\n          name\n        }\n        defaultStartingFloat\n        tillAmountUpperLimit\n      }\n      registerTotalCash\n      registerNetCash\n      activeTill\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TRANSACTION_SUMMARY(\n    $register: String\n    $till: String\n    $date: String!\n    $locationId: String!\n  ) {\n    tillTransactionsSummary(\n      till: $till\n      register: $register\n      date: $date\n      locationId: $locationId\n    ) {\n      cash\n      sale\n      cashAdjustment\n      cashPickup\n      paidIn\n      paidOut\n      tillFloat\n      open\n      tillClose\n      reconcile\n      transfer\n      refund\n      cashDrop\n      startingDate\n      endingDate\n      registerTotalCash\n      safeTotalCash\n      storeTotalCash\n      reserveTotalCash\n    }\n  }\n'
): (typeof documents)['\n  query TRANSACTION_SUMMARY(\n    $register: String\n    $till: String\n    $date: String!\n    $locationId: String!\n  ) {\n    tillTransactionsSummary(\n      till: $till\n      register: $register\n      date: $date\n      locationId: $locationId\n    ) {\n      cash\n      sale\n      cashAdjustment\n      cashPickup\n      paidIn\n      paidOut\n      tillFloat\n      open\n      tillClose\n      reconcile\n      transfer\n      refund\n      cashDrop\n      startingDate\n      endingDate\n      registerTotalCash\n      safeTotalCash\n      storeTotalCash\n      reserveTotalCash\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TRANSACTIONS(\n    $register: String\n    $till: String\n    $dateRange: [String!]!\n    $locationId: String!\n    $employeeCode: String\n    $transactionType: String\n    $pageIndex: Int\n    $pageSize: Int\n  ) {\n    cashTransactions(\n      till: $till\n      register: $register\n      dateRange: $dateRange\n      locationId: $locationId\n      employeeCode: $employeeCode\n      transactionType: $transactionType\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n    ) {\n      totalPageCount\n      nodes {\n        amount\n        closeTime\n        eventTime\n        dateCreated\n        description\n        employeeCode\n        eventType\n        id\n        openTime\n        register\n        storeNumber\n        till\n        transactionType\n        transactionId\n        transferTo\n        transferToTill\n        transactionTime\n        eventId\n        safeType\n        isSafeTransaction\n        source\n        employee {\n          name\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query TRANSACTIONS(\n    $register: String\n    $till: String\n    $dateRange: [String!]!\n    $locationId: String!\n    $employeeCode: String\n    $transactionType: String\n    $pageIndex: Int\n    $pageSize: Int\n  ) {\n    cashTransactions(\n      till: $till\n      register: $register\n      dateRange: $dateRange\n      locationId: $locationId\n      employeeCode: $employeeCode\n      transactionType: $transactionType\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n    ) {\n      totalPageCount\n      nodes {\n        amount\n        closeTime\n        eventTime\n        dateCreated\n        description\n        employeeCode\n        eventType\n        id\n        openTime\n        register\n        storeNumber\n        till\n        transactionType\n        transactionId\n        transferTo\n        transferToTill\n        transactionTime\n        eventId\n        safeType\n        isSafeTransaction\n        source\n        employee {\n          name\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GET_STATUS($locationId: String!, $businessId: String!) {\n    getTillStatus(locationId: $locationId, businessId: $businessId)\n  }\n'
): (typeof documents)['\n  query GET_STATUS($locationId: String!, $businessId: String!) {\n    getTillStatus(locationId: $locationId, businessId: $businessId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BANK_DEPOSITS(\n    $locationId: String!\n    $dateRange: [String!]!\n    $pageIndex: Int\n    $pageSize: Int\n  ) {\n    getBankDeposits(\n      locationId: $locationId\n      dateRange: $dateRange\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n    ) {\n      totalPageCount\n      nodes {\n        id\n        amount\n        createdAt\n        updatedAt\n        depositNumber\n        depositReceipt\n        employee {\n          name\n        }\n        status\n      }\n    }\n  }\n'
): (typeof documents)['\n  query BANK_DEPOSITS(\n    $locationId: String!\n    $dateRange: [String!]!\n    $pageIndex: Int\n    $pageSize: Int\n  ) {\n    getBankDeposits(\n      locationId: $locationId\n      dateRange: $dateRange\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n    ) {\n      totalPageCount\n      nodes {\n        id\n        amount\n        createdAt\n        updatedAt\n        depositNumber\n        depositReceipt\n        employee {\n          name\n        }\n        status\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AGGREGRATE_CASH_TRANSACTIONS(\n    $storeNumbers: [String!]\n    $locationIds: [String!]\n    $transactionTimeRange: [String!]\n    $eventType: [String!]\n  ) {\n    aggregrateCashTransactions(\n      storeNumbers: $storeNumbers\n      locationIds: $locationIds\n      transactionTimeRange: $transactionTimeRange\n      eventType: $eventType\n    ) {\n      cashSalesStats {\n        cashSales\n        percentageChange\n      }\n      averageTransactionValueStats {\n        averageTransactionValue\n        percentageChange\n      }\n      cashVarianceStats {\n        cashVariance\n        percentageChange\n      }\n    }\n  }\n'
): (typeof documents)['\n  query AGGREGRATE_CASH_TRANSACTIONS(\n    $storeNumbers: [String!]\n    $locationIds: [String!]\n    $transactionTimeRange: [String!]\n    $eventType: [String!]\n  ) {\n    aggregrateCashTransactions(\n      storeNumbers: $storeNumbers\n      locationIds: $locationIds\n      transactionTimeRange: $transactionTimeRange\n      eventType: $eventType\n    ) {\n      cashSalesStats {\n        cashSales\n        percentageChange\n      }\n      averageTransactionValueStats {\n        averageTransactionValue\n        percentageChange\n      }\n      cashVarianceStats {\n        cashVariance\n        percentageChange\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VARIANCE_REPORT(\n    $storeNumbers: [String!]\n    $locationIds: [String!]\n    $transactionTimeRange: [String!]\n    $employeeCodes: [String!]\n    $eventType: [String!]\n  ) {\n    aggregrateCashTransactions(\n      storeNumbers: $storeNumbers\n      locationIds: $locationIds\n      transactionTimeRange: $transactionTimeRange\n      employeeCodes: $employeeCodes\n      eventType: $eventType\n    ) {\n      totalExpectedCashStats {\n        totalExpectedCash\n        percentageChange\n      }\n      totalActualCashStats {\n        totalActualCash\n        percentageChange\n      }\n      totalVarianceStats {\n        totalVariance\n        percentageChange\n      }\n      averageVariancePercentageStats {\n        averageVariancePercentage\n        percentageChange\n      }\n    }\n  }\n'
): (typeof documents)['\n  query VARIANCE_REPORT(\n    $storeNumbers: [String!]\n    $locationIds: [String!]\n    $transactionTimeRange: [String!]\n    $employeeCodes: [String!]\n    $eventType: [String!]\n  ) {\n    aggregrateCashTransactions(\n      storeNumbers: $storeNumbers\n      locationIds: $locationIds\n      transactionTimeRange: $transactionTimeRange\n      employeeCodes: $employeeCodes\n      eventType: $eventType\n    ) {\n      totalExpectedCashStats {\n        totalExpectedCash\n        percentageChange\n      }\n      totalActualCashStats {\n        totalActualCash\n        percentageChange\n      }\n      totalVarianceStats {\n        totalVariance\n        percentageChange\n      }\n      averageVariancePercentageStats {\n        averageVariancePercentage\n        percentageChange\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getRegisterTills($businessId: String!, $locationId: String!) {\n    fetchRegisterList(businessId: $businessId, locationId: $locationId) {\n      id\n      name\n    }\n\n    fetchTills(businessId: $businessId, locationId: $locationId) {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  query getRegisterTills($businessId: String!, $locationId: String!) {\n    fetchRegisterList(businessId: $businessId, locationId: $locationId) {\n      id\n      name\n    }\n\n    fetchTills(businessId: $businessId, locationId: $locationId) {\n      id\n      name\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getCashRegisters($locationId: String!, $pageIndex: Int, $pageSize: Int) {\n    getCashRegisters(locationId: $locationId, pageIndex: $pageIndex, pageSize: $pageSize) {\n      nodes {\n        id\n        name\n        isActive\n        externalRegisterId\n        source\n        createdAt\n        updatedAt\n      }\n      totalPageCount  \n    }\n  }\n'
): (typeof documents)['\n  query getCashRegisters($locationId: String!, $pageIndex: Int, $pageSize: Int) {\n    getCashRegisters(locationId: $locationId, pageIndex: $pageIndex, pageSize: $pageSize) {\n      nodes {\n        id\n        name\n        isActive\n        externalRegisterId\n        source\n        createdAt\n        updatedAt\n      }\n      totalPageCount  \n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getTills($locationId: String!, $pageIndex: Int, $pageSize: Int) {\n    getTills(locationId: $locationId, pageIndex: $pageIndex, pageSize: $pageSize) {\n      nodes {\n        id\n        name\n        isActive\n        externalTillId\n        source\n        createdAt\n        updatedAt\n      }\n      totalPageCount  \n    }\n  }\n'
): (typeof documents)['\n  query getTills($locationId: String!, $pageIndex: Int, $pageSize: Int) {\n    getTills(locationId: $locationId, pageIndex: $pageIndex, pageSize: $pageSize) {\n      nodes {\n        id\n        name\n        isActive\n        externalTillId\n        source\n        createdAt\n        updatedAt\n      }\n      totalPageCount  \n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query listCustomers(\n    $businessId: String!\n    $locationId: String!\n    $customerId: String\n  ) {\n    listCustomers(\n      businessId: $businessId\n      locationId: $locationId\n      customerId: $customerId\n    ) {\n      title\n      id\n      firstName\n      lastName\n      avatar\n      barcode\n      email\n      phone\n      updatedAt\n      preferences {\n        key\n        title\n        value\n      }\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n    }\n  }\n'
): (typeof documents)['\n  query listCustomers(\n    $businessId: String!\n    $locationId: String!\n    $customerId: String\n  ) {\n    listCustomers(\n      businessId: $businessId\n      locationId: $locationId\n      customerId: $customerId\n    ) {\n      title\n      id\n      firstName\n      lastName\n      avatar\n      barcode\n      email\n      phone\n      updatedAt\n      preferences {\n        key\n        title\n        value\n      }\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GenieQueryConsumerAgent(\n    $query: String!\n    $chatSessionId: String!\n    $queryType: String\n    $locationId: String\n  ) {\n    genieQueryConsumerAgent(\n      query: $query\n      chatSessionId: $chatSessionId\n      queryType: $queryType\n      locationId: $locationId\n    ) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieChatQuery {\n        products {\n          skuType\n          sku\n          unitPrice\n          unitDiscount\n          unitTaxAmount\n          description\n          unitSubTotal\n          aisleLocation\n          logoUrl\n          promotions {\n            description\n            promotionType\n            promotionValue\n            promotionName\n            active\n            businessId\n            canBeCombined\n            startDate\n            endDate\n            priority\n            subscriptionType\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GenieQueryConsumerAgent(\n    $query: String!\n    $chatSessionId: String!\n    $queryType: String\n    $locationId: String\n  ) {\n    genieQueryConsumerAgent(\n      query: $query\n      chatSessionId: $chatSessionId\n      queryType: $queryType\n      locationId: $locationId\n    ) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieChatQuery {\n        products {\n          skuType\n          sku\n          unitPrice\n          unitDiscount\n          unitTaxAmount\n          description\n          unitSubTotal\n          aisleLocation\n          logoUrl\n          promotions {\n            description\n            promotionType\n            promotionValue\n            promotionName\n            active\n            businessId\n            canBeCombined\n            startDate\n            endDate\n            priority\n            subscriptionType\n          }\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FetchGenieMessageConsumerAgent($messageId: String!) {\n    fetchGenieMessageConsumerAgent(messageId: $messageId) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieChatQuery {\n        products {\n          skuType\n          sku\n          unitPrice\n          unitDiscount\n          unitTaxAmount\n          description\n          unitSubTotal\n          aisleLocation\n          logoUrl\n          promotions {\n            description\n            promotionType\n            promotionValue\n            promotionName\n            active\n            businessId\n            canBeCombined\n            startDate\n            endDate\n            priority\n            subscriptionType\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query FetchGenieMessageConsumerAgent($messageId: String!) {\n    fetchGenieMessageConsumerAgent(messageId: $messageId) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieChatQuery {\n        products {\n          skuType\n          sku\n          unitPrice\n          unitDiscount\n          unitTaxAmount\n          description\n          unitSubTotal\n          aisleLocation\n          logoUrl\n          promotions {\n            description\n            promotionType\n            promotionValue\n            promotionName\n            active\n            businessId\n            canBeCombined\n            startDate\n            endDate\n            priority\n            subscriptionType\n          }\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query fetchCustomer {\n    fetchCustomer {\n      id\n      firstName\n      lastName\n      avatar\n      barcode\n      email\n      phone\n      updatedAt\n      preferences {\n        key\n        title\n        value\n      }\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n    }\n  }\n'
): (typeof documents)['\n  query fetchCustomer {\n    fetchCustomer {\n      id\n      firstName\n      lastName\n      avatar\n      barcode\n      email\n      phone\n      updatedAt\n      preferences {\n        key\n        title\n        value\n      }\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PredefinedChatQueryConsumerAgent(\n    $queryId: String!\n    $locationId: String!\n    $businessId: String\n  ) {\n    predefinedChatQueryConsumerAgent(\n      queryId: $queryId\n      locationId: $locationId\n      businessId: $businessId\n    ) {\n      products {\n        skuType\n        sku\n        unitPrice\n        unitDiscount\n        unitTaxAmount\n        description\n        unitSubTotal\n        aisleLocation\n        logoUrl\n        promotions {\n          description\n          promotionType\n          promotionValue\n          promotionName\n          active\n          businessId\n          canBeCombined\n          startDate\n          endDate\n          priority\n          subscriptionType\n        }\n      }\n      answer\n      query\n      queryId\n    }\n  }\n'
): (typeof documents)['\n  query PredefinedChatQueryConsumerAgent(\n    $queryId: String!\n    $locationId: String!\n    $businessId: String\n  ) {\n    predefinedChatQueryConsumerAgent(\n      queryId: $queryId\n      locationId: $locationId\n      businessId: $businessId\n    ) {\n      products {\n        skuType\n        sku\n        unitPrice\n        unitDiscount\n        unitTaxAmount\n        description\n        unitSubTotal\n        aisleLocation\n        logoUrl\n        promotions {\n          description\n          promotionType\n          promotionValue\n          promotionName\n          active\n          businessId\n          canBeCombined\n          startDate\n          endDate\n          priority\n          subscriptionType\n        }\n      }\n      answer\n      query\n      queryId\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEmployee {\n    getEmployee {\n      id\n      name\n      email\n      locations {\n        id\n        address {\n          street\n          city\n          state\n          zipCode\n          country\n        }\n        name\n        timeZone\n        status\n        storeNumber\n        locationConfig {\n          cashManagement {\n            tillAmountUpperLimit\n            storeFloatAmount\n          }\n        }\n        storeGroups {\n          id\n          name\n          status\n          storeGroupCluster {\n            id\n            name\n            hierarchyLevel\n          }\n        }\n      }\n      business {\n        id\n        name\n        logo\n        brand\n        featureFlags\n        configurations {\n          colors {\n            primary\n            secondary\n          }\n        }\n        storeGroupClusters {\n          id\n          name\n          hierarchyLevel\n        }\n      }\n      role {\n        id\n        name\n        permissions\n        description\n      }\n      toteSuperAdmin\n      assignedStoreGroups {\n        id\n        name\n        status\n        storeGroupCluster {\n          id\n          name\n          hierarchyLevel\n        }\n        parentStoreGroup {\n          id\n          name\n          status\n          storeGroupCluster {\n            id\n            name\n            hierarchyLevel\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getEmployee {\n    getEmployee {\n      id\n      name\n      email\n      locations {\n        id\n        address {\n          street\n          city\n          state\n          zipCode\n          country\n        }\n        name\n        timeZone\n        status\n        storeNumber\n        locationConfig {\n          cashManagement {\n            tillAmountUpperLimit\n            storeFloatAmount\n          }\n        }\n        storeGroups {\n          id\n          name\n          status\n          storeGroupCluster {\n            id\n            name\n            hierarchyLevel\n          }\n        }\n      }\n      business {\n        id\n        name\n        logo\n        brand\n        featureFlags\n        configurations {\n          colors {\n            primary\n            secondary\n          }\n        }\n        storeGroupClusters {\n          id\n          name\n          hierarchyLevel\n        }\n      }\n      role {\n        id\n        name\n        permissions\n        description\n      }\n      toteSuperAdmin\n      assignedStoreGroups {\n        id\n        name\n        status\n        storeGroupCluster {\n          id\n          name\n          hierarchyLevel\n        }\n        parentStoreGroup {\n          id\n          name\n          status\n          storeGroupCluster {\n            id\n            name\n            hierarchyLevel\n          }\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAllEmployees(\n    $businessId: String!\n    $locationId: String\n    $name: String\n    $email: String\n    $role: String\n    $employeeCode: String\n    $pageIndex: Int\n    $pageSize: Int\n    $fetchAll: Boolean\n    $storeGroupIds: [String!]\n    $locationIds: [String!]\n  ) {\n    getAllEmployees(\n      businessId: $businessId\n      locationId: $locationId\n      name: $name\n      email: $email\n      role: $role\n      employeeCode: $employeeCode\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n      fetchAll: $fetchAll\n      storeGroupIds: $storeGroupIds\n      locationIds: $locationIds\n    ) {\n      employees {\n        id\n        name\n        email\n        phoneNumber\n        employeeCode\n        fullAddress {\n          street\n          city\n          state\n          zipCode\n          country\n        }\n        role {\n          id\n          name\n        }\n        locations {\n          id\n          name\n        }\n        defaultLocation {\n          id\n          name\n        }\n        status\n        assignedStoreGroups {\n          id\n          name\n          status\n          storeGroupCluster {\n            id\n            name\n            hierarchyLevel\n          }\n          parentStoreGroup {\n            id\n            name\n            status\n            storeGroupCluster {\n              id\n              name\n              hierarchyLevel\n            }\n          }\n        }\n      }\n      totalCount\n    }\n  }\n'
): (typeof documents)['\n  query getAllEmployees(\n    $businessId: String!\n    $locationId: String\n    $name: String\n    $email: String\n    $role: String\n    $employeeCode: String\n    $pageIndex: Int\n    $pageSize: Int\n    $fetchAll: Boolean\n    $storeGroupIds: [String!]\n    $locationIds: [String!]\n  ) {\n    getAllEmployees(\n      businessId: $businessId\n      locationId: $locationId\n      name: $name\n      email: $email\n      role: $role\n      employeeCode: $employeeCode\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n      fetchAll: $fetchAll\n      storeGroupIds: $storeGroupIds\n      locationIds: $locationIds\n    ) {\n      employees {\n        id\n        name\n        email\n        phoneNumber\n        employeeCode\n        fullAddress {\n          street\n          city\n          state\n          zipCode\n          country\n        }\n        role {\n          id\n          name\n        }\n        locations {\n          id\n          name\n        }\n        defaultLocation {\n          id\n          name\n        }\n        status\n        assignedStoreGroups {\n          id\n          name\n          status\n          storeGroupCluster {\n            id\n            name\n            hierarchyLevel\n          }\n          parentStoreGroup {\n            id\n            name\n            status\n            storeGroupCluster {\n              id\n              name\n              hierarchyLevel\n            }\n          }\n        }\n      }\n      totalCount\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAuthMethod($emailId: String!, $businessId: String, $token: String) {\n    getAuthMethod(emailId: $emailId, businessId: $businessId, token: $token) {\n      businessId\n      businessName\n      authMethod\n      okta {\n        clientId\n        issuer\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getAuthMethod($emailId: String!, $businessId: String, $token: String) {\n    getAuthMethod(emailId: $emailId, businessId: $businessId, token: $token) {\n      businessId\n      businessName\n      authMethod\n      okta {\n        clientId\n        issuer\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getOktaConfigFromIssuer($issuer: String!, $businessId: String) {\n    getOktaConfigFromIssuer(issuer: $issuer, businessId: $businessId) {\n      businessId\n      businessName\n      authMethod\n      okta {\n        clientId\n        issuer\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getOktaConfigFromIssuer($issuer: String!, $businessId: String) {\n    getOktaConfigFromIssuer(issuer: $issuer, businessId: $businessId) {\n      businessId\n      businessName\n      authMethod\n      okta {\n        clientId\n        issuer\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation sendPasswordSetLink($email: String!, $businessId: String!) {\n    sendPasswordSetLink(email: $email, businessId: $businessId)\n  }\n'
): (typeof documents)['\n  mutation sendPasswordSetLink($email: String!, $businessId: String!) {\n    sendPasswordSetLink(email: $email, businessId: $businessId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query validatePasswordSetToken($token: String!) {\n    validatePasswordSetToken(token: $token)\n  }\n'
): (typeof documents)['\n  query validatePasswordSetToken($token: String!) {\n    validatePasswordSetToken(token: $token)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAdminUser {\n    getAdminUser {\n      id\n      name\n      email\n      phoneNumber\n    }\n  }\n'
): (typeof documents)['\n  query getAdminUser {\n    getAdminUser {\n      id\n      name\n      email\n      phoneNumber\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GenieQueryDashboard(\n    $query: String!\n    $chatSessionId: String!\n    $queryType: String\n  ) {\n    genieQueryDashboard(\n      query: $query\n      chatSessionId: $chatSessionId\n      queryType: $queryType\n    ) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieAnalyticsQuery {\n        data\n        html\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GenieQueryDashboard(\n    $query: String!\n    $chatSessionId: String!\n    $queryType: String\n  ) {\n    genieQueryDashboard(\n      query: $query\n      chatSessionId: $chatSessionId\n      queryType: $queryType\n    ) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieAnalyticsQuery {\n        data\n        html\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FetchGenieMessageDashboard($messageId: String!) {\n    fetchGenieMessageDashboard(messageId: $messageId) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieAnalyticsQuery {\n        data\n        html\n      }\n    }\n  }\n'
): (typeof documents)['\n  query FetchGenieMessageDashboard($messageId: String!) {\n    fetchGenieMessageDashboard(messageId: $messageId) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieAnalyticsQuery {\n        data\n        html\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getInsight(\n    $insightTypes: String!\n    $durationType: String!\n    $storeNumbers: String\n    $locationIds: String\n    $employeeCodes: String\n    $eventTypes: String\n    $duration: String\n  ) {\n    getInsights(\n      insightTypes: $insightTypes\n      durationType: $durationType\n      storeNumbers: $storeNumbers\n      locationIds: $locationIds\n      employeeCodes: $employeeCodes\n      eventTypes: $eventTypes\n      duration: $duration\n    ) {\n      type\n      dashboardId\n      dashboardTitle\n      dashboardSubtitle\n      dashboardBaseUrl\n      dashboardDurationType\n      dashboardHeight\n      dashboardWidth\n      enableDashboard\n      charts {\n        chartId\n        chartBaseUrl\n        chartName\n        category\n        xAxis {\n          name\n          type\n          timeType\n          label\n        }\n        yAxis {\n          name\n          type\n          timeType\n          label\n        }\n        filter\n        enableChart\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getInsight(\n    $insightTypes: String!\n    $durationType: String!\n    $storeNumbers: String\n    $locationIds: String\n    $employeeCodes: String\n    $eventTypes: String\n    $duration: String\n  ) {\n    getInsights(\n      insightTypes: $insightTypes\n      durationType: $durationType\n      storeNumbers: $storeNumbers\n      locationIds: $locationIds\n      employeeCodes: $employeeCodes\n      eventTypes: $eventTypes\n      duration: $duration\n    ) {\n      type\n      dashboardId\n      dashboardTitle\n      dashboardSubtitle\n      dashboardBaseUrl\n      dashboardDurationType\n      dashboardHeight\n      dashboardWidth\n      enableDashboard\n      charts {\n        chartId\n        chartBaseUrl\n        chartName\n        category\n        xAxis {\n          name\n          type\n          timeType\n          label\n        }\n        yAxis {\n          name\n          type\n          timeType\n          label\n        }\n        filter\n        enableChart\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getInsightCharts(\n    $chartCategory: String!\n    $insightType: String!\n    $durationType: String!\n    $storeNumbers: String\n    $locationIds: String\n    $employeeCodes: String\n    $eventTypes: String\n    $duration: String\n  ) {\n    getInsightCharts(\n      chartCategory: $chartCategory\n      insightType: $insightType\n      durationType: $durationType\n      storeNumbers: $storeNumbers\n      locationIds: $locationIds\n      employeeCodes: $employeeCodes\n      eventTypes: $eventTypes\n      duration: $duration\n    ) {\n      charts {\n        category\n        chartId\n        chartBaseUrl\n        chartName\n        enableChart\n        xAxis {\n          label\n          name\n          timeType\n          type\n        }\n        yAxis {\n          label\n          name\n          timeType\n          type\n        }\n        filter\n      }\n      type\n      category\n      durationType\n    }\n  }\n'
): (typeof documents)['\n  query getInsightCharts(\n    $chartCategory: String!\n    $insightType: String!\n    $durationType: String!\n    $storeNumbers: String\n    $locationIds: String\n    $employeeCodes: String\n    $eventTypes: String\n    $duration: String\n  ) {\n    getInsightCharts(\n      chartCategory: $chartCategory\n      insightType: $insightType\n      durationType: $durationType\n      storeNumbers: $storeNumbers\n      locationIds: $locationIds\n      employeeCodes: $employeeCodes\n      eventTypes: $eventTypes\n      duration: $duration\n    ) {\n      charts {\n        category\n        chartId\n        chartBaseUrl\n        chartName\n        enableChart\n        xAxis {\n          label\n          name\n          timeType\n          type\n        }\n        yAxis {\n          label\n          name\n          timeType\n          type\n        }\n        filter\n      }\n      type\n      category\n      durationType\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query fetchLocationInventory(\n    $locationId: String!\n    $description: String!\n    $sku: String!\n    $unitPrice: String!\n    $unitCount: String!\n    $pageIndex: Int\n    $pageSize: Int\n  ) {\n    fetchLocationInventory(\n      locationId: $locationId\n      description: $description\n      sku: $sku\n      unitPrice: $unitPrice\n      unitCount: $unitCount\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n    ) {\n      inventories {\n        id\n        sku\n        description\n        unit\n        unitCount\n        taxable\n        sendEmail\n        taxDetails {\n          taxAmount\n          taxGroup\n          taxRate\n        }\n        pricingDetails {\n          currency\n          currencyMultiplier\n          discount\n          total\n          unitPrice\n        }\n        logoUrl\n        upc\n        categoryName\n        className\n        subclassName\n        skuType\n        ageVerificationRequired\n        requiredMinimumAge\n        recommendedProducts {\n          sku\n          description\n        }\n      }\n      totalPageCount\n      pageIndex\n      pageSize\n    }\n  }\n'
): (typeof documents)['\n  query fetchLocationInventory(\n    $locationId: String!\n    $description: String!\n    $sku: String!\n    $unitPrice: String!\n    $unitCount: String!\n    $pageIndex: Int\n    $pageSize: Int\n  ) {\n    fetchLocationInventory(\n      locationId: $locationId\n      description: $description\n      sku: $sku\n      unitPrice: $unitPrice\n      unitCount: $unitCount\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n    ) {\n      inventories {\n        id\n        sku\n        description\n        unit\n        unitCount\n        taxable\n        sendEmail\n        taxDetails {\n          taxAmount\n          taxGroup\n          taxRate\n        }\n        pricingDetails {\n          currency\n          currencyMultiplier\n          discount\n          total\n          unitPrice\n        }\n        logoUrl\n        upc\n        categoryName\n        className\n        subclassName\n        skuType\n        ageVerificationRequired\n        requiredMinimumAge\n        recommendedProducts {\n          sku\n          description\n        }\n      }\n      totalPageCount\n      pageIndex\n      pageSize\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getInventoryByCommand(\n    $command: String!\n    $locationId: String!\n    $genieVersion: String!\n    $chatSessionId: String\n  ) {\n    getInventoryByCommand(\n      command: $command\n      locationId: $locationId\n      genieVersion: $genieVersion\n      chatSessionId: $chatSessionId\n    ) {\n      products {\n        skuType\n        sku\n        unitPrice\n        unitDiscount\n        unitTaxAmount\n        description\n        unitSubTotal\n        aisleLocation\n        logoUrl\n        promotions {\n          promotionType\n          promotionValue\n          promotionName\n        }\n      }\n      answer\n      resultType\n      query\n      chatSessionId\n      followups\n    }\n  }\n'
): (typeof documents)['\n  query getInventoryByCommand(\n    $command: String!\n    $locationId: String!\n    $genieVersion: String!\n    $chatSessionId: String\n  ) {\n    getInventoryByCommand(\n      command: $command\n      locationId: $locationId\n      genieVersion: $genieVersion\n      chatSessionId: $chatSessionId\n    ) {\n      products {\n        skuType\n        sku\n        unitPrice\n        unitDiscount\n        unitTaxAmount\n        description\n        unitSubTotal\n        aisleLocation\n        logoUrl\n        promotions {\n          promotionType\n          promotionValue\n          promotionName\n        }\n      }\n      answer\n      resultType\n      query\n      chatSessionId\n      followups\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getLocations(\n    $businessId: String!\n    $storeGroupIds: [String!]\n    $locationIds: [String!]\n  ) {\n    getAllLocations(\n      businessId: $businessId\n      storeGroupIds: $storeGroupIds\n      locationIds: $locationIds\n    ) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      notificationEmails\n      status\n      storeGroups {\n        id\n        name\n        status\n        storeGroupCluster {\n          id\n          name\n          hierarchyLevel\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getLocations(\n    $businessId: String!\n    $storeGroupIds: [String!]\n    $locationIds: [String!]\n  ) {\n    getAllLocations(\n      businessId: $businessId\n      storeGroupIds: $storeGroupIds\n      locationIds: $locationIds\n    ) {\n      id\n      name\n      brand\n      address {\n        street\n        city\n        state\n        zipCode\n        country\n      }\n      phoneNumbers {\n        contactPhone\n        supportPhone\n      }\n      description\n      storeNumber\n      operatingHours {\n        day\n        fromHour\n        toHour\n      }\n      timeZone\n      locationConfig {\n        stripe {\n          displayName\n          stripeTerminalLocationId\n        }\n      }\n      notificationEmails\n      status\n      storeGroups {\n        id\n        name\n        status\n        storeGroupCluster {\n          id\n          name\n          hierarchyLevel\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\nquery getLocationsWithGroups($businessId: String!, $status: String) {\n  getAllLocations(businessId: $businessId, status: $status) {\n    id\n    name\n    storeGroups {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n}\n'
): (typeof documents)['\nquery getLocationsWithGroups($businessId: String!, $status: String) {\n  getAllLocations(businessId: $businessId, status: $status) {\n    id\n    name\n    storeGroups {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n}\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getOauthApplications($locationId: String) {\n    getOauthApplications(locationId: $locationId) {\n      applications {\n        appName\n        clientId\n        clientSecret\n        status\n      }\n      locationId\n      message\n      success\n    }\n  }\n'
): (typeof documents)['\n  query getOauthApplications($locationId: String) {\n    getOauthApplications(locationId: $locationId) {\n      applications {\n        appName\n        clientId\n        clientSecret\n        status\n      }\n      locationId\n      message\n      success\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query listOrders(\n    $businessId: String!\n    $locationId: String!\n    $customerId: String\n    $pageIndex: Int\n    $pageSize: Int\n    $storeGroups: [String!]\n    $locationIds: [String!]\n  ) {\n    listOrders(\n      businessId: $businessId\n      locationId: $locationId\n      customerId: $customerId\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n      storeGroups: $storeGroups\n      locationIds: $locationIds\n    ) {\n      totalPageCount\n      orders {\n        id\n        orderNumber\n        amountPaid\n        createdAt\n        paymentStatus\n        totalAmount\n        currency\n        items {\n          sku\n          description\n          quantity\n          taxAmount\n          totalDiscount\n          unitPrice\n          unit\n          total\n        }\n        subscriptionItems {\n          sku\n          description\n          quantity\n          taxAmount\n          totalDiscount\n          unitPrice\n          unit\n          total\n          frequency\n          status\n        }\n        location {\n          id\n          name\n          address {\n            street\n            city\n            state\n          }\n          storeGroups {\n            id\n            name\n            storeGroupCluster {\n              id\n              name\n              hierarchyLevel\n            }\n          }\n        }\n        customer {\n          firstName\n          lastName\n        }\n        employee {\n          id\n          name\n          employeeCode\n        }\n        register\n        orderType\n        fulfillmentStatus\n      }\n    }\n  }\n'
): (typeof documents)['\n  query listOrders(\n    $businessId: String!\n    $locationId: String!\n    $customerId: String\n    $pageIndex: Int\n    $pageSize: Int\n    $storeGroups: [String!]\n    $locationIds: [String!]\n  ) {\n    listOrders(\n      businessId: $businessId\n      locationId: $locationId\n      customerId: $customerId\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n      storeGroups: $storeGroups\n      locationIds: $locationIds\n    ) {\n      totalPageCount\n      orders {\n        id\n        orderNumber\n        amountPaid\n        createdAt\n        paymentStatus\n        totalAmount\n        currency\n        items {\n          sku\n          description\n          quantity\n          taxAmount\n          totalDiscount\n          unitPrice\n          unit\n          total\n        }\n        subscriptionItems {\n          sku\n          description\n          quantity\n          taxAmount\n          totalDiscount\n          unitPrice\n          unit\n          total\n          frequency\n          status\n        }\n        location {\n          id\n          name\n          address {\n            street\n            city\n            state\n          }\n          storeGroups {\n            id\n            name\n            storeGroupCluster {\n              id\n              name\n              hierarchyLevel\n            }\n          }\n        }\n        customer {\n          firstName\n          lastName\n        }\n        employee {\n          id\n          name\n          employeeCode\n        }\n        register\n        orderType\n        fulfillmentStatus\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDeviceInfo($deviceId: String!) {\n    getDeviceInfo(deviceId: $deviceId) {\n      name\n      id\n      status\n      deviceType\n      numberOfErrors\n      timeUsed\n      metadata\n    }\n  }\n'
): (typeof documents)['\n  query getDeviceInfo($deviceId: String!) {\n    getDeviceInfo(deviceId: $deviceId) {\n      name\n      id\n      status\n      deviceType\n      numberOfErrors\n      timeUsed\n      metadata\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDeviceActivities($deviceId: String!) {\n    getDeviceActivities(deviceId: $deviceId) {\n      id\n      time\n      user\n      action\n      description\n    }\n  }\n'
): (typeof documents)['\n  query getDeviceActivities($deviceId: String!) {\n    getDeviceActivities(deviceId: $deviceId) {\n      id\n      time\n      user\n      action\n      description\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getErrorNotifications(\n    $businessId: String!\n    $locationId: String!\n    $live: Boolean\n  ) {\n    getErrorNotifications(\n      businessId: $businessId\n      locationId: $locationId\n      live: $live\n    ) {\n      id\n      activityId\n      lastActive\n      severity\n      deviceName\n      registerName\n      zoneName\n      serialNumber\n      issueDescription\n      action\n      timeNotified\n      timeResolved\n    }\n  }\n'
): (typeof documents)['\n  query getErrorNotifications(\n    $businessId: String!\n    $locationId: String!\n    $live: Boolean\n  ) {\n    getErrorNotifications(\n      businessId: $businessId\n      locationId: $locationId\n      live: $live\n    ) {\n      id\n      activityId\n      lastActive\n      severity\n      deviceName\n      registerName\n      zoneName\n      serialNumber\n      issueDescription\n      action\n      timeNotified\n      timeResolved\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDeviceData($businessId: String!, $locationId: String!) {\n    getDeviceData(businessId: $businessId, locationId: $locationId) {\n      id\n      lastActive\n      deviceName\n      severity\n      registerName\n      zoneName\n      serialNumber\n      issueDescription\n      action\n      deviceType\n    }\n  }\n'
): (typeof documents)['\n  query getDeviceData($businessId: String!, $locationId: String!) {\n    getDeviceData(businessId: $businessId, locationId: $locationId) {\n      id\n      lastActive\n      deviceName\n      severity\n      registerName\n      zoneName\n      serialNumber\n      issueDescription\n      action\n      deviceType\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getBusiness($businessId: String!) {\n    getBusiness(businessId: $businessId) {\n      id\n      name\n      brand\n      logo\n      authMethod\n      featureFlags\n    }\n  }\n'
): (typeof documents)['\n  query getBusiness($businessId: String!) {\n    getBusiness(businessId: $businessId) {\n      id\n      name\n      brand\n      logo\n      authMethod\n      featureFlags\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPeripheralHubNotificationConfig($employeeId: String!) {\n    getPeripheralHubNotificationConfig(employeeId: $employeeId) {\n      enabled\n      severity\n      issue\n      device\n      alert\n      methods {\n        email\n        text\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getPeripheralHubNotificationConfig($employeeId: String!) {\n    getPeripheralHubNotificationConfig(employeeId: $employeeId) {\n      enabled\n      severity\n      issue\n      device\n      alert\n      methods {\n        email\n        text\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getZoneData1($businessId: String!, $locationId: String!) {\n    fetchZones(businessId: $businessId, locationId: $locationId) {\n      id\n      name\n      description\n      registers {\n        id\n      }\n    }\n\n    fetchDevices(businessId: $businessId, locationId: $locationId) {\n      id\n      status\n      deviceName\n      metadata\n    }\n\n    fetchRegisters(businessId: $businessId, locationId: $locationId) {\n      id\n      name\n      devices {\n        id\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getZoneData1($businessId: String!, $locationId: String!) {\n    fetchZones(businessId: $businessId, locationId: $locationId) {\n      id\n      name\n      description\n      registers {\n        id\n      }\n    }\n\n    fetchDevices(businessId: $businessId, locationId: $locationId) {\n      id\n      status\n      deviceName\n      metadata\n    }\n\n    fetchRegisters(businessId: $businessId, locationId: $locationId) {\n      id\n      name\n      devices {\n        id\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query deviceHubVersionMetadata(\n    $businessId: String!\n    $locationId: String!\n    $generateUrl: Boolean!\n  ) {\n    deviceHubVersionMetadata(\n      businessId: $businessId\n      locationId: $locationId\n      generateUrl: $generateUrl\n    ) {\n      currentVersion\n      url\n      hash\n      versions\n      readme {\n        version\n        text\n      }\n    }\n  }\n'
): (typeof documents)['\n  query deviceHubVersionMetadata(\n    $businessId: String!\n    $locationId: String!\n    $generateUrl: Boolean!\n  ) {\n    deviceHubVersionMetadata(\n      businessId: $businessId\n      locationId: $locationId\n      generateUrl: $generateUrl\n    ) {\n      currentVersion\n      url\n      hash\n      versions\n      readme {\n        version\n        text\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation assignCurrentVersion(\n    $businessId: String!\n    $locationId: String!\n    $version: String!\n    $current: Boolean!\n  ) {\n    assignCurrentVersion(\n      businessId: $businessId\n      locationId: $locationId\n      version: $version\n      current: $current\n    )\n  }\n'
): (typeof documents)['\n  mutation assignCurrentVersion(\n    $businessId: String!\n    $locationId: String!\n    $version: String!\n    $current: Boolean!\n  ) {\n    assignCurrentVersion(\n      businessId: $businessId\n      locationId: $locationId\n      version: $version\n      current: $current\n    )\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query allRules($businessId: String!) {\n    allRules(businessId: $businessId) {\n      businessId\n      ruleName\n      ruleType\n      ruleValue\n      applyTo\n      excludeFrom\n      applyToProductGroup\n      excludeFromProductGroup\n    }\n  }\n'
): (typeof documents)['\n  query allRules($businessId: String!) {\n    allRules(businessId: $businessId) {\n      businessId\n      ruleName\n      ruleType\n      ruleValue\n      applyTo\n      excludeFrom\n      applyToProductGroup\n      excludeFromProductGroup\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getRuleTypes {\n    getRuleTypes\n  }\n'
): (typeof documents)['\n  query getRuleTypes {\n    getRuleTypes\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPromotionTypes {\n    getPromotionTypes\n  }\n'
): (typeof documents)['\n  query getPromotionTypes {\n    getPromotionTypes\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProductSkuList {\n    getProductSkuList\n  }\n'
): (typeof documents)['\n  query getProductSkuList {\n    getProductSkuList\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPromotionSchemaTypes {\n    getPromotionSchemaTypes\n  }\n'
): (typeof documents)['\n  query getPromotionSchemaTypes {\n    getPromotionSchemaTypes\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAllPromotions($businessId: String!) {\n    getAllPromotions(businessId: $businessId) {\n      id\n      businessId\n      active\n      canBeCombined\n      description\n      endDate\n      promotionName\n      promotionType\n      startDate\n      promotionValue\n      priority\n      promotionSchema\n      subscriptionType\n      rules {\n        ruleName\n        ruleType\n        ruleValue\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getAllPromotions($businessId: String!) {\n    getAllPromotions(businessId: $businessId) {\n      id\n      businessId\n      active\n      canBeCombined\n      description\n      endDate\n      promotionName\n      promotionType\n      startDate\n      promotionValue\n      priority\n      promotionSchema\n      subscriptionType\n      rules {\n        ruleName\n        ruleType\n        ruleValue\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetTotalsForPromotions(\n    $sku: String!\n    $quantity: Int!\n    $promotionNames: [String!]!\n    $businessId: String!\n  ) {\n    getTotalsForPromotions(\n      sku: $sku\n      quantity: $quantity\n      promotionNames: $promotionNames\n      businessId: $businessId\n    ) {\n      totalAmount\n      promotionAmount\n    }\n  }\n'
): (typeof documents)['\n  query GetTotalsForPromotions(\n    $sku: String!\n    $quantity: Int!\n    $promotionNames: [String!]!\n    $businessId: String!\n  ) {\n    getTotalsForPromotions(\n      sku: $sku\n      quantity: $quantity\n      promotionNames: $promotionNames\n      businessId: $businessId\n    ) {\n      totalAmount\n      promotionAmount\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getInventoryBySkuAndDiscount($sku: String!) {\n    getInventoryBySkuAndDiscount(sku: $sku) {\n      sku\n      unitPrice\n      unitDiscount\n      unitTaxAmount\n      description\n      unitSubTotal\n      aisleLocation\n      logoUrl\n    }\n  }\n'
): (typeof documents)['\n  query getInventoryBySkuAndDiscount($sku: String!) {\n    getInventoryBySkuAndDiscount(sku: $sku) {\n      sku\n      unitPrice\n      unitDiscount\n      unitTaxAmount\n      description\n      unitSubTotal\n      aisleLocation\n      logoUrl\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query cartGuest($cartId: String!) {\n    cartGuest(cartId: $cartId) {\n      id\n      items {\n        sku\n        quantity\n        unitPrice\n        unitDiscount\n        description\n        logoUrl\n        unitSubTotal\n        unitDiscount\n        unitTaxAmount\n        totalTaxAmount\n        totalDiscount\n        total\n      }\n      totalDiscount\n      subTotal\n      totalAmount\n      totalTax\n    }\n  }\n'
): (typeof documents)['\n  query cartGuest($cartId: String!) {\n    cartGuest(cartId: $cartId) {\n      id\n      items {\n        sku\n        quantity\n        unitPrice\n        unitDiscount\n        description\n        logoUrl\n        unitSubTotal\n        unitDiscount\n        unitTaxAmount\n        totalTaxAmount\n        totalDiscount\n        total\n      }\n      totalDiscount\n      subTotal\n      totalAmount\n      totalTax\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAllRoles($businessId: String!) {\n    getAllRoles(businessId: $businessId) {\n      id\n      name\n      description\n      isActive\n      permissions\n      createdAt\n      updatedAt\n    }\n\n    getAllPermissions {\n      displayName\n      name\n      description\n      category\n    }\n  }\n'
): (typeof documents)['\n  query getAllRoles($businessId: String!) {\n    getAllRoles(businessId: $businessId) {\n      id\n      name\n      description\n      isActive\n      permissions\n      createdAt\n      updatedAt\n    }\n\n    getAllPermissions {\n      displayName\n      name\n      description\n      category\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query listSecrets {\n    listSecrets {\n      secretName\n      secretValue\n    }\n  }\n'
): (typeof documents)['\n  query listSecrets {\n    listSecrets {\n      secretName\n      secretValue\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FetchServiceConfigByType(\n    $serviceConfigType: String!\n    $businessId: String\n    $locationId: String\n  ) {\n    serviceConfig(\n      serviceConfigType: $serviceConfigType\n      businessId: $businessId\n      locationId: $locationId\n    ) {\n      type\n      name\n      configs\n      updatedAt\n    }\n  }\n'
): (typeof documents)['\n  query FetchServiceConfigByType(\n    $serviceConfigType: String!\n    $businessId: String\n    $locationId: String\n  ) {\n    serviceConfig(\n      serviceConfigType: $serviceConfigType\n      businessId: $businessId\n      locationId: $locationId\n    ) {\n      type\n      name\n      configs\n      updatedAt\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FetchServiceConfigProviders($category: String!) {\n    serviceConfigProviders(category: $category) {\n      provider\n      metadata {\n        shopifyMetadata {\n          webhookTopicsToSubscribe\n          availableApiVersions\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query FetchServiceConfigProviders($category: String!) {\n    serviceConfigProviders(category: $category) {\n      provider\n      metadata {\n        shopifyMetadata {\n          webhookTopicsToSubscribe\n          availableApiVersions\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FetchServiceConfigsByCategory(\n    $category: String!\n    $businessId: String\n    $locationId: String\n  ) {\n    serviceConfigsByCategory(\n      category: $category\n      businessId: $businessId\n      locationId: $locationId\n    ) {\n      type\n      name\n      configs\n      updatedAt\n    }\n  }\n'
): (typeof documents)['\n  query FetchServiceConfigsByCategory(\n    $category: String!\n    $businessId: String\n    $locationId: String\n  ) {\n    serviceConfigsByCategory(\n      category: $category\n      businessId: $businessId\n      locationId: $locationId\n    ) {\n      type\n      name\n      configs\n      updatedAt\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query StoreGroupClusterStats {\n    storeGroupClusterStats {\n      id\n      storeGroupClusterName\n      activeCount\n      draftCount\n    }\n  }\n'
): (typeof documents)['\n  query StoreGroupClusterStats {\n    storeGroupClusterStats {\n      id\n      storeGroupClusterName\n      activeCount\n      draftCount\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query StoreGroupsByClusterId(\n    $storeGroupClusterId: String!\n    $status: String\n    $fetchOrphanedStoreGroups: Boolean\n  ) {\n    storeGroupsByClusterId(\n      storeGroupClusterId: $storeGroupClusterId\n      status: $status\n      fetchOrphanedStoreGroups: $fetchOrphanedStoreGroups\n    ) {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n'
): (typeof documents)['\n  query StoreGroupsByClusterId(\n    $storeGroupClusterId: String!\n    $status: String\n    $fetchOrphanedStoreGroups: Boolean\n  ) {\n    storeGroupsByClusterId(\n      storeGroupClusterId: $storeGroupClusterId\n      status: $status\n      fetchOrphanedStoreGroups: $fetchOrphanedStoreGroups\n    ) {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ChildStoreGroups($storeGroupId: String!) {\n    childStoreGroups(storeGroupId: $storeGroupId) {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n'
): (typeof documents)['\n  query ChildStoreGroups($storeGroupId: String!) {\n    childStoreGroups(storeGroupId: $storeGroupId) {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query StoreGroups {\n    storeGroups {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n      parentStoreGroup {\n        id\n        name\n        status\n        storeGroupCluster {\n          id\n          name\n          hierarchyLevel\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query StoreGroups {\n    storeGroups {\n      id\n      name\n      status\n      storeGroupCluster {\n        id\n        name\n        hierarchyLevel\n      }\n      parentStoreGroup {\n        id\n        name\n        status\n        storeGroupCluster {\n          id\n          name\n          hierarchyLevel\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ListDeviceTemplates($locationId: String) {\n    listDeviceTemplates(locationId: $locationId)\n  }\n'
): (typeof documents)['\n  query ListDeviceTemplates($locationId: String) {\n    listDeviceTemplates(locationId: $locationId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ListDeviceBitmaps($locationId: String) {\n    listDeviceBitmaps(locationId: $locationId)\n  }\n'
): (typeof documents)['\n  query ListDeviceBitmaps($locationId: String) {\n    listDeviceBitmaps(locationId: $locationId)\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query cashTransactionFailures(\n    $locationId: String!\n    $dateRange: [String!]!\n    $pageIndex: Int\n    $pageSize: Int\n  ) {\n    cashTransactionFailures(\n      locationId: $locationId\n      dateRange: $dateRange\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n    ) {\n      nodes {\n        createdAt\n        error\n        eventId\n        eventType\n        id\n        payload\n      }\n      totalPageCount\n    }\n  }\n'
): (typeof documents)['\n  query cashTransactionFailures(\n    $locationId: String!\n    $dateRange: [String!]!\n    $pageIndex: Int\n    $pageSize: Int\n  ) {\n    cashTransactionFailures(\n      locationId: $locationId\n      dateRange: $dateRange\n      pageIndex: $pageIndex\n      pageSize: $pageSize\n    ) {\n      nodes {\n        createdAt\n        error\n        eventId\n        eventType\n        id\n        payload\n      }\n      totalPageCount\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription cashEventSubscription($locationId: String!) {\n    webCashEvent(locationId: $locationId) {\n      cashEvent\n    }\n  }\n'
): (typeof documents)['\n  subscription cashEventSubscription($locationId: String!) {\n    webCashEvent(locationId: $locationId) {\n      cashEvent\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription transactionEventSubscription(\n    $locationId: String!\n    $businessId: String!\n  ) {\n    webCashEvent(locationId: $locationId, businessId: $businessId) {\n      cashEvent\n    }\n  }\n'
): (typeof documents)['\n  subscription transactionEventSubscription(\n    $locationId: String!\n    $businessId: String!\n  ) {\n    webCashEvent(locationId: $locationId, businessId: $businessId) {\n      cashEvent\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription customerCartSubscriptionEvents($token: String!) {\n    customerCartSubscriptionEvents(token: $token) {\n      flow\n      cart {\n        subscriptionItems {\n          frequency\n          status\n          description\n          sku\n          total\n          quantity\n        }\n        id\n        location {\n          id\n          name\n          business {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  subscription customerCartSubscriptionEvents($token: String!) {\n    customerCartSubscriptionEvents(token: $token) {\n      flow\n      cart {\n        subscriptionItems {\n          frequency\n          status\n          description\n          sku\n          total\n          quantity\n        }\n        id\n        location {\n          id\n          name\n          business {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription genieQueryEventConsumerAgent(\n    $locationId: String\n    $chatSessionId: String\n    $lastReadTimestamp: DateTime\n  ) {\n    genieQueryEventConsumerAgent(\n      locationId: $locationId\n      chatSessionId: $chatSessionId\n      lastReadTimestamp: $lastReadTimestamp\n    ) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieChatQuery {\n        products {\n          skuType\n          sku\n          unitPrice\n          unitDiscount\n          unitTaxAmount\n          description\n          unitSubTotal\n          aisleLocation\n          logoUrl\n          promotions {\n            description\n            promotionType\n            promotionValue\n            promotionName\n            active\n            businessId\n            canBeCombined\n            startDate\n            endDate\n            priority\n            subscriptionType\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  subscription genieQueryEventConsumerAgent(\n    $locationId: String\n    $chatSessionId: String\n    $lastReadTimestamp: DateTime\n  ) {\n    genieQueryEventConsumerAgent(\n      locationId: $locationId\n      chatSessionId: $chatSessionId\n      lastReadTimestamp: $lastReadTimestamp\n    ) {\n      messageId\n      queryType\n      answer\n      query\n      status\n      chatSessionId\n      followups\n      genieChatQuery {\n        products {\n          skuType\n          sku\n          unitPrice\n          unitDiscount\n          unitTaxAmount\n          description\n          unitSubTotal\n          aisleLocation\n          logoUrl\n          promotions {\n            description\n            promotionType\n            promotionValue\n            promotionName\n            active\n            businessId\n            canBeCombined\n            startDate\n            endDate\n            priority\n            subscriptionType\n          }\n        }\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription GenieQueryEventDashboard($chatSessionId: String!) {\n    genieQueryEventDashboard(chatSessionId: $chatSessionId) {\n      answer\n      chatSessionId\n      messageId\n      query\n      status\n      queryType\n      genieAnalyticsQuery {\n        data\n        html\n      }\n    }\n  }\n'
): (typeof documents)['\n  subscription GenieQueryEventDashboard($chatSessionId: String!) {\n    genieQueryEventDashboard(chatSessionId: $chatSessionId) {\n      answer\n      chatSessionId\n      messageId\n      query\n      status\n      queryType\n      genieAnalyticsQuery {\n        data\n        html\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription orderUpdateEventsConsumerAgent(\n    $cartId: String!\n    $businessId: String!\n    $locationId: String!\n  ) {\n    orderUpdateEventsConsumerAgent(\n      cartId: $cartId\n      requestContext: {businessId: $businessId, locationId: $locationId}\n    ) {\n      order {\n        id\n        paymentStatus\n        refundStatus\n        totalAmount\n        currency\n        amountPaid\n        amountRefunded\n      }\n    }\n  }\n'
): (typeof documents)['\n  subscription orderUpdateEventsConsumerAgent(\n    $cartId: String!\n    $businessId: String!\n    $locationId: String!\n  ) {\n    orderUpdateEventsConsumerAgent(\n      cartId: $cartId\n      requestContext: {businessId: $businessId, locationId: $locationId}\n    ) {\n      order {\n        id\n        paymentStatus\n        refundStatus\n        totalAmount\n        currency\n        amountPaid\n        amountRefunded\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription orderUpdateEventsDashboard {\n    orderUpdateEventsDashboard {\n      order {\n        id\n        orderNumber\n        amountPaid\n        createdAt\n        paymentStatus\n        totalAmount\n        currency\n        items {\n          sku\n          description\n          quantity\n          taxAmount\n          totalDiscount\n          unitPrice\n          unit\n          total\n        }\n        subscriptionItems {\n          sku\n          description\n          quantity\n          taxAmount\n          totalDiscount\n          unitPrice\n          unit\n          total\n          frequency\n          status\n        }\n        location {\n          id\n          name\n          address {\n            street\n            city\n            state\n          }\n          storeGroups {\n            id\n            name\n            storeGroupCluster {\n              id\n              name\n              hierarchyLevel\n            }\n          }\n        }\n        customer {\n          firstName\n          lastName\n        }\n        employee {\n          id\n          name\n          employeeCode\n        }\n        register\n        orderType\n        fulfillmentStatus\n      }\n    }\n  }\n'
): (typeof documents)['\n  subscription orderUpdateEventsDashboard {\n    orderUpdateEventsDashboard {\n      order {\n        id\n        orderNumber\n        amountPaid\n        createdAt\n        paymentStatus\n        totalAmount\n        currency\n        items {\n          sku\n          description\n          quantity\n          taxAmount\n          totalDiscount\n          unitPrice\n          unit\n          total\n        }\n        subscriptionItems {\n          sku\n          description\n          quantity\n          taxAmount\n          totalDiscount\n          unitPrice\n          unit\n          total\n          frequency\n          status\n        }\n        location {\n          id\n          name\n          address {\n            street\n            city\n            state\n          }\n          storeGroups {\n            id\n            name\n            storeGroupCluster {\n              id\n              name\n              hierarchyLevel\n            }\n          }\n        }\n        customer {\n          firstName\n          lastName\n        }\n        employee {\n          id\n          name\n          employeeCode\n        }\n        register\n        orderType\n        fulfillmentStatus\n      }\n    }\n  }\n']

export function graphql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never
