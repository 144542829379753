import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, Grid, IconButton, Switch, Tooltip} from '@mui/material'
import StyledButton from 'ui/atoms/StyledButton'
import StyledTable from 'ui/molecules/StyledTable'
import {LocationStatus} from 'constants/constants'
import {Permission} from 'constants/permissions'
import useNotify from 'hooks/useNotify'
import usePermission from 'hooks/usePermission'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useAppSelector} from 'adminPanel/adminPanel.store'
import {selectBusinessAndLocation} from 'clientDashboard/employee.selectors'
import LocationActivationConfirmationModal from 'clientDashboard/settings/locations/LocationActivationConfirmationModal'
import EditLocationModal from 'clientDashboard/settings/locations/EditLocationModal'
import _filter from 'lodash/filter'
import {omitTypenameDeep} from 'utils/common.utils'
import useLocationColumns from 'clientDashboard/settings/locations/useLocationColumns'
import useLocationApis from 'clientDashboard/settings/locations/useLocationApis'
import {selectLocations} from 'clientDashboard/settings/locations/locations.selectors'

function LocationManager() {
  const notify = useNotify()

  const {t} = useTranslation()

  const columns = useLocationColumns()
  const [editLocationModalData, setEditLocationModalData] = useState<any>(null)
  const [locationActivationModalData, setLocationActivationModalData] =
    useState<any>(null)
  const {
    loadingLocations,
    _addLocation,
    _updateLocation,
    _deactivateLocation,
    _activateLocation
  } = useLocationApis()

  const hasCreateLocationPermission = usePermission(Permission.CREATE_LOCATION)
  const hasFullAccess = usePermission(Permission.FULL_ACCESS_LOCATION)

  const {businessId, locationId} = useAppSelector(selectBusinessAndLocation)
  const locationData = useAppSelector(selectLocations) || []

  const [
    activateOrDeactivateTooltipTitle,
    setActivateOrDeactivateTooltipTitle
  ] = useState('Deactivate')

  const addOrUpdateLocation = (data: any) => {
    const {id, operatingHours, locationConfig, ...rest} = data || {}
    const workingHours = _filter(
      operatingHours,
      (timing: any) => timing && timing.fromHour && timing.toHour
    )
    if (id) {
      _updateLocation({
        variables: {
          locationId: id,
          location: {
            operatingHours: workingHours,
            business: businessId,
            ...rest
          }
        }
      })
    } else {
      _addLocation({
        variables: {
          location: {
            operatingHours: workingHours,
            business: businessId,
            ...rest
          }
        }
      })
    }
  }

  const toggleLocationActivation = () => {
    const isLocationActive =
      locationActivationModalData.status === LocationStatus.ACTIVE
    const payload = {
      variables: {
        locationId: locationActivationModalData.id
      }
    }
    const updateMethod = isLocationActive
      ? _deactivateLocation
      : _activateLocation
    updateMethod(payload)
  }

  return (
    <Grid>
      <StyledTable
        columns={columns}
        data={locationData}
        state={{
          showSkeletons: loadingLocations,
          showProgressBars: loadingLocations
        }}
        initialState={{
          columnPinning: {
            right: ['mrt-row-actions']
          },
          columnVisibility: {
            status: false
          },
          sorting: [
            {
              id: 'status',
              desc: false
            }
          ]
        }}
        enableRowActions
        renderTopToolbarCustomActions={() => {
          return (
            <Grid
              style={{
                order: 1
              }}
            >
              <StyledButton
                disabled={!hasCreateLocationPermission || !hasFullAccess}
                onClick={() => {
                  setEditLocationModalData({})
                }}
              >
                {t('location-management.add-location')}
              </StyledButton>
            </Grid>
          )
        }}
        renderRowActions={({row}) => (
          <Box>
            <IconButton
              disabled={!hasFullAccess}
              onClick={() => {
                setEditLocationModalData(omitTypenameDeep(row.original))
              }}
            >
              <Tooltip title={t('location-management.edit')}>
                <FontAwesomeIcon
                  icon={icon({
                    name: 'edit',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  color='black'
                  style={{
                    borderWidth: '1px'
                  }}
                />
              </Tooltip>
            </IconButton>
            <IconButton
              disabled={!hasFullAccess}
              onMouseEnter={() => {
                setActivateOrDeactivateTooltipTitle(
                  row.original.status === LocationStatus.ACTIVE
                    ? 'Deactivate'
                    : 'Activate'
                )
              }}
              onClick={() => {
                if (row.original.id === locationId) {
                  notify.show(
                    t(
                      'location-management.deactivate-current-location-error-message'
                    ),
                    'warning'
                  )
                  return
                }
                setLocationActivationModalData(row.original)
              }}
            >
              <Tooltip title={activateOrDeactivateTooltipTitle}>
                <Switch
                  checked={row.original.status === LocationStatus.ACTIVE}
                  disabled={row.original.id === locationId}
                />
              </Tooltip>
            </IconButton>
          </Box>
        )}
      />

      {editLocationModalData && (
        <EditLocationModal
          editLocationModalData={editLocationModalData}
          onSubmit={(data: any) => {
            addOrUpdateLocation(data)
            setTimeout(() => {
              setEditLocationModalData(null)
            })
          }}
          onClose={() => {
            setEditLocationModalData(null)
          }}
        />
      )}

      {locationActivationModalData && (
        <LocationActivationConfirmationModal
          isLocationActive={
            locationActivationModalData.status === LocationStatus.ACTIVE
          }
          storeNumber={locationActivationModalData.storeNumber}
          onClose={() => setLocationActivationModalData(null)}
          onConfirm={() => {
            toggleLocationActivation()
            setTimeout(() => {
              setLocationActivationModalData(null)
            })
          }}
        />
      )}
    </Grid>
  )
}

export default LocationManager
