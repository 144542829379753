import {
  currencyList,
  CurrencyOptionsMap,
  IsTaxableOptions,
  IsTaxableOptionsMap,
  taxableList,
  unitTypeList,
  UnitTypeOptionsMap
} from 'clientDashboard/inventoryManagement/inventory.constants'
import ImageGrid from 'ui/organisms/FormBuilder/customFields/ImageGrid'
import {Field, FieldType} from 'ui/organisms/FormBuilder/FormBuilder'
import {getSelectOptions} from 'utils/array.utils'
import _keyBy from 'lodash/keyBy'
import {UseFormReturn} from 'react-hook-form'
import {t} from 'app/i18n'
import {Tooltip} from '@mui/material'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {space} from 'config/theme'

export interface IFormInput {
  sku: string
  skuType: string
  description: string
  unit: string
  unitCount: number
  upc: string
  categoryName: string
  className: string
  subclassName: string
  taxable: string
  taxGroup: string
  logoUrl: string
  taxDetails: {
    taxGroup: string
    taxRate: string
  }
  pricingDetails: {
    unitPrice: number
    currency: string
    discount: number
    currencyMultiplier: number
  }
  recommendedProducts: string
  sendEmail: boolean
  ageVerificationRequired: boolean
  requiredMinimumAge: string
}

type SaveInventoryModalFields = {
  formContext: UseFormReturn<any>
  skus: string[]
  saveInventoryModalData: any
}

const fields: Field[] = [
  {
    id: 'detailsHeader',
    label: t('inventory.form.details'),
    type: FieldType.SectionHeader
  },
  {
    id: 'sku',
    label: t('inventory.form.sku'),
    type: FieldType.Text,
    required: true
  },
  {
    id: 'skuType',
    label: t('inventory.form.sku-type'),
    type: FieldType.Text,
    required: false
  },
  {
    id: 'description',
    label: t('inventory.form.description'),
    type: FieldType.Text,
    required: true,
    rules: {
      maxLength: {
        value: 256,
        message: t('inventory.form.descrption-length-exceeds')
      }
    }
  },
  {
    id: 'unit',
    label: t('inventory.form.unit-type'),
    type: FieldType.Select,
    required: true,
    options: getSelectOptions(unitTypeList)
  },
  {
    id: 'unitCount',
    label: t('inventory.form.unit-count'),
    type: FieldType.Number,
    required: true,
    rules: {
      min: {
        value: 0,
        message: t('inventory.unit-count-cant-negative')
      }
    }
  },
  {
    id: 'upc',
    label: t('inventory.form.upc'),
    type: FieldType.Text,
    required: false
  },
  {
    id: 'categoryName',
    label: t('inventory.form.category-name'),
    type: FieldType.Text,
    required: false
  },
  {
    id: 'className',
    label: t('inventory.form.class-name'),
    type: FieldType.Text,
    required: false
  },
  {
    id: 'subclassName',
    label: t('inventory.form.subclass-name'),
    type: FieldType.Text,
    required: false
  },
  {
    id: 'taxable',
    label: t('inventory.form.is-taxable'),
    type: FieldType.Select,
    options: getSelectOptions(taxableList),
    required: false
  },
  {
    id: 'taxDetails',
    label: t('inventory.form.tax-details'),
    type: FieldType.SectionHeader
  },
  {
    id: 'taxDetails.taxGroup',
    label: t('inventory.form.tax-group'),
    type: FieldType.Text,
    required: false
  },
  {
    id: 'taxDetails.taxRate',
    label: t('inventory.form.tax-rate'),
    type: FieldType.Number,
    required: false,
    rules: {
      min: {
        value: 0,
        message: t('inventory.tax-rate-cant-negative')
      }
    }
  },
  {
    id: 'pricingDetails',
    label: t('inventory.form.pricing-details'),
    type: FieldType.SectionHeader
  },
  {
    id: 'pricingDetails.unitPrice',
    label: t('inventory.form.unit-price'),
    type: FieldType.Number,
    required: true,
    rules: {
      min: {
        value: 0,
        message: t('inventory.unit-price-cant-negative')
      }
    }
  },
  {
    id: 'pricingDetails.currency',
    label: t('inventory.form.currency'),
    type: FieldType.Select,
    options: getSelectOptions(currencyList)
  },
  {
    id: 'pricingDetails.discount',
    label: t('inventory.form.discount'),
    type: FieldType.Number,
    rules: {
      min: {
        value: 0,
        message: t('inventory.discount-cant-negative')
      }
    }
  },
  {
    id: 'miscellaneous',
    label: t('inventory.form.miscellaneous'),
    type: FieldType.SectionHeader
  },
  {
    id: 'recommendedProducts',
    label: t('inventory.form.recommended-skus'),
    type: FieldType.Text,
    helperText: t('inventory.form.recommended-skus-placeholder')
  },
  {
    id: 'sendEmail',
    type: FieldType.Switch,
    span: 12,
    label: (
      <span>
        {t('inventory.form.send-email')}
        <span style={{marginLeft: space.XS}}>
          <Tooltip
            title={t('inventory.send-email-helper-text')}
            placement='right'
          >
            <FontAwesomeIcon
              icon={icon({
                name: 'info-circle',
                family: 'classic',
                style: 'regular'
              })}
              fontSize={14}
            />
          </Tooltip>
        </span>
      </span>
    )
  },
  {
    id: 'ageVerificationRequired',
    label: t('inventory.form.age-verification-required'),
    type: FieldType.Switch,
    span: 6
  },
  {
    id: 'requiredMinimumAge',
    label: t('inventory.form.required-minimum-age'),
    type: FieldType.Text,
    span: 6,
    required: true
  },
  {
    id: 'imagesHeader',
    label: t('inventory.form.images'),
    type: FieldType.SectionHeader
  },
  {
    id: 'logoUrl',
    label: t('inventory.form.main-image-url'),
    type: FieldType.Text,
    required: true
  },
  {
    id: 'imageLoader',
    fieldComponent: ImageGrid
  }
]

const fieldsById = _keyBy(fields, 'id')

export const getSaveInventoryModalFields = ({
  formContext,
  skus,
  saveInventoryModalData
}: SaveInventoryModalFields) => {
  fieldsById['sku'].rules = {
    validate: (value: string) => {
      if (saveInventoryModalData.sku !== value && skus.includes(value)) {
        return t('inventory.sku-already-exists')
      }
      return true
    }
  }

  fieldsById['sku'].onChange = () => {
    formContext.trigger('sku')
  }
  fieldsById['sku'].isDisabled = !!saveInventoryModalData.id

  const isTaxDetailsVisible = (values: any) => {
    return values?.taxable === IsTaxableOptionsMap[IsTaxableOptions.YES]
  }
  fieldsById['taxDetails'].activator = isTaxDetailsVisible
  fieldsById['taxDetails.taxRate'].activator = isTaxDetailsVisible
  fieldsById['taxDetails.taxGroup'].activator = isTaxDetailsVisible

  const isAgeVerificationVisible = (values: any) => {
    return values?.ageVerificationRequired === true
  }
  fieldsById['requiredMinimumAge'].activator = isAgeVerificationVisible
  fieldsById['requiredMinimumAge'].value = () => {
    const requiredMinimumAge = formContext.getValues('requiredMinimumAge')
    return requiredMinimumAge || '18'
  }

  fieldsById['imageLoader'].activator = (values: any) => {
    return values?.logoUrl
  }
  fieldsById['imageLoader'].image = {
    url: formContext.getValues('logoUrl'),
    id: formContext.getValues('sku'),
    title: ''
  }

  return fields
}
