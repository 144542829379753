import {useEffect, useState} from 'react'
import {LocationType} from 'graphql/generatedTypes/graphql'
import {useLazyQuery, useMutation} from '@apollo/client'
import {GET_LOCATIONS} from 'graphql/queries/location.queries'
import {
  ACTIVATE_LOCATION,
  ADD_LOCATION,
  DEACTIVATE_LOCATION,
  UPDATE_LOCATION
} from 'graphql/mutations/location.mutation'
import useNotify from 'hooks/useNotify'
import {useTranslation} from 'react-i18next'
import useEmployee from 'hooks/useEmployee'
import {useLocation} from 'react-router-dom'
import {useAppSelector} from 'adminPanel/adminPanel.store'
import {selectAppliedLocationGroups, selectBusinessAndLocation} from 'clientDashboard/employee.selectors'
import {useAppDispatch} from 'clientDashboard/clientDashboard.store'
import {setLocations} from 'clientDashboard/settings/locations/locations.slice'
import {selectLocations} from 'clientDashboard/settings/locations/locations.selectors'
import _isEqual from 'lodash/isEqual'


const useLocationApis = () => {
  const location = useLocation()
  const passedData = location.state
    ? JSON.parse(JSON.stringify(location.state))
    : null
  const navigatedSelectedStoreGroupIds =
    passedData?.selectedStoreGroupIds || null

  const notify = useNotify()
  const {t} = useTranslation()
  const {getEmployee} = useEmployee()
  const dispatch = useAppDispatch()
  const {businessId} = useAppSelector(selectBusinessAndLocation)
  const appliedLocationsGroups = useAppSelector(selectAppliedLocationGroups)

  const [locationData, setLocationData] = useState<LocationType[]>([])
  const currentLocationData = useAppSelector(selectLocations) || []

  const [getLocations, {loading: loadingLocations}] =
    useLazyQuery(GET_LOCATIONS, {
      onCompleted: (data) => {
        setLocationData(data.getAllLocations as LocationType[])
      },
    })

  const [_addLocation] = useMutation(ADD_LOCATION, {
    onCompleted: (data) => {
      setLocationData((prev: any) => {
        return [...prev, data.addLocation]
      })
    }
  })

  const [_updateLocation] = useMutation(UPDATE_LOCATION, {
    onCompleted: (data) => {
      setLocationData((prev: any) => {
        return prev.map((location: any) => {
          if (location.id === data.updateLocation.id) {
            return data.updateLocation
          }
          return location
        })
      })
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const [_deactivateLocation] = useMutation(DEACTIVATE_LOCATION, {
    onCompleted: () => {
      notify.show(
        t('location-management.location-deactivated-successfully'),
        'success'
      )
      fetchAllLocations()
      getEmployee()
    },
    onError: (error) => {
      console.log(error)
      notify.show(t('location-management.location-deactivation-error'), 'error')
    }
  })

  const [_activateLocation] = useMutation(ACTIVATE_LOCATION, {
    onCompleted: () => {
      notify.show(
        t('location-management.location-activated-successfully'),
        'success'
      )
      fetchAllLocations()
      getEmployee()
    },
    onError: (error) => {
      console.log(error)
      notify.show(t('location-management.location-activation-error'), 'error')
    }
  })

  const fetchAllLocations = () => {
    getLocations({
      variables: {
        businessId: businessId,
        storeGroupIds: navigatedSelectedStoreGroupIds
      }
    })
  }

  useEffect(() => {
    fetchAllLocations()
  }, [appliedLocationsGroups])

  useEffect(() => {
    if (!loadingLocations && !_isEqual(locationData, currentLocationData)) {
      dispatch(setLocations(locationData))
    }
  }, [locationData, loadingLocations, currentLocationData])

  return {
    loadingLocations,
    _addLocation,
    _updateLocation,
    _deactivateLocation,
    _activateLocation
  }

}


export default useLocationApis;
