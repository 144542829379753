import {Box, Grid, Tab, Tabs, Typography} from '@mui/material'
import DeviceTemplates from 'clientDashboard/settings/templates/DeviceTemplates'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

const TabPanel = (props: any) => {
  const {children, value, index, ...other} = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const Templates = () => {
  const {t} = useTranslation()
  const [selectedTab, setSelectedTab] = useState(1)
  const handleChange = (event: any, newValue: any) => {
    setSelectedTab(newValue)
  }

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label='template tabs'
        >
          <Tab label={t('templates.email-templates')} />
          <Tab label={t('templates.device-templates')} />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index={0}>
        <Grid container spacing={2}>
          {t('feature-not-implemented-yet')}
        </Grid>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <DeviceTemplates />
      </TabPanel>
    </Box>
  )
}

export default Templates
