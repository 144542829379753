import {useMutation} from '@apollo/client'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import {COPY_NFC_CART_TO_MPOS} from 'graphql/mutations/cart.mutations'
import useNotify from 'hooks/useNotify'
import {t} from 'i18next'
import {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useAppSelector} from 'consumerAgent/consumerAgent.store'
import useConsumerAgent from 'consumerAgent/useConsumerAgent'
import {selectCartId} from 'consumerAgent/cart.selector'

type RouteParams = {
  cartId: string
  locationId: string
}

function ConsumerAgentCartMerge() {
  useConsumerAgent()
  const notify = useNotify()
  const history = useHistory()
  const consumerAgentCartId = useAppSelector(selectCartId)

  const {cartId: mposCartId, locationId} = useParams<RouteParams>()
  const [isTransferred, setIsTransferred] = useState(false)

  const [transferCart, {loading: isTransferring, error: mergeCartError}] =
    useMutation(COPY_NFC_CART_TO_MPOS, {
      onCompleted: () => {
        notify.show(
          t('customer-management.success-you-will-be-redirected-now'),
          'success'
        )
        setIsTransferred(true)
        setTimeout(() => {
          history.push('/customers/location/' + locationId + '/consumer-agent')
        }, 3000)
      },
      onError: (error) => {
        notify.show(error.message, 'error')
      }
    })

  useEffect(() => {
    if (consumerAgentCartId && mposCartId) {
      transferCart({
        variables: {
          mposCartId,
          nfcCartId: consumerAgentCartId,
          locationId
        }
      })
    }
  }, [mposCartId, consumerAgentCartId])

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      height='100dvh'
    >
      <Typography variant='h4'>Merging Cart</Typography>
      {isTransferring ? (
        <CircularProgress />
      ) : mergeCartError ? (
        <Typography variant='h6' color='error'>
          Error merging cart: {mergeCartError.message}
        </Typography>
      ) : isTransferred ? (
        <Typography>
          {t('customer-management.thanks-for-shopping-with-us')}
        </Typography>
      ) : null}
    </Box>
  )
}

export default ConsumerAgentCartMerge
