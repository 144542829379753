import {Card, CardContent, CardMedia, Grid, Typography} from '@mui/material'
import {TextFieldElementProps} from 'react-hook-form-mui'
import cx from 'clsx'

export type ImageProp = {
  id: string
  url: string
  title: string
}

type FieldProps = TextFieldElementProps & {image: ImageProp}

const ImageGrid = (props: FieldProps) => {
  const {image, className} = props
  return (
    <div className={cx('flex items-center', className)}>
      <Grid item xs={12} sm={6} md={4} key={image.id}>
        <Card>
          <CardMedia component='img' image={image.url} alt={image.title} />
          {image.title && (
            <CardContent>
              <Typography variant='h5' component='h2'>
                {image.title}
              </Typography>
            </CardContent>
          )}
        </Card>
      </Grid>
    </div>
  )
}

export default ImageGrid
