import React from 'react'
import {Grid} from '@mui/material'
import RegisterTab from 'clientDashboard/settings/cashManagementSettings/RegisterTab'
import TillTab from 'clientDashboard/settings/cashManagementSettings/TillTab'

function CashSettingManager() {
    const [selectedTab, setSelectedTab] = React.useState('register')
    
    return (
      <>
        <Grid item xs={12}>
          {selectedTab === 'register' && <RegisterTab  setSelectedTab={setSelectedTab} />}
          {selectedTab === 'till' && <TillTab setSelectedTab={setSelectedTab} />}
        </Grid>
      </>
    )
  }
  
export default CashSettingManager
