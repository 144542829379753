import cx from 'clsx'


const SectionHeader = (props: any) => {
  const { title, className } = props  || {}
  return (
    <div className={cx('text-h4 font-semibold -mb-1 mt-3', className)}>
      {title}
    </div>
  )
}

export default SectionHeader;
