import {forwardRef} from 'react'
import {SwitchElement, SwitchElementProps} from 'react-hook-form-mui'

const SwitchElementField = forwardRef(
  (props: SwitchElementProps & {className?: string, elementClassName?: string}, ref: any) => {
    const {className, elementClassName, ...rest} = props
    return (
      <div className={className}>
        <SwitchElement
          ref={ref}
          className={elementClassName}
          {...rest}
        />
      </div>
    )
  }
)

export default SwitchElementField
