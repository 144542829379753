import {ChangeEvent} from 'react'
import {useController} from 'react-hook-form'
import {TextField} from '@mui/material'
import {TextFieldElementProps} from 'react-hook-form-mui'
import cx from 'clsx'
import {useTranslation} from 'react-i18next'
import _lowerCase from 'lodash/lowerCase'
import _capitalize from 'lodash/capitalize'

type Value = {
  day: string
  fromHour: string
  toHour: string
}

const OpeningHoursField = (props: TextFieldElementProps) => {
  const {control, name, label, className} = props
  const {field} = useController({
    control,
    name
  })
  const {t} = useTranslation()

  const day = label as string

  const onTimeChange = (key: keyof Value, e: ChangeEvent<HTMLInputElement>) => {
    const timingCopy = {
      day: _lowerCase(day),
      fromHour: field.value?.fromHour,
      toHour: field.value?.toHour,
      [key]: e.target.value
    }

    // send data to react hook form
    field.onChange(timingCopy)
  }

  return (
    <div className={cx('flex items-center', className)}>
      <span className='mr-auto'>{_capitalize(day)}</span>
      <TextField
        className={'w-44 mr-4'}
        type='time'
        label={t('location-management.from-hour')}
        InputLabelProps={{
          shrink: true
        }}
        onChange={(e) => onTimeChange('fromHour', e as any)}
        value={field.value?.fromHour}
      />

      <TextField
        className={'w-44'}
        type='time'
        label={t('location-management.to-hour')}
        InputLabelProps={{
          shrink: true
        }}
        onChange={(e) => onTimeChange('toHour', e as any)}
        value={field.value?.toHour}
      />
    </div>
  )
}

export default OpeningHoursField
