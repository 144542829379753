import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Grid} from '@mui/material'
import theme, {colors, space} from 'config/theme'
import {selectCartData} from 'consumerAgent/cart.selector'
import {useAppDispatch, useAppSelector} from 'consumerAgent/consumerAgent.store'
import {
  selectPaymentIntentClientSecret,
  selectSelectedPaymentMethod
} from 'consumerAgent/payment.selector'
import {
  createPaymentIntent,
  setSelectedPaymentMethod
} from 'consumerAgent/payment.slice'
import {setIsBottomDrawerOpen} from 'consumerAgent/ui.slice'
import useNotify from 'hooks/useNotify'

export enum ConsumerAgentPaymentMethods {
  CASH = 'CASH',
  CARD = 'CARD',
  APPLE_PAY = 'APPLE_PAY'
}

const ConsumerAgentPayments = () => {
  const notify = useNotify()
  const dispatch = useAppDispatch()
  const selectedPaymentMethod = useAppSelector(selectSelectedPaymentMethod)
  const paymentIntentClientSecret = useAppSelector(
    selectPaymentIntentClientSecret
  )
  const cartData = useAppSelector(selectCartData)

  return (
    <Grid
      gap={4}
      width='100%'
      style={{
        padding: `0 ${space.SMALL}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
      }}
    >
      <Grid
        item
        container
        xs={12}
        style={{
          display: 'flex',
          padding: space.XS,
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexGrow: 1,
          alignItems: 'center',
          borderRadius: '8px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.20)'
        }}
      >
        <Grid
          item
          xs={3.5}
          onClick={() => {
            dispatch(setSelectedPaymentMethod(ConsumerAgentPaymentMethods.CASH))
            dispatch(setIsBottomDrawerOpen(true))
          }}
          style={{
            cursor: 'pointer',
            padding: space.SMALL,
            borderRadius: '6px',
            backgroundColor: 'white',
            textAlign: 'center',
            border: '1px solid',
            borderColor:
              selectedPaymentMethod === ConsumerAgentPaymentMethods.CASH
                ? theme.palette.primary.main
                : colors.grayBorder
          }}
        >
          In-Store
        </Grid>
        <Grid
          item
          xs={3.5}
          onClick={() => {
            dispatch(setSelectedPaymentMethod(ConsumerAgentPaymentMethods.CARD))
            dispatch(setIsBottomDrawerOpen(true))
            !paymentIntentClientSecret &&
              dispatch(createPaymentIntent(cartData?.totalAmount || 0))
            // notify.show('Feature not available yet', 'warning')
          }}
          style={{
            cursor: 'pointer',
            padding: space.SMALL,
            borderRadius: '6px',
            backgroundColor: 'white',
            textAlign: 'center',
            border: '1px solid',
            borderColor:
              selectedPaymentMethod === ConsumerAgentPaymentMethods.CARD
                ? theme.palette.primary.main
                : colors.grayBorder
          }}
        >
          <FontAwesomeIcon
            style={{
              fontSize: '14px',
              marginRight: space.XXS
            }}
            icon={icon({
              name: 'credit-card',
              family: 'classic',
              style: 'solid'
            })}
          />
          Card
        </Grid>
        <Grid
          item
          xs={3.5}
          onClick={() => {
            // setSelectedPaymentMethod(ConsumerAgentPaymentMethods.APPLE_PAY)
            // setIsBottomDrawerOpen(true)
            notify.show('Feature not available yet', 'warning')
          }}
          style={{
            cursor: 'pointer',
            padding: space.SMALL,
            borderRadius: '6px',
            backgroundColor: 'white',
            textAlign: 'center',
            border: '1px solid',
            borderColor:
              selectedPaymentMethod === ConsumerAgentPaymentMethods.APPLE_PAY
                ? theme.palette.primary.main
                : colors.grayBorder
          }}
        >
          <FontAwesomeIcon
            style={{
              fontSize: '20px',
              marginRight: space.XXS
            }}
            icon={icon({
              name: 'apple',
              family: 'classic',
              style: 'brands'
            })}
          />
          Pay
        </Grid>
      </Grid>
    </Grid>
  )
}
export default ConsumerAgentPayments
