import {useLazyQuery, useMutation} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  Grid,
  ListItemIcon,
  MenuItem,
  Typography
} from '@mui/material'
import StyledButton from 'ui/atoms/StyledButton'
import StyledTable from 'ui/molecules/StyledTable'
import {Permission} from 'constants/permissions'
import {CustomerType, PreferenceType} from 'graphql/generatedTypes/graphql'
import {
  ADD_CUSTOMER,
  UPDATE_CUSTOMER
} from 'graphql/mutations/customer.mutation'
import {FETCH_CUSTOMERS} from 'graphql/queries/customers.queries'
import useNotify from 'hooks/useNotify'
import usePermission from 'hooks/usePermission'
import {MRT_ColumnDef} from 'material-react-table'
import {useMemo} from 'react'
import {useEffect, useState} from 'react'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {convertToLocalTimeString, stringAvatar} from 'utils/common'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {
  selectBusinessId,
  selectLocationId
} from 'clientDashboard/employee.selectors'
import SaveCustomerProfileModal from 'clientDashboard/customers/SaveCustomerProfileModal'
import {omitTypenameDeep} from 'utils/common.utils'

function Customers() {
  const notify = useNotify()

  const [data, setData] = useState<CustomerType[]>([])
  const businessId = useAppSelector(selectBusinessId)
  const locationId = useAppSelector(selectLocationId)
  const history = useHistory()
  const [saveCustomerModalData, setSaveCustomerModalData] = useState<any>(null)

  const hasCreateCustomerAccess = usePermission(
    Permission.CREATE_AND_UPDATE_CUSTOMER
  )

  const [getCustomers, {data: customersData, loading: loadingCustomers}] =
    useLazyQuery(FETCH_CUSTOMERS, {
      fetchPolicy: 'no-cache'
    })

  const [_addCustomer] = useMutation(ADD_CUSTOMER, {
    onCompleted: () => {
      fetchAllCustomers()
      setSaveCustomerModalData(null)
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const [_updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: () => {
      fetchAllCustomers()
      setSaveCustomerModalData(null)
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const saveCustomer = (data: any) => {
    let isEditMode = data.id ? true : false
    const {
      id,
      firstName,
      lastName,
      email,
      phone,
      gender,
      address,
      preferences
    } = data
    const customerPreferences = preferences
      ?.filter(
        (customerPre: any): customerPre is PreferenceType =>
          customerPre !== null
      )
      .map((customerPre: PreferenceType) => {
        return {
          title: customerPre.title as string,
          value: customerPre.value as string[],
          key: customerPre.key as string
        }
      })

    const customerData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      gender: gender,
      address: {
        street: address.street,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        country: address.country
      },
      preferences: customerPreferences,
      business: businessId
    }
    if (isEditMode) {
      _updateCustomer({
        variables: {
          customerId: id,
          customer: customerData
        }
      })
    } else {
      _addCustomer({
        variables: {
          customer: {
            ...customerData
          }
        }
      })
    }
  }

  const fetchAllCustomers = () => {
    getCustomers({
      variables: {
        businessId: businessId as string,
        locationId: locationId as string
      }
    })
  }

  useEffect(() => {
    fetchAllCustomers()
  }, [])

  useEffect(() => {
    if (customersData) {
      setData(customersData.listCustomers as CustomerType[])
    }
  }, [customersData])

  const columns = useMemo<MRT_ColumnDef<CustomerType>[]>(
    () => [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        id: 'name',
        header: 'Name',
        size: 250,
        Cell: ({renderedCellValue, row}) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem'
            }}
          >
            {row.original.avatar ? (
              <Avatar alt='avatar' src={row.original.avatar} />
            ) : (
              <Avatar
                {...stringAvatar(
                  `${row.original.firstName} ${row.original.lastName}`
                )}
              />
            )}
            <span>{renderedCellValue}</span>
          </Box>
        )
      },
      {
        accessorKey: 'email',
        header: 'Email',
        Cell: ({cell, row}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        },
        enableSorting: true
      },

      {
        accessorKey: 'barcode',
        header: 'Customer ID',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'updatedAt',
        header: 'Sign Up Date',
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? convertToLocalTimeString(cell.getValue()) : ''}
            </Typography>
          )
        }
      }
    ],
    []
  )

  return (
    <>
      <StyledTable
        columns={columns}
        data={data}
        enableRowActions
        state={{
          showSkeletons: loadingCustomers,
          showProgressBars: loadingCustomers
        }}
        initialState={{
          columnPinning: {
            left: ['mrt-row-expand'],
            right: ['mrt-row-actions']
          },
          columnVisibility: {
            updatedAt: false
          }
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Grid
              style={{
                order: 1
              }}
            >
              <StyledButton
                fullWidth={false}
                id='successButton'
                disabled={!hasCreateCustomerAccess}
                size='medium'
                onClick={() => {
                  setSaveCustomerModalData({})
                }}
                startIcon={
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'plus'
                    })}
                  />
                }
              >
                Create Customer
              </StyledButton>
            </Grid>
          )
        }}
        renderRowActionMenuItems={({row, closeMenu}) => [
          <MenuItem
            key={`view-profile-${row.original.id}`}
            onClick={() => {
              closeMenu()
              history.push(`/${businessId}/customers/${row.original.id}`)
            }}
            sx={{m: 0}}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={icon({
                  name: 'user-circle'
                })}
                size='2x'
              />
            </ListItemIcon>
            View Profile
          </MenuItem>,
          <MenuItem
            disabled={!hasCreateCustomerAccess}
            key={0}
            onClick={() => {
              setSaveCustomerModalData(omitTypenameDeep(row.original))
              closeMenu()
            }}
            sx={{m: 0}}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={icon({
                  name: 'edit'
                })}
                size='2x'
              />
            </ListItemIcon>
            Edit
          </MenuItem>
        ]}
      />

      {saveCustomerModalData && (
        <SaveCustomerProfileModal
          onSubmit={(data: any) => {
            saveCustomer(data)
            setTimeout(() => {
              setSaveCustomerModalData(null)
            })
          }}
          onClose={() => {
            setSaveCustomerModalData(null)
          }}
          saveCustomerModalData={saveCustomerModalData}
        />
      )}
    </>
  )
}

export default Customers
