import {UseFormReturn} from 'react-hook-form'
import _keyBy from 'lodash/keyBy'
import {Field, FieldType} from 'ui/organisms/FormBuilder/FormBuilder'
import {t} from 'i18next'
import { MRT_ColumnDef } from 'material-react-table'
import { RegisterType } from 'graphql/generatedTypes/graphql'
import {Chip, Typography} from '@mui/material'
import { localDateTimeString } from 'utils/common'



export const columns: MRT_ColumnDef<RegisterType>[] = [
  {
      accessorKey: 'externalRegisterId',
      header: 'Register ID',
      enableColumnFilter: false,
      Cell: ({cell, row}: any) => {
        return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
      }
  },
  {
      accessorKey: 'name',
      header: 'Register Name',
      enableColumnFilter: false,
      Cell: ({cell, row}: any) => {
        return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
      }
  },
  {
    accessorKey: 'createdAt',
    header: 'Created On',
    enableColumnFilter: false,
    Cell: ({cell}: any) => {
      return (
        <Typography variant='subtitle2'>
          {cell.getValue() ? localDateTimeString(cell.getValue()) : '-'}
        </Typography>
      )
    }
  },
  {
    accessorKey: 'isActive',
    header: 'Status',
    enableColumnFilter: false,
    Cell: ({cell, row}: any) => {
      return (
        <Chip
          label={cell.getValue() ? 'Active' : 'Inactive'}
          color={cell.getValue() ? 'success' : 'error'}
        />
      )
    }
  }
]

const fields: Field[] = [
  {
    id: 'externalRegisterId',
    label: t('cash-management.fields.register-id'),
    required: true
  },
  {
    id: 'name',
    label: t('cash-management.fields.register-name'),
    required: true
  },
  {
    id: 'location',
    label: t('cash-management.fields.location'),
    type: FieldType.Autocomplete,
    options: [],
    required: true
  },
  {
    id: 'isActive',
    label: t('cash-management.fields.status'),
    labelPlacement: 'end',
    type: FieldType.Switch
  }
]

const fieldsById = _keyBy(fields, 'id')

type RegisterFieldType = {
  formContext: UseFormReturn<any>
  locations: any
}

export const getRegisterFields = ({
  formContext,
  locations
}: RegisterFieldType) => {
  fieldsById['location'].options = (locations || []).map((location: any) => ({
    id: location.locationId,
    label: location.name
  }))
  fieldsById['isActive'].label = t(
    `cash-management.fields.register.status.${formContext.getValues('isActive')}`
  )
  return fields
}
