import React from 'react'
import _map from 'lodash/map'
import _isFunction from 'lodash/isFunction'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {
  FormContainer,
  SelectElement,
} from 'react-hook-form-mui'
import {DevTool} from '@hookform/devtools'
import cx from 'clsx'
import DatePickerElement from './ext/DatePickerElement'
import DateTimePickerElement from './ext/DateTimePickerElement'
import {DateFnsProvider} from './ext/DateFnsProvider'
import AutocompleteField from './defaultFields/AutocompleteField'
import CheckboxField from './defaultFields/CheckboxField'
import MultiSelectField from './defaultFields/MultiSelectField'
import SectionHeader from './defaultFields/SectionHeader'
import RadioButtonGroupField from './defaultFields/RadioButtonGroupField'
import SwitchElementField from './defaultFields/SwitchElementField'
import TextFieldElement, { TextFieldElementProps } from './ext/TextFieldElement'

export type Field = {
  id: string
  label?: string | React.ReactNode
  type?: FieldType
  // fieldComponent should integrate with react-hook-form using useController api.
  // It must accept a root level className prop.
  // And it should support errorText rendering as well
  fieldComponent?: TextFieldElementProps & {[x: string]: any}
  className?: string
  required?: boolean
  // Spans a field should cover. Form row has 12 spans.
  span?: number
  // if provided, field will only be part of the form
  // if the activator value is truthy, or the callback returns truthy.
  activator?: boolean | Function
  rules?: any
  [x: string]: any
}

type FormBuilderProps = {
  formContext: any
  fields: Field[]
  className?: string
  debug?: boolean
}

export enum FieldType {
  Autocomplete = 'autocomplete',
  Checkbox = 'checkbox',
  CheckboxGroup = 'checkbox-group',
  RadioButtonGroup = 'radio-button-group',
  Date = 'date',
  DateTime = 'date-time',
  Number = 'number',
  Password = 'password',
  Email = 'email',
  Select = 'select',
  MultiSelect = 'multi-select',
  Switch = 'switch',
  Text = 'text',

  // Non form components
  SectionHeader = 'section-header'
}

// https://react-hook-form-material-ui.vercel.app/
const fieldTypeVsComponent: Record<FieldType, any> = {
  [FieldType.Autocomplete]: AutocompleteField,

  [FieldType.Checkbox]: CheckboxField,
  [FieldType.CheckboxGroup]: CheckboxField,
  [FieldType.RadioButtonGroup]: RadioButtonGroupField,

  [FieldType.Date]: DatePickerElement,
  [FieldType.DateTime]: DateTimePickerElement,

  [FieldType.Number]: TextFieldElement,
  [FieldType.Password]: TextFieldElement,
  [FieldType.Email]: TextFieldElement,

  [FieldType.Select]: SelectElement,
  [FieldType.MultiSelect]: MultiSelectField,

  [FieldType.Switch]: SwitchElementField,
  [FieldType.Text]: TextFieldElement,

  [FieldType.SectionHeader]: SectionHeader
}

const FormBuilder = ({
  formContext,
  fields,
  className: formClassName,
  debug
}: FormBuilderProps) => {

  return (
    <DateFnsProvider dateAdapter={AdapterMoment as any}>
      <FormContainer
        formContext={formContext}
        FormProps={{
          className: cx(
            'w-full grid grid-cols-12 gap-x-3 gap-y-3',
            formClassName
          )
        }}
      >
        {_map(fields, (field) => {
          const {
            id,
            label,
            type,
            fieldComponent,
            span = 6,
            activator = true,
            rules,
            className,
            ...rest
          } = field || {}

          const isActive = _isFunction(activator) ? activator(formContext.getValues()) : activator
          if (!isActive) {
            return null
          }

          if (type && [FieldType.SectionHeader].includes(type)) {
            const NonFieldComponent = fieldTypeVsComponent[type]
            return (
              <NonFieldComponent
                key={id || label || field.title}
                title={label || field.title}
                className={cx(`col-span-12`, className)}
              />
            )
          }

          const FieldComponent =
            fieldComponent || fieldTypeVsComponent[type || 'text']

          return (
            <FieldComponent
              key={`${id}-${label}`}
              name={id}
              label={label}
              type={type}
              className={cx(`col-span-${span}`, 'min-w-16', className)}
              rules={rules}
              {...rest}
            />
          )
        })}
        {debug && <DevTool control={formContext.control} />}
      </FormContainer>
    </DateFnsProvider>
  )
}

export default FormBuilder
