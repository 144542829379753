import 'react-notifications-component/dist/theme.css'
import ProtectedRoute from 'clientDashboard/ProtectedRoute'
import {Permission} from 'constants/permissions'
import CustomerProfile from 'clientDashboard/customers/CustomerProfile'
import Customers from 'clientDashboard/customers/Customers'
import EmployeesSettings from 'clientDashboard/settings/employees/EmployeesSettings'
import InventoryManager from 'clientDashboard/inventoryManagement/InventoryManager'
import PaymentSettings from 'clientDashboard/settings/payments/PaymentSettings'
import Promotions from 'clientDashboard/growthAndSales/Promotions'
import RolesAndPermissions from 'clientDashboard/settings/rolesAndPermissions/RolesAndPermissions'
import Rules from 'clientDashboard/growthAndSales/Rules'
import Settings from 'clientDashboard/settings/Settings'
import Transactions from 'clientDashboard/growthAndSales/Transactions'
import {useEffect} from 'react'
import {Route, Switch, useRouteMatch} from 'react-router-dom'

import Loading from 'ui/molecules/Loading'
import LocationNotFound from 'clientDashboard/LocationNotFound'
import NotFound from 'ui/organisms/NotFound'
import {EventTypes, LocalStorageKeys} from 'constants/constants'
import CashManagerSummary from 'clientDashboard/cashManagement/CashManagerSummary'
import CashManagerTransaction from 'clientDashboard/cashManagement/CashManagerTransaction'
import Home from 'ui/molecules/Home'
import LocationSettings from 'clientDashboard/settings/locations/LocationSettings'
import OAuthApplications from 'clientDashboard/settings/applications/OAuthApplications'
import PeripheralHubOverview from 'clientDashboard/peripheralHub/peripheralHubOverview/PeripheralHubOverview'
import PeripheralHubActivityLog from 'clientDashboard/peripheralHub/PeripheralHubActivityLog'
import PeripheralHubDevices from 'clientDashboard/peripheralHub/PeripheralHubDevices'
import PeripheralHubNotifications from 'clientDashboard/peripheralHub/PeripheralHubNotifications'
import {authEventEmitter} from 'auth/events'
import useEmployee from 'hooks/useEmployee'
import useLocalStorage from 'hooks/useLocalStorage'
import CashTransactionFailureLogs from 'clientDashboard/cashManagement/CashTransactionFailureLogs'
import BusinessSettings from 'clientDashboard/settings/business/BusinessSettings'
import OmsSettings from 'clientDashboard/settings/OmsSettings'
import CashManagementSalesInsights from 'clientDashboard/cashManagement/cashManagerInsights/CashManagementSalesInsights'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectFeatureFlags} from 'clientDashboard/business.selectors'
import {
  selectPermissions,
  selectSelectedLocationId
} from 'clientDashboard/employee.selectors'
import PeripheralHubInstallation from 'clientDashboard/peripheralHub/PeripheralHubInstallation'
import CashManagementVarianceInsights from 'clientDashboard/cashManagement/cashManagerInsights/CashManagementVarianceInsights'
import AnalyticsAgent from 'clientDashboard/analyticsAgent/AnalyticsAgent'
import InsightsDashboard from 'clientDashboard/cashManagement/cashManagerInsights/InsightsDashboard'
import StoreGrouping from 'clientDashboard/settings/StoreGrouping/StoreGrouping'
import StoreGroupList from 'clientDashboard/settings/StoreGrouping/StoreGroupList'
import Templates from 'clientDashboard/settings/templates/Templates'
import DeviceTemplateConfig from 'clientDashboard/settings/templates/DeviceTemplateConfig'
import CashSettingManager from 'clientDashboard/settings/cashManagementSettings/CashSettingManager'

type ClientRoutesProps = {
  signOut: () => void
  isAuthenticated: boolean
}

const ClientRoutes = ({signOut, isAuthenticated}: ClientRoutesProps) => {
  let {url}: any = useRouteMatch()
  const [toteAccessToken, , clearToteAccessToken] = useLocalStorage(
    LocalStorageKeys.TOTE_ACCESS_TOKEN
  )
  const {getEmployee, errorInGetEmployee, loadingEmployee} = useEmployee()
  const featureFlags = useAppSelector(selectFeatureFlags)
  const permissions = useAppSelector(selectPermissions)
  const selectedLocationId = useAppSelector(selectSelectedLocationId)

  // Fetch the employee info, if the user is authenticated and the locationId is not set
  useEffect(() => {
    if (!isAuthenticated) {
      clearToteAccessToken()
      return
    }

    if (toteAccessToken && !selectedLocationId) {
      getEmployee()
    }
  }, [isAuthenticated])

  // Event listener for EventTypes.SIGN_OUT
  useEffect(() => {
    authEventEmitter.on(EventTypes.SIGN_OUT, () => signOut?.())
    return () => {
      authEventEmitter.off(EventTypes.SIGN_OUT, () => signOut?.())
    }
  }, [isAuthenticated])

  if (errorInGetEmployee) {
    return <LocationNotFound />
  }

  if (isAuthenticated && !selectedLocationId) {
    return <Loading />
  }
  if (loadingEmployee) return <div />

  return (
    <Switch>
      <ProtectedRoute path={`${url}/`} exact component={Home} />

      {((featureFlags.peripheralHub &&
        permissions.includes(Permission.MANAGE_PERIPHERAL_HUB)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/peripheral-hub/overview`}
          component={PeripheralHubOverview}
        />
      )}

      {((featureFlags.peripheralHub &&
        permissions.includes(Permission.MANAGE_PERIPHERAL_HUB)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/peripheral-hub/devices`}
          component={PeripheralHubDevices}
        />
      )}

      {((featureFlags.peripheralHub &&
        permissions.includes(Permission.MANAGE_PERIPHERAL_HUB)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/peripheral-hub/activity-log/:id`}
          component={PeripheralHubActivityLog}
        />
      )}

      {((featureFlags.peripheralHub &&
        permissions.includes(Permission.MANAGE_PERIPHERAL_HUB)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/peripheral-hub/notifications`}
          component={PeripheralHubNotifications}
        />
      )}

      {((featureFlags.peripheralHub &&
        permissions.includes(Permission.MANAGE_PERIPHERAL_HUB)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/peripheral-hub/installation`}
          component={PeripheralHubInstallation}
        />
      )}

      {((featureFlags.cashManagement &&
        permissions.includes(Permission.MANAGE_CASH_MANAGEMENT)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/cash-management`}
          component={CashManagerSummary}
        />
      )}

      {((featureFlags.cashManagement &&
        permissions.includes(Permission.MANAGE_CASH_MANAGEMENT)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/cash-management/summary`}
          component={CashManagerSummary}
        />
      )}

      {((featureFlags.cashManagement &&
        permissions.includes(Permission.MANAGE_CASH_MANAGEMENT)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/cash-management/transaction`}
          component={CashManagerTransaction}
        />
      )}

      {((featureFlags.cashManagement &&
        permissions.includes(Permission.MANAGE_CASH_MANAGEMENT)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/cash-management/failure-logs`}
          component={CashTransactionFailureLogs}
        />
      )}

      {((featureFlags.cashManagement &&
        permissions.includes(Permission.MANAGE_CASH_MANAGEMENT)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/cash-management/settings`}
          component={CashSettingManager}
        />
      )}

      {((featureFlags.orders &&
        permissions.includes(Permission.MANAGE_ORDERS)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/transactions`}
          component={Transactions}
        />
      )}

      {((featureFlags.enableCustomerProfile &&
        permissions.includes(Permission.MANAGE_CUSTOMERS)) ||
        !isAuthenticated) && (
        <ProtectedRoute exact path={`${url}/customers`} component={Customers} />
      )}

      {((featureFlags.enableCustomerProfile &&
        permissions.includes(Permission.MANAGE_CUSTOMERS)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/customers/:id`}
          component={CustomerProfile}
        />
      )}

      {(permissions.includes(Permission.MANAGE_OAUTH_APPLICATIONS) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/oauth-applications`}
          component={OAuthApplications}
        />
      )}

      {((featureFlags.inventory &&
        permissions.includes(Permission.MANAGE_INVENTORY)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/inventory-management`}
          component={InventoryManager}
        />
      )}

      {(permissions.includes(Permission.MANAGE_LOCATION_SETTINGS) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/location-settings`}
          component={LocationSettings}
        />
      )}

      {(permissions.includes(Permission.MANAGE_ROLES) || !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/roles-and-permissions`}
          component={RolesAndPermissions}
        />
      )}

      {(permissions.includes(Permission.MANAGE_EMPLOYEE) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/employee-management`}
          component={EmployeesSettings}
        />
      )}

      {((featureFlags.promotions &&
        permissions.includes(Permission.MANAGE_PROMOTIONS)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/promotions`}
          component={Promotions}
        />
      )}

      {((featureFlags.promotions &&
        permissions.includes(Permission.MANAGE_RULES)) ||
        !isAuthenticated) && (
        <ProtectedRoute exact path={`${url}/rules`} component={Rules} />
      )}

      {((featureFlags.payments &&
        permissions.includes(Permission.MANAGE_PAYMENTS_CONFIGURATIONS)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/payment-settings`}
          component={PaymentSettings}
        />
      )}

      {((featureFlags.oms &&
        permissions.includes(Permission.MANAGE_OMS_CONFIGURATIONS)) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/OMS-settings`}
          component={OmsSettings}
        />
      )}

      {(permissions.includes(Permission.MANAGE_BUSINESS_SETTINGS) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/business-settings`}
          component={BusinessSettings}
        />
      )}
      {(permissions.includes(Permission.MANAGE_INSIGHTS) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/insights`}
          component={InsightsDashboard}
        />
      )}

      {(permissions.includes(Permission.MANAGE_CASH_MANAGEMENT_INSIGHTS) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/insights/cash-management/sales`}
          component={CashManagementSalesInsights}
        />
      )}

      {(permissions.includes(Permission.MANAGE_CASH_MANAGEMENT_INSIGHTS) ||
        !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/insights/cash-management/variance`}
          component={CashManagementVarianceInsights}
        />
      )}

      {(featureFlags.enableAnalyticsAgent || !isAuthenticated) && (
        <ProtectedRoute
          exact
          path={`${url}/analytics-agent`}
          component={AnalyticsAgent}
        />
      )}

      {(permissions.includes(Permission.READ_LOCATION_GROUP) ||
        !isAuthenticated) &&
        featureFlags.locationGrouping && (
          <ProtectedRoute
            exact
            path={`${url}/location-grouping`}
            component={StoreGrouping}
          />
        )}

      {(permissions.includes(Permission.READ_LOCATION_GROUP) ||
        !isAuthenticated) &&
        featureFlags.locationGrouping && (
          <ProtectedRoute
            exact
            path={`${url}/location-grouping/*`}
            component={StoreGroupList}
          />
        )}

      <ProtectedRoute exact path={`${url}/templates`} component={Templates} />
      <ProtectedRoute
        exact
        path={`${url}/templates/add`}
        component={DeviceTemplateConfig}
      />
      <ProtectedRoute
        exact
        path={`${url}/templates/:templateName`}
        component={DeviceTemplateConfig}
      />

      <ProtectedRoute exact path={`${url}/settings`} component={Settings} />

      <Route path={'*'} component={NotFound} />
    </Switch>
  )
}

export default ClientRoutes
