import _cloneDeepWith from 'lodash/cloneDeepWith'
import {wsLink} from 'config/apollo.config'

export const isProdEnv = () => process.env.NODE_ENV === 'production'
export const isDevEnv = () => process.env.NODE_ENV === 'development'

export const isNonProdAppEnv = () => window.env.APP_ENV !== 'production'

// https://stackoverflow.com/a/65490859
export const omitTypenameDeep = (
  variables: Record<string, unknown>
): Record<string, unknown> =>
  _cloneDeepWith(variables, (value) => {
    if (value && value.__typename) {
      const {__typename, ...valWithoutTypename} = value
      return omitTypenameDeep(valWithoutTypename)
    }

    return undefined
  })

export const reconnectWebsocket = async () => {
  const subscriptionClient = (wsLink as any)?.subscriptionClient as any
  await subscriptionClient?.tryReconnect()
}
