import {graphql} from 'graphql/generatedTypes'

export const CREATE_REGISTER = graphql(`
    mutation AddRegister($register: RegisterInput!) {
        addRegister(register: $register) {
            id 
            name
            isActive
        }
    }
`)

export const UPDATE_REGISTER = graphql(`
    mutation UpdateRegister($register: RegisterInput!, $registerId: String!) {
        updateRegister(register: $register, registerId: $registerId) {
            id
            name
            isActive
        }
    }
`)
