import FormBuilder from 'ui/organisms/FormBuilder/FormBuilder'
import {useForm, useWatch} from 'react-hook-form'
import StyledDialog from 'ui/molecules/StyledDialog'
import { getRegisterFields } from 'clientDashboard/settings/cashManagementSettings/registerForm.helpers'
import { useAppSelector } from 'clientDashboard/clientDashboard.store'
import { selectAllLocations, selectLocationId } from 'clientDashboard/employee.selectors'
import { useTranslation } from 'react-i18next'
import { API_SOURCE } from 'constants/constants'

const defaultValues ={
    name: '',
    location: '',
    isActive: false,
    externalRegisterId: '',
    source: API_SOURCE
}

type Props = {
    modalData: any
    onSubmit: (data: any) => void
    onClose: () => void
  }

const RegisterForm = (props: Props) => {
  const {t} = useTranslation()
  const {modalData, onSubmit, onClose} = props
  const locations = useAppSelector(selectAllLocations)
  const defaultLocation = useAppSelector(selectLocationId)

  defaultValues.location = defaultLocation

  const formContext = useForm<any>({
    defaultValues,
    values: modalData,
    resetOptions: {
      keepDefaultValues: true
    }
  })

  const {isValid} = formContext.formState
  const isEditMode = !!modalData?.id

  const _ = useWatch({
    control: formContext.control,
    name: ['isActive']
  })

  const registerDialogBody = () => {
    return (
      <div className={'px-2'}>
        <FormBuilder
          formContext={formContext}
          fields={getRegisterFields({formContext, locations})}
        />
      </div>
    )
  }

  return (
    <>
      <StyledDialog
      open
      onClose={() => {}}
      maxWidth={'sm'}
      title={
        isEditMode
          ? t('cash-management.title.edit-register')
          : t('cash-management.title.add-register')
      }
      successButtonName={
        isEditMode
          ? t('cash-management.action.update')
          : t('cash-management.action.add')
      }
      cancelButtonName={t('cash-management.action.cancel')}
      cancelCallback={() => {
        onClose()
      }}
      disableSuccessButton={!isValid}
      successCallback={() => {
        formContext.handleSubmit(
          (data) => {
            onSubmit(data)
          },
          (error) => {
            console.log(error)
          }
        )()
      }}
      body={registerDialogBody()}
    />
    </>
    
  )
}

export default RegisterForm
