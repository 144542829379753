import {RootState} from 'consumerAgent/consumerAgent.store'

export const selectAddress = (state: RootState) => state.cart.address
export const selectAddressFields = (state: RootState) =>
  state.cart.addressFields
export const selectCartData = (state: RootState) => state.cart.cartData
export const selectCartId = (state: RootState) => state.cart.cartId
export const selectCartItems = (state: RootState) => state.cart.cartItems
export const selectCartTransferMethod = (state: RootState) =>
  state.cart.cartTransferMethod
