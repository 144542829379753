import {Grid} from '@mui/material'
import {space} from 'config/theme'
import {selectCartItems} from 'consumerAgent/cart.selector'
import {selectChatMessages} from 'consumerAgent/chat.selector'
import {submitGenieQuery} from 'consumerAgent/chat.slice'
import {useAppDispatch, useAppSelector} from 'consumerAgent/consumerAgent.store'
import ConsumerAgentChat from 'consumerAgent/ConsumerAgentChat'
import ConsumerAgentHotkeys from 'consumerAgent/ConsumerAgentHotkeys'
import ConsumerAgentPayments from 'consumerAgent/ConsumerAgentPayments'
import ConsumerAgentQueryInput, {
  ConsumerAgentQueryInputRef
} from 'consumerAgent/ConsumerAgentQueryInput'
import {selectScrollToBottomTrigger} from 'consumerAgent/ui.selector'
import {triggerScrollToBottom} from 'consumerAgent/ui.slice'
import {Fragment, useEffect, useRef, useState} from 'react'

const ConsumerAgentChatConversation = () => {
  const dispatch = useAppDispatch()
  const queryInputRef = useRef<ConsumerAgentQueryInputRef>(null)
  const chatMessages = useAppSelector(selectChatMessages)

  const cartItems = useAppSelector(selectCartItems)

  const scrollToBottomTrigger = useAppSelector(selectScrollToBottomTrigger)
  const [text, setText] = useState('')

  const scrollRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (scrollRef.current) {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({behavior: 'smooth'})
      }, 100)
    }
  }, [scrollToBottomTrigger])

  const submitText = () => {
    if (!text) return
    dispatch(submitGenieQuery(text))
    setText('')
    dispatch(triggerScrollToBottom())
  }

  useEffect(() => {
    dispatch(triggerScrollToBottom())
  }, [])

  const focusOnInput = () => {
    if (queryInputRef.current) {
      queryInputRef.current.focusOnInput()
    }
  }

  return (
    <Fragment>
      <Grid
        item
        xs
        sx={{
          flexGrow: 1,
          overflowY: 'scroll',
          display: 'flex',
          p: 2
        }}
        padding={space.SMALL}
      >
        <Grid item width={'100%'} marginTop={'auto'}>
          <Grid container width={'100%'}>
            <ConsumerAgentHotkeys
              setText={(text) => {
                setText(text)
                focusOnInput()
              }}
            />

            {chatMessages.map((_, index) => (
              <ConsumerAgentChat
                key={index}
                index={index}
                setText={setText}
                focusOnInput={focusOnInput}
              />
            ))}
          </Grid>
          <div ref={scrollRef} />
        </Grid>
      </Grid>

      {!!cartItems.length && <ConsumerAgentPayments />}

      <ConsumerAgentQueryInput
        ref={queryInputRef}
        text={text}
        setText={setText}
        submitText={submitText}
      />
    </Fragment>
  )
}

export default ConsumerAgentChatConversation
