import {graphql} from 'graphql/generatedTypes'

export const GET_REGISTER_AND_TILL = graphql(`
  query getRegisterTills($businessId: String!, $locationId: String!) {
    fetchRegisterList(businessId: $businessId, locationId: $locationId) {
      id
      name
    }

    fetchTills(businessId: $businessId, locationId: $locationId) {
      id
      name
    }
  }
`)


export const GET_REGISTERS = graphql(`
  query getCashRegisters($locationId: String!, $pageIndex: Int, $pageSize: Int) {
    getCashRegisters(locationId: $locationId, pageIndex: $pageIndex, pageSize: $pageSize) {
      nodes {
        id
        name
        isActive
        externalRegisterId
        source
        createdAt
        updatedAt
      }
      totalPageCount  
    }
  }
`)

export const GET_TILLS = graphql(`
  query getTills($locationId: String!, $pageIndex: Int, $pageSize: Int) {
    getTills(locationId: $locationId, pageIndex: $pageIndex, pageSize: $pageSize) {
      nodes {
        id
        name
        isActive
        externalTillId
        source
        createdAt
        updatedAt
      }
      totalPageCount  
    }
  }
`)
