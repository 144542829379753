import {t} from 'app/i18n'

export const UnitTypeOptions = {
  PER_ITEM: t('inventory.unit-type.per-item'),
  PER_POUND: t('inventory.unit-type.per-pound')
}

export const UnitTypeOptionsMap = {
  [UnitTypeOptions.PER_ITEM]: 'Per item',
  [UnitTypeOptions.PER_POUND]: 'Per pound'
}

export const IsTaxableOptions = {
  YES: 'Yes',
  NO: 'No'
}

export const IsTaxableOptionsMap = {
  [IsTaxableOptions.YES]: 'yes',
  [IsTaxableOptions.NO]: 'no'
}

export const CurrencyOptions = {
  USD: 'USD'
}

export const CurrencyOptionsMap = {
  [CurrencyOptions.USD]: 'USD'
}

export const currencyList = [
  {id: CurrencyOptions.USD, label: CurrencyOptions.USD, value: 'USD'}
]
export const unitTypeList = [
  {
    id: UnitTypeOptions.PER_ITEM,
    label: UnitTypeOptions.PER_ITEM,
    value: 'Per item'
  },
  {
    id: UnitTypeOptions.PER_POUND,
    label: UnitTypeOptions.PER_POUND,
    value: 'Per pound'
  }
]
export const taxableList = [
  {id: IsTaxableOptions.YES, label: IsTaxableOptions.YES, value: 'yes'},
  {id: IsTaxableOptions.NO, label: IsTaxableOptions.NO, value: 'no'}
]
