import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import client from 'config/apollo.config'
import {RootState} from 'consumerAgent/consumerAgent.store'
import {triggerScrollToBottom} from 'consumerAgent/ui.slice'
import {GenieQueryType} from 'graphql/generatedTypes/graphql'
import {
  DELETE_CHAT_HISTORY_FOR_CONSUMER_AGENT,
  GENERATE_GENIE_CHAT_SESSION_ID_FOR_CONSUMER_AGENT
} from 'graphql/mutations/customer.mutation'
import {
  FETCH_GENIE_MESSAGE_FOR_CONSUMER_AGENT,
  GENIE_QUERY_FOR_CONSUMER_AGENT,
  PREDEFINED_CHAT_QUERY_CONSUMER_AGENT
} from 'graphql/queries/customers.queries'
import {get} from 'lodash'

type ChatMessagesType = {
  query: string
  hasFetched: boolean
  response: any
  messageId: string
  isFetching?: boolean
  hideQuery?: boolean
}

interface ChatState {
  chatMessages: ChatMessagesType[]
  chatSessionId: string
  isPollingGenieResponse: boolean
  queryId: string
}

const initialState: ChatState = {
  chatMessages: [],
  chatSessionId: '',
  isPollingGenieResponse: false,
  queryId: ''
}

// Async Thunk for submitting Genie Query
export const submitGenieQuery = createAsyncThunk(
  'chat/submitGenieQuery',
  async (query: string, {getState, dispatch}) => {
    const state = getState() as RootState
    const chatSessionId = state.chat.chatSessionId
    const locationId = state.consumer.locationId

    const response = await client.query({
      query: GENIE_QUERY_FOR_CONSUMER_AGENT,
      variables: {query, chatSessionId, locationId}
    })

    dispatch(
      addChatMessage({
        query: response.data.genieQueryConsumerAgent.query || '',
        hasFetched: false,
        response: null,
        messageId: response.data.genieQueryConsumerAgent.messageId,
        isFetching: true
      })
    )
    dispatch(triggerScrollToBottom())

    dispatch(pollGenieResponse(response.data.genieQueryConsumerAgent.messageId))
  }
)

// Async Thunk for generating Chat Session Id
export const generateChatSessionId = createAsyncThunk(
  'chat/generateChatSessionId',
  async () => {
    const response = await client.mutate({
      mutation: GENERATE_GENIE_CHAT_SESSION_ID_FOR_CONSUMER_AGENT
    })

    return response.data?.generateGenieChatSessionIdConsumerAgent
  }
)

// Async Thunk for deleting Chat History
export const deleteChatHistory = createAsyncThunk(
  'chat/deleteChatHistory',
  async (_, {getState, dispatch}) => {
    const state = getState() as RootState
    const chatSessionId = state.chat.chatSessionId
    try {
      await client.mutate({
        mutation: DELETE_CHAT_HISTORY_FOR_CONSUMER_AGENT,
        variables: {chatSessionId}
      })
      dispatch(generateChatSessionId())
    } catch (_) {
      dispatch(generateChatSessionId())
    }
  }
)

// Async Thunk for polling Genie response
export const fetchGenieResponse = createAsyncThunk(
  'chat/fetchGenieResponse',
  async (messageId: string) => {
    try {
      const response = await client.query({
        query: FETCH_GENIE_MESSAGE_FOR_CONSUMER_AGENT,
        variables: {messageId}
      })
      return response.data.fetchGenieMessageConsumerAgent
    } catch (error) {
      return {
        status: 'failed',
        answer:
          'We could not process your request at this time. Please try again',
        messageId: messageId,
        chatSessionId: '',
        query: ''
      }
    }
  }
)

// Polling function to repeatedly check for genie response
export const pollGenieResponse = createAsyncThunk(
  'chat/pollGenieResponse',
  async (messageId: string, {dispatch, getState}) => {
    dispatch(startPollingGenieResponse())
    let counter = 0
    const maxAttempts = 12
    let timeoutRef: any = null

    const poll = async () => {
      // if the polling is stopped, clear the timeout and return
      const isPollingGenieResponse = (getState() as RootState).chat
        .isPollingGenieResponse

      if (!isPollingGenieResponse) {
        clearTimeout(timeoutRef)
        return
      }

      // if the counter exceeds the max attempts, stop polling and update the message as failed
      if (counter >= maxAttempts) {
        dispatch(stopPollingGenieResponse())
        dispatch(updateMessageAsFailed(messageId))
        return
      }

      // fetch the genie response and process it if it is processed or failed
      const response = await dispatch(fetchGenieResponse(messageId))
      await dispatch(
        processGenieResponse(get(response, 'payload') as GenieQueryType)
      )

      // increment the counter and set the timeout for the next poll
      counter++
      timeoutRef = setTimeout(poll, 5000)
    }
    poll()
  }
)

export const processGenieResponse = createAsyncThunk(
  'chat/processGenieResponse',
  async (response: GenieQueryType, {dispatch, getState}) => {
    const {messageId, answer, status, genieChatQuery, chatSessionId} = response

    if (!['processed', 'failed'].includes(status as string)) return

    dispatch(stopPollingGenieResponse())
    const state = getState() as RootState
    const index = state.chat.chatMessages.findIndex(
      (message) => message.messageId === messageId
    )

    if (index !== -1) {
      dispatch(
        updateChatMessage({
          messageId,
          update: {
            isFetching: false,
            hasFetched: true,
            response: {
              answer: answer,
              chatSessionId: chatSessionId || '',
              products: genieChatQuery?.products || []
            }
          }
        })
      )
    } else {
      dispatch(
        addChatMessage({
          query: '',
          hasFetched: true,
          response: {
            answer: answer,
            chatSessionId: chatSessionId || '',
            products: genieChatQuery?.products || []
          },
          messageId: messageId
        })
      )
    }
    dispatch(triggerScrollToBottom())
  }
)

export const updateMessageAsFailed = createAsyncThunk(
  'chat/updateMessageAsFailed',
  async (messageId: string, {dispatch, getState}) => {
    const state = getState() as RootState
    const chatMessages = state.chat.chatMessages
    const index = chatMessages.findIndex((msg) => msg.messageId === messageId)

    if (index !== -1) {
      dispatch(
        updateChatMessage({
          messageId,
          update: {
            isFetching: false,
            hasFetched: true,
            response: {
              answer:
                'We could not process your request at this time. Please try again.'
            }
          }
        })
      )
    }
  }
)

// async thunk for fetching predefined chat query
export const fetchPredefinedChatQuery = createAsyncThunk(
  'chat/fetchPredefinedChatQuery',
  async (_, {dispatch, getState}) => {
    const state = getState() as RootState
    const locationId = state.consumer.locationId
    const businessId = state.consumer.businessId
    const queryId = state.chat.queryId
    const response = await client.query({
      query: PREDEFINED_CHAT_QUERY_CONSUMER_AGENT,
      variables: {queryId, locationId, businessId}
    })

    const products =
      response.data?.predefinedChatQueryConsumerAgent?.products || []
    const answer = response.data?.predefinedChatQueryConsumerAgent?.answer || ''
    const query = response.data?.predefinedChatQueryConsumerAgent?.query || ''

    dispatch(
      setChatMessages([
        {
          query,
          hideQuery: true,
          hasFetched: true,
          messageId: 'queryId-' + queryId,
          response: {
            products,
            answer
          }
        }
      ])
    )
    dispatch(triggerScrollToBottom())
    dispatch(setQueryId(''))
  }
)

// Chat Slice
const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatMessages(state, action: PayloadAction<ChatMessagesType[]>) {
      state.chatMessages = action.payload
    },
    addChatMessage(state, action: PayloadAction<ChatMessagesType>) {
      state.chatMessages.push(action.payload)
    },
    startPollingGenieResponse(state) {
      state.isPollingGenieResponse = true
    },
    stopPollingGenieResponse(state) {
      state.isPollingGenieResponse = false
    },
    setQueryId(state, action: PayloadAction<string>) {
      state.queryId = action.payload
    },
    updateChatMessage(
      state,
      action: PayloadAction<{
        messageId: string
        update: Partial<ChatMessagesType>
      }>
    ) {
      const index = state.chatMessages.findIndex(
        (msg) => msg.messageId === action.payload.messageId
      )
      if (index !== -1) {
        state.chatMessages[index] = {
          ...state.chatMessages[index],
          ...action.payload.update
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(generateChatSessionId.fulfilled, (state, action) => {
      state.chatSessionId = action.payload || ''
    })
  }
})

export const {
  setChatMessages,
  addChatMessage,
  startPollingGenieResponse,
  stopPollingGenieResponse,
  updateChatMessage,
  setQueryId
} = chatSlice.actions

export default chatSlice.reducer
