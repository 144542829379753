import {Grid} from '@mui/material'
import StyledInput from 'ui/atoms/StyledInput'
import {set} from 'lodash'
import {useEffect, useState} from 'react'
import {ServiceConfigType} from 'graphql/generatedTypes/graphql'
import StyledDialog from 'ui/molecules/StyledDialog'
import {ADD_UPDATE_SERVICE_CONFIG} from 'graphql/mutations/service-config.mutations'
import {useMutation} from '@apollo/client'
import useNotify from 'hooks/useNotify'
import {useTranslation} from 'react-i18next'
import {titleize} from 'utils/common'
import {
  PAYMENTS_CATEGORY,
  STRIPE,
  STRIPE_PAYMENT_GATEWAY
} from 'constants/constants'
import cloneDeep from 'lodash/cloneDeep'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessId} from 'clientDashboard/employee.selectors'

type StripeServiceConfigProps = {
  serviceConfig: ServiceConfigType
  toggleServiceConfigInputDialog: (flag: boolean) => void
  onSuccessCallback: () => void
  inputDialogOpen: boolean
  onCloseOrCancelCallback: () => void
}

const defaultFormValues: ServiceConfigType = {
  type: STRIPE,
  category: PAYMENTS_CATEGORY,
  configs: {
    secretKey: '',
    publishableKey: '',
    displayName: ''
  }
}

const StripeServiceConfigDialog = ({
  serviceConfig,
  toggleServiceConfigInputDialog,
  onSuccessCallback,
  inputDialogOpen,
  onCloseOrCancelCallback
}: StripeServiceConfigProps) => {
  const {t} = useTranslation()
  const notify = useNotify()
  const businessId = useAppSelector(selectBusinessId)
  const [formValues, setFormValues] = useState({...defaultFormValues})
  const [isSecretKeyEditMode, setIsSecretKeyEditMode] = useState(false)
  const [secretKey, setSecretKey] = useState('')
  const [publishableKey, setPublishableKey] = useState('')
  const handleChange = (path: string, value: string) => {
    setFormValues((prev) => {
      return set({...prev}, path, value)
    })
  }

  useEffect(() => {
    setFormValues(cloneDeep(serviceConfig))
  }, [serviceConfig])

  const handleSecretKeyChange = (path: string, value: any) => {
    setSecretKey(value)
    handleChange(path, value)
  }
  const handlePublishableKeyChange = (path: string, value: any) => {
    setPublishableKey(value)
    handleChange(path, value)
  }

  const [_addUpdateServiceConfig, {loading: loadingAddUpdateServiceConfig}] =
    useMutation(ADD_UPDATE_SERVICE_CONFIG, {
      onCompleted: () => {
        onSuccessCallback()
        toggleServiceConfigInputDialog(false)
        setSecretKey('')
        setPublishableKey('')
      },
      onError: (error) => {
        setSecretKey('')
        setPublishableKey('')
        toggleServiceConfigInputDialog(false)
        console.log('Error adding stripe service configs', error)
        if (error.message.includes('Invalid API Key')) {
          notify.show(
            t('payment-settings.stripe.invalid-stripe-secret-key-error'),
            'error'
          )
        } else if (error.message.includes('Location creation failed')) {
          notify.show(
            t('payment-settings.stripe.invalid-business-address-error'),
            'error'
          )
        } else
          notify.show(
            t('payment-settings.stripe.error-adding-stripe-service-configs'),
            'error'
          )
      }
    })

  const addUpdateServiceConfig = () => {
    _addUpdateServiceConfig({
      variables: {
        input: {
          businessId,
          name: STRIPE_PAYMENT_GATEWAY,
          type: STRIPE,
          category: PAYMENTS_CATEGORY,
          configs: {
            secretKey: formValues.configs?.secretKey,
            displayName: formValues.configs?.displayName,
            publishableKey: formValues.configs?.publishableKey
          }
        }
      }
    })
  }

  const loadStripeServiceConfigDialogBody = () => {
    return (
      <Grid container spacing={4} padding={2}>
        <Grid item xs={12}>
          <StyledInput
            label={t('payment-settings.payment-provider')}
            value={titleize(STRIPE)}
            variant='outlined'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            fullWidth
            label={t('payment-settings.stripe.form.secret-key')}
            variant='outlined'
            value={
              !isSecretKeyEditMode
                ? formValues.configs?.secretKeyMasked || ''
                : secretKey || ''
            }
            onChange={(e: any) =>
              handleSecretKeyChange('configs.secretKey', e.target.value)
            }
            onFocus={() => setIsSecretKeyEditMode(true)}
            autoComplete='off'
          />
        </Grid>

        <Grid item xs={12}>
          <StyledInput
            fullWidth
            label={t('payment-settings.stripe.form.publishable-key')}
            variant='outlined'
            value={formValues.configs?.publishableKey}
            onChange={(e: any) =>
              handlePublishableKeyChange(
                'configs.publishableKey',
                e.target.value
              )
            }
            autoComplete='off'
          />
        </Grid>

        <Grid item xs={12}>
          <StyledInput
            fullWidth
            label={t('payment-settings.stripe.form.display-name')}
            variant='outlined'
            value={formValues.configs?.displayName}
            onChange={(e: any) =>
              handleChange('configs.displayName', e.target.value)
            }
            helperText={t(
              'payment-settings.stripe.form.display-name-helper-text'
            )}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid>
      <StyledDialog
        open={inputDialogOpen}
        title={t('payment-settings.add-payment-provider')}
        onClose={() => {
          toggleServiceConfigInputDialog(false)
          onCloseOrCancelCallback()
        }}
        body={loadStripeServiceConfigDialogBody()}
        successButtonName={t('save')}
        cancelButtonName={t('cancel')}
        cancelCallback={() => {
          toggleServiceConfigInputDialog(false)
          onCloseOrCancelCallback()
        }}
        successCallback={() => {
          addUpdateServiceConfig()
        }}
        disableSuccessButton={
          loadingAddUpdateServiceConfig ||
          (secretKey === '' &&
            formValues.configs?.displayName ===
              serviceConfig.configs?.displayName &&
            publishableKey === serviceConfig.configs?.publishableKey)
        }
      ></StyledDialog>
    </Grid>
  )
}

export default StripeServiceConfigDialog
