import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import StyledTable from 'ui/molecules/StyledTable'
import { Box, Grid, IconButton, Tab, Tabs, Tooltip } from '@mui/material'
import { GET_TILLS } from 'graphql/queries/cash-register-till.queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import { useTranslation } from 'react-i18next'
import useNotify from 'hooks/useNotify'
import { useAppSelector } from 'clientDashboard/clientDashboard.store'
import { selectLocationId} from 'clientDashboard/employee.selectors'
import StyledButton from 'ui/atoms/StyledButton'
import { omitTypenameDeep } from 'utils/common.utils'
import TillForm from 'clientDashboard/settings/cashManagementSettings/TillForm'
import { CREATE_TILL, UPDATE_TILL } from 'graphql/mutations/till.mutations'
import usePermission from 'hooks/usePermission'
import { Permission } from 'constants/permissions'
import { columns } from 'clientDashboard/settings/cashManagementSettings/tillForm.helpers'

type Props = {
  setSelectedTab: any
}

function TillTab(props: Props) {
    const {setSelectedTab} = props
    const hasAccess = usePermission(Permission.MANAGE_CASH_REGISTERS_AND_TILLS)
    
    const {t} = useTranslation()
    const notify = useNotify()

    const locationId = useAppSelector(selectLocationId)
    const [modalData, setModalData] = React.useState<any>(null)

    const [pagination, setPagination] = React.useState({
      pageIndex: 0,
      pageSize: 10
    })
  
    const [columnFilters, setColumnFilters] = React.useState([])

    const [_addTill] = useMutation(CREATE_TILL, {
        onCompleted: () => {
            notify.show(
                t('cash-management.till.success'),
                'success'
            )
            refetchTill()
            setTimeout(() => {
                setModalData(null)
            })
        },
        onError: (error) => {
          notify.show(error.message, 'error')
        }
    })
    const [_updateTill] = useMutation(UPDATE_TILL, {
        onCompleted: () => {
            notify.show(
                t('cash-management.till.update-success'),
                'success'
            )
            refetchTill()
            setTimeout(() => {
                setModalData(null)
            })
        },
        onError: (error) => {
          notify.show(error.message, 'error')
        }
      })

    const {
        loading: loadingTill,
        error: tillError,
        data: tillData,
        refetch: refetchTill
        } = useQuery(GET_TILLS, {
        variables: {
            locationId: locationId,
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize
        }
    })

    
    const addOrUpdateTill = (data: any) => {
        const {id, location, ...rest} = data || {}
        if (id) {
          _updateTill({
            variables: {
              tillId: id,
              till: {
                name: data.name,
                isActive: data.isActive,
                location: location.id ? location.id : data.location,
                externalTillId: data.externalTillId,
                source: data.source
              }
            }
          })
        } else {
          _addTill({
            variables: {
              till: {
                location: location.id ? location.id : data.location,
                ...rest
              }
            }
          })
        }
      }

    if (tillError) {
        return <p>Error fetching data</p>
    }
  
    return (
      <>
        <StyledTable
          columns={columns}
          data={tillData?.getTills.nodes || []}
          state={{
            showSkeletons: loadingTill,
            showProgressBars: loadingTill,
            columnFilters: columnFilters,
            pagination: pagination
          }}
          enableTableFooter={true}
          enableSorting={false}
          enableStickyHeader={true}
          manualFiltering={true}
          manualPagination={true}
          enableRowActions={true}
          onColumnFiltersChange={setColumnFilters as any}
          onPaginationChange={setPagination}
          rowCount={tillData?.getTills?.totalPageCount || 0}
          initialState={{
            columnPinning: {
                right: ['mrt-row-actions']
            }
          }}

          muiTopToolbarProps ={ {
          }}

          renderTopToolbarCustomActions={() => {
            return (
              <>
                <Box className={'mr-auto'}>
                  <Tabs
                    value={'till'} 
                    onChange={(event: React.ChangeEvent<{}>, value: string) =>
                      setSelectedTab(value)
                    }
                  >
                    <Tab value="register" label="Register"/>
                    <Tab value="till" label="Till" />
                  </Tabs>
                </Box>
                <Grid style={{order: 1}}>
                  <StyledButton 
                    disabled={!hasAccess}
                    onClick={() => { setModalData({})}}>
                      {t('cash-management.action.add-till')}
                  </StyledButton>
                </Grid>
              </>
            )
          }}
          renderRowActions={({row}) => (
            <Box>
                <IconButton
                    disabled={!hasAccess}
                    onClick={() => {
                        setModalData(omitTypenameDeep(row.original))
                    }}
                >
                    <Tooltip title={t('cash-management.action.edit')}>
                        <FontAwesomeIcon
                            icon={icon({
                                name: 'edit',
                                family: 'classic',
                                style: 'light'
                            })}
                            fontSize={20}
                            color='black'
                            style={{
                                borderWidth: '1px'
                            }}
                        />
                    </Tooltip>
                </IconButton>
            </Box>
          )}
        />

        {modalData && (
        <TillForm
          modalData={modalData}
          onSubmit={(data: any) => {
            addOrUpdateTill(data)
          }}
          onClose={() => {
            setModalData(null)
          }}
        />
      )}
      
      </>
    )
  }
  
export default TillTab
