import {Box, Grid, Typography} from '@mui/material'
import theme, {fontSize, space} from 'config/theme'
import {t} from 'i18next'

import {ReactComponent as GenieIcon} from 'assets/sparkles.svg'
import {useAppSelector} from 'consumerAgent/consumerAgent.store'
import {selectChatMessages} from 'consumerAgent/chat.selector'

const ConsumerAgentHotkeys = ({
  setText,
  hotkeys = ['Show me availability', 'Recommend a product'],
  isFollowUp = false
}: {
  setText: (text: string) => void
  isFollowUp?: boolean
  hotkeys?: string[]
}) => {
  const chatMessages = useAppSelector(selectChatMessages)
  return (
    <Grid
      container
      width={'100%'}
      style={{
        padding: space.MEDIUM
      }}
    >
      {!isFollowUp && (
        <Grid
          item
          width={'100%'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: space.MEDIUM,
            marginBottom: space.MEDIUM
          }}
        >
          <Box display='flex' alignItems='center'>
            <GenieIcon />
            <Typography variant='h6' paddingLeft={2}>
              Genie AI
            </Typography>
          </Box>
          <Typography fontSize={fontSize.XL}>
            {t('customer-management.how-can-i-help-you-today')}
          </Typography>
        </Grid>
      )}

      {(!chatMessages?.length || isFollowUp) && (
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          gap={2}
          width={'100%'}
        >
          {hotkeys.map((hotkey, index) => (
            <Grid
              key={'hotkey-' + index}
              item
              xs={10}
              style={{
                cursor: 'pointer',
                borderRadius: '12px',
                backgroundColor: theme.palette.background.paper,
                padding: space.MEDIUM,
                gap: space.SMALL
              }}
              onClick={() => setText(hotkey)}
              display={'flex'}
            >
              <Typography variant='subtitle2'>
                {hotkey + (isFollowUp ? '' : '...')}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  )
}

export default ConsumerAgentHotkeys
