import {Currency, CurrencyMap, DeviceStatus} from 'constants/constants'
import moment from 'moment'
import {getI18n} from 'react-i18next'
import dayjs from 'dayjs'

import {ReactComponent as CriticalHealthIcon} from 'assets/critical-health-point.svg'
import {ReactComponent as HealthyHealthIcon} from 'assets/healthy-health-point.svg'
import {ReactComponent as WarningHealthIcon} from 'assets/warning-health-point.svg'

export const getHealthIcon = (status: string, size = 'small') => {
  let sizeInPx = '20px'
  if (size === 'large') {
    sizeInPx = '24px'
  }
  let Component = HealthyHealthIcon
  switch (status) {
    case DeviceStatus.HEALTHY:
      Component = HealthyHealthIcon
      break
    case DeviceStatus.WARNING:
      Component = WarningHealthIcon
      break
    case DeviceStatus.CRITICAL:
      Component = CriticalHealthIcon
      break
  }

  // viewBox doesn't support px values
  const viewBoxValue = sizeInPx.replace('px', '')
  return (
    <Component
      width={sizeInPx}
      height={sizeInPx}
      viewBox={`0 0 ${viewBoxValue} ${viewBoxValue}`}
    />
  )
}

export const isValidObjectId = (id: string) => {
  const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$')
  return checkForHexRegExp.test(id)
}

export const parseJson = (value: any) => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

export const convertToLocalTimeString = (date: string): string => {
  if (!moment(date).isValid()) {
    return date
  }
  return moment.utc(date).local().format('YYYY-MM-DD hh:mm:ss A')
}

export const diffInMinutes = (startDate: string, endDate: string): string => {
  const duration = moment
    .duration(moment(startDate).diff(moment(endDate)))
    .abs()
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()
  return `${hours}h ${minutes}m ${seconds}s`
}

export const convertCamelCaseToNormalText = (text: string) => {
  return text.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase()
  })
}

export const formatCurrency = (
  amount: number | string,
  currency = Currency.USD
): string => {
  const language = getI18n().language || 'en-US'
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: CurrencyMap[language] || currency
  }).format(parseFloat(amount.toString()))
}

export const centsToDollar = (amount: number): number => {
  return amount / 100
}

export const titleize = (title: string): string => {
  return title
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const formatNumber = (amount: string) => {
  let value = amount.replace(/[$,]/g, '')
  return parseFloat(value)
}

export const base64toBlob = (base64Data: any) => {
  const byteString = atob(base64Data)
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const uintArray = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i)
  }
  return new Blob([arrayBuffer], {type: 'application/octet-stream'})
}

export const localDateTimeString = (date: string) => {
  return moment.utc(date).local().format('MMM DD, YYYY h:mm A')
}

export const beginningOfDay = (date: string) => {
  return moment(date).local().startOf('day').utc().format('MM/DD/YYYY HH:mm:ss')
}

export const endOfDay = (date: string) => {
  return moment(date).local().endOf('day').utc().format('MM/DD/YYYY HH:mm:ss')
}

export const localDateString = (date: string) => {
  return moment(date).local().format('MM/DD/YYYY')
}

export function underscoreToReadable(input: string): string {
  return input.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
}

export function isValidEmail(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function isValidURL(url: string) {
  try {
    url = url.trim().toLowerCase()
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url
    }
    new URL(url)
    return true
  } catch (e) {}
  return false
}

export function getURLHost(url: string) {
  try {
    url = url.trim().toLowerCase()
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url
    }
    const urlObject = new URL(url)
    return urlObject.hostname
  } catch (e) {}
  return ''
}

function stringToColor(string: string) {
  let hash = 0
  let i
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  return color
}

export function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  }
}

export function checkForNegative(value: string) {
  return value.includes('-') as boolean
}

export function checkLength(value: string, threshold: number) {
  return (value.length > threshold) as boolean
}

export function convertToCents(value: string) {
  value = (value + '').replace(/[^\d.-]/g, '')
  if (value && value.includes('.')) {
    value = value.substring(0, value.indexOf('.') + 3)
  }

  return (value ? Math.round(parseFloat(value) * 100) : 0).toString()
  // return (parseFloat(value) * 100).toString()
}

export function convertToDollars(value: any) {
  value = (value + '').replace(/[^\d.-]/g, '')
  value = parseFloat(value)
  return (value ? value / 100 : 0).toString()
}

export function downloadJsonAsCsv(jsonData: any, fileName: string) {
  // Check if jsonData is not empty
  if (!jsonData || !jsonData.length) {
    console.error('No data provided')
    return
  }

  // Extract keys from the first object to use as headers
  const headers = Object.keys(jsonData[0])

  // Convert JSON to CSV
  const csv = [
    headers.join(','), // Header row
    ...jsonData.map((row: any) =>
      headers.map((field) => JSON.stringify(row[field], replacer)).join(',')
    ) // Data rows
  ].join('\r\n')

  // Create a Blob from the CSV string
  const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})

  // Create a link element
  const link = document.createElement('a')
  if (link.download !== undefined) {
    // Set the link attributes
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)

    // Append the link to the body
    document.body.appendChild(link)

    // Simulate a click to trigger the download
    link.click()

    // Remove the link from the document
    document.body.removeChild(link)
  }
}

// Helper function to handle special cases in CSV formatting
function replacer(key: any, value: any) {
  if (value === null) {
    return ''
  }
  if (typeof value === 'string') {
    return value.replace(/"/g, '""') // Escape double quotes
  }
  return value
}

export function formatFileName(fileName: string, extension: any) {
  fileName = fileName.replace(/\s+/g, '-').toLowerCase()
  if (extension) {
    fileName += '.' + extension
  }
  return fileName
}
