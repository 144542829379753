import {graphql} from 'graphql/generatedTypes'

export const FETCH_CUSTOMERS = graphql(`
  query listCustomers(
    $businessId: String!
    $locationId: String!
    $customerId: String
  ) {
    listCustomers(
      businessId: $businessId
      locationId: $locationId
      customerId: $customerId
    ) {
      title
      id
      firstName
      lastName
      avatar
      barcode
      email
      phone
      updatedAt
      preferences {
        key
        title
        value
      }
      address {
        street
        city
        state
        zipCode
        country
      }
    }
  }
`)

export const GENIE_QUERY_FOR_CONSUMER_AGENT = graphql(`
  query GenieQueryConsumerAgent(
    $query: String!
    $chatSessionId: String!
    $queryType: String
    $locationId: String
  ) {
    genieQueryConsumerAgent(
      query: $query
      chatSessionId: $chatSessionId
      queryType: $queryType
      locationId: $locationId
    ) {
      messageId
      queryType
      answer
      query
      status
      chatSessionId
      followups
      genieChatQuery {
        products {
          skuType
          sku
          unitPrice
          unitDiscount
          unitTaxAmount
          description
          unitSubTotal
          aisleLocation
          logoUrl
          promotions {
            description
            promotionType
            promotionValue
            promotionName
            active
            businessId
            canBeCombined
            startDate
            endDate
            priority
            subscriptionType
          }
        }
      }
    }
  }
`)

export const FETCH_GENIE_MESSAGE_FOR_CONSUMER_AGENT = graphql(`
  query FetchGenieMessageConsumerAgent($messageId: String!) {
    fetchGenieMessageConsumerAgent(messageId: $messageId) {
      messageId
      queryType
      answer
      query
      status
      chatSessionId
      followups
      genieChatQuery {
        products {
          skuType
          sku
          unitPrice
          unitDiscount
          unitTaxAmount
          description
          unitSubTotal
          aisleLocation
          logoUrl
          promotions {
            description
            promotionType
            promotionValue
            promotionName
            active
            businessId
            canBeCombined
            startDate
            endDate
            priority
            subscriptionType
          }
        }
      }
    }
  }
`)

export const FETCH_CUSTOMER = graphql(`
  query fetchCustomer {
    fetchCustomer {
      id
      firstName
      lastName
      avatar
      barcode
      email
      phone
      updatedAt
      preferences {
        key
        title
        value
      }
      address {
        street
        city
        state
        zipCode
        country
      }
    }
  }
`)

export const PREDEFINED_CHAT_QUERY_CONSUMER_AGENT = graphql(`
  query PredefinedChatQueryConsumerAgent(
    $queryId: String!
    $locationId: String!
    $businessId: String
  ) {
    predefinedChatQueryConsumerAgent(
      queryId: $queryId
      locationId: $locationId
      businessId: $businessId
    ) {
      products {
        skuType
        sku
        unitPrice
        unitDiscount
        unitTaxAmount
        description
        unitSubTotal
        aisleLocation
        logoUrl
        promotions {
          description
          promotionType
          promotionValue
          promotionName
          active
          businessId
          canBeCombined
          startDate
          endDate
          priority
          subscriptionType
        }
      }
      answer
      query
      queryId
    }
  }
`)
