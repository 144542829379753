import {useLocalizationContext} from '@mui/x-date-pickers/internals'

export function getTimezone<TDate>(
  adapter: ReturnType<typeof useLocalizationContext>,
  value: TDate
): string | null {
  return value == null || !adapter.utils.isValid(value as any)
    ? null
    : adapter.utils.getTimezone(value as any)
}
