import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit'
import {LOGIN_GUEST_FOR_CONSUMER_AGENT} from 'graphql/mutations/login.mutation'
import client from 'config/apollo.config'
import {CustomerType} from 'graphql/generatedTypes/graphql'
import {jwtDecode, JwtPayload} from 'jwt-decode'
import {
  fetchPredefinedChatQuery,
  generateChatSessionId
} from 'consumerAgent/chat.slice'
import {verifyCartForConsumerAgent} from 'consumerAgent/cart.slice'
import {RootState} from 'consumerAgent/consumerAgent.store'
import {reconnectWebsocket} from 'utils/common.utils'

interface ConsumerState {
  locationId: string
  locationName: string
  isLoggedIn: boolean
  isAuthenticated: boolean
  customer: Partial<CustomerType> | null
  sessionToken: string
  businessName: string
  businessId: string
  stripePublishableKey: string
}

const initialState: ConsumerState = {
  locationId: '',
  locationName: '',
  isLoggedIn: false,
  isAuthenticated: false,
  customer: null,
  sessionToken: '',
  businessName: '',
  businessId: '',
  stripePublishableKey: ''
}

// Async Thunk for logging in guest users
export const loginGuestForConsumerAgent = createAsyncThunk(
  'consumer/loginGuestForConsumerAgent',
  async (locationId: string, {dispatch, getState}) => {
    const state = getState() as RootState
    const response = await client.mutate({
      mutation: LOGIN_GUEST_FOR_CONSUMER_AGENT,
      variables: {locationId}
    })

    const {token, location, businessConfigs} = response.data?.loginGuest || {}

    // Dispatch actions to update the state after login
    dispatch(setSessionToken(token || ''))
    dispatch(setIsAuthenticated(true))

    dispatch(setLocationId(location?.id || ''))
    dispatch(setBusinessName(location?.business?.name || ''))
    dispatch(setBusinessId(location?.business?.id || ''))

    dispatch(setIsLoggedIn(true))
    dispatch(
      setStripePublishableKey(businessConfigs?.stripePublishableKey || '')
    )
    dispatch(setLocationName(location?.name || ''))
    !state.chat.chatSessionId && dispatch(generateChatSessionId())
    dispatch(verifyCartForConsumerAgent())

    state.chat.queryId && dispatch(fetchPredefinedChatQuery())

    // Reconnect the subscription client
    await reconnectWebsocket()
  }
)

// Thunk for re-authentication when token expires
export const reAuthenticate = createAsyncThunk(
  'consumer/reAuthenticate',
  async (_, {dispatch, getState}) => {
    const state = getState() as RootState
    const locationId = state.consumer.locationId
    const sessionToken = state.consumer.sessionToken

    try {
      const decodedToken: JwtPayload = jwtDecode(sessionToken)

      const isAuthenticated =
        Number(decodedToken.exp) > Math.round(Date.now() / 1000)

      if (!isAuthenticated) {
        dispatch(loginGuestForConsumerAgent(locationId))
        return
      }

      !state.chat.chatSessionId && dispatch(generateChatSessionId())

      state.chat.queryId && dispatch(fetchPredefinedChatQuery())

      // Reconnect the subscription client
      await reconnectWebsocket()

      dispatch(verifyCartForConsumerAgent())
    } catch (error) {
      await dispatch(loginGuestForConsumerAgent(locationId))
    }
  }
)

// Thunk for sign-out
export const signOut = createAsyncThunk(
  'consumer/signOut',
  async (_, {dispatch}) => {
    dispatch(clearCustomerDetails())
    dispatch(setSessionToken(''))
    dispatch(setLocationId(''))
    dispatch(setIsLoggedIn(false))
    dispatch(setIsAuthenticated(false))
    dispatch(setBusinessId(''))
    dispatch(setBusinessName(''))
    dispatch(setStripePublishableKey(''))
  }
)

// Consumer Slice
const consumerSlice = createSlice({
  name: 'consumer',
  initialState,
  reducers: {
    setLocationId(state, action: PayloadAction<string>) {
      state.locationId = action.payload
    },
    setLocationName(state, action: PayloadAction<string>) {
      state.locationName = action.payload
    },
    setIsLoggedIn(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload
    },
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload
    },
    setBusinessName(state, action: PayloadAction<string>) {
      state.businessName = action.payload
    },
    setBusinessId(state, action: PayloadAction<string>) {
      state.businessId = action.payload
    },
    setSessionToken(state, action: PayloadAction<string>) {
      state.sessionToken = action.payload
    },

    setStripePublishableKey(state, action: PayloadAction<string>) {
      state.stripePublishableKey = action.payload
    },
    clearCustomerDetails(state) {
      state.customer = null
    },
    setCustomerDetails(
      state,
      action: PayloadAction<Partial<CustomerType> | null>
    ) {
      state.customer = action.payload
    }
  }
})

// Export actions and reducer
export const {
  setLocationId,
  setLocationName,
  setIsLoggedIn,
  setIsAuthenticated,
  setBusinessName,
  setBusinessId,
  setSessionToken,
  setStripePublishableKey,
  clearCustomerDetails,
  setCustomerDetails
} = consumerSlice.actions

export default consumerSlice.reducer
