import {Box, Grid, styled, Typography} from '@mui/material'
import {selectCartData} from 'consumerAgent/cart.selector'
import {useAppSelector} from 'consumerAgent/consumerAgent.store'
import classes from 'consumerAgent/consumerAgentCartPriceSummaryCard/nfc-customer.styles.module.css'
import {convertToDollars, formatCurrency} from 'utils/common'

type ConsumerAgentCartPriceSummaryCardProps = {
  waived?: boolean
}

const DiscountValue = styled(Typography)(({theme}) => ({
  color: theme.palette.error.main,
  fontWeight: 'bold',
  textAlign: 'right'
}))

const ConsumerAgentCartPriceSummaryCard = ({
  waived = false
}: ConsumerAgentCartPriceSummaryCardProps) => {
  const cartData = useAppSelector(selectCartData)

  const {
    subTotalAmount = 0,
    // totalAmount = 0,
    totalDiscount = 0,
    deliveryFee = 0,
    totalTax = 0
  } = cartData || {}

  return (
    <Box className={classes.summaryCard}>
      <Grid
        container
        spacing={0}
        alignItems='center'
        style={{height: '100%', width: '100%'}}
        justifyContent='center'
      >
        <Grid item xs={6}>
          <Typography className={classes.priceLabel}>Subtotal</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.priceValue}>
            {formatCurrency(convertToDollars(subTotalAmount.toFixed(2)))}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.priceLabel}>Discount</Typography>
        </Grid>
        <Grid item xs={6}>
          {totalDiscount > 0 ? (
            <DiscountValue className={classes.discountValue}>
              {formatCurrency(convertToDollars(totalDiscount.toFixed(2)))}
            </DiscountValue>
          ) : (
            <Typography className={classes.priceValue}>
              {formatCurrency(convertToDollars(totalDiscount.toFixed(2)))}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.priceLabel}>
            Delivery Fee {waived ? '(Waived)' : ''}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.priceValue}>
            {waived
              ? '$0.00'
              : `${formatCurrency(convertToDollars(deliveryFee.toFixed(2)))}`}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.priceLabel}>Tax</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.priceValue}>
            {formatCurrency(convertToDollars(totalTax.toFixed(2)))}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ConsumerAgentCartPriceSummaryCard
