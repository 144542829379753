import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, Grid, Typography} from '@mui/material'
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

import {selectCartData} from 'consumerAgent/cart.selector'
import {selectStripePublishableKey} from 'consumerAgent/consumer.selector'
import {useAppDispatch, useAppSelector} from 'consumerAgent/consumerAgent.store'
import {
  selectPaymentInProgress,
  selectPaymentIntentClientSecret,
  selectPaymentMetadata
} from 'consumerAgent/payment.selector'
import {
  placeOrder,
  setPaymentGatewayReferenceId,
  setPaymentInProgress,
  setPaymentMetadata
} from 'consumerAgent/payment.slice'
import useNotify from 'hooks/useNotify'
import {forwardRef, useImperativeHandle, useMemo, useRef} from 'react'
import Loading from 'ui/molecules/Loading'

const StripeCheckout = forwardRef((_, ref) => {
  const stripePublishableKey = useAppSelector(selectStripePublishableKey)
  const paymentIntentClientSecret = useAppSelector(
    selectPaymentIntentClientSecret
  )
  const paymentMetadata = useAppSelector(selectPaymentMetadata)

  const checkoutFormRef = useRef(null)

  useImperativeHandle(ref, () => ({
    submitPaymentForm: () => {
      if (checkoutFormRef.current) {
        return (checkoutFormRef.current as any)?.submitPaymentForm?.()
      }
    }
  }))

  const stripePromise = useMemo(
    () => loadStripe(stripePublishableKey),
    [stripePublishableKey]
  )

  if (paymentMetadata.isPaymentSuccessful) {
    return (
      <Box
        sx={{
          textAlign: 'center',
          padding: '20px',
          borderRadius: '10px',
          backgroundColor: '#f0f5f9',
          width: '350px',
          margin: '0 auto',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Box sx={{marginBottom: '20px'}}>
          <FontAwesomeIcon
            icon={icon({
              name: 'check-circle',
              family: 'classic',
              style: 'solid'
            })}
            fontSize={60}
            color='green'
          />
        </Box>

        <Typography variant='h6' gutterBottom>
          Payment Successful
        </Typography>

        <Typography variant='body1' sx={{marginBottom: '10px', color: '#777'}}>
          Order Id: {paymentMetadata.orderId}
        </Typography>

        <Typography variant='body1' sx={{marginBottom: '10px', color: '#777'}}>
          Payment Id: {paymentMetadata.paymentId}
        </Typography>

        <Typography variant='body1' sx={{color: '#777'}}>
          Payment gateway reference Id:{' '}
          {paymentMetadata.paymentGatewayReferenceId}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      {paymentIntentClientSecret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: paymentIntentClientSecret,
            appearance: {
              theme: 'stripe'
            }
          }}
        >
          <CheckoutForm ref={checkoutFormRef} />
        </Elements>
      )}
    </>
  )
})

const CheckoutForm = forwardRef((_, ref) => {
  const stripe = useStripe()
  const elements = useElements()
  const notify = useNotify()
  const dispatch = useAppDispatch()

  const paymentInProgress = useAppSelector(selectPaymentInProgress)
  const paymentMetadata = useAppSelector(selectPaymentMetadata)

  const cartData = useAppSelector(selectCartData)

  useImperativeHandle(ref, () => ({
    submitPaymentForm
  }))

  const submitPaymentForm = async () => {
    if (!stripe || !elements) return

    if (!cartData?.totalAmount) {
      notify.show("Cart amount can't be zero", 'error')
      return
    }

    const {error, paymentIntent} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href
      },
      redirect: 'if_required'
    })

    if (
      error &&
      (error.type === 'card_error' || error.type === 'validation_error')
    ) {
      notify.show(error.message as string, 'error')
    } else if (paymentIntent && paymentIntent.status === 'requires_capture') {
      const _paymentGatewayReferenceId = paymentIntent.id

      dispatch(setPaymentGatewayReferenceId(_paymentGatewayReferenceId))
      dispatch(
        setPaymentMetadata({
          ...paymentMetadata,
          paymentGatewayReferenceId: _paymentGatewayReferenceId
        })
      )
      dispatch(placeOrder())
      dispatch(setPaymentInProgress(true))
    } else {
      notify.show('An unexpected error occurred.', 'error')
      console.error('An unexpected error occurred.', error)
    }
  }

  return (
    <Grid
      item
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center'
      }}
      spacing={4}
    >
      {paymentInProgress && <Loading />}
      {!paymentInProgress && (
        <Grid item>
          <PaymentElement
            id='payment-element'
            options={{
              layout: 'tabs',

              defaultValues: {
                billingDetails: {
                  address: {
                    country: 'auto'
                  }
                }
              }
            }}
          />
        </Grid>
      )}
    </Grid>
  )
})

export default StripeCheckout
