import {useLazyQuery} from '@apollo/client'
import {Button, Card, CardContent, Grid, Typography} from '@mui/material'
import {space} from 'config/theme'
import {LIST_DEVICE_TEMPLATES} from 'graphql/queries/templates.queries'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import Loading from 'ui/molecules/Loading'

const DeviceTemplates = () => {
  const [
    listDeviceTemplates,
    {data: deviceTemplates, loading: deviceTemplatesLoading}
  ] = useLazyQuery(LIST_DEVICE_TEMPLATES, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    listDeviceTemplates()
  }, [])

  if (deviceTemplatesLoading) {
    return (
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Loading />
      </Grid>
    )
  }

  return (
    <Grid
      container
      spacing={2}
      style={{
        marginTop: space.MEDIUM
      }}
    >
      <TemplateCard
        workflow='Order Receipt'
        name={deviceTemplates?.listDeviceTemplates?.[0] || ''}
        isSaved={!!deviceTemplates?.listDeviceTemplates?.[0]}
      />
    </Grid>
  )
}

const TemplateCard = (template: {
  workflow?: string
  name: string
  isSaved: boolean
}) => {
  const history = useHistory()
  const {t} = useTranslation()

  const redirectToTemplateConfig = (templateName: string) => {
    if (template.isSaved) {
      history.push(`templates/${templateName}`)
    } else {
      history.push(`templates/add`)
    }
  }

  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography variant='h6'>{template.workflow}</Typography>
        <Typography color='textSecondary'>{template.name}</Typography>
        <Grid
          style={{
            marginTop: space.MEDIUM,
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            variant='text'
            color='primary'
            onClick={() => {
              redirectToTemplateConfig(template.name)
            }}
          >
            {template.isSaved ? t('edit') : t('add')}
          </Button>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DeviceTemplates
