import 'react-notifications-component/dist/theme.css'
import {Provider as ReduxStoreProvider} from 'react-redux'
import {store, persistor} from 'consumerAgent/consumerAgent.store'
import {PersistGate} from 'redux-persist/integration/react'
import ConsumerAgentCartMerge from 'consumerAgent/ConsumerAgentCartMerge'
import ConsumerAgentHomePage from 'consumerAgent/ConsumerAgentHomePage'
import ConsumerAgentLogin from 'consumerAgent/ConsumerAgentLogin'
import CustomerSignup from 'consumerAgent/CustomerSignup'
import {Route, Switch} from 'react-router-dom'
import CustomerSubscriptions from 'consumerAgent/CustomerSubscriptions'
import CustomerDelivery from 'consumerAgent/CustomerDelivery'
import {ConsumerAgentProvider} from 'consumerAgent/ConsumerAgentContext'

function CustomerRouteHandler() {
  return (
    <ReduxStoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Switch>
          <Route exact path='/customers/signup' component={CustomerSignup} />

          <Route
            exact
            path='/customer/subscriptions'
            component={CustomerSubscriptions}
          />

          <Route exact path='/customer/delivery' component={CustomerDelivery} />

          <Route path='/customers/location/:locationId'>
            <ConsumerAgentProvider>
              <Switch>
                <Route
                  exact
                  path='/customers/location/:locationId/consumer-agent'
                  component={ConsumerAgentHomePage}
                />

                <Route
                  exact
                  path='/customers/location/:locationId/login'
                  component={ConsumerAgentLogin}
                />

                <Route
                  exact
                  path='/customers/location/:locationId/cart/:cartId/merge'
                  component={ConsumerAgentCartMerge}
                />
              </Switch>
            </ConsumerAgentProvider>
          </Route>
        </Switch>
      </PersistGate>
    </ReduxStoreProvider>
  )
}

export default CustomerRouteHandler
