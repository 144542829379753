import StreetAddressField, {
  Address
} from 'ui/organisms/FormBuilder/customFields/StreetAddressField'
import {UseFormReturn} from 'react-hook-form'
import {Field, FieldType} from 'ui/organisms/FormBuilder/FormBuilder'
import _keyBy from 'lodash/keyBy'
import {t} from 'app/i18n'

const fields: Field[] = [
  {
    id: 'detailsHeader',
    label: 'Details',
    type: FieldType.SectionHeader
  },
  {
    id: 'firstName',
    label: t('customers.first-name'),
    type: FieldType.Text,
    required: true
  },
  {
    id: 'lastName',
    label: t('customers.last-name'),
    type: FieldType.Text,
    required: true
  },
  {
    id: 'email',
    label: t('customers.email'),
    type: FieldType.Email,
    required: true
  },
  {
    id: 'phone',
    label: t('customers.phone'),
    type: FieldType.Text,
    required: true
  },
  {
    id: 'Address',
    label: t('customers.address'),
    type: FieldType.SectionHeader
  },
  {
    id: 'address.street',
    label: t('Street'),
    fieldComponent: StreetAddressField,
    span: 12
  },
  {
    id: 'address.city',
    label: t('City')
  },
  {
    id: 'address.state',
    label: t('State')
  },
  {
    id: 'address.zipCode',
    label: t('Zip Code')
  },
  {
    id: 'address.country',
    label: t('Country')
  },
  {
    id: 'preferencesHeader',
    label: t('customers.preferences'),
    type: FieldType.SectionHeader
  }
]

const fieldsById = _keyBy(fields, 'id')

export const getSaveCustomerModalFields = (formContext: UseFormReturn<any>) => {
  fieldsById['address.street'].setFullAddress = (address: Address) => {
    formContext.setValue('address.street', address.street)
    formContext.setValue('address.city', address.city)
    formContext.setValue('address.state', address.state)
    formContext.setValue('address.zipCode', address.zipcode)
    formContext.setValue('address.country', address.country)
  }

  return fields
}
