import {useLazyQuery} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, Grid, IconButton, Tooltip, Typography} from '@mui/material'
import MultipleImages from 'clientDashboard/inventoryManagement/ImageGrid'
import StyledButton from 'ui/atoms/StyledButton'
import StyledTable from 'ui/molecules/StyledTable'
import theme, {colors} from 'config/theme'
import {Permission} from 'constants/permissions'
import {InventoryDetailsType} from 'graphql/generatedTypes/graphql'
import {GET_LOCATION_INVENTORY} from 'graphql/queries/inventory.queries'
import {MRT_ColumnDef} from 'material-react-table'
import React from 'react'
import {useMemo} from 'react'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {convertToCents, convertToDollars, formatCurrency} from 'utils/common'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {
  selectLocationId,
  selectPermissions
} from 'clientDashboard/employee.selectors'
import {SaveInventoryModalData} from 'clientDashboard/inventoryManagement/saveInventoryModal'
import DeleteInventoryModal from 'clientDashboard/inventoryManagement/deleteInventoryModal'

interface Image {
  id: string
  url: string
  title: string
}

function InventoryManager() {
  const {t} = useTranslation('commons')
  const locationId = useAppSelector(selectLocationId)
  const permissions = useAppSelector(selectPermissions)
  const [inventoryData, setInventoryData] = useState<InventoryDetailsType[]>([])
  const [saveInventoryModalData, setSaveInventoryModalData] =
    useState<any>(null)

  const [inventoryToDelete, setInventoryToDelete] =
    useState<InventoryDetailsType | null>(null)
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10
  })
  const [columnFilters, setColumnFilters] = React.useState([])

  const [unitPriceFilter, setUnitPriceFilter] = useState('')
  const [unitCountFilter, setUnitCountFilter] = useState('')
  const [description, setDescription] = useState('')
  const [sku, setSku] = useState('')

  useEffect(() => {
    console.log('columnFilters', columnFilters)
    if (columnFilters.length === 0) {
      setUnitPriceFilter('')
      setUnitCountFilter('')
      setDescription('')
      setSku('')
      return
    }
    columnFilters.forEach((filter: any) => {
      if (filter?.id === 'pricingDetails.unitPrice') {
        setUnitPriceFilter(convertToCents(filter?.value as string))
      }
      if (filter?.id === 'unitCount') {
        setUnitCountFilter(filter?.value)
      }
      if (filter?.id === 'description') {
        setDescription(filter?.value)
      }
      if (filter?.id === 'sku') {
        setSku(filter?.value)
      }
    })
  }, [columnFilters])

  const [
    getLocationInventory,
    {data: _inventoryData, loading: loadingInventories}
  ] = useLazyQuery(GET_LOCATION_INVENTORY, {
    fetchPolicy: 'no-cache',
    variables: {
      locationId: locationId as string,
      description: description,
      sku: sku,
      unitPrice: unitPriceFilter,
      unitCount: unitCountFilter,
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize
    }
  })

  const fetchAllInventories = () => {
    getLocationInventory()
  }

  const getImages = (row: InventoryDetailsType) => {
    const images: Image[] = []
    if (row) {
      images.push({
        id: row.sku as string,
        url: row.logoUrl as string,
        title: ''
      })
    }
    return images
  }

  useEffect(() => {
    fetchAllInventories()
  }, [])

  useEffect(() => {
    if (_inventoryData) {
      setInventoryData(
        _inventoryData.fetchLocationInventory
          ?.inventories as InventoryDetailsType[]
      )
    }
  }, [_inventoryData])

  const sendEmailTooltip = () => {
    return (
      <Tooltip
        title='Send Email to customer when the item is purchased/subscribed'
        placement='right'
      >
        <FontAwesomeIcon
          icon={icon({
            name: 'info-circle',
            family: 'classic',
            style: 'regular'
          })}
          fontSize={14}
        />
      </Tooltip>
    )
  }

  const columns = useMemo<MRT_ColumnDef<InventoryDetailsType>[]>(
    () => [
      {
        accessorKey: 'sku',
        header: 'SKU',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'description',
        header: 'Description',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'pricingDetails.unitPrice',
        header: 'Unit Price',
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {formatCurrency(convertToDollars(cell.getValue()))}
            </Typography>
          )
        },
        muiTableBodyCellProps: {
          align: 'right'
        }
      },
      {
        accessorKey: 'unitCount',
        header: 'Unit Count',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        },
        muiTableBodyCellProps: {
          align: 'right'
        }
      }
    ],

    []
  )

  return (
    <>
      <StyledTable
        columns={columns}
        data={inventoryData}
        enableRowActions
        onPaginationChange={setPagination}
        manualPagination={true}
        manualFiltering={true}
        onColumnFiltersChange={setColumnFilters as any}
        rowCount={_inventoryData?.fetchLocationInventory?.totalPageCount || 0}
        state={{
          showSkeletons: loadingInventories,
          showProgressBars: loadingInventories,
          pagination: pagination
        }}
        initialState={{
          columnPinning: {
            left: ['mrt-row-expand'],
            right: ['mrt-row-actions']
          }
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Grid
              style={{
                order: 1
              }}
            >
              <StyledButton onClick={() => setSaveInventoryModalData({})}>
                Add Inventory
              </StyledButton>
            </Grid>
          )
        }}
        renderDetailPanel={({row}) => {
          if (loadingInventories) return ''
          return (
            <Box
              style={{
                padding: '10px',
                borderRadius: '10px',
                border: '1px solid',
                borderColor: colors.grayBorder,
                backgroundColor: theme.palette.background.paper
              }}
            >
              <Grid
                container
                spacing={2}
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Grid item xs={3}>
                  <Typography variant='h3'>Tax Details</Typography>
                  <Typography variant='subtitle2'>
                    Tax Group: {row.original.taxDetails?.taxGroup}
                  </Typography>
                  <Typography variant='subtitle2'>
                    Tax Rate: {row.original.taxDetails?.taxRate}
                  </Typography>
                  <Typography variant='subtitle2'>
                    Taxable:{' '}
                    {row.original.taxable?.toLowerCase() === 'yes'
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant='h3'>Pricing Details</Typography>
                  <Typography variant='subtitle2'>
                    Unit Price:{' '}
                    {formatCurrency(
                      convertToDollars(
                        row.original.pricingDetails.unitPrice as string
                      )
                    )}
                  </Typography>
                  <Typography variant='subtitle2'>
                    Currency: {row.original.pricingDetails.currency}
                  </Typography>
                  <Typography variant='subtitle2'>
                    Discount:{' '}
                    {formatCurrency(
                      convertToDollars(
                        row.original.pricingDetails?.discount as string
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant='h3'>Unit Details</Typography>
                  <Typography variant='subtitle2'>
                    Unit: {row.original.unit}
                  </Typography>
                  <Typography variant='subtitle2'>
                    Unit Count: {row.original.unitCount}
                  </Typography>
                </Grid>
                {row.original.upc && (
                  <Grid item xs={3}>
                    <Typography variant='h3'>UPC</Typography>
                    <Typography variant='subtitle2'>
                      {row.original.upc}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={3}>
                  <Typography variant='h3'>SKU Type</Typography>
                  <Typography variant='subtitle2'>
                    {row.original.skuType}
                  </Typography>
                </Grid>
                {row.original.categoryName && (
                  <Grid item xs={3}>
                    <Typography variant='h3'>Category Name</Typography>
                    <Typography variant='subtitle2'>
                      {row.original.categoryName}
                    </Typography>
                  </Grid>
                )}
                {row.original.className && (
                  <Grid item xs={3}>
                    <Typography variant='h3'>Class Name</Typography>
                    <Typography variant='subtitle2'>
                      {row.original.className}
                    </Typography>
                  </Grid>
                )}
                {row.original.subclassName && (
                  <Grid item xs={3}>
                    <Typography variant='h3'>Subclass Name</Typography>
                    <Typography variant='subtitle2'>
                      {row.original.subclassName}
                    </Typography>
                  </Grid>
                )}
                {row.original.recommendedProducts &&
                  row.original.recommendedProducts.length > 0 && (
                    <Grid item xs={3}>
                      <Typography variant='h3'>Recommended Skus</Typography>
                      {row.original.recommendedProducts.map(
                        (product, index) => (
                          <Typography variant='subtitle2' key={product.sku}>
                            {product.sku} ({product.description})
                            {index ===
                            (row.original.recommendedProducts
                              ?.length as number) -
                              1
                              ? ''
                              : ', '}
                          </Typography>
                        )
                      )}
                    </Grid>
                  )}

                <Grid item xs={3}>
                  <Typography variant='h3'>Images</Typography>
                  <MultipleImages images={getImages(row.original)} />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='h3'>
                    Send Email {sendEmailTooltip()}
                  </Typography>
                  <Typography variant='subtitle2'>
                    {row.original.sendEmail ? 'Yes' : 'No'}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='h3'>Age Verification</Typography>
                  <Typography variant='subtitle2'>
                    {row.original.ageVerificationRequired ? 'Yes' : 'No'}
                  </Typography>
                  {row.original.ageVerificationRequired && (
                    <Typography variant='subtitle2'>
                      Required Minimum Age: {row.original.requiredMinimumAge}{' '}
                      year(s)
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          )
        }}
        renderRowActions={({row}) => (
          <Box>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => {
                  setSaveInventoryModalData(row.original)
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'edit',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  color='black'
                  style={{
                    borderWidth: '1px'
                  }}
                />
              </IconButton>
            </Tooltip>
            {permissions?.includes(Permission.INVENTORY_DELETE) && (
              <Tooltip title={t('delete')}>
                <IconButton
                  onClick={() => {
                    setInventoryToDelete(row.original as InventoryDetailsType)
                  }}
                >
                  <FontAwesomeIcon
                    icon={icon({
                      name: 'trash-alt',
                      family: 'classic',
                      style: 'light'
                    })}
                    fontSize={20}
                    color='black'
                    style={{
                      borderWidth: '1px'
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      />
      {saveInventoryModalData && (
        <SaveInventoryModalData
          onSubmit={() => {
            fetchAllInventories()
            setSaveInventoryModalData(null)
          }}
          onClose={() => {
            setSaveInventoryModalData(null)
          }}
          saveInventoryModalData={saveInventoryModalData}
          skus={inventoryData.map((inventory) => inventory.sku)}
        />
      )}

      {inventoryToDelete && (
        <DeleteInventoryModal
          onClose={() => {
            setInventoryToDelete(null)
          }}
          inventoryData={inventoryToDelete}
          onSubmit={() => {
            setInventoryToDelete(null)
            fetchAllInventories()
          }}
        />
      )}
    </>
  )
}

export default InventoryManager
