import {useMutation} from '@apollo/client'
import {Grid, Link, Typography} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import StyledButton from 'ui/atoms/StyledButton'
import {UPDATE_BUSINESS_COLORS} from 'graphql/mutations/business.mutation'
import useNotify from 'hooks/useNotify'
import {useEffect, useState} from 'react'
import {ChromePicker} from 'react-color'
import {useTranslation} from 'react-i18next'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessAndLocation} from 'clientDashboard/employee.selectors'

function BusinessColors() {
  const {authInfo} = useAuth()
  const {businessId, locationId} = useAppSelector(selectBusinessAndLocation)
  const notify = useNotify()
  const {t} = useTranslation()

  const [primaryColor, setPrimaryColor] = useState('#000000')
  const [secondaryColor, setSecondaryColor] = useState('#000000')
  const handlePrimaryChange = (color: any) => {
    setPrimaryColor(color.hex)
  }

  const [updateBusinessColors] = useMutation(UPDATE_BUSINESS_COLORS, {
    onCompleted: (data) => {
      setPrimaryColor(data.updateBusinessColors.primary as string)
      setSecondaryColor(data.updateBusinessColors.secondary as string)
      notify.show(
        t('business-colors.business-colors-updated-successfully'),
        'success'
      )
    },
    onError(error) {
      notify.show(t('business-colors.something-went-wrong'), 'error')
    }
  })

  useEffect(() => {
    if (authInfo.primaryColor && authInfo.secondaryColor) {
      setPrimaryColor(authInfo.primaryColor as string)
      setSecondaryColor(authInfo.secondaryColor as string)
    }
  }, [authInfo.primaryColor, authInfo.secondaryColor])

  const handleSecondaryChange = (color: any) => {
    setSecondaryColor(color.hex)
  }

  return (
    <Grid
      spacing={4}
      container
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Grid item>
        <Typography variant='h1'>
          {t('business-colors.business-colors')}
        </Typography>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Typography variant='subtitle1' color={'text.secondary'}>
            These colors will be used in the consumer agent, to test{' '}
            <Link
              // new tab
              target='_blank'
              href={
                window.location.origin +
                `/customer?location_id=${locationId}`
              }
            >
              Click here
            </Link>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        item
        spacing={4}
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Grid item>
          <Grid item>
            <Typography variant='h6'>
              {t('business-colors.primary-color')}
            </Typography>
          </Grid>

          <Grid item>
            <ChromePicker color={primaryColor} onChange={handlePrimaryChange} />
          </Grid>
        </Grid>

        <Grid item>
          <Grid item>
            <Typography variant='h6'>
              {t('business-colors.secondary-color')}
            </Typography>
          </Grid>

          <Grid item>
            <ChromePicker
              color={secondaryColor}
              onChange={handleSecondaryChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <StyledButton
          onClick={() => {
            updateBusinessColors({
              variables: {
                businessId,
                primary: primaryColor,
                secondary: secondaryColor
              }
            })
          }}
        >
          {t('business-colors.update')}
        </StyledButton>
      </Grid>
    </Grid>
  )
}

export default BusinessColors
