import {combineSlices, configureStore} from '@reduxjs/toolkit'
import {useDispatch, useSelector} from 'react-redux'
import storage from 'redux-persist/lib/storage'
import {persistStore, persistReducer} from 'redux-persist'
import cartReducer from './cart.slice'
import chatReducer from './chat.slice'
import consumerReducer from './consumer.slice'
import uiReducer from './ui.slice'
import paymentReducer from './payment.slice'

const persistConfig = {
  key: 'root',
  storage, // localStorage
  whitelist: ['cart', 'consumer', 'chat']
}

const rootReducer = combineSlices({
  cart: cartReducer,
  chat: chatReducer,
  consumer: consumerReducer,
  ui: uiReducer,
  payment: paymentReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: {
    name: 'Consumer Agent'
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

const persistor = persistStore(store)

export {store, persistor}
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
