import {Grid, Typography} from '@mui/material'
import LocationManager from 'clientDashboard/settings/locations/LocationManager'
import PermissionWrapper from 'clientDashboard/settings/PermissionWrapper'
import {Permission} from 'constants/permissions'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

function LocationSettings() {
  const location = useLocation()
  const passedData = location.state
  const [headerDescription, setHeaderDescription] =
    useState<string>('Manage Locations')
  useEffect(() => {
    if (!passedData) {
      return
    }
    let jsonData = JSON.parse(JSON.stringify(passedData))
    if (!jsonData) {
      return
    }
    if (jsonData.headerDescription) {
      setHeaderDescription(jsonData.headerDescription)
    }
  }, [passedData])
  return (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}
      spacing={4}
    >
      <Grid
        item
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start'
        }}
      >
        <Grid item>
          <Typography variant='h2'>{headerDescription}</Typography>
        </Grid>

        <PermissionWrapper permissions={[Permission.READ_LOCATION]}>
          <Grid item>
            <LocationManager />
          </Grid>
        </PermissionWrapper>
      </Grid>
    </Grid>
  )
}

export default LocationSettings
