import {Grid, Typography} from '@mui/material'
import StyledInput from 'ui/atoms/StyledInput'
import {set} from 'lodash'
import {useEffect, useMemo, useState} from 'react'

type AddressFieldsProps = {
  setAddress: (address: {
    street: string
    city: string
    state: string
    zipCode: string
    country: string
  }) => void

  address: {
    street: string
    city: string
    state: string
    zipCode: string
    country: string
  }
  heading?: string
}

const AddressFields = ({address, setAddress, heading}: AddressFieldsProps) => {
  // TODO: instead of querySelector, use useRef
  let streetHTMLInputElement: HTMLInputElement = document.querySelector(
    '#street-input'
  ) as HTMLInputElement

  const [formValues, setFormValues] = useState(address)

  const handleChange = useMemo(
    () => (path: string, value: string) => {
      setFormValues((prev) => {
        return set({...prev}, path, value)
      })
    },
    []
  )

  useEffect(() => {
    if (!streetHTMLInputElement || !window?.google?.maps) return

    const fillInAddress = () => {
      const place = autocomplete.getPlace()
      let _zipcode = ''
      let _street = ''
      let _city = ''
      let _state = ''
      let _country = ''
      if (place && place.address_components) {
        for (const component of place?.address_components as google.maps.GeocoderAddressComponent[]) {
          const componentType = component.types[0]
          switch (componentType) {
            case 'street_number': {
              _street = `${component.long_name}`
              break
            }

            case 'route': {
              _street += ' ' + component.short_name
              break
            }

            case 'postal_code': {
              _zipcode = `${component.long_name}`
              break
            }

            case 'locality':
              _city = component.long_name
              break

            case 'sublocality_level_1':
              _city = component.long_name
              break

            case 'administrative_area_level_1': {
              _state = component.short_name
              break
            }

            case 'country': {
              _country = component.long_name
              break
            }
          }
        }
        handleChange('city', _city)
        handleChange('state', _state)
        handleChange('zipCode', _zipcode)
        handleChange('street', _street)
        handleChange('country', _country)
      }
    }

    const autocomplete = new google.maps.places.Autocomplete(
      streetHTMLInputElement,
      {
        fields: ['address_components', 'geometry'],
        types: ['address'],
        componentRestrictions: {
          country: 'us'
        }
      }
    )

    autocomplete.addListener('place_changed', fillInAddress)

    return () => {
      google.maps.event.clearInstanceListeners(streetHTMLInputElement)
    }
  }, [streetHTMLInputElement])

  useEffect(() => {
    setAddress(formValues)
  }, [formValues])

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h4'>{heading || 'Address'}</Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledInput
          id='street-input'
          fullWidth
          label='Street'
          variant='outlined'
          value={formValues.street}
          onChange={(e: any) => handleChange('street', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledInput
          id='city-input'
          fullWidth
          label='City'
          variant='outlined'
          value={formValues.city}
          onChange={(e: any) => handleChange('city', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledInput
          id='state-input'
          fullWidth
          label='State'
          variant='outlined'
          value={formValues.state}
          onChange={(e: any) => handleChange('state', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledInput
          id='zipcode-input'
          fullWidth
          label='Zip Code'
          variant='outlined'
          value={formValues.zipCode}
          onChange={(e: any) => handleChange('zipCode', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledInput
          id='country-input'
          fullWidth
          label='Country'
          variant='outlined'
          value={formValues.country}
          onChange={(e: any) => handleChange('country', e.target.value)}
        />
      </Grid>
    </>
  )
}

export default AddressFields
