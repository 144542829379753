import {useLazyQuery, useMutation} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, Grid, IconButton, Tooltip, Typography} from '@mui/material'
import EmployeeCreateUpdateDialogue from 'ui/organisms/EmployeeConfigurationDialog'
import StyledButton from 'ui/atoms/StyledButton'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledTable from 'ui/molecules/StyledTable'
import {AuthMethod, LocalStorageKeys, LocationStatus} from 'constants/constants'
import {Permission} from 'constants/permissions'
import {EmployeeType} from 'graphql/generatedTypes/graphql'
import {
  GET_ALL_EMPLOYEES,
  SEND_PASSWORD_SET_LINK
} from 'graphql/queries/employee.queries'
import {GET_LOCATIONS_WITH_GROUPS} from 'graphql/queries/location.queries'
import {GET_ALL_ROLES_AND_PERMISSIONS} from 'graphql/queries/role.queries'
import useLocalStorage from 'hooks/useLocalStorage'
import useNotify from 'hooks/useNotify'
import usePermission from 'hooks/usePermission'
import {MRT_ColumnDef} from 'material-react-table'
import React from 'react'
import {useCallback, useMemo} from 'react'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  useAppDispatch,
  useAppSelector
} from 'clientDashboard/clientDashboard.store'
import {
  selectAppliedLocationGroups, selectBusinessAndLocation,
  selectClusters,
  selectSelectedLocationGroupIds
} from 'clientDashboard/employee.selectors'
import {FETCH_STORE_GROUPS} from 'graphql/queries/store-groups'
import {
  setAllGroupsAndClusters,
  setFullLocationList
} from 'clientDashboard/settings/employees/employeeManagement.slice'

function EmployeeManager() {
  const dispatch = useAppDispatch()
  const clusters = useAppSelector(selectClusters)

  const notify = useNotify()

  const {t} = useTranslation()

  const {businessId, locationId} = useAppSelector(selectBusinessAndLocation)
  const [authMethod] = useLocalStorage(
    LocalStorageKeys.AUTH_METHOD,
    AuthMethod.TOTE
  )

  const hasEmployeeManagerAccess = usePermission(Permission.MANAGE_EMPLOYEE)

  const [allEmployees, setAllEmployees] = useState<any>([])

  const [showCreateEmployeeDialog, setShowCreateEmployeeDialog] =
    useState(false)

  const [isEditMode, setIsEditMode] = useState(false)
  const [editEmployeeId, setEditEmployeeId] = useState('')
  const [employeeIdForPasswordReset, setEmployeeIdForPasswordReset] =
    useState('')
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  })
  const [totalEmployeePageCount, setTotalEmployeePageCount] = useState(0)
  const [columnFilters, setColumnFilters] = useState([])

  const [allRoles, setAllRoles] = useState<
    {
      id: string
      name: string
    }[]
  >([])

  const [allLocations, setAllLocations] = useState<
    {
      id: string
      lable: string
    }[]
  >([])

  const [getRoleAndPermissions, {data: _rolesAndPermissionsData}] =
    useLazyQuery(GET_ALL_ROLES_AND_PERMISSIONS, {
      fetchPolicy: 'no-cache'
    })

  const [fetchStoreGroups] = useLazyQuery(FETCH_STORE_GROUPS, {
    onCompleted: (data) => {
      dispatch(
        setAllGroupsAndClusters({
          ...data,
          clusters
        })
      )
    }
  })

  useEffect(() => {
    if (_rolesAndPermissionsData?.getAllRoles?.length) {
      setAllRoles(
        _rolesAndPermissionsData.getAllRoles.map((role) => ({
          id: role.id,
          name: role.name
        }))
      )
    }
  }, [_rolesAndPermissionsData])

  const [nameFilter, setNameFilter] = useState('')
  const [emailFilter, setEmailFilter] = useState('')
  const [businessRolesFilter, setBusinessRolesFilter] =
    React.useState<string>('')
  const [employeeCodeFilter, setEmployeeCodeFilter] = useState('')
  const appliedLocationGroups = useAppSelector(selectAppliedLocationGroups)
  const selectedLocationGroupIds = useAppSelector(
    selectSelectedLocationGroupIds
  )

  useEffect(() => {
    if (columnFilters.length === 0) {
      setNameFilter('')
      setEmailFilter('')
      setBusinessRolesFilter('')
      setEmployeeCodeFilter('')
      return
    }
    columnFilters.forEach((filter: any) => {
      if (filter?.id === 'name') {
        setNameFilter(filter?.value)
      }
      if (filter?.id === 'email') {
        setEmailFilter(filter?.value)
      }
      if (filter?.id === 'role.name') {
        setBusinessRolesFilter(filter?.value?.join(',') as string)
      }
      if (filter?.id === 'employeeCode') {
        setEmployeeCodeFilter(filter?.value)
      }
    })
  }, [columnFilters])

  useEffect(() => {
    getRoleAndPermissions({
      variables: {
        businessId
      }
    })
    fetchStoreGroups()
  }, [])

  const [getLocations] = useLazyQuery(GET_LOCATIONS_WITH_GROUPS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(setFullLocationList(data.getAllLocations))

      setAllLocations(
        data.getAllLocations.map((location) => {
          return {
            id: location.id,
            lable: location.name
          }
        })
      )
    }
  })

  const [_sendPasswordSetLink] = useMutation(SEND_PASSWORD_SET_LINK, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      notify.show(
        t('employee-management.password-reset-link-sent-message'),
        'success'
      )
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const fetchAllLocations = useCallback(() => {
    getLocations({
      variables: {
        businessId,
        status: LocationStatus.ACTIVE
      }
    })
  }, [getLocations, businessId])

  const [getAllEmployees, {data: _employeesData, loading: loadingEmployees}] =
    useLazyQuery(GET_ALL_EMPLOYEES, {
      fetchPolicy: 'no-cache',
      variables: {
        businessId,
        locationId,
        name: nameFilter,
        email: emailFilter,
        role: businessRolesFilter,
        employeeCode: employeeCodeFilter,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        ...selectedLocationGroupIds
      }
    })

  useEffect(() => {
    fetchAllLocations()
    getAllEmployees()
  }, [])

  useEffect(() => {
    if (!selectedLocationGroupIds) {
      return
    }
    fetchAllLocations()
    getAllEmployees()
  }, [appliedLocationGroups])

  useEffect(() => {
    if (_employeesData?.getAllEmployees) {
      setAllEmployees(_employeesData.getAllEmployees.employees)
      setTotalEmployeePageCount(_employeesData.getAllEmployees.totalCount)
    }
  }, [_employeesData])

  const sendPasswordResetLink = useCallback(
    (employeeId: string) => {
      const employeeEmail = allEmployees.find(
        (employee: any) => employee.id === employeeId
      )?.email
      if (employeeEmail) {
        _sendPasswordSetLink({
          variables: {
            email: employeeEmail,
            businessId
          }
        })
      }
    },
    [_sendPasswordSetLink, allEmployees, businessId]
  )

  const columns = useMemo<MRT_ColumnDef<EmployeeType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('employee-management.table-headers.name'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        },
        enableEditing: false,
        enableSorting: true
      },
      {
        accessorKey: 'email',
        header: t('employee-management.table-headers.email'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        },
        enableEditing: false,
        enableSorting: false
      },
      {
        accessorKey: 'role.name',
        header: t('employee-management.table-headers.role'),
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() || '-'}
            </Typography>
          )
        },
        enableColumnFilter: true,
        muiFilterTextFieldProps: {
          placeholder: 'All',
          sx: {
            minWidth: '100px'
          }
        },
        filterVariant: 'multi-select',
        filterSelectOptions: allRoles?.map((role: any) => ({
          label: role.name,
          value: role.id
        })),
        enableEditing: false,
        enableSorting: false
      },
      {
        accessorKey: 'employeeCode',
        header: t('employee-management.table-headers.employee-code'),
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        },
        enableEditing: false
      }
    ],

    [allRoles]
  )

  return (
    <>
      <StyledTable
        columns={columns}
        data={allEmployees}
        getRowId={(row) => row.id}
        enableRowActions={AuthMethod.TOTE === authMethod}
        onPaginationChange={setPagination}
        manualPagination={true}
        manualFiltering={true}
        onColumnFiltersChange={setColumnFilters as any}
        rowCount={totalEmployeePageCount || 0}
        state={{
          showSkeletons: loadingEmployees,
          showProgressBars: loadingEmployees,
          pagination: pagination
        }}
        enableSorting={false}
        initialState={{
          columnPinning: {
            right: ['mrt-row-actions']
          },
          columnOrder: [
            'name',
            'email',
            'role.name',
            'employeeCode',
            'mrt-row-actions'
          ],
          sorting: [
            {
              id: 'name',
              desc: false
            }
          ]
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Grid
              style={{
                order: 1
              }}
            >
              <StyledButton
                onClick={() => {
                  setShowCreateEmployeeDialog(true)
                }}
                disabled={!hasEmployeeManagerAccess}
              >
                {t('employee-management.create-employee')}
              </StyledButton>
            </Grid>
          )
        }}
        renderRowActions={({row}) => (
          <Box>
            <Tooltip title={t('employee-management.edit-employee')}>
              <IconButton
                disabled={!hasEmployeeManagerAccess}
                onClick={() => {
                  setIsEditMode(true)
                  setShowCreateEmployeeDialog(true)
                  setEditEmployeeId(row.id)
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'edit',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  style={{
                    borderWidth: '1px'
                  }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={t('employee-management.send-password-reset-link-tooltip')}
            >
              <IconButton
                disabled={!hasEmployeeManagerAccess}
                onClick={() => {
                  setEmployeeIdForPasswordReset(row.id)
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'key',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  style={{
                    borderWidth: '1px'
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />

      <EmployeeCreateUpdateDialogue
        allRoles={allRoles}
        allLocations={allLocations}
        allEmployees={allEmployees}
        businessId={businessId}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        showCreateEmployeeDialog={showCreateEmployeeDialog}
        setShowCreateEmployeeDialog={setShowCreateEmployeeDialog}
        setAllEmployees={setAllEmployees}
        editEmployeeId={editEmployeeId}
      />

      {employeeIdForPasswordReset && (
        <StyledDialog
          open={!!employeeIdForPasswordReset}
          onClose={() => {
            setEmployeeIdForPasswordReset('')
          }}
          title={t('employee-management.are-you-sure')}
          successButtonName={t('employee-management.send')}
          cancelButtonName={t('employee-management.cancel')}
          cancelCallback={() => {
            setEmployeeIdForPasswordReset('')
          }}
          successCallback={() => {
            // send password reset link
            sendPasswordResetLink(employeeIdForPasswordReset)
            setEmployeeIdForPasswordReset('')
          }}
          body={
            <Grid>
              <Typography variant='subtitle2'>
                {t(
                  'employee-management.are-you-sure-you-want-to-send-password-reset-link-to'
                )}{' '}
                {
                  allEmployees.find(
                    (employee: any) =>
                      employee.id === employeeIdForPasswordReset
                  )?.name
                }{' '}
                at{' '}
                {
                  allEmployees.find(
                    (employee: any) =>
                      employee.id === employeeIdForPasswordReset
                  )?.email
                }
                ?
              </Typography>
            </Grid>
          }
        />
      )}
    </>
  )
}

export default EmployeeManager
