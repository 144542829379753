import {useLazyQuery} from '@apollo/client'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessId} from 'clientDashboard/employee.selectors'
import {GET_TOTALS_FOR_PROMOTIONS} from 'graphql/queries/promo-engine.queries'
import React, {useState} from 'react'

interface PromotionAssignmentDialogProps {
  open: boolean
  onClose: () => void
  productSkuList: string[]
  promotionsList: string[]
}

interface ProductSelection {
  sku: string
  quantity: number
}

const PromotionAssignmentDialog: React.FC<PromotionAssignmentDialogProps> = ({
  open,
  onClose,
  productSkuList,
  promotionsList
}) => {
  const businessId = useAppSelector(selectBusinessId)

  const [products, setProducts] = useState<ProductSelection[]>([
    {sku: '', quantity: 1}
  ])
  const [selectedPromotions, setSelectedPromotions] = useState<string[]>([])

  const [
    fetchTotals,
    {data: totalsData, loading: totalsLoading, error: totalsError}
  ] = useLazyQuery(GET_TOTALS_FOR_PROMOTIONS, {
    variables: {
      businessId: businessId
      // Example variables structure, adjust based on your actual GraphQL operation
    },
    fetchPolicy: 'no-cache'
  })

  const handleProductChange = (
    index: number,
    value: string | number,
    type: keyof ProductSelection
  ) => {
    const updatedProducts = products.map((product, i) => {
      if (i === index) {
        return {...product, [type]: value}
      }
      return product
    })

    setProducts(updatedProducts)
  }

  const handleRemoveProduct = (index: number) => {
    setProducts(products.filter((_, i) => i !== index))
  }

  const handleSubmit = () => {
    // Perform your submission logic here, e.g., fetching totals for the selected SKUs and promotions
    console.log('Selected products:', products[0]['sku'])
    fetchTotals({
      variables: {
        sku: products[0]['sku'],
        quantity: products[0]['quantity'],
        promotionNames: selectedPromotions,
        businessId: businessId
      }
    })
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>Assign Promotions to Products</DialogTitle>
      <DialogContent>
        {products.map((product, index) => (
          <Grid container spacing={2} key={index} alignItems='center'>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel>Product SKU</InputLabel>
                <Select
                  value={product.sku}
                  onChange={(e) =>
                    handleProductChange(index, e.target.value as string, 'sku')
                  }
                >
                  {productSkuList.map((sku) => (
                    <MenuItem key={sku} value={sku}>
                      {sku}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                type='number'
                label='Quantity'
                value={product.quantity}
                onChange={(e) =>
                  handleProductChange(
                    index,
                    parseInt(e.target.value, 10),
                    'quantity'
                  )
                }
                InputProps={{inputProps: {min: 1}}}
              />
            </Grid>
            <Grid item xs={2}>
              <Button onClick={() => handleRemoveProduct(index)}>-</Button>
            </Grid>
          </Grid>
        ))}
        <FormControl fullWidth sx={{mt: 2}}>
          <InputLabel>Promotions</InputLabel>

          <Select
            labelId='rules-label'
            id='rules-select'
            multiple
            value={selectedPromotions}
            onChange={(e) => setSelectedPromotions(e.target.value as string[])}
          >
            {promotionsList && promotionsList.length > 0 ? (
              promotionsList.map((promotion) => (
                <MenuItem key={promotion} value={promotion}>
                  {promotion}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No Promotions Available</MenuItem>
            )}
          </Select>
        </FormControl>
        <Box sx={{mt: 2}}>
          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={totalsLoading}
          >
            Get Totals
          </Button>
        </Box>
        {totalsLoading && <Typography>Loading totals...</Typography>}
        {totalsData && (
          <Box sx={{mt: 2}}>
            <Typography>
              Total Amount: {totalsData.getTotalsForPromotions.totalAmount}
            </Typography>
            <Typography>
              Total Discount:{' '}
              {totalsData.getTotalsForPromotions.promotionAmount}
            </Typography>
          </Box>
        )}
        {totalsError && (
          <Typography color='error'>
            Error fetching totals: {totalsError.message}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default PromotionAssignmentDialog
