import StyledDialog from 'ui/molecules/StyledDialog'
import {useTranslation} from 'react-i18next'
import FormBuilder from 'ui/organisms/FormBuilder/FormBuilder'
import {useForm} from 'react-hook-form'
import {LocationStatus} from 'constants/constants'
import {getEditLocationFields} from 'clientDashboard/settings/locations/editLocationModal.helpers'
import {Button} from '@mui/material'
import {useAppSelector} from 'adminPanel/adminPanel.store'
import {selectFeatureFlags} from 'clientDashboard/business.selectors'
import {useHistory} from 'react-router-dom'
import {selectBusinessAndLocation} from 'clientDashboard/employee.selectors'
import {selectStoreNumbersAndNames} from 'clientDashboard/settings/locations/locations.selectors'

const defaultTimezone = 'America/Los_Angeles'
const defaultValues = {
  name: '',
  brand: '',
  description: '',
  storeNumber: '',
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
  phoneNumbers: {
    contactPhone: '',
    supportPhone: ''
  },
  operatingHours: [
    {day: 'monday', fromHour: '10:00', toHour: '18:00'},
    {day: 'tuesday', fromHour: '10:00', toHour: '18:00'},
    {day: 'wednesday', fromHour: '10:00', toHour: '18:00'},
    {day: 'thursday', fromHour: '10:00', toHour: '18:00'},
    {day: 'friday', fromHour: '10:00', toHour: '18:00'},
    {day: 'saturday', fromHour: '10:00', toHour: '18:00'},
    {day: 'sunday', fromHour: '10:00', toHour: '18:00'}
  ],
  timeZone: defaultTimezone,
  status: LocationStatus.ACTIVE,
  notificationEmails: [''] as string[]
}

type Props = {
  editLocationModalData: any
  onSubmit: (data: any) => void
  onClose: () => void
}

const EditLocationModal = (props: Props) => {
  const {editLocationModalData, onSubmit, onClose} = props
  const formContext = useForm<any>({
    defaultValues,
    values: editLocationModalData,
    resetOptions: {
      keepDefaultValues: true
    }
  })
  const {t} = useTranslation()
  const history = useHistory()

  const {businessId} = useAppSelector(selectBusinessAndLocation)
  const featureFlags = useAppSelector(selectFeatureFlags)
  const { storeNumbers, storeNames } = useAppSelector(selectStoreNumbersAndNames)

  const {isValid} = formContext.formState
  const isEditMode = !!editLocationModalData?.id

  const locationConfigurationDialogBody = () => {
    return (
      <div className={'px-2'}>
        <FormBuilder
          formContext={formContext}
          fields={getEditLocationFields({formContext, storeNumbers, storeNames, editLocationModalData})}
        />
        {/* Location Groups */}
        {featureFlags.locationGrouping && (
          <div className={'mt-2 mb-2 flex justify-end'}>
            <Button
              onClick={() => history.push(`/${businessId}/location-grouping`)}
              variant={'text'}
              sx={{
                paddingLeft: 0,
                justifyContent: 'flex-start'
              }}
            >
              Assign Location Groups
            </Button>
          </div>
        )}
      </div>
    )
  }

  return (
    <StyledDialog
      open
      onClose={() => {
        onClose()
      }}
      maxWidth={'sm'}
      title={
        isEditMode
          ? t('location-management.edit-location')
          : t('location-management.add-location')
      }
      successButtonName={
        isEditMode
          ? t('location-management.button-names.update')
          : t('location-management.button-names.add')
      }
      cancelButtonName={t('location-management.button-names.cancel')}
      cancelCallback={() => {
        onClose()
      }}
      disableSuccessButton={!isValid}
      successCallback={() => {
        formContext.handleSubmit(
          (data) => {
            onSubmit(data)
          },
          (error) => {
            console.log(error)
          }
        )()
      }}
      body={locationConfigurationDialogBody()}
    />
  )
}

export default EditLocationModal
