import {useLazyQuery, useMutation} from '@apollo/client'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import {useAuth} from 'auth/AuthContext'
import StyledButton from 'ui/atoms/StyledButton'
import StyledTable from 'ui/molecules/StyledTable'
import {space} from 'config/theme'
import {PermissionDataType, RoleType} from 'graphql/generatedTypes/graphql'
import {
  CREATE_ROLE,
  DELETE_ROLE,
  UPDATE_ROLE
} from 'graphql/mutations/role.mutation'
import {GET_ALL_ROLES_AND_PERMISSIONS} from 'graphql/queries/role.queries'
import useEmployee from 'hooks/useEmployee'
import useNotify from 'hooks/useNotify'
import _ from 'lodash'
import {MRT_ColumnDef, MRT_TableOptions} from 'material-react-table'
import {useMemo} from 'react'
import {useEffect, useState} from 'react'
import {convertToLocalTimeString} from 'utils/common'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessAndLocation, selectEmployee} from 'clientDashboard/employee.selectors'

function RoleManager() {
  const notify = useNotify()

  const {updateAuthInfo} = useAuth()
  const {businessId} = useAppSelector(selectBusinessAndLocation)
  const employee = useAppSelector(selectEmployee)
  const {getEmployee} = useEmployee()

  const [allPermissions, setAllPermissions] = useState<PermissionDataType[]>([])
  const [allRoles, setAllRoles] = useState<RoleType[]>([])

  const [editRowIndex, setEditRowIndex] = useState<number | null>(null)
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])

  const permissionCategories = useMemo(() => {
    return allPermissions.reduce(
      (
        acc: {
          [key: string]: PermissionDataType[]
        },
        permission
      ) => {
        const category = permission.category || 'uncategorized'
        if (!acc[category]) {
          acc[category] = []
        }
        acc[category].push(permission)
        return acc
      },
      {}
    )
  }, [allPermissions])

  const [
    getRoleAndPermissions,
    {data: _rolesAndPermissionsData, loading: loadingRolesAndPermissions}
  ] = useLazyQuery(GET_ALL_ROLES_AND_PERMISSIONS, {
    fetchPolicy: 'no-cache'
  })

  const [_createRole] = useMutation(CREATE_ROLE, {
    onCompleted: () => {
      fetchRolesAndPermissions()
    }
  })

  const [_updateRole] = useMutation(UPDATE_ROLE, {
    onCompleted: () => {
      fetchRolesAndPermissions()

      if (employee?.role?.name === allRoles[editRowIndex as number]?.name) {
        getEmployee()
      }

      setEditRowIndex(null)
      setSelectedPermissions([])
    }
  })

  const [_removeRole] = useMutation(DELETE_ROLE, {
    onCompleted: () => {
      fetchRolesAndPermissions()
    },
    onError: (error) => {
      notify.show(error.message, 'error')
    }
  })

  const removeRole = (id: string) => {
    _removeRole({
      variables: {
        id,
        businessId
      }
    })
  }

  const fetchRolesAndPermissions = () => {
    getRoleAndPermissions({
      variables: {
        businessId
      }
    })
  }

  useEffect(() => {
    fetchRolesAndPermissions()
  }, [])

  useEffect(() => {
    if (_rolesAndPermissionsData?.getAllPermissions?.length) {
      setAllPermissions(_rolesAndPermissionsData.getAllPermissions)
    }

    if (_rolesAndPermissionsData?.getAllRoles?.length) {
      setAllRoles(_rolesAndPermissionsData.getAllRoles)
      updateAuthInfo({
        allRoles: _rolesAndPermissionsData.getAllRoles.map((role) => ({
          id: role.id,
          name: role.name
        }))
      })
    }
  }, [_rolesAndPermissionsData])

  const columns = useMemo<MRT_ColumnDef<RoleType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        },
        enableEditing: true
      },
      {
        accessorKey: 'id',
        header: 'ID',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        },
        enableEditing: false
      },
      {
        accessorKey: 'description',
        header: 'Description',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        },
        enableEditing: true
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At',
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? convertToLocalTimeString(cell.getValue()) : ''}
            </Typography>
          )
        },
        enableEditing: false
      },
      {
        accessorKey: 'updatedAt',
        header: 'Updated At',
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {cell.getValue() ? convertToLocalTimeString(cell.getValue()) : ''}
            </Typography>
          )
        },
        enableEditing: false
      }
    ],

    []
  )

  const handleCreateRole: MRT_TableOptions<RoleType>['onCreatingRowSave'] =
    async ({values, table}) => {
      if (!values.name) {
        notify.show('Role name is required', 'error')
        return
      }
      _createRole({
        variables: {
          description: values.description,
          name: values.name,
          businessId,
          permissions: selectedPermissions
        }
      })
      table.setCreatingRow(null)
      setSelectedPermissions([])
      table.toggleAllRowsExpanded(false)
    }

  return (
    <StyledTable
      columns={columns}
      data={allRoles}
      enableTableFooter
      state={{
        showSkeletons: loadingRolesAndPermissions,
        showProgressBars: loadingRolesAndPermissions
      }}
      initialState={{
        columnPinning: {
          left: ['mrt-row-expand'],
          right: ['mrt-row-actions']
        }
      }}
      createDisplayMode='row'
      editDisplayMode='row'
      enableEditing
      getRowId={(row) => row.id}
      onCreatingRowSave={handleCreateRole}
      onCreatingRowCancel={({table}) => {
        table.setCreatingRow(null)
        setSelectedPermissions([])
        table.toggleAllRowsExpanded(false)
      }}
      onEditingRowCancel={({row, table}) => {
        setEditRowIndex(null)
        table.setEditingRow(null)
        table.setCreatingRow(null)
        setSelectedPermissions([])
        row.toggleExpanded(false)
      }}
      onEditingRowSave={({values, table, row}) => {
        _updateRole({
          variables: {
            description: values.description || allRoles[row.index].description,
            name: values.name || allRoles[row.index].name,
            id: allRoles[row.index].id,
            businessId,
            isActive: true,
            permissions: selectedPermissions
          }
        })

        table.setEditingRow(null)
      }}
      renderRowActions={({table, row}) => (
        <Grid
          container
          spacing={2}
          style={{
            justifyContent: 'center'
          }}
        >
          <Grid item>
            <Tooltip title='Edit Role'>
              <IconButton
                onClick={() => {
                  table.setEditingRow(row)
                  setEditRowIndex(row.index)
                  setSelectedPermissions(row.original.permissions || [])
                  row.toggleExpanded(true)
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'edit',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  style={{
                    borderWidth: '1px'
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title='Delete Role'>
              <IconButton
                onClick={() => {
                  removeRole(row.original.id)
                }}
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: 'trash-alt',
                    family: 'classic',
                    style: 'light'
                  })}
                  fontSize={20}
                  style={{
                    borderWidth: '1px'
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      renderTopToolbarCustomActions={({table}) => {
        return (
          <Grid
            style={{
              order: 1
            }}
          >
            <StyledButton
              onClick={() => {
                table.setCreatingRow(true)
                setSelectedPermissions([])
                table.setEditingRow(null)
                setEditRowIndex(null)
                table.toggleAllRowsExpanded(true)
              }}
            >
              Create Role
            </StyledButton>
          </Grid>
        )
      }}
      renderDetailPanel={({row}) => {
        if (loadingRolesAndPermissions) return ''
        return (
          <Box p={2}>
            <Grid container spacing={2}>
              {Object.entries(permissionCategories).map(
                ([category, permissions]) => (
                  <Grid
                    item
                    xs={12}
                    key={category}
                    sx={{padding: space.MEDIUM}}
                  >
                    <Typography variant='h3' gutterBottom>
                      {_.capitalize(category)}
                    </Typography>
                    <Grid container spacing={2}>
                      {permissions.map((permission) => (
                        <Grid item xs={12} sm={4} md={3} key={permission.name}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  (editRowIndex !== row.index &&
                                    row.original.permissions?.some(
                                      (p) => p === permission.name
                                    )) ||
                                  selectedPermissions.some(
                                    (p) => p === permission.name
                                  )
                                }
                                onChange={(e) => {
                                  if (
                                    editRowIndex !== row.index &&
                                    row.index !== -1
                                  ) {
                                    notify.show(
                                      'Turn on the edit mode to change permissions',
                                      'warning'
                                    )
                                    return
                                  }

                                  const checked = e.target.checked
                                  let permissions = selectedPermissions
                                  const newPermissions = checked
                                    ? [...permissions, permission.name]
                                    : permissions.filter(
                                        (p) => p !== permission.name
                                      )
                                  setSelectedPermissions(newPermissions)
                                }}
                              />
                            }
                            label={
                              <Tooltip
                                title={permission.description}
                                placement='top'
                              >
                                <Typography variant='body1'>
                                  {permission.displayName}
                                </Typography>
                              </Tooltip>
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        )
      }}
    />
  )
}

export default RoleManager
