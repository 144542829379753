import {RootState} from 'consumerAgent/consumerAgent.store'

export const selectLocationId = (state: RootState) => state.consumer.locationId
export const selectLocationName = (state: RootState) =>
  state.consumer.locationName
export const selectIsLoggedIn = (state: RootState) => state.consumer.isLoggedIn
export const selectIsAuthenticated = (state: RootState) =>
  state.consumer.isAuthenticated
export const selectCustomer = (state: RootState) => state.consumer.customer
export const selectSessionToken = (state: RootState) =>
  state.consumer.sessionToken
export const selectBusinessName = (state: RootState) =>
  state.consumer.businessName
export const selectBusinessId = (state: RootState) => state.consumer.businessId
export const selectStripePublishableKey = (state: RootState) =>
  state.consumer.stripePublishableKey
