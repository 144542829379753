import {useMutation, useQuery} from '@apollo/client'
import {Grid, Typography} from '@mui/material'
import StyledTable from 'ui/molecules/StyledTable'
import {MRT_ColumnDef} from 'material-react-table'
import React, {useMemo, useState} from 'react'

import StyledButton from 'ui/atoms/StyledButton'
import StyledDialog from 'ui/molecules/StyledDialog'
import StyledInput from 'ui/atoms/StyledInput'
import {fontSize, space} from 'config/theme'
import {CREATE_OAUTH_APPLICATIONS} from 'graphql/mutations/oauthapplications.mutations'
import {GET_OAUTH_APPLICATIONS} from 'graphql/queries/oauthapplications.queries'
import {titleize} from 'utils/common'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessAndLocation} from 'clientDashboard/employee.selectors'

type TableDateType = {
  appName: string
  clientId: string
  clientSecret: string
  status: string
}

const OAuthApplications = () => {
  const {locationId} = useAppSelector(selectBusinessAndLocation)
  const [columnFilters, setColumnFilters] = React.useState([])
  const [applications, setApplications] = useState<any>([])
  const [modelOpen, setModelOpen] = React.useState(false)
  const [appName, setAppName] = useState<any>(null)

  const [createApplication] = useMutation(CREATE_OAUTH_APPLICATIONS, {
    onCompleted: (data) => {
      console.log('Created successfully', data)
      setApplications([...applications, data.generateOauthApplications])
    },
    onError: (error) => {
      console.log('Error adding oauth applications', error)
    }
  })

  const {loading: loadingApplications} = useQuery(GET_OAUTH_APPLICATIONS, {
    variables: {
      locationId
    },
    onCompleted: (data) => {
      if (data.getOauthApplications?.applications) {
        setApplications(data?.getOauthApplications?.applications || [])
      }
    },
    onError: (error) => {
      console.log('Error getting applications', error)
    }
  })

  const createApplicationCallback = () => {
    createApplication({
      variables: {
        locationId,
        appName: appName
      }
    }).then(() => {
      setModelOpen(false)
      setAppName(null)
    })
  }

  const columns = useMemo<MRT_ColumnDef<TableDateType>[]>(
    () => [
      {
        accessorKey: 'appName',
        header: 'Application Name',
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'clientId',
        header: 'Client ID',
        enableClickToCopy: true,
        Cell: ({cell}: any) => {
          return <Typography variant='subtitle2'>{cell.getValue()}</Typography>
        }
      },
      {
        accessorKey: 'clientSecret',
        header: 'Client Secret',
        enableClickToCopy: true,
        Cell: ({cell}: any) => {
          const [showSecretKey, setShowSecretKey] = useState(false)
          return (
            <div>
              {showSecretKey ? (
                <Typography variant='subtitle2'>{cell.getValue()}</Typography>
              ) : (
                <Typography
                  style={{
                    fontSize: fontSize.MEDIUM,
                    cursor: 'pointer',
                    color: 'blue',
                    textDecoration: 'underline'
                  }}
                  onClick={() => setShowSecretKey(true)}
                >
                  Show
                </Typography>
              )}
            </div>
          )
        }
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({cell}: any) => {
          return (
            <Typography variant='subtitle2'>
              {titleize(cell.getValue())}
            </Typography>
          )
        }
      }
    ],
    []
  )

  return (
    <>
      <StyledTable
        columns={columns}
        data={applications}
        onColumnFiltersChange={setColumnFilters as any}
        state={{
          columnFilters,
          showSkeletons: loadingApplications,
          showProgressBars: loadingApplications
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Grid
              style={{
                order: 1
              }}
            >
              <StyledButton
                onClick={() => setModelOpen(true)}
                style={{marginBottom: space.SMALL}}
              >
                Add Application
              </StyledButton>
            </Grid>
          )
        }}
      />
      {modelOpen && (
        <StyledDialog
          open={modelOpen}
          title='Create New Application'
          body={
            <Grid>
              <Grid item></Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  gap: 24
                }}
              >
                <StyledInput
                  value={appName || ''}
                  placeholder='Enter Application Name'
                  type='text'
                  onChange={(e) => {
                    setAppName(e.target.value)
                  }}
                  style={{width: '100%'}}
                />
              </Grid>
            </Grid>
          }
          successButtonName='Confirm'
          cancelButtonName='Dismiss'
          cancelCallback={() => {
            setModelOpen(false)
          }}
          successCallback={createApplicationCallback}
        />
      )}
    </>
  )
}

export default OAuthApplications
