import {useMutation, useQuery} from '@apollo/client'
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import StyledButton from 'ui/atoms/StyledButton'
import StyledInput from 'ui/atoms/StyledInput'
import StyledTable from 'ui/molecules/StyledTable'
import {ADD_RULES} from 'graphql/mutations/promo-engine.mutations'
import {
  GET_PRODUCT_SKU_LIST,
  GET_RULES,
  GET_RULES_TYPES
} from 'graphql/queries/promo-engine.queries'
import {MRT_ColumnDef} from 'material-react-table'
import {useEffect, useMemo, useState} from 'react'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectBusinessId} from 'clientDashboard/employee.selectors'

const Rules = () => {
  const businessId = useAppSelector(selectBusinessId)
  const [ruleName, setRuleName] = useState('')
  const [ruleType, setRuleType] = useState('')
  const [ruleValue, setRuleValue] = useState('')
  const [applyTo, setApplyTo] = useState<string[]>([])
  const [excludeFrom, setExcludeFrom] = useState<string[]>([])
  const [applyToProductGroup, setApplyToProductGroup] = useState<string[]>([])
  const [excludeFromProductGroup, setExcludeFromProductGroup] = useState<
    string[]
  >([])
  const [productSkuList, setProductSkuList] = useState<string[]>([])

  const [createRule, {data: createRuleData}] = useMutation(ADD_RULES)
  const {loading, data: allRulesData} = useQuery(GET_RULES, {
    variables: {businessId: businessId}
  })

  const {data: productSkuListData} = useQuery(GET_PRODUCT_SKU_LIST)

  const {data: ruleTypesData} = useQuery(GET_RULES_TYPES)
  const [ruleTypes, setRuleTypes] = useState<string[]>([])
  useEffect(() => {
    if (ruleTypesData && ruleTypesData.getRuleTypes) {
      setRuleTypes(ruleTypesData.getRuleTypes)
    }
  }, [ruleTypesData])

  useEffect(() => {
    if (productSkuListData && productSkuListData.getProductSkuList) {
      setProductSkuList(productSkuListData.getProductSkuList)
    }
  }, [productSkuListData])

  const handleCreateRule = () => {
    createRule({
      variables: {
        businessId: businessId,
        ruleName,
        ruleType,
        ruleValue,
        applyTo,
        excludeFrom,
        applyToProductGroup,
        excludeFromProductGroup
      },
      refetchQueries: [{query: GET_RULES, variables: {businessId: businessId}}]
    })
  }

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'ruleName',
        header: 'Rule Name'
      },
      {
        accessorKey: 'ruleType',
        header: 'Rule Type'
      },
      {
        accessorKey: 'ruleValue',
        header: 'Rule Value'
      },
      {
        accessorKey: 'applyTo',
        header: 'Include Product SKUs',
        Cell: ({cell}: any) => (cell?.getValue() || []).join(', ')
      },
      {
        accessorKey: 'excludeFrom',
        header: 'Exclude Product SKUs',
        Cell: ({cell}: any) => (cell?.getValue() || []).join(', ')
      },
      {
        accessorKey: 'applyToProductGroup',
        header: 'Include Product Type SKUs'
      },
      {
        accessorKey: 'excludeFromProductGroup',
        header: 'Exclude Product Type SKUs'
      }
    ],
    []
  )

  return (
    <Grid>
      <Grid item>
        <Typography
          variant='body2'
          style={{
            fontSize: '24px'
          }}
        >
          Rules
        </Typography>
      </Grid>

      <Grid item container spacing={4} padding={2}>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            fullWidth
            label='Rule Name'
            value={ruleName}
            onChange={(e) => setRuleName(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id='rule-type-label'>Rule Type</InputLabel>
            <Select
              fullWidth
              inputProps={{'aria-label': 'Without label'}}
              size='medium'
              labelId='rule-type-label'
              id='rule-type-select'
              value={ruleType}
              label='Rule Type'
              onChange={(e) => setRuleType(e.target.value as string)}
            >
              {ruleTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            fullWidth
            label='Rule Value'
            value={ruleValue}
            onChange={(e) => setRuleValue(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id='rules-label'>Include Product SKU List</InputLabel>
            <Select
              labelId='rules-label'
              id='rules-select'
              multiple
              value={applyTo}
              onChange={(e) => setApplyTo(e.target.value as string[])}
              fullWidth
              label='Include Product SKU List'
              inputProps={{'aria-label': 'Without label'}}
            >
              <MenuItem value='' disabled>
                Include Product SKU List
              </MenuItem>

              {productSkuList.map((sku) => (
                <MenuItem key={sku} value={sku}>
                  {sku}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id='rules-label'>Exclude SKUs</InputLabel>
            <Select
              labelId='rules-label'
              id='rules-select'
              multiple
              value={excludeFrom}
              onChange={(e) => setExcludeFrom(e.target.value as string[])}
              fullWidth
              label='Exclude sku'
              inputProps={{'aria-label': 'Without label'}}
            >
              <MenuItem value='' disabled>
                Exclude SKUs
              </MenuItem>

              {productSkuList.map((sku) => (
                <MenuItem key={sku} value={sku}>
                  {sku}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            fullWidth
            label='Include Product Type SKU List'
            value={applyToProductGroup}
            onChange={(e) => setApplyToProductGroup([e.target.value])}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledInput
            fullWidth
            label='Exclude Product Type SKU List'
            value={excludeFromProductGroup}
            onChange={(e) => setExcludeFromProductGroup([e.target.value])}
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        spacing={4}
        padding={2}
        style={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <Grid item>
          <StyledButton
            variant='contained'
            onClick={handleCreateRule}
            disabled={!ruleName || !ruleType || !ruleValue}
          >
            Create Rule
          </StyledButton>
        </Grid>
      </Grid>

      {createRuleData && (
        <Box mt={3}>
          <Typography variant='h6'>Created Rule:</Typography>
          <pre>{JSON.stringify(createRuleData.createRule, null, 2)}</pre>
        </Box>
      )}

      <StyledTable
        data={allRulesData?.allRules || []}
        columns={columns}
        state={{
          showSkeletons: loading,
          showProgressBars: loading
        }}
      />
    </Grid>
  )
}

export default Rules
