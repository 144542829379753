import {useEffect, useRef} from 'react'
import {TextFieldElement, TextFieldElementProps} from 'react-hook-form-mui'
import _isFunction from 'lodash/isFunction'

export type Address = {
  street: string
  city: string
  state: string
  country: string
  zipcode: string
}

type FieldProps = TextFieldElementProps & {setFullAddress: (v: Address) => void}

function getPlaceAddress(autocomplete: google.maps.places.Autocomplete) {
  const place = autocomplete.getPlace()
  let zipcode = ''
  let street = ''
  let city = ''
  let state = ''
  let country = ''
  if (place && place.address_components) {
    for (const component of place?.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0]
      switch (componentType) {
        case 'street_number': {
          street = `${component.long_name}`
          break
        }

        case 'route': {
          street += ' ' + component.short_name
          break
        }

        case 'postal_code': {
          zipcode = `${component.long_name}`
          break
        }

        case 'locality' || 'sublocality_level_1':
          city = component.long_name
          break

        case 'administrative_area_level_1': {
          state = component.short_name
          break
        }

        case 'country': {
          country = component.long_name
          break
        }
      }
    }
    return {zipcode, street, city, state, country}
  }
  return null
}

const StreetAddressField = (props: FieldProps) => {
  const { setFullAddress, ...rest } = props
  const inputRef = useRef()

  useEffect(() => {
    if (!inputRef.current || !window?.google?.maps) return

    const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
      fields: ['address_components', 'geometry'],
      types: ['address'],
      componentRestrictions: {
        country: 'us'
      }
    })

    autocomplete.addListener('place_changed', () => {
      const address = getPlaceAddress(autocomplete)
      if (address && _isFunction(setFullAddress)) {
        setFullAddress(address)
      }
    })

    return () => {
      google.maps.event.clearInstanceListeners(inputRef.current || {})
    }
  }, [])

  return <TextFieldElement inputRef={inputRef} {...rest} />
}

export default StreetAddressField
