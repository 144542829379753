import StyledDialog from 'ui/molecules/StyledDialog'
import {useTranslation} from 'react-i18next'
import {Grid, Typography} from '@mui/material'

type Props = {
  onClose: () => void,
  isLocationActive: boolean,
  onConfirm: () => void,
  storeNumber: string,
}

const LocationActivationConfirmationModal = (props: Props) => {
  const { onClose, isLocationActive, onConfirm, storeNumber } = props
  const {t} = useTranslation()

  const activateLocationBody = () => {
    return (
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            {t('location-management.activate-message')} {storeNumber}
            {' ?'}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const deactivateLocationBody = () => {
    return (
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>
            {t('location-management.deactivate-message-warning-prefix-note')}
            <ul>
              <li>
                {t(
                  'location-management.deactivate-message-transactions-warning'
                )}
              </li>
              <li>
                {t(
                  'location-management.deactivate-message-employee-access-revoked'
                )}
              </li>
            </ul>
            {t('location-management.deactivate-message')}{' '}
            {storeNumber}
            {' ?'}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const activateOrDeactivateLocationBody = () => {
    return isLocationActive
      ? deactivateLocationBody()
      : activateLocationBody()
  }

  return (
    <StyledDialog
      open
      onClose={() => {
        onClose()
      }}
      title={
        isLocationActive
          ? t('location-management.deactivate-location-title')
          : t('location-management.activate-location-title')
      }
      successButtonName={
        isLocationActive
          ? t('location-management.deactivate')
          : t('location-management.activate')
      }
      cancelButtonName={t('cancel')}
      cancelCallback={() => {
        onClose()
      }}
      successCallback={() => {
        onConfirm()
      }}
      body={activateOrDeactivateLocationBody()}
    />
  )
}

export default LocationActivationConfirmationModal
