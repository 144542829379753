import {graphql} from 'graphql/generatedTypes'

export const SAVE_DEVICE_TEMPLATE = graphql(`
  mutation SaveDeviceTemplate(
    $fileContent: String!
    $fileName: String!
    $locationId: String
  ) {
    saveDeviceTemplate(
      fileContent: $fileContent
      fileName: $fileName
      locationId: $locationId
    )
  }
`)

export const DELETE_DEVICE_TEMPLATE = graphql(`
  mutation DeleteDeviceTemplate($templateName: String!, $locationId: String) {
    deleteDeviceTemplate(locationId: $locationId, templateName: $templateName)
  }
`)

export const SAVE_DEVICE_BITMAP = graphql(`
  mutation SaveDeviceBitmap(
    $fileContent: String!
    $fileName: String!
    $locationId: String
  ) {
    saveDeviceBitmap(
      fileContent: $fileContent
      fileName: $fileName
      locationId: $locationId
    )
  }
`)

export const DELETE_DEVICE_BITMAP = graphql(`
  mutation DeleteDeviceBitmap($bitmapName: String!, $locationId: String) {
    deleteDeviceBitmap(locationId: $locationId, bitmapName: $bitmapName)
  }
`)
