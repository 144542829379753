import {Grid, Typography} from '@mui/material'
import theme, {space} from 'config/theme'
import {selectCartId} from 'consumerAgent/cart.selector'
import {selectLocationId} from 'consumerAgent/consumer.selector'
import {useAppSelector} from 'consumerAgent/consumerAgent.store'
import ConsumerAgentBottomDrawer from 'consumerAgent/ConsumerAgentBottomDrawer'
import ConsumerAgentCartInterface from 'consumerAgent/ConsumerAgentCartInterface'
import ConsumerAgentChatConversation from 'consumerAgent/ConsumerAgentChatConversation'
import ConsumerAgentHeader from 'consumerAgent/ConsumerAgentHeader'
import useConsumerAgent from 'consumerAgent/useConsumerAgent'
import {t} from 'i18next'
import {useState} from 'react'

const ConsumerAgentHomePage = () => {
  useConsumerAgent()
  const locationId = useAppSelector(selectLocationId)

  const cartId = useAppSelector(selectCartId)

  const [isCartPageOpen, setIsCartPageOpen] = useState(false)

  if (!locationId) {
    return (
      <Grid>
        <Typography variant='h6' style={{color: 'red', padding: space.LARGE}}>
          {t('customer-management.location-required')}
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid
      container
      style={{
        height: '100dvh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default
      }}
    >
      <ConsumerAgentHeader
        openCartPage={() => {
          setIsCartPageOpen(true)
        }}
        handleHomeButton={() => {
          setIsCartPageOpen(false)
        }}
        isCartPageOpen={isCartPageOpen}
      />
      {!isCartPageOpen && <ConsumerAgentChatConversation />}
      {isCartPageOpen && cartId && <ConsumerAgentCartInterface />}

      <ConsumerAgentBottomDrawer />
    </Grid>
  )
}

export default ConsumerAgentHomePage
