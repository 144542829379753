import {useMutation} from '@apollo/client'
import {Grid} from '@mui/material'
import {useAppSelector} from 'clientDashboard/clientDashboard.store'
import {selectLocationId} from 'clientDashboard/employee.selectors'
import {getSaveInventoryModalFields} from 'clientDashboard/inventoryManagement/inventory.helper'
import {
  ADD_INVENTORY,
  UPDATE_INVENTORY
} from 'graphql/mutations/inventory-management.mutations'
import {useForm, useWatch} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {notify} from 'ui/molecules/CustomNotifications'
import StyledDialog from 'ui/molecules/StyledDialog'
import FormBuilder from 'ui/organisms/FormBuilder/FormBuilder'
import {convertToCents, convertToDollars} from 'utils/common'
import {InventoryDetailsType} from 'graphql/generatedTypes/graphql'

const defaultValues = {
  sku: '',
  skuType: '',
  categoryName: '',
  className: '',
  subclassName: '',
  taxDetails: {
    taxGroup: '',
    taxRate: '0.0'
  },
  taxable: 'no',
  pricingDetails: {
    unitPrice: 0,
    currency: 'USD',
    currencyMultiplier: 100,
    discount: 0
  },
  logoUrl: '',
  description: '',
  unit: 'Per item',
  unitCount: 0,
  upc: '',
  recommendedProducts: '',
  sendEmail: false,
  ageVerificationRequired: false,
  requiredMinimumAge: '18'
}

type Props = {
  saveInventoryModalData: any
  skus: string[]
  onSubmit: () => void
  onClose: () => void
}

const transformInventorySaveModalData = (row: InventoryDetailsType) => {
  let _data = {
    ...row,
    pricingDetails: {
      unitPrice: convertToDollars(row.pricingDetails?.unitPrice as string),
      currency: row.pricingDetails?.currency as string,
      currencyMultiplier: row.pricingDetails?.currencyMultiplier as string,
      discount: convertToDollars(row.pricingDetails?.discount as string)
    },
    recommendedProducts: row.recommendedProducts
      ?.map((product) => product.sku)
      .join(',') as string,
    sendEmail: !!row.sendEmail,
    ageVerificationRequired: !!row.ageVerificationRequired
  }
  return _data
}

export const SaveInventoryModalData = (props: Props) => {
  const {saveInventoryModalData, skus, onSubmit, onClose} = props

  const locationId = useAppSelector(selectLocationId)

  const [_addInventory] = useMutation(ADD_INVENTORY, {
    onCompleted: () => {
      onSubmit()
      notify.show(t('inventory.added-successfully'), 'success')
    },
    onError: (error) => {
      console.log('error', error)
      notify.show(error.message, 'error')
    }
  })

  const [_updateInventory] = useMutation(UPDATE_INVENTORY, {
    onCompleted: () => {
      onSubmit()
    },
    onError: (error) => {
      console.log('error', error)
      notify.show(error.message, 'error')
    }
  })

  const saveInventory = (data: any) => {
    const {
      id,
      sku,
      skuType,
      categoryName,
      className,
      subclassName,
      taxDetails,
      taxable,
      pricingDetails,
      logoUrl,
      description,
      unit,
      unitCount,
      upc,
      recommendedProducts,
      sendEmail,
      ageVerificationRequired,
      requiredMinimumAge
    } = data

    let _inventoryData = {
      sku: sku || saveInventoryModalData.sku,
      skuType: skuType,
      categoryName: categoryName,
      className: className,
      subclassName: subclassName,
      taxDetails: {
        taxGroup: taxDetails?.taxGroup || '',
        taxRate: taxDetails?.taxRate ? taxDetails.taxRate.toString() : '0.0'
      },
      taxable: taxable,
      pricingDetails: {
        unitPrice: convertToCents(pricingDetails.unitPrice.toString()),
        currency: pricingDetails.currency,
        currencyMultiplier: pricingDetails.currencyMultiplier
          ? pricingDetails.currencyMultiplier.toString()
          : '100',
        discount: convertToCents(pricingDetails.discount.toString())
      },
      logoUrl: logoUrl,
      description: description,
      unit: unit,
      unitCount: unitCount.toString(),
      upc: upc,
      recommendedProducts: recommendedProducts,
      sendEmail: sendEmail,
      ageVerificationRequired: ageVerificationRequired,
      requiredMinimumAge: requiredMinimumAge
    }
    if (typeof _inventoryData.recommendedProducts === 'string') {
      _inventoryData.recommendedProducts =
        _inventoryData.recommendedProducts.split(',')
    }

    if (id) {
      _updateInventory({
        variables: {
          locationId: locationId as string,
          inventory: {
            ..._inventoryData
          }
        }
      })
    } else {
      _addInventory({
        variables: {
          locationId: locationId as string,
          inventory: {
            ..._inventoryData
          }
        }
      })
    }
  }

  const formContext = useForm<any>({
    defaultValues,
    values: transformInventorySaveModalData(saveInventoryModalData),
    resetOptions: {
      keepDefaultValues: true
    },
    mode: 'onChange'
  })
  const {t} = useTranslation()
  const {isValid} = formContext.formState
  const isEditMode = !!saveInventoryModalData?.id
  useWatch({
    control: formContext.control
  })

  const inventoryConfigurationDialog = () => {
    return (
      <Grid container spacing={2} padding={2}>
        <FormBuilder
          formContext={formContext}
          fields={getSaveInventoryModalFields({
            formContext,
            skus,
            saveInventoryModalData: transformInventorySaveModalData(
              saveInventoryModalData
            )
          })}
        />
      </Grid>
    )
  }

  return (
    <StyledDialog
      open
      onClose={() => {}}
      title={
        isEditMode
          ? t('inventory.edit-inventory')
          : t('inventory.add-inventory')
      }
      successButtonName={
        isEditMode ? t('inventory.update') : t('inventory.add')
      }
      cancelButtonName={t('inventory.cancel')}
      cancelCallback={() => {
        onClose()
      }}
      disableSuccessButton={!isValid}
      successCallback={() => {
        formContext.handleSubmit(
          (data) => {
            saveInventory(data)
          },
          (error) => {
            console.log(error)
          }
        )()
      }}
      body={inventoryConfigurationDialog()}
    />
  )
}
