import {forwardRef} from 'react'
import RadioButtonGroup, { RadioButtonGroupProps } from 'ui/organisms/FormBuilder/ext/RadioButtonGroup'

const RadioButtonGroupField = forwardRef(
  (props: RadioButtonGroupProps & {className?: string}, ref: any) => {
    const {className, ...rest} = props
    return (
      <RadioButtonGroup
        formControlProps={{
          className: props.className
        }}
        ref={ref}
        row
        {...rest}
      />
    )
  }
)

export default RadioButtonGroupField
