// ui.slice.ts
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export enum ConsumerAgentCheckoutSteps {
  DELIVERY = 0,
  PAYMENT = 1
}

interface UIState {
  isBottomDrawerOpen: boolean
  selectedCheckoutStep: ConsumerAgentCheckoutSteps
  scrollToBottomTrigger: boolean
}

const initialState: UIState = {
  isBottomDrawerOpen: false,
  selectedCheckoutStep: ConsumerAgentCheckoutSteps.DELIVERY,
  scrollToBottomTrigger: false
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsBottomDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isBottomDrawerOpen = action.payload
    },
    setSelectedCheckoutStep(
      state,
      action: PayloadAction<ConsumerAgentCheckoutSteps>
    ) {
      state.selectedCheckoutStep = action.payload
    },

    triggerScrollToBottom(state) {
      state.scrollToBottomTrigger = !state.scrollToBottomTrigger
    }
  }
})

export const {
  setIsBottomDrawerOpen,
  setSelectedCheckoutStep,
  triggerScrollToBottom
} = uiSlice.actions

export default uiSlice.reducer
