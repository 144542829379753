import {DenominationInput} from 'graphql/generatedTypes/graphql'

export const ALL_ZONES = 'All Zones'
export const ALL_DEVICES = 'All Devices'
export const ALL_REGISTERS = 'All Registers'
export const ALL_TILLS = 'All Tills'
export const UNASSIGNED = 'Unassigned'

export const ZONE_EDIT_TABS = {
  ZONE_INFO: 'zoneInfo',
  ZONE_DEVICES: 'zoneDevices'
}

export enum DeviceStatus {
  HEALTHY = 'healthy',
  WARNING = 'warning',
  CRITICAL = 'critical'
}

export const NEW_ZONE_ID = 'new_zone_id'

export enum Currency {
  USD = 'USD'
}

export enum TransactionType {
  DEBIT = 'debit',
  CREDIT = 'credit'
}

export enum TillStatus {
  OPEN = 'open',
  REVIEW = 'review',
  CLOSED = 'closed'
}

export const REVIEW_EVENT = 'close'

export const LocalStorageKeys = {
  TOTE_ACCESS_TOKEN: 'tote_access_token',
  TOTE_ADMIN_ACCESS_TOKEN: 'tote_admin_access_token',
  OKTA_CONFIG: 'okta_config',
  BUSINESS_ID: 'business_id',
  STORE_NUMBER: 'store_number',
  NAVIGATION_OPEN_MENU_FLAGS: 'navigation_open_menu_flags',
  EMAIL_ID: 'email_id',
  AUTH_METHOD: 'auth_method',
  IS_AUTHENTICATED: 'is_authenticated',
  GUEST_ACCESS_TOKEN: 'guest_access_token',
  GUEST: {
    CART_ID: 'guest_cart_id',
    CART_DATA: 'guest_cart_data',
    CHAT_SESSION_ID: 'guest_chat_session_id'
  },
  CONSUMER_AGENT: {
    CHAT: 'consumer_agent_chat',
    CART: 'consumer_agent_cart',
    CART_ID: 'consumer_agent_cart_id',
    CUSTOMER_SESSION_TOKEN: 'consumer_agent_customer_session_token',
    CUSTOMER_DETAILS: 'consumer_agent_customer_details',
    CHAT_SESSION_ID: 'consumer_agent_chat_session_id'
  }
}

export const EventTypes = {
  SIGN_OUT: 'sign_out'
}

export enum DepositStatus {
  PENDING = 'pending',
  COMPLETE = 'complete'
}

export const SAFE_DEFAULT_AMOUNT = 500000
export const REFETCH_INTERVAL = 20000
export const CASH_ADJUSTMENT_EVENT_TYPE = 'cash_adjustment'
export const RECONCILED_EVENT_TYPE = 'reconcile'
export const INSIGHTS_CHART_CASH_MANAGEMENT_CATEGORY_VARIANCE = 'cash-variance-graph'
export const INSIGHTS_CHART_CASH_MANAGEMENT_CATEGORY_SALES = 'cash-sales-graph'
export const INSIGHTS_CHART_CASH_MANAGEMENT_CATEGORY_VARIANCE_NUMBER =
  'cash-variance-number'
export const INSIGHTS_CHART_CASH_MANAGEMENT_CATEGORY_SALES_NUMBER =
  'cash-sales-number'
export const INSIGHTS_TYPE_CASH_MANAGEMENT = 'cash-management'

export const REFUND_EVENT = 'refund'
export const SALE_EVENT = 'sale'
export const EVENT_OPTIONS = [
  {
    value: 'open',
    label: 'Open',
    transactionType: TransactionType.CREDIT
  },
  {
    value: 'sale',
    label: 'Sale',
    transactionType: TransactionType.CREDIT
  },
  {
    value: 'refund',
    label: 'Refund',
    transactionType: TransactionType.DEBIT
  },
  {
    value: 'cash_pickup',
    label: 'Cash Pickup',
    transactionType: TransactionType.DEBIT
  },
  {
    value: 'paid_in',
    label: 'Paid In',
    transactionType: TransactionType.CREDIT
  },
  {
    value: 'paid_out',
    label: 'Paid Out',
    transactionType: TransactionType.DEBIT
  },
  {
    value: 'cash_drop',
    label: 'Cash Drop',
    transactionType: TransactionType.CREDIT
  },
  {
    value: 'cash_adjustment',
    label: 'Cash Adjustment',
    transactionType: TransactionType.CREDIT
  },
  {
    value: 'close',
    label: 'Close',
    transactionType: TransactionType.DEBIT
  },
  {
    value: 'reconcile',
    label: 'Reconcile',
    transactionType: TransactionType.DEBIT
  }
]

export const SAFE_EVENT_OPTIONS = [
  {
    value: 'transfer',
    label: 'Transfer',
    transactionType: TransactionType.DEBIT
  }
]

export const CASH_ADJUSTMENT_EVENT = 'cash_adjustment'

export const STORE_SAFE_REASON_CODE = [
  {
    value: 'safe_reconcile',
    label: 'Safe Reconcile'
  }
]

export const TILL_REASON_CODE = [
  {
    value: 'cashier_error',
    label: 'Cashier Error'
  },
  {
    value: 'other',
    label: 'Other'
  }
]

export enum SAFE_TYPES_ENUM {
  TILL_SAFE = 'till_safe',
  RESERVE_SAFE = 'reserve_safe'
}

export const SAFE_TYPES = [
  {
    value: SAFE_TYPES_ENUM.TILL_SAFE,
    label: 'Till Safe'
  },
  {
    value: SAFE_TYPES_ENUM.RESERVE_SAFE,
    label: 'Reserve Safe'
  }
]

export enum AuthMethod {
  TOTE = 'tote',
  OKTA = 'okta'
}

export const PAYMENTS_CATEGORY = 'payments'
export const STRIPE = 'stripe'
export const STRIPE_PAYMENT_GATEWAY = 'Stripe Payment Gateway'
export const OMS_CATEGORY = 'oms'
export const SHOPIFY = 'shopify'
export const SHOPIFY_OMS = 'Shopify OMS'

export enum LocationStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated'
}

export enum ResultType {
  PRODUCTS = 'products',
  ITEM_RETURN = 'item_return',
  AVAILABILITY = 'availability',
  CHEAPEST = 'cheapest',
  PROMOTION = 'promotion'
}

export const CurrencyMap: {
  [key: string]: Currency
} = {
  en: Currency.USD,
  'en-US': Currency.USD
}

export const DEFAULT_DENOMINATIONS: DenominationInput[] = [
  {denomination: 10000, count: 0},
  {denomination: 5000, count: 0},
  {denomination: 2000, count: 0},
  {denomination: 1000, count: 0},
  {denomination: 500, count: 0},
  {denomination: 200, count: 0},
  {denomination: 100, count: 0},
  {denomination: 50, count: 0},
  {denomination: 25, count: 0},
  {denomination: 10, count: 0},
  {denomination: 5, count: 0},
  {denomination: 1, count: 0}
]

export const RECONCILE_TYPE = {
  total_amount: {
    label: 'Total Amount',
    value: 'total_amount'
  },
  individual_amount: {
    label: 'Individual Amount',
    value: 'individual_amount'
  }
}

export const API_SOURCE = 'dashboard'
