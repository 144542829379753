import {useSubscription} from '@apollo/client'
import {authEventEmitter} from 'auth/events'
import {wsLink} from 'config/apollo.config'
import {EventTypes} from 'constants/constants'
import {selectCartId} from 'consumerAgent/cart.selector'
import {selectChatSessionId} from 'consumerAgent/chat.selector'
import {processGenieResponse} from 'consumerAgent/chat.slice'
import {
  selectBusinessId,
  selectLocationId
} from 'consumerAgent/consumer.selector'
import {reAuthenticate} from 'consumerAgent/consumer.slice'
import {useAppDispatch, useAppSelector} from 'consumerAgent/consumerAgent.store'
import {processOrderPaymentStatus} from 'consumerAgent/payment.slice'
import {GenieQueryType, OrderType} from 'graphql/generatedTypes/graphql'
import {GENIE_QUERY_EVENT_FOR_CONSUMER_AGENT} from 'graphql/subscriptions/customers.subscriptions'
import {ORDER_UPDATE_SUBSCRIPTION} from 'graphql/subscriptions/payments.subscription'
import useNotify from 'hooks/useNotify'
import {createContext, useEffect, useState} from 'react'
import {SubscriptionClient} from 'subscriptions-transport-ws'

const ConsumerAgentContext = createContext({})

export const ConsumerAgentProvider = (props: {children: React.ReactNode}) => {
  const dispatch = useAppDispatch()
  const notify = useNotify()
  const chatSessionId = useAppSelector(selectChatSessionId)

  const locationId = useAppSelector(selectLocationId)
  const businessId = useAppSelector(selectBusinessId)

  const cartId = useAppSelector(selectCartId)

  const [isWebsocketConnected, setIsWebsocketConnected] = useState(false)

  useEffect(() => {
    const signOutCallback = () => {
      notify.show('Session expired. Please try again', 'error')
      dispatch(reAuthenticate())
    }

    authEventEmitter.on(EventTypes.SIGN_OUT, signOutCallback)

    const subscriptionClient = (wsLink as any)
      ?.subscriptionClient as SubscriptionClient
    subscriptionClient?.onReconnected(() => {
      setIsWebsocketConnected(subscriptionClient.status === 1)
    })

    return () => {
      authEventEmitter.off(EventTypes.SIGN_OUT, signOutCallback)
    }
  }, [])

  useSubscription(GENIE_QUERY_EVENT_FOR_CONSUMER_AGENT, {
    shouldResubscribe: true,
    variables: {chatSessionId, locationId},
    skip: !isWebsocketConnected,
    onError: (error) => {
      console.error('Error in genie subscription, ', error)
    },
    onData: (data) => {
      dispatch(
        processGenieResponse(
          data.data.data?.genieQueryEventConsumerAgent as GenieQueryType
        )
      )
    }
  })

  useSubscription(ORDER_UPDATE_SUBSCRIPTION, {
    variables: {cartId, businessId, locationId},
    shouldResubscribe: true,
    skip: !isWebsocketConnected,
    onError: (error) => {
      console.error('Error in order subscription, ', error)
    },
    onData: (data) => {
      dispatch(
        processOrderPaymentStatus(
          data.data.data?.orderUpdateEventsConsumerAgent.order as OrderType
        )
      )
    }
  })

  return (
    <ConsumerAgentContext.Provider value={{}}>
      {props.children}
    </ConsumerAgentContext.Provider>
  )
}
