import CheckboxElement, {
  CheckboxElementProps
} from 'ui/organisms/FormBuilder/ext/CheckboxElement'
import {forwardRef} from 'react'

const CheckboxField = forwardRef(
  (props: CheckboxElementProps & {className?: string}, ref: any) => {
    const {className, ...rest} = props
    return (
      <CheckboxElement
        formControlProps={{
          className: props.className
        }}
        ref={ref}
        {...rest}
      />
    )
  }
)

export default CheckboxField
