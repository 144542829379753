import {graphql} from 'graphql/generatedTypes'

export const CREATE_TILL = graphql(`
    mutation AddTill($till: TillInput!) {
        addTill(till: $till) {
            id 
            name
            isActive
        }
    }
`)

export const UPDATE_TILL = graphql(`
    mutation UpdateTill($till: TillInput!, $tillId: String!) {
        updateTill(till: $till, tillId: $tillId) {
            id
            name
            isActive
        }
    }
`)
