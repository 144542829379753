import {NullablePartial} from 'utils/type.utils'
import _get from 'lodash/get'

export type Option = {
  label: string
  value: string | unknown
}

type Config = {
  labelKey?: string
  valueKey?: string

  outputLabelKey?: string
  outputValueKey?: string
}

export const getSelectOptions = (
  options: string[] | NullablePartial<Option>[],
  config?: Config
) =>
  (options || []).map((option) => {
    const {
      valueKey = 'value',
      labelKey = 'label',

      outputValueKey = 'id',
      outputLabelKey = 'label'
    } = config || {}

    if (typeof option === 'string') {
      return {
        [outputValueKey]: option,
        [outputLabelKey]: option
      }
    }

    return {
      [outputValueKey]: _get(option, valueKey),
      [outputLabelKey]: _get(option, labelKey)
    }
  })
