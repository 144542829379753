import type {RootState} from 'clientDashboard/clientDashboard.store'
import {createSelector} from '@reduxjs/toolkit'
import _lowerCase from 'lodash/lowerCase'

/* Locations Selectors */
export const selectSaveStoreGroupModalData = (state: RootState) =>
  state.settings.locations?.saveStoreGroupModal || null

export const selectStoreGroups = (state: RootState) =>
  state.settings.locations?.storeGroups || {}

export const selectChildStoreGroups = (state: RootState) =>
  state.settings.locations?.childStoreGroups || {}

export const selectStoreGroupsByIdMap = (state: RootState) =>
  state.settings.locations?.storeGroupsByIdMap || {}

export const selectAncestorsStoreGroupsByStoreGroupIdMap = (state: RootState) =>
  state.settings.locations?.ancestorsStoreGroupsByStoreGroupIdMap || {}

export const selectLocations = (state: RootState) =>
  state.settings.locations?.locations

export const selectStoreNumbersAndNames = createSelector(
  selectLocations,
  (locations) => {
    const storeNames = [] as string[]
    const storeNumbers = locations?.map((location) => {
      storeNames.push(_lowerCase(location.name))
      return _lowerCase(location.storeNumber)
    }) || []

    return ({storeNumbers, storeNames})
  }
)
